<div class="table-header">
    <div class="title-container">
        <div
            class="link-count"
            *ngIf="!isLoading"
            translate
            [translateParams]="{ count: this.linksCount }"
        >
            UI.EntityLinks.linksCount
        </div>
        <div class="link-count" *ngIf="areLinksSuggestionsAvaible">
            <i class="glyph-robot2"></i>&nbsp;<span
                [innerHTML]="htmlLinksSuggestionsLabel"
                (click)="onLinksSuggestionsClick()"
            ></span>
        </div>
    </div>

    <button
        *ngIf="hasWriteAccess"
        mat-mini-fab
        (click)="createLinkedObject()"
        [matTooltip]="'UI.EntityLinks.btnNewLink' | translate"
    >
        <span class="glyph glyph-add"></span>
    </button>
</div>

<div *ngIf="isEmptyGrid" class="dxy-placeholder">
    <img
        src="/images/placeholders/linked-objects.svg"
        class="dxy-placeholder-img"
        alt="entity linked objects"
    />
    <div class="dxy-placeholder-text" translate>UI.EntityLinks.lblNoLinks</div>
</div>

<dxy-omni-grid
    *ngIf="showDataGrid"
    [data]="resultData"
    (onReady)="onGridReady($event)"
    (onRowClick)="onRowClick($event.data)"
    logId="entity-links"
></dxy-omni-grid>

<dxy-spinner *ngIf="isLoading" class="xl absolute-centered"></dxy-spinner>
