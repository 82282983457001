<ng-container *ngIf="buttonVisible$ | async">
    <button
        dxyButton
        size="small"
        translate
        [color]="(translated$ | async) ? 'secondary' : 'primary'"
        [disabled]="loading$ | async"
        (click)="translateEntity()"
    >
        <dxy-spinner
            *ngIf="loading$ | async"
            class="xs no-margin no-padding inverted-color"
        ></dxy-spinner>
        Multilingual.translateObject
    </button>
</ng-container>
