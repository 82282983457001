import { Injectable } from '@angular/core';
import { EntityServerTypeUtils } from '@datagalaxy/webclient/entity/utils';
import { ObjectLinkType, ServerType } from '@datagalaxy/dg-object-model';
import { EntityService } from '../shared/entity/services/entity.service';
import { EntityLinkUtils } from '../entity-links/entity-link.utils';
import { EntityLinkLoadMultiEntityAdapter } from '../entity-links/entity-link-load-multientity.adapter';
import { LineageNodeSpec } from './lineage.types';
import { CollectionsHelper } from '@datagalaxy/core-util';
import { EntityLinkService } from '../entity-links/entity-link.service';
import { getLocalId } from '@datagalaxy/webclient/utils';
import { SpaceIdentifier } from '@datagalaxy/webclient/workspace/utils';
import { ISpaceIdentifier } from '@datagalaxy/webclient/workspace/domain';
import { Filter, FilterOperator } from '@datagalaxy/webclient/filter/domain';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { ServerConstants } from '@datagalaxy/shared/server/domain';
import {
    EntityAttributeLinkGroup,
    EntityAttributeLinks,
} from '../entity-links/entity-links.types';
import { LineageNodeStreamDirection } from './lineage-entity-stream-buttons/lineage-entity-stream.types';
import { LineageEntityStreamUtils } from './lineage-entity-stream-buttons/lineage-entity-stream.utils';
import PropertyName = ServerConstants.PropertyName;

@Injectable({ providedIn: 'root' })
export class LineageApiService {
    constructor(
        private entityService: EntityService,
        private entityLinkService: EntityLinkService
    ) {}

    public async loadRoot(entity: EntityItem): Promise<LineageNodeSpec[]> {
        const spaceIdr = SpaceIdentifier.fromEntity(entity);
        return await this.getLineageLevelNodes([entity.ReferenceId], spaceIdr, {
            level: 2,
        });
    }

    public async getLineageNodes(
        entityReferenceIds: string[],
        spaceIdr: ISpaceIdentifier
    ): Promise<LineageNodeSpec[]> {
        if (!entityReferenceIds?.length) {
            return;
        }

        const [parents, children] = await Promise.all([
            this.loadParents(spaceIdr, entityReferenceIds),
            this.loadChildren(spaceIdr, entityReferenceIds),
        ]);

        const parentIds = parents.map((entity) => entity.ReferenceId);
        const childrenIds = children.Entities.map(
            (entity) => entity.ReferenceId
        );
        const allEntitiesIdr: string[] = entityReferenceIds
            .concat(parentIds)
            .concat(childrenIds);

        const chunks = this.splitArrayIntoChunks(allEntitiesIdr, 6, 1000);

        const res = await Promise.all(
            chunks.map((chunk) => this.loadLinks(spaceIdr, chunk))
        );
        const entityAttributeLinks = CollectionsHelper.flatten(res);

        await this.setupGoldenLinks(spaceIdr, entityAttributeLinks);

        return entityAttributeLinks.map((data) => {
            const hidden =
                parentIds.includes(data.entity.ReferenceId) &&
                !childrenIds.includes(data.entity.ReferenceId) &&
                !entityReferenceIds.includes(data.entity.ReferenceId);
            const parentId = data.entity.HddData.Parents[0].ReferenceId;

            return {
                entity: data.entity,
                parent: parentId !== data.entity.ReferenceId ? parentId : null,
                links: data.groups,
                hidden,
            };
        });
    }

    private async getLineageLevelNodes(
        entityReferenceIds: string[],
        spaceIdr: ISpaceIdentifier,
        options: {
            direction?: LineageNodeStreamDirection;
            level?: number;
        }
    ) {
        const res = await this.getLineageNodes(entityReferenceIds, spaceIdr);

        if (options?.level > 1) {
            const linkedEntities = res
                .flatMap((item) =>
                    this.getDirectedLinksIds(item.links, options?.direction)
                )
                .filter(
                    (item) => !res.some((d) => d.entity.ReferenceId === item)
                );

            if (linkedEntities.length) {
                const neighboursRes = await this.getLineageLevelNodes(
                    CollectionsHelper.distinct(linkedEntities),
                    spaceIdr,
                    {
                        ...options,
                        level: options.level - 1,
                    }
                );
                res.push(...neighboursRes);
            }
        }

        return res;
    }

    private async loadChildren(
        spaceIdr: SpaceIdentifier,
        entitiesIdr: string[]
    ) {
        const filter = new Filter(
            PropertyName.Parents,
            FilterOperator.ListContains,
            entitiesIdr
        );

        return this.loadEntities(spaceIdr, filter);
    }

    private async loadParents(
        spaceIdr: SpaceIdentifier,
        entitiesIdr: string[]
    ) {
        const chunks = this.splitArrayIntoChunks(entitiesIdr, 6, 1000);

        const res = await Promise.all(
            chunks.map((chunk) => {
                const filter = new Filter(
                    PropertyName.FilterDescendentId,
                    FilterOperator.ListContains,
                    chunk
                );

                return this.loadEntities(spaceIdr, filter);
            })
        );

        return CollectionsHelper.flatten(res.map((r) => r.Entities));
    }

    private async loadEntities(spaceIdr: ISpaceIdentifier, filter: Filter) {
        return this.entityService.loadMultiEntity({
            dataTypes: [
                ...EntityServerTypeUtils.firstClassEntityServerTypes,
                ServerType.DataProcessingItem,
            ],
            parentReferenceId: spaceIdr.spaceId,
            versionId: spaceIdr.versionId,
            includeHdd: false,
            filters: [filter],
            includedAttributesFilter: [
                PropertyName.DisplayName,
                PropertyName.TechnicalName,
                PropertyName.LogicalParentId,
                PropertyName.EntityType,
            ],
        });
    }

    private async loadLinks(spaceIdr: SpaceIdentifier, entitiesIdr: string[]) {
        if (!entitiesIdr?.length) {
            return [];
        }

        const linkAttributes = EntityLinkUtils.directedLinkTypes.map(
            (lt) => `ObjectLinks_${ObjectLinkType[lt]}`
        );
        const res = await this.entityService.loadMultiEntity({
            dataTypes: [
                ...EntityServerTypeUtils.firstClassEntityServerTypes,
                ServerType.DataProcessingItem,
            ],
            parentReferenceId: spaceIdr.spaceId,
            versionId: spaceIdr.versionId,
            includeHdd: false,
            dataReferenceIdList: entitiesIdr,
            includeHData: false,
            includedAttributesFilter: [
                ...linkAttributes,
                PropertyName.DisplayName,
                PropertyName.TechnicalName,
                PropertyName.LogicalParentId,
                PropertyName.EntityType,
            ],
        });

        return EntityLinkLoadMultiEntityAdapter.convert(res);
    }

    private splitArrayIntoChunks<T>(
        array: T[],
        numChunks: number,
        minItemsPerChunk: number
    ): T[][] {
        let chunks = [];
        let chunkSize = Math.max(
            Math.ceil(array.length / numChunks),
            minItemsPerChunk
        );

        for (let i = 0; i < array.length; i += chunkSize) {
            chunks.push(array.slice(i, i + chunkSize));
        }

        return chunks.filter((chunk) => chunk.length > 0);
    }

    private async setupGoldenLinks(
        spaceIdr: ISpaceIdentifier,
        entityAttributeLinks: EntityAttributeLinks[]
    ) {
        const ids = new Set<string>();

        entityAttributeLinks.forEach((eal) => {
            eal.groups.forEach((group) => {
                if (
                    group.linkType === ObjectLinkType.Implements ||
                    group.linkType === ObjectLinkType.IsImplementedBy
                ) {
                    ids.add(eal.entity.ReferenceId);
                    group.entityIds.forEach((entityId) => ids.add(entityId));
                }
            });
        });

        const goldenLinks = await this.entityLinkService.getEntitiesGoldenLinks(
            spaceIdr,
            Array.from(ids)
        );

        if (!goldenLinks) {
            return;
        }

        const allGoldenLinks = [];

        goldenLinks.forEach((goldenLink) => {
            allGoldenLinks.push({
                sourceGuid: goldenLink.targetGuid,
                targetGuid: goldenLink.sourceGuid,
            });
        });

        allGoldenLinks.push(...goldenLinks);

        allGoldenLinks?.forEach((goldenLink) => {
            const eal = entityAttributeLinks.find(
                (e) =>
                    getLocalId(e.entity.ReferenceId) === goldenLink.sourceGuid
            );
            const group = eal?.groups.find((group) =>
                group.entityIds
                    .map((entityId) => getLocalId(entityId))
                    .includes(goldenLink.targetGuid)
            );

            if (group) {
                if (!group.goldenIds) {
                    group.goldenIds = [];
                }

                group.goldenIds.push(goldenLink.targetGuid);
            }
        });
    }

    private getDirectedLinksIds(
        links: EntityAttributeLinkGroup[],
        direction: LineageNodeStreamDirection
    ): string[] {
        const directedLinkGroup = direction
            ? LineageEntityStreamUtils.makeStreamLinkGroup(links, direction)
            : links;

        return directedLinkGroup.flatMap((group) => group.entityIds);
    }
}
