import {
    deserialize,
    deserializeAs,
    inheritSerialization,
    serialize,
    serializeAs,
} from 'cerialize';
import { HierarchicalData, ObjectLinkType } from '@datagalaxy/dg-object-model';
import { ABaseDiagramDto, ABaseDiagramItem } from '../diagram';

export enum DiagramEdgeKind {
    undefined = 0,
    EntityLink,
    ForeignKey,
}

@inheritSerialization(ABaseDiagramDto)
export class DiagramEdgeDto extends ABaseDiagramDto {
    @deserialize EdgeKind!: DiagramEdgeKind; // deserialized as number

    /** ReferenceId of the source DiagramNodeDto */
    @deserialize SourceNodeId!: string;
    /** ReferenceId of the target DiagramNodeDto */
    @deserialize TargetNodeId!: string;

    @deserialize DisplayName?: string;
    @deserialize Description?: string;

    /** ReferenceId of actual EntityLink - Set only if EdgeType is DiagramEdgeType.EntityLink */
    @deserialize EntityLinkId?: string;
    /** Link type of EntityLink
     * Note: Will only be set if EdgeType is EntityLink */
    @deserializeAs(ObjectLinkType) ObjectLinkType?: ObjectLinkType;

    /** ReferenceId - Set only if EdgeType is DiagramEdgeType.ForeignKey */
    @deserialize ForeignKeyId?: string;
    /** Set only if EdgeType is DiagramEdgeType.ForeignKey */
    @deserializeAs(HierarchicalData)
    ForeignKeyHierarchicalData!: HierarchicalData;

    @deserialize VisualData?: string;
    @deserialize LinkGeometryData?: string;
}

@inheritSerialization(ABaseDiagramItem)
export class DiagramEdgeItem extends ABaseDiagramItem {
    public static readonly keys: (keyof DiagramEdgeItem)[] = [
        ...ABaseDiagramItem.baseKeys,
        'DisplayName',
        'Description',
        'EdgeKind',
        'SourceNodeId',
        'TargetNodeId',
        'EntityLinkId',
        'ForeignKeyId',
        'VisualData',
        'LinkGeometryData',
        'ObjectLinkType',
    ];

    @serialize DisplayName?: string;
    @serialize Description?: string;
    @serializeAs(DiagramEdgeKind) EdgeKind?: DiagramEdgeKind;
    // Note: ReferenceId of the source DiagramNodeDto (mandatory on creation)
    @serialize SourceNodeId?: string;
    // Note: ReferenceId of the target DiagramNodeDto (mandatory on creation)
    @serialize TargetNodeId?: string;

    // Note: ReferenceId of actual EntityLink
    // Note: Used only when EdgeKind is EntityLink (mandatory on creation in that case)
    @serialize EntityLinkId?: string;
    // Note: Used only when EdgeKind is EntityLink
    @serialize ObjectLinkType?: ObjectLinkType;

    // Note: ReferenceId
    // Note: Used only when EdgeKind is ForeignKey (mandatory on creation in that case)
    @serialize ForeignKeyId?: string;

    @serialize VisualData?: string;
    @serialize LinkGeometryData?: string;
}
