import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { IListOption } from '@datagalaxy/core-ui';
import { ViewIdentifier } from '../../shared/util/ViewIdentifier';
import { EntityUiService } from '../../shared/entity/services/entity-ui.service';
import { CurrentSpaceService } from '../../services/currentSpace.service';
import { EntityCreationOrigin } from '../../shared/entity/entity.types';
import { BehaviorSubject } from 'rxjs';
import { AppEventsService } from '../../services/AppEvents.service';
import { StateService } from '@uirouter/core';
import { DxyBaseComponent } from '@datagalaxy/ui/core';

@Component({
    selector: 'dxy-nav-sidebar-item',
    templateUrl: './dxy-nav-sidebar-item.component.html',
    styleUrls: ['./dxy-nav-sidebar-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DxyNavSidebarItemComponent
    extends DxyBaseComponent
    implements OnInit
{
    @Input() stateId: string;
    @Input() itemIdentifier: string;
    @Input() glyphClass: string;
    @Input() dtName: string;
    @Input() showTooltip = false;

    @Input() menuOptions: IListOption[] = null;
    @Input() menuClass: string;

    public isActive$ = new BehaviorSubject(false);

    @Output() onMatMenuTrigger = new EventEmitter();
    @Output() onNavigation = new EventEmitter<string>();
    public get dtNameFull() {
        return `Side Bar - ${this.dtName}`;
    }
    public get translateKey(): string {
        return `UI.NavSideBar.${this.itemIdentifier}`;
    }
    public get isGlyphUndefined() {
        return !this.glyphClass;
    }
    public get logModule() {
        const prjOrgPrefix = this.currentSpaceService.getFuncLogPrefix();
        switch (this.itemIdentifier) {
            case ViewIdentifier.ClientDashboard:
                return 'MAIN_DASHBOARD,R';
            case ViewIdentifier.Glossary:
                return `MAIN_${prjOrgPrefix}GLOSSARY,R`;
            case ViewIdentifier.Modeler:
                return `MAIN_${prjOrgPrefix}DICTIONARY,R`;
            case ViewIdentifier.DataProcessing:
                return `MAIN_${prjOrgPrefix}DATAPROCESSING,R`;
            case ViewIdentifier.ImpactAnalysis:
                return `MAIN_${prjOrgPrefix}IMPACT_ANALYSIS,R`;
            case ViewIdentifier.Diagrams:
                return `MAIN_${prjOrgPrefix}DIAGRAMS,R`;
            case ViewIdentifier.Software:
                return `MAIN_${prjOrgPrefix}USES,R`;
            case ViewIdentifier.ClientTasks:
                return 'MAIN_TASK_SCREEN,R';
            case ViewIdentifier.SpaceSettings:
                return `MAIN_${prjOrgPrefix}SETTINGS,R`;
            case ViewIdentifier.Campaigns:
                return `MAIN_${prjOrgPrefix}CAMPAIGNS,R`;
        }
    }

    public get matTooltipContent() {
        return this.showTooltip ? this.translateKey : null;
    }

    constructor(
        private entityUiService: EntityUiService,
        private currentSpaceService: CurrentSpaceService,
        private appEvents: AppEventsService,
        private stateService: StateService
    ) {
        super();
    }

    ngOnInit(): void {
        this.stateId &&
            this.registerSubscription(
                this.appEvents.stateChanged$.subscribe((newStateId) => {
                    this.isActive$.next(newStateId.includes(this.stateId));
                })
            ) &&
            this.stateService.includes(this.stateId) &&
            this.isActive$.next(true);
    }

    public async onClick() {
        this.log('onClick', this.itemIdentifier);

        if (this.menuOptions) {
            this.onMatMenuTrigger.next(void 0);
            return;
        }

        switch (this.itemIdentifier) {
            case ViewIdentifier.GUIMenuExpand:
            case ViewIdentifier.GUIMenuCollapse:
                break;

            case ViewIdentifier.Create:
                await this.entityUiService.openCreationModal(
                    EntityCreationOrigin.globalAddButton
                );
                break;

            default:
                this.onNavigation.next(this.itemIdentifier);
        }
    }
}
