import { Injectable } from '@angular/core';
import { BackendApiService } from '@datagalaxy/data-access';
import {
    DiagramSourceParameter,
    DiagramSourceResult,
    GetDiagramContentParameter,
    GetDiagramContentResult,
    UpdateDiagramContentParameter,
    UpdateDiagramContentResult,
} from './diagram';

@Injectable({ providedIn: 'root' })
export class DiagramApiService {
    constructor(private backendApiService: BackendApiService) {}

    public async getDiagramContent(param: GetDiagramContentParameter) {
        return await this.backendApiService.postPromise(
            'Diagram/GetDiagramContent',
            param,
            GetDiagramContentResult
        );
    }

    public async updateDiagramContent(param: UpdateDiagramContentParameter) {
        return await this.backendApiService.postPromise(
            'Diagram/UpdateDiagramContent',
            param,
            UpdateDiagramContentResult
        );
    }

    /** https://dev-ci.datagalaxy.com/swagger/index.html#/Diagram/Diagram_AddDiagramSources */
    public async addDiagramSources(param: DiagramSourceParameter) {
        return await this.backendApiService.postPromise(
            'Diagram/AddDiagramSources',
            param,
            DiagramSourceResult
        );
    }
}
