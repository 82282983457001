import { getBootLogger, logStates } from './boot';
const logBoot = getBootLogger('main.ts');
logBoot('IN');

//#region Angular
import '@angular/compiler';
import { environment } from './environments/environment';
import { ApplicationRef, enableProdMode, NgModuleRef } from '@angular/core';
import { LegacyAppModule } from './app/app.module.legacy';
import { Category, trace } from '@uirouter/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { CoreUtil } from '@datagalaxy/core-util';
import { enableDebugTools } from '@angular/platform-browser';
import { AppModule } from './app/app.module';
import { APP_CONFIG, AppConfig } from '@datagalaxy/webclient/config';
//#endregion

logBoot('start');
CoreUtil.isProduction = environment.production;
console.log(environment.name);

let isLocalDevEnv = false;
if (!environment.production) {
    isLocalDevEnv = environment.name == 'development';
    loadDevStyleSheets();
}
window.addEventListener('load', () => {
    logBoot('window.loaded');
    void main();
});

async function main() {
    logBoot('main-in');

    Zone.currentTask.source = 'load-config';
    const config = await AppConfig.load(logBoot);

    Zone.currentTask.source = 'main-init';

    if (environment.production) {
        enableProdMode();
    }

    setUiRouterTrace();

    const authV2 = config.appConfig.enableAuthV2;
    if (authV2 === 'true') {
        platformBrowserDynamic([{ provide: APP_CONFIG, useValue: config }])
            .bootstrapModule(AppModule)
            .then((module) => {
                logBoot(`${AppModule.name} bootstrapped`);
                activateDebugTools(module);
            })
            .catch((err) =>
                console.error(
                    `${AppModule.name} bootstrap error`,
                    err,
                    AppModule
                )
            );
    } else {
        platformBrowserDynamic([{ provide: APP_CONFIG, useValue: config }])
            .bootstrapModule(LegacyAppModule)
            .then((module) => {
                logBoot(`${LegacyAppModule.name} bootstrapped`);
                activateDebugTools(module);
            })
            .catch((err) =>
                console.error(
                    `${LegacyAppModule.name} bootstrap error`,
                    err,
                    LegacyAppModule
                )
            );
    }

    logBoot('main-out');
}

function loadDevStyleSheets() {
    // Append temporary stylesheets to index.html head
    [
        //'https://www.datagalaxy.com/wp-content/svg/dng-staging-studio.css'
    ].forEach((href) => {
        const link = document.createElement('link');
        link.href = href;
        link.rel = 'stylesheet';
        document.head.appendChild(link);
    });
}

function setUiRouterTrace() {
    if (!logStates?.length) {
        return;
    }
    logBoot('setUiRouterTrace', logStates.map((c) => Category[c]).join(', '));
    trace.enable(...logStates);
}

function activateDebugTools(module: NgModuleRef<unknown>) {
    if (!isLocalDevEnv) {
        return;
    }
    enableDebugTools(module.injector.get(ApplicationRef).components[0]);
    console.log(`
Angular debugTools enabled. You can use:
ng.profiler.timeChangeDetection()`);
}

logBoot('end');
logBoot('OUT');
