import { IDisplayedDataGroup } from '@datagalaxy/core-ui/omnigrid';

export class ResultDataGroup implements IDisplayedDataGroup {
    /* filtered objects */
    public objects: any[];

    get rawCount() {
        return this._objects?.length ?? 0;
    }
    get count() {
        return this.objects?.length ?? 0;
    }

    constructor(
        public key: string,
        private _objects: any[],
        public displayName: string,
        public sortIndex = 0,
        public glyphClassName?: string
    ) {
        this.objects = this._objects;
    }
}
