<dxy-spinner
    *ngIf="isLoading"
    [message]="
        'Import.GenericImportWizard.ConnectionForm.ObjectsSelection.loadingMessage'
            | translate
    "
></dxy-spinner>

<ng-container *ngIf="!isLoading">
    <span class="title">{{ titleText }}</span>
    <mat-radio-group
        [(ngModel)]="isSelectAll"
        (ngModelChange)="onChangeSelectAll()"
        name="is-private"
    >
        <mat-radio-button [value]="true">
            <span translate
                >Import.GenericImportWizard.ConnectionForm.ObjectsSelection.radioSelectAll</span
            >
        </mat-radio-button>
        <mat-radio-button [value]="false">
            <span translate
                >Import.GenericImportWizard.ConnectionForm.ObjectsSelection.radioCustomSelection</span
            >
        </mat-radio-button>
    </mat-radio-group>

    <dxy-search-input
        class="no-border-top"
        [(searchString)]="searchString"
        (onSearchString)="onSearchTermChange()"
    ></dxy-search-input>
    <dxy-omni-grid
        [data]="omniGridData"
        (onReady)="onReadyGrid($event)"
        (onSelectionChange)="onSelectionChange()"
        [class.disabled-selection]="isSelectAll"
    ></dxy-omni-grid>
</ng-container>
