import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { BaseService } from '@datagalaxy/core-ui';
import { Injectable } from '@angular/core';
import {
    EntityCommentaryDTO,
    EntityTaskDTO,
    IDataIdentifier,
    ServerType,
} from '@datagalaxy/dg-object-model';
import { EntityCreationOrigin, IEntityCreationContext } from '../entity.types';
import { RealTimeCommService } from '../../../services/realTimeComm.service';
import {
    DeleteEntityParameter,
    SetEntitiesParentResult,
    UpdateEntityLinkResult,
} from '@datagalaxy/webclient/entity/data-access';
import { DeleteEntityTasksParameter } from '@datagalaxy/webclient/task/data-access';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';

@Injectable({ providedIn: 'root' })
export class EntityEventService extends BaseService {
    private entityChanged = new Subject<IServerTypeEventArg<EntityItem>>();
    private entityCreated = new Subject<
        IServerTypeEventArg<EntityCreateEventData>
    >();
    private entityUpdated = new Subject<IServerTypeEventArg<EntityItem>>();
    private entityTechnologyUpdated = new Subject<
        IServerTypeEventArg<EntityItem>
    >();
    private entityBulkUpdated = new Subject<
        IServerTypeEventArg<EntityItem[]>
    >();
    private entityDeleted = new Subject<
        IServerTypeEventArg<EntityDeleteEventData>
    >();
    private entityParentUpdated = new Subject<
        IServerTypeEventArg<SetEntitiesParentResult>
    >();
    private entitySecurityUpdated = new Subject<
        IServerTypeEventArg<EntityItem>
    >();

    private entityLinkCreated = new Subject<IServerTypeEventArg<EntityItem>>();
    private entityLinkIdentifierAdded = new Subject<
        IServerTypeEventArg<IDataIdentifier>
    >();
    private entityLinkDeleted = new Subject<IServerTypeEventArg<EntityItem>>();
    private entityLinkIdentifierDeleted = new Subject<
        IServerTypeEventArg<IDataIdentifier>
    >();
    private entityLinkGoldenUpdated = new Subject<
        IRealtimeEventArg<UpdateEntityLinkResult>
    >();

    private taskCreated = new Subject<IRealtimeEventArg<EntityTaskDTO>>();
    private taskUpdated = new Subject<IRealtimeEventArg<EntityTaskDTO>>();
    private taskDeleted = new Subject<
        IRealtimeEventArg<DeleteEntityTasksParameter>
    >();

    private commentCreated = new Subject<
        IRealtimeEventArg<EntityCommentaryDTO>
    >();

    private entityFieldsUpdated = new Subject<EntityItemEventArg>();

    constructor(private realTimeCommService: RealTimeCommService) {
        super();
        this.subscribeExternalEntityEvents();
        this.subscribeExternalTaskEvents();
    }

    //#region EntityItem

    /** when a different entity is selected in the dashboard.
     *
     * Note: For detecting changes on an entity, use *subscribeEntityUpdate* instead */
    public subscribeEntityChange(
        serverType: ServerType,
        handler: IEntityItemEventHandler
    ) {
        return this.subscribeEventFiltered(
            this.entityChanged,
            serverType,
            handler
        );
    }
    public notifyEntityChange(entity: EntityItem, external: boolean = false) {
        this.entityChanged.next(new EntityItemEventArg(entity, external));
    }

    public subscribeEntityCreationLocal(
        serverType: ServerType,
        handler: IEntityCreationEventHandler<EntityItem>
    ) {
        return this.subscribeEntityCreation(
            serverType,
            (data, external) =>
                !external && handler(data.entity, false, data.context)
        );
    }
    public subscribeEntityCreation(
        serverType: ServerType,
        handler: IRealTimeEventHandler<EntityCreateEventData>
    ) {
        return serverType === null
            ? this.subscribeEvent(this.entityCreated, handler)
            : this.subscribeEventFiltered(
                  this.entityCreated,
                  serverType,
                  handler
              );
    }
    public notifyEntityCreation(
        entityCreateEventData: EntityCreateEventData,
        external: boolean = false
    ) {
        this.entityCreated.next(
            new ServerTypeFilteredEventArg(
                entityCreateEventData.entity.ServerType,
                entityCreateEventData,
                external
            )
        );
    }

    /** Note: *null* (not *undefined*) ServerType will subscribe to every ServerType values */
    public subscribeEntityUpdate(
        serverType: ServerType,
        handler: IEntityItemEventHandler
    ) {
        return serverType === null
            ? this.subscribeEvent(this.entityUpdated, handler)
            : this.subscribeEventFiltered(
                  this.entityUpdated,
                  serverType,
                  handler
              );
    }
    public notifyEntityUpdate(entity: EntityItem, external = false) {
        this.entityUpdated.next(new EntityItemEventArg(entity, external));
    }

    public subscribeEntityTechnologyUpdate(
        serverType: ServerType,
        handler: IEntityItemEventHandler
    ) {
        return this.subscribeEventFiltered(
            this.entityTechnologyUpdated,
            serverType,
            handler
        );
    }
    public notifyEntityTechnologyUpdate(
        entity: EntityItem,
        external: boolean = false
    ) {
        this.entityTechnologyUpdated.next(
            new EntityItemEventArg(entity, external)
        );
    }

    public subscribeEntityBulkUpdate(
        serverType: ServerType,
        handler: IRealTimeEventHandler<EntityItem[]>
    ) {
        return serverType === null
            ? this.subscribeEvent(this.entityBulkUpdated, handler)
            : this.subscribeEventFiltered(
                  this.entityBulkUpdated,
                  serverType,
                  handler
              );
    }
    public notifyEntityBulkUpdateEvent(
        entities: EntityItem[],
        external: boolean = false
    ) {
        if (!entities.length) {
            return;
        }
        this.entityBulkUpdated.next(
            new ServerTypeFilteredEventArg(
                entities[0].ServerType,
                entities,
                external
            )
        );
    }

    public subscribeEntityDelete(
        serverType: ServerType,
        handler: IRealTimeEventHandler<EntityDeleteEventData>
    ) {
        return this.subscribeEventFiltered(
            this.entityDeleted,
            serverType,
            handler
        );
    }
    public notifyEntityDelete(
        serverType: ServerType,
        entityEventData: EntityDeleteEventData,
        external: boolean = false
    ) {
        this.entityDeleted.next(
            new ServerTypeFilteredEventArg(
                serverType,
                entityEventData,
                external
            )
        );
    }

    public subscribeEntityParentUpdate(
        serverType: ServerType,
        handler: IRealTimeEventHandler<SetEntitiesParentResult>
    ) {
        return this.subscribeEventFiltered(
            this.entityParentUpdated,
            serverType,
            handler
        );
    }
    public notifyEntityParentUpdate(
        setEntitiesParentResult: SetEntitiesParentResult,
        external: boolean = false
    ) {
        this.entityParentUpdated.next(
            new ServerTypeFilteredEventArg(
                setEntitiesParentResult.UpdatedEntities[0].ServerType,
                setEntitiesParentResult,
                external
            )
        );
    }

    public subscribeEntitySecurityUpdate(
        serverType: ServerType,
        handler: IEntityItemEventHandler
    ) {
        return this.subscribeEventFiltered(
            this.entitySecurityUpdated,
            serverType,
            handler
        );
    }
    public notifyEntitySecurityUpdate(
        entity: EntityItem,
        external: boolean = false
    ) {
        this.entitySecurityUpdated.next(
            new EntityItemEventArg(entity, external)
        );
    }

    //#endregion EntityItem

    //#region EntityLink

    public subscribeEntityLinkAdd(
        serverType: ServerType,
        handler: IDataIdentifierEventHandler<EntityItem>
    ) {
        return serverType === null
            ? this.subscribeEvent(this.entityLinkCreated, handler)
            : this.subscribeEventFiltered(
                  this.entityLinkCreated,
                  serverType,
                  handler
              );
    }
    public notifyEntityLinkAdd(
        updatedEntity: EntityItem,
        external: boolean = false
    ) {
        this.log('notifyEntityLinkAdd', external, updatedEntity);
        this.entityLinkCreated.next(
            new EntityItemEventArg(updatedEntity, external)
        );
    }

    public subscribeEntityLinkUpdateGoldenLink(
        handler: IRealTimeEventHandler<UpdateEntityLinkResult>
    ) {
        return this.subscribeEvent(this.entityLinkGoldenUpdated, handler);
    }
    public notifyEntityLinkUpdateGoldenLink(
        updatedEntityLinks: UpdateEntityLinkResult,
        external: boolean = false
    ) {
        this.log(
            'notifyEntityLinkUpdateGoldenLink',
            external,
            updatedEntityLinks
        );
        this.entityLinkGoldenUpdated.next(
            new RealTimeEventArg(updatedEntityLinks, false)
        );
    }

    public subscribeEntityLinkIdentifierAdd(
        serverType: ServerType,
        handler: IDataIdentifierEventHandler<IDataIdentifier>
    ) {
        return this.subscribeEventFiltered(
            this.entityLinkIdentifierAdded,
            serverType,
            handler
        );
    }
    public notifyEntityLinkIdentifierAdd(
        updatedIdentifier: IDataIdentifier,
        external: boolean = false
    ) {
        const st = ServerType[updatedIdentifier.DataTypeName];
        this.log(
            'notifyEntityLinkIdentifierAdd',
            ServerType[st],
            external,
            updatedIdentifier
        );
        this.entityLinkIdentifierAdded.next(
            new ServerTypeFilteredEventArg(st, updatedIdentifier, external)
        );
    }

    public subscribeEntityLinkDelete(
        serverType: ServerType,
        handler: IDataIdentifierEventHandler<EntityItem>
    ) {
        return serverType === null
            ? this.subscribeEvent(this.entityLinkDeleted, handler)
            : this.subscribeEventFiltered(
                  this.entityLinkDeleted,
                  serverType,
                  handler
              );
    }
    public notifyEntityLinkDelete(
        updatedEntity: EntityItem,
        external: boolean = false
    ) {
        this.log('notifyEntityLinkDelete', external, updatedEntity);
        this.entityLinkDeleted.next(
            new EntityItemEventArg(updatedEntity, external)
        );
    }

    public subscribeEntityLinkIdentifierDelete(
        serverType: ServerType,
        handler: IDataIdentifierEventHandler<IDataIdentifier>
    ) {
        return this.subscribeEventFiltered(
            this.entityLinkIdentifierDeleted,
            serverType,
            handler
        );
    }
    public notifyEntityLinkDeleteIdentifier(
        updatedIdentifier: IDataIdentifier,
        external: boolean = false
    ) {
        this.log(
            'notifyEntityLinkDeleteIdentifier',
            external,
            updatedIdentifier
        );
        this.entityLinkIdentifierDeleted.next(
            new ServerTypeFilteredEventArg(
                ServerType[updatedIdentifier.DataTypeName],
                updatedIdentifier,
                external
            )
        );
    }

    //#endregion EntityLink

    //#region ObjectTask

    public subscribeTaskCreation(
        handler: IRealTimeEventHandler<EntityTaskDTO>
    ) {
        return this.subscribeEvent(this.taskCreated, handler);
    }
    public notifyTaskCreation(task: EntityTaskDTO, external: boolean = false) {
        this.taskCreated.next(new RealTimeEventArg(task, external));
    }

    public subscribeTaskUpdate(handler: IRealTimeEventHandler<EntityTaskDTO>) {
        return this.subscribeEvent(this.taskUpdated, handler);
    }
    public notifyTaskUpdate(task: EntityTaskDTO, external: boolean = false) {
        this.taskUpdated.next(new RealTimeEventArg(task, external));
    }

    public subscribeTaskDelete(
        handler: IRealTimeEventHandler<DeleteEntityTasksParameter>
    ) {
        return this.subscribeEvent(this.taskDeleted, handler);
    }
    public notifyTaskDelete(
        deleteParam: DeleteEntityTasksParameter,
        external: boolean = false
    ) {
        this.taskDeleted.next(new RealTimeEventArg(deleteParam, external));
    }

    //#endregion ObjectTask

    //#region Commentary

    public subscribeCommentaryCreation(
        handler: IRealTimeEventHandler<EntityCommentaryDTO>
    ) {
        return this.subscribeEvent(this.commentCreated, handler);
    }
    public notifyCommentaryCreation(
        comment: EntityCommentaryDTO,
        external: boolean = false
    ) {
        this.commentCreated.next(new RealTimeEventArg(comment, external));
    }

    //#endregion Commentary

    //#region EntityField

    public subscribeEntityFieldsUpdate(
        serverType: ServerType,
        handler: IEntityItemEventHandler
    ) {
        return this.subscribeEventFiltered(
            this.entityFieldsUpdated,
            serverType,
            handler
        );
    }

    public notifyEntityFieldsUpdate(
        updatedEntity: EntityItem,
        external: boolean = false
    ) {
        this.entityFieldsUpdated.next(
            new EntityItemEventArg(updatedEntity, external)
        );
    }

    //#endregion EntityField

    private subscribeEventFiltered<T>(
        observable: Observable<IServerTypeEventArg<T>>,
        serverType: ServerType,
        handler: (data: T, external?: boolean) => void
    ) {
        const filtered = observable.pipe(
            filter((eventArg) => eventArg.serverType === serverType)
        );
        return this.subscribeEvent(filtered, handler);
    }

    private subscribeEvent<T>(
        observable: Observable<IRealtimeEventArg<T>>,
        handler: (data: T, external?: boolean) => void
    ) {
        return observable.subscribe({
            next: (eventArg) => handler(eventArg.data, eventArg.external),
        });
    }

    private subscribeExternalEntityEvents() {
        this.realTimeCommService.subscribeDeleteEntityEvent(
            (userData: any, deleteEntityParameter: DeleteEntityParameter) =>
                this.notifyEntityDelete(
                    ServerType[deleteEntityParameter.DataTypeName],
                    new EntityDeleteEventData(
                        deleteEntityParameter.DataReferenceIdList
                    ),
                    true
                )
        );

        this.realTimeCommService.subscribeCreateEntityEvent(
            (userData: any, createdEntity: EntityItem) =>
                this.notifyEntityCreation(
                    new EntityCreateEventData(createdEntity),
                    true
                )
        );

        this.realTimeCommService.subscribeUpdateEntity(
            (userData: any, updatedEntity: EntityItem) =>
                this.notifyEntityUpdate(updatedEntity, true)
        );

        this.realTimeCommService.subscribeBulkUpdateEntity(
            (userData: any, updatedEntities: EntityItem[]) =>
                this.notifyEntityBulkUpdateEvent(updatedEntities, true)
        );

        this.realTimeCommService.subscribeUpdateEntityParentEvent(
            (userData: any, setEntitiesParentResult: SetEntitiesParentResult) =>
                this.notifyEntityParentUpdate(setEntitiesParentResult, true)
        );
    }

    private subscribeExternalTaskEvents() {
        this.realTimeCommService.subscribeUpdateTask(
            (userData: any, task: EntityTaskDTO) =>
                this.notifyTaskUpdate(task, true)
        );

        this.realTimeCommService.subscribeDeleteTask(
            (userData: any, deleteParameter: DeleteEntityTasksParameter) =>
                this.notifyTaskDelete(deleteParameter, true)
        );
    }
}

export class EntityDeleteEventData {
    constructor(
        public deletedIds: string[],
        public deletedTotalCount?: number
    ) {}
}

export class EntityCreateEventData {
    public readonly context: IEntityCreationContext;
    constructor(
        public entity: EntityItem,
        /** from entity-create-modal, true when the *create another* check-box is checked */
        isMultiCreation?: boolean,
        origin?: EntityCreationOrigin
    ) {
        this.context = { origin, isMultiCreation };
    }
}

export type IRealTimeEventHandler<T> = (data: T, external?: boolean) => void;
export type IDataIdentifierEventHandler<T extends IDataIdentifier> =
    IRealTimeEventHandler<T>;
export type IEntityItemEventHandler = IDataIdentifierEventHandler<EntityItem>;
export type IEntityCreationEventHandler<T extends IDataIdentifier> = (
    data: T,
    external?: boolean,
    context?: IEntityCreationContext
) => void;

export interface IRealtimeEventArg<T> {
    data: T;
    /** true means the event has not been emitted locally (ie comes from the bask-office) */
    external?: boolean;
}
export interface IServerTypeEventArg<TData> extends IRealtimeEventArg<TData> {
    serverType: ServerType;
}

export class RealTimeEventArg<T> implements IRealtimeEventArg<T> {
    constructor(public data: T, public external?: boolean) {}
}
class ServerTypeFilteredEventArg<T> extends RealTimeEventArg<T> {
    constructor(public serverType: ServerType, data: T, external?: boolean) {
        super(data, external);
    }
}
class EntityItemEventArg extends ServerTypeFilteredEventArg<EntityItem> {
    constructor(public data: EntityItem, external?: boolean) {
        super(data.ServerType, data, external);
    }
}
