import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import {
    CoreEventsService,
    IFunctionalEvent,
    UiSpinnerService,
} from '@datagalaxy/core-ui';
import { AppDataService } from '../../services/app-data.service';
import { NavigationService } from '../../services/navigation.service';
import { VersioningUiService } from '../../versioning/services/versioning-ui.service';
import { AppEventsService } from '../../services/AppEvents.service';
import { DxyModalService } from '../../shared/dialogs/DxyModalService';
import { VersioningEventService } from '../../versioning/services/versioning-event.service';
import { EntityUiService } from '../../shared/entity/services/entity-ui.service';
import { DashboardService } from '../../widgets-dashboard/dashboard.service';
import {
    RealTimeCommService,
    UpdateScreenRtData,
} from '../../services/realTimeComm.service';
import { EntityCreationOrigin } from '../../shared/entity/entity.types';
import { ProjectVersion } from '@datagalaxy/webclient/versioning/data-access';
import { FreshDeskService } from '../../shared/support/freshdesk/fresh-desk.service';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { SecurityService } from '../../services/security.service';
import { FunctionalLogService } from '@datagalaxy/webclient/monitoring/data-access';
import {
    CurrentUserService,
    CurrentUserTimeoutService,
} from '@datagalaxy/webclient/user/feature/current-user';
import { LegacyLoginService } from '../../services/login.service';
import { DataIdentifier } from '@datagalaxy/dg-object-model';
import { DialogType } from '@datagalaxy/ui/dialog';
import { CurrentSpaceService } from '../../services/currentSpace.service';
import { EntityPreviewPanelService } from '../../shared/entity/services/entity-preview-panel.service';
import { RtAttributeDTO } from '@datagalaxy/webclient/attribute/domain';
import { ClientAdminAttributeService } from '../../client-admin-attribute/client-admin-attribute.service';
import { ScreenService } from '../../screens/screen.service';
import { AttributeDataService } from '../../shared/attribute/attribute-data.service';
import { UserService } from '../../services/user.service';
import { NavigationApiService } from '../../navigation/services/navigation-api.service';
import { ToasterService } from '../../services/toaster.service';
import { FeatureFlagService } from '@datagalaxy/webclient/feature-flag';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-client-main',
    templateUrl: './client-main.component.html',
    styleUrls: ['./client-main.component.scss'],
})
export class ClientMainComponent
    extends DxyBaseComponent
    implements OnInit, OnDestroy, AfterViewInit
{
    public isSpotlightInputHidden = false;
    public get isSpotlightInputVisible() {
        return (
            this.showSpotlightInput && !this.navigationService.isInSpaceHomeView
        );
    }
    public get isSpinnerActive$() {
        return this.uiSpinnerService.isSpinnerActive$;
    }
    public get translatedMessage$() {
        return this.uiSpinnerService.translatedMessage$;
    }

    protected get isCurrentUserClientAdmin() {
        return this.securityService.isCurrentUserClientAdmin();
    }

    private showSpotlightInput = true;
    private readonly keyDownListener: ($event: KeyboardEvent) => void;

    constructor(
        private appDataService: AppDataService,
        private navigationService: NavigationService,
        private versioningEventService: VersioningEventService,
        private versioningUiService: VersioningUiService,
        private appEventsService: AppEventsService,
        private dxyModalService: DxyModalService,
        private entityUiService: EntityUiService,
        private dashboardService: DashboardService,
        private realTimeCommService: RealTimeCommService,
        private uiSpinnerService: UiSpinnerService,
        private functionalLogService: FunctionalLogService,
        private freshDeskService: FreshDeskService,
        private securityService: SecurityService,
        private currentUserTimeoutService: CurrentUserTimeoutService,
        private loginService: LegacyLoginService,
        private currentSpaceService: CurrentSpaceService,
        private entityPreviewPanelService: EntityPreviewPanelService,
        private attributeAdminService: ClientAdminAttributeService,
        private screenService: ScreenService,
        private attributeDataService: AttributeDataService,
        private userService: UserService,
        private navigationApiService: NavigationApiService,
        private toasterService: ToasterService,
        private featureFlagService: FeatureFlagService,
        private currentUserService: CurrentUserService,
        private translate: TranslateService,
        private coreEventsService: CoreEventsService
    ) {
        super();
        this.keyDownListener = (e) => this.onKeyDown(e);
        currentUserTimeoutService.startSessionTimeoutDetection();
    }

    ngOnInit() {
        this.subscribeEvents();

        void this.freshDeskService.init();

        if (this.featureFlagService.isFeatureEnabled('ENABLE_AUTH_V2')) {
            this.currentUserService.selectLanguage().subscribe((lang) => {
                this.translate.use(lang);
                this.translate.setDefaultLang(lang);
            });
        } else {
            this.coreEventsService.uiLanguageChanged$.subscribe((lang) => {
                this.translate.use(lang);
                this.translate.setDefaultLang(lang);
            });
        }
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        if (this.keyDownListener) {
            document.removeEventListener('keypress', this.keyDownListener);
        }
    }

    ngAfterViewInit() {
        setTimeout(() =>
            document.addEventListener('keypress', this.keyDownListener)
        );
    }

    public onLogFunctional(functionalEvent: IFunctionalEvent) {
        this.functionalLogService.parseAndLog(
            functionalEvent.text,
            functionalEvent.origin
        );
    }

    private onEnableVersioning(projectVersion: ProjectVersion) {
        this.versioningUiService.handleEnableVersioningResult(projectVersion);
    }

    private subscribeEvents() {
        super.subscribe(
            this.versioningEventService.enableVersioning$,
            (event) => this.onEnableVersioning(event.data)
        );

        super.subscribe(
            this.dashboardService.isEditingChange$,
            (isEditing) => (this.showSpotlightInput = !isEditing)
        );

        super.registerSubscription(
            this.realTimeCommService.subscribeReloadData(() =>
                this.navigationService.reloadCurrentState().then()
            )
        );

        super.subscribe(
            this.appEventsService.topSpotlightShouldBeHidden$,
            (hide) => (this.isSpotlightInputHidden = hide)
        );

        super.subscribe(
            this.currentUserTimeoutService.userSessionTimeout$,
            (isTimeout) => {
                if (isTimeout) {
                    this.log('userSessionTimeout');
                    this.navigationService.goLogoutSessionTimeout();
                } else {
                    this.log('userSessionKeepAlive');
                    this.loginService.sessionKeepAlive();
                }
            }
        );

        super.registerSubscriptions(
            this.realTimeCommService.subscribeDeleteSpaceEvent(
                (userData, spaceIdentifier) =>
                    this.onSpaceDeletedNotification(spaceIdentifier)
            ),

            this.realTimeCommService.subscribeUpdateScreen(
                (userData, screenRtData: UpdateScreenRtData) => {
                    if (
                        !this.screenService.updateScreenRealTime(
                            screenRtData,
                            this.currentSpaceService.getCurrentSpace()
                        )
                    ) {
                        return;
                    }
                }
            ),

            this.realTimeCommService.subscribeUpdateAttribute(
                (userData, updatedAttribute: RtAttributeDTO) =>
                    this.attributeDataService.refreshLocalCacheAttributes(
                        updatedAttribute.Attribute.ModuleName
                    )
            ),

            this.realTimeCommService.subscribeCreateAttribute(
                (userData, createdAttribute: RtAttributeDTO) =>
                    this.attributeDataService.refreshLocalCacheAttributes(
                        createdAttribute.Attribute.ModuleName
                    )
            ),

            this.realTimeCommService.subscribeAttributeDelete(
                (userData, rtAttribute: RtAttributeDTO) => {
                    this.screenService.clearScreenLocalCache();
                    this.screenService.loadClientScreens();

                    const attribute = rtAttribute.Attribute;

                    this.attributeDataService.removeAttributeFromLocalCache(
                        attribute.ModuleName,
                        attribute.AttributeKey
                    );
                    this.attributeAdminService.removeAttributeFromLocalCache(
                        attribute.ModuleName,
                        attribute.AttributeKey
                    );
                }
            ),

            this.realTimeCommService.subscribeSecurityRightsChangeEvent(() => {
                this.toasterService.infoToast({
                    titleKey: 'UI.Notification.SecurityRightsUpdate.title',
                    messageKey: 'UI.Notification.SecurityRightsUpdate.msg',
                });
                // Update navigation space list and redirection on access loss
                this.navigationApiService.init();
                this.navigationApiService.getNavSpaces();
            }),

            this.realTimeCommService.subscribeUserListChange(() => {
                this.userService.init();
                this.userService.loadClientUserData();
            })
        );
    }

    private onKeyDown(event: KeyboardEvent) {
        if (
            this.dxyModalService.isActiveModal ||
            !this.isShortcutEventValid(event) ||
            !this.appDataService.canCreateEntities
        ) {
            return;
        }

        if (event.key.toLocaleLowerCase() == 'a') {
            this.entityUiService.openCreationModal(
                EntityCreationOrigin.globalAddButton
            );
        }
    }

    private isShortcutEventValid(event: KeyboardEvent) {
        const eventTarget = event.target as HTMLElement;
        return (
            !eventTarget.isContentEditable &&
            eventTarget.localName != 'input' &&
            eventTarget.localName != 'textarea'
        );
    }

    private async onSpaceDeletedNotification(spaceIdentifier: DataIdentifier) {
        if (
            !this.currentSpaceService.isCurrentSpace(
                spaceIdentifier.DataReferenceId
            )
        ) {
            return;
        }

        this.entityPreviewPanelService.hidePanel();

        await this.dxyModalService.inform({
            titleKey: 'UI.SpaceDeleteInfo.title',
            messageKey: 'UI.SpaceDeleteInfo.msg',
            type: DialogType.Close,
        });
        await this.navigationService.goToClientSpacesList();
    }
}
