import {
    IListOption,
    IDropdownSection,
    IActionOption,
    IDragDropConfig,
} from '@datagalaxy/core-ui';
import {
    BaseCellComponent,
    IDisplayedDataGroupOf,
    IGridCellParams,
    IOmniGridActionDef,
    IOmniGridRowHeightParams,
    OmniGridSortModel,
} from '@datagalaxy/core-ui/omnigrid';
import {
    EntityType,
    HierarchicalData,
    HierarchyDataDescriptor,
    IEntityIdentifier,
} from '@datagalaxy/dg-object-model';
import { EntityCreationOrigin } from '../entity.types';
import { Type } from '@angular/core';
import { DgZone } from '@datagalaxy/webclient/domain';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { AttributeMetaInfo } from '@datagalaxy/webclient/attribute/domain';

/** Options for EntityGrid component.
 * Every boolean value is considered false if undefined, except for:
 * - isHierarchical
 */
export interface IEntityGridOptions {
    /** data to be used instead of querying the server.
     * One of the (objects, groups) properties must be populated.
     * When directData is defined, those options will be forced :
     * - isHierarchical,
     * - isHierarchicalSingleServerType,
     * - useParentDataIdIfNullEntityRefId,
     * - showHierarchicalSwitch,
     * - dgZone,
     * - filterEntityTypes
     */
    directData?: {
        items?: EntityItem[];
        groups?: IDisplayedDataGroupOf<EntityItem>[];
        groupColumnMinWidth?: number;
        /** message to display above the grid */
        headerMessage?: string;
        /** called when a column is added or removed */
        onColumnsChanged?: () => void | Promise<void>;
        onSortChanged?: (sortModel: OmniGridSortModel) => void;
        updateDataOnColumnsChanged?: boolean;
        onFilteredViewChanged?: () => void | Promise<void>;
        /** to show/hide space in mini-object's breadcrumb */
        isAllSpaces?: boolean;

        /** set by entitygrid.
         * To be called after *items* or *groups* have been changed, to update the displayed rows */
        update?: () => Promise<void>;
    };

    /** when undefined, the value is obtained from the current application state (ie the navigation service) */
    isHierarchical?: boolean;

    /** when true only a specific set of columns will be set sortable
     * For now only text, formatted text, number, time series, date and boolean can be sorted
     */
    isServerSideSorting?: boolean;

    /** set to true to pass only the entity ServerType to getEntityChildren/flatLoadItems,
     * instead of (for the modeler: all the modeler types) */
    isSingleServerType?: boolean;

    /** set to true to when we want to include all Children Types, e.g., as in entityTree */
    includeAllChildrenTypes?: boolean;

    /** if isHierarchical is true,
     * set to true to use parentDataId as the parent id in getEntityChildren when the received entityRefId is null */
    useParentDataIdIfNullEntityRefId?: boolean;

    /** true to display a list instead of a grid */
    isSingleColumn?: boolean;

    /** if isSingleColumn is true,
     * set to true not to display the count or title messages above the rows */
    noTopMessages?: boolean;

    /** true to show the Create button */
    canCreate?: boolean;

    /** true to show the Hierarchical / Flat button */
    showHierarchicalSwitch?: boolean;

    /** true to enable selection of multiple rows with checkboxes */
    isMultiSelect?: boolean;

    bulkEditConfiguration?: BulkEditConfiguration;

    /** true to show export entities action button */
    showExportEntitiesAction?: boolean;

    /** true to show the dropdown list for to change columns visibility */
    showColumnsSelector?: boolean;

    /** true to load & store the grid state (ie visible columns and their position) from database.
     *
     * Note: the component binding gridStatePersistenceId must be defined. */
    canSaveGridState?: boolean;

    /** if true then the user can move the columns */
    canMoveColumns?: boolean;

    /** true for the miniobject not to display breadcrumbs */
    noBreadCrumbs?: boolean;

    /** the action to execute when clicking on a row */
    rowClickAction?: EntityGridRowClickAction;

    /** the default columns to display, if no user settings configured for the particular gridStatePersistenceId
     * (this has no effect when isSingleColumn is true) */
    wantedColumns?: string[];

    /** true to use the Order column as the default sorting column */
    useOrderColumn?: boolean;

    /** true not to display the total count message */
    noTotalCountMessage?: boolean;

    /** when true, the miniobject will not execute navigation on link click */
    noNavLink?: boolean;

    /** when true, the miniobject will not execute navigation on label link click */
    noLabelNavLink?: boolean;

    /* when true, the miniobject will show a button to open the entity pane with the clicked entity */
    actionButtonOpenEntityPane?: boolean;

    /* when true, the miniobject will show a button to navigate to the impact-analysis page for the entity */
    actionButtonOpenAnalysis?: boolean;

    /** actions displayed on row hover at the end of the row */
    hoverActions?: IOmniGridActionDef<EntityItem>[];

    /** WARNING: Setting this to true will cause important slowdown if many rows.
     * if true then the grid will be full height, making every row visible
     * If false then the grid fills its parent element */
    autoHeight?: boolean;

    /** function to get row height dynamically */
    getRowHeight?: (params: IOmniGridRowHeightParams) => number;

    preserveCurrentSingleSelectedOnEntityCreated?: boolean;

    /** the type of filteredView to use when loading data */
    dgZone?: DgZone;

    /** when true, entityApiService.getEntity will be called to get full entity data for the docking pane */
    getFullEntityForDockingPane?: boolean;

    /** when defined and not empty, only objects matching those values will be displayed */
    filterEntityTypes?: EntityType[];
    /** when defined, only children objects of the given referenceId object will be displayed */
    filterOnParentId?: string;

    /** set to true not to display the count or title messages above the rows and the title bar */
    noMessagesAndTitleBar?: boolean;

    /** overrides EntityGridController#entityAttributes */
    entityAttributes?: AttributeMetaInfo[];

    /** groups of available columns displayed in the colums selector */
    entityAttributeGroups?: IDropdownSection[];

    /** overrides EntityGridController#gridStatePersistenceId - identifier to store the grid state for the current user */
    gridStatePersistenceId?: string;

    /** action button to be displayed in the title bar */
    headerActionButton?: IListOption | (() => IListOption);

    /** function called when showEntityDetails is called */
    onShowEntityDetailsCallBack?: (entity: EntityItem) => void;

    /** Allow to have a local Hiearchical toggling, as opposed to normal navigationService-state based */
    isHierarchyToggleLocal?: boolean;

    /** Displays an integrated direct text input to allow searching on primary Attribute */
    showSearchBox?: boolean;

    /** Ability to provide additional action buttons to be displayed in a cell */
    optionalActions?: IActionOption<EntityItem>[];

    /** Defines primary display name */
    primaryName?: string;

    /** Defines primary column's displayName */
    primaryDisplayName?: string;

    /** Defines if there is secondary display name */
    hasSecondaryName?: boolean;

    /** when true, the full-page mode will be set to false when redirecting to entity details, from a row or an entity-card-cell */
    entityDetailsNoFullPage?: boolean;

    /** to use as special cell as first column  */
    firstCellRendererFramework?: Type<
        BaseCellComponent<unknown, HierarchicalData>
    >;

    firstCellValueGetter?: (
        params: IGridCellParams<EntityItem, HierarchyDataDescriptor>
    ) => unknown;

    /** when true, entity rows are being updated on entity updates even if there is a current filtered view */
    forceRefreshForUpdatedEntity?: boolean;

    /** excluded columns */
    excludedAttributePaths?: string[];

    /** true to prevent navigation to the current entity's details page */
    noNavToDetails?: boolean;

    /** drag&drop configuration used for
     * - a *cdkDrag* directive applied to each entity-card-cell component
     * - a *cdkDropList* directive applied to the grid */
    cardCellDragDrop?: IDragDropConfig<IEntityIdentifier>;

    burgerMenuConfiguration?: IBurgerMenuConfiguration;
}

export enum EntityGridRowClickAction {
    none = 0,
    showEntityDetail,
    openEntityPreviewPanel,
}

export interface IBurgerMenuConfiguration {
    /** when true, the miniobject will show a button displaying a navigation contextual dropdown for the entity
     * It allows if available, to:
     * - Navigate to details
     * - Open preview pane
     * - Navigation to lineage
     * - Navigate to impact analysis
     * Note: if actionButtonBurgerMenu is true, the options will be merged in one burger menu with separate headers
     */
    navigationButton?: boolean;
    /** when true, the miniobject will show a button displaying a contextual dropdown for the entity,
     * by default using the internal burger menu manager.
     * One can provide its own manager using the *externalBurgerMenuManager* option,
     * and by letting this *actionButtonBurgerMenu* undefined */
    actionButton?: boolean;
    /** When **not** provided, the contextual action origin will be set, depending on the menu option, to one of the folowing values:
     * - EntityCreationOrigin.fromBurgerMenuCreateChild,
     * - EntityCreationOrigin.fromBurgerMenu */
    entityCreationOrigin?: EntityCreationOrigin;
    readonly?: boolean;
    hideReadonlyActions?: boolean;
}

export interface BulkEditConfiguration {
    /** true to show the bulk actions buttons */
    showActions?: boolean;

    hideDelete?: boolean;

    hideCampaignActions?: boolean;
}
