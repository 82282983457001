<dxy-spinner *ngIf="loading$ | async; else content"></dxy-spinner>

<ng-template #content>
    <header>
        <dxy-search-input
            class="no-border-top"
            (onSearchString)="onSearchStringChange($event.searchString)"
        ></dxy-search-input>
        <button
            dxyIconButton
            (click)="openAddLanguageModal()"
            [matTooltip]="
                'Multilingual.Administration.LanguagesTab.addLanguageTooltip'
                    | translate
            "
        >
            <i class="glyph-add"></i>
        </button>
    </header>
    <div
        *ngFor="
            let code of languageCodes$ | async;
            trackBy: trackByLanguageCode
        "
        class="language"
    >
        <dxy-language-card [languageCode]="code"> </dxy-language-card>
        <div
            *ngIf="(sourceLanguageCode$ | async) === code"
            class="dg5-badge"
            translate
        >
            Multilingual.Administration.sourceLanguage
        </div>
        <div class="progess">
            <dxy-translation-progress
                *dxyIfFeatureFlag="'batch-translation'"
                [languageCode]="code"
            ></dxy-translation-progress>
        </div>
    </div>
</ng-template>
