import { IListOption, IListOptionItem } from '@datagalaxy/core-ui';
import { EventEmitter } from '@angular/core';
import { IMultiSelectData } from '@datagalaxy/core-ui';
import { CollectionsHelper } from '@datagalaxy/core-util';
import { IWidgetConfigParam, WidgetFormFieldType } from '../WidgetUtil';
import {
    DashboardService,
    IOption,
    IWidgetSettingsData,
} from '../dashboard.service';

/** Note: This is a helper, not an angular component */
export class WidgetSettingsManager {
    public params: IWidgetConfigParam[];
    public get hideFilters() {
        return this.config.hideFilters;
    }
    public set hideFilters(value: boolean) {
        this.config.hideFilters = value;
    }

    private selectOptions: Map<IWidgetConfigParam, IListOptionItem[]>;
    private multiSelectDataCache = new Map<
        IWidgetConfigParam,
        IMultiSelectData
    >();
    private optionsLoadingAll: Promise<void>;
    private optionsLoadingByParamName: string[] = [];

    constructor(
        private config: IWidgetSettingsData,
        private dashboardService: DashboardService,
        private onFiltersUpdate: EventEmitter<IWidgetConfigParam[]>
    ) {
        this.params = DashboardService.getSortedParams(config.config);
    }

    /** Async initization of options */
    public async init() {
        const wcc = this.config.config;
        const optionsMap = await this.dashboardService.getSettingsSelectOptions(
            wcc
        );
        for (let [key, value] of optionsMap) {
            if (this.isSingleSelect(key)) {
                value = value.map((option: IOption) => {
                    return {
                        labelKey: option.label,
                        data: option.data || option.value,
                        glyphClass: option.glyphClass,
                        optionClass: 'menu-item',
                    };
                });
                optionsMap.set(key, value);
            }
        }
        this.selectOptions = optionsMap;
        this.optionsLoadingAll = null;
    }

    public isMultiSelect(param: IWidgetConfigParam) {
        return DashboardService.isMultiOptionParam(param);
    }
    public isSingleSelect(param: IWidgetConfigParam) {
        return DashboardService.isSingleOptionParam(param);
    }
    public isLoadingOptions(param: IWidgetConfigParam) {
        return !!(
            this.optionsLoadingAll ||
            (param &&
                this.optionsLoadingByParamName.find((d) => d === param.name))
        );
    }
    public isUserSelect(param: IWidgetConfigParam) {
        return (
            param.formFieldType === WidgetFormFieldType.users ||
            param.formFieldType === WidgetFormFieldType.licenseUsers
        );
    }
    public getSelectOptions(param: IWidgetConfigParam) {
        return this.isLoadingOptions(param)
            ? undefined
            : this.selectOptions?.get(param);
    }

    public getMultiSelectData(param: IWidgetConfigParam) {
        if (this.isLoadingOptions(param)) {
            return;
        }
        if (this.multiSelectDataCache.has(param)) {
            return this.multiSelectDataCache.get(param);
        }

        const msd = this.buildMultiSelectData(param);
        this.multiSelectDataCache.set(param, msd);
        return msd;
    }

    public getParamValue(paramName: string) {
        return this.config.config.params.find((p) => p.name === paramName)
            ?.name;
    }

    public getParam(paramName: string) {
        return this.config?.config?.params?.find((p) => p.name === paramName);
    }

    public cancel() {
        this.config.cancel();
        this.params.forEach((param) => this.onSelectChange(param, true));
        this.onFiltersUpdate.emit(this.params);
    }

    public save() {
        this.config.save();
    }

    public reset() {
        this.config.reset();
        this.params.forEach((param) => this.onSelectChange(param, true));
        this.onFiltersUpdate.emit(this.params);
    }

    public async onSelectChange(
        param: IWidgetConfigParam,
        skipEmitEvent?: boolean
    ) {
        const config = this.config.config;
        if (DashboardService.isParamDgModule(param)) {
            // this.dashboardService.setCommonFieldDefaults(config, this.prevDgModule, this.data.commonFieldEmptyToDefault)
            // this.prevDgModule = param.value as DgModule
            // this.debug && this.log('onSelectChange-commonFields:', DashboardService.debugData(this.params.filter(DashboardService.isParamCommonField)))
        }
        // clear dependent parameters selected value and options
        const dependents = DashboardService.getDependentParams(param, config);
        await Promise.all(
            dependents.map(async (p) => {
                let noRemove = false;
                if (this.multiSelectDataCache.has(p)) {
                    this.multiSelectDataCache.delete(p);
                }
                if (this.selectOptions?.has(p)) {
                    this.selectOptions.set(p, []);
                    const valueIds = p.value as Array<string | number>;
                    this.optionsLoadingByParamName.push(p.name);
                    await this.setSelectOption(p, valueIds);
                    CollectionsHelper.remove(
                        this.optionsLoadingByParamName,
                        (d) => d === p.name
                    );
                    noRemove = true;
                }
                if (!noRemove) {
                    p.value = undefined;
                }
            })
        );
        if (skipEmitEvent) {
            return;
        }
        this.onFiltersUpdate.emit(this.params);
    }
    private async setSelectOption(
        p: IWidgetConfigParam,
        previousValueIds: Array<string | number>
    ) {
        const newOptions = (await this.config.getOptions(p)) as Array<
            IOption & IListOptionItem & IListOption
        >;
        p.value = previousValueIds?.filter((valueId) =>
            newOptions.some((option) => option.valueId === valueId)
        );

        this.selectOptions?.set(p, newOptions);
    }

    private buildMultiSelectData(param: IWidgetConfigParam) {
        const items = this.selectOptions?.get(param) as IListOptionItem[];
        const valueIds = (param.value as Array<string | number>)?.map((v) =>
            v?.toString()
        );
        const selectedItems = items?.filter((item) =>
            valueIds?.includes(item.valueId)
        );

        const msd: IMultiSelectData = {
            items,
            selectedItems,
            hasSearch: true,
            dataType: DashboardService.getUiMultiselectDataTypeFromFieldType(
                param.formFieldType
            ),
            hasSelectAll: true,
            onSelectionChange: (selectedItems) =>
                this.onMultiSelectChange(param, selectedItems),
        };
        return msd;
    }

    private onMultiSelectChange(
        param: IWidgetConfigParam,
        selectedItems: IListOptionItem[]
    ) {
        const paramValue = selectedItems?.map((item) => item.valueId);
        param.value = paramValue?.length ? paramValue : undefined;
        this.onSelectChange(param);
    }
}
