import { Injectable } from '@angular/core';
import { MultilingualFunnelState } from './multilingual-funnel-state';
import { Language } from '../../languages/language';
import { AvailableLanguagesService } from '../../languages/available-languages.service';
import {
    MultilingualAttributeSetting,
    MultilingualSettings,
} from '@datagalaxy/webclient/multilingual/data-access';
import { BaseStateService } from '@datagalaxy/utils';
import { AttributeMetaInfo } from '@datagalaxy/webclient/attribute/domain';

@Injectable({
    providedIn: 'root',
})
export class MultilingualFunnelStateService extends BaseStateService<MultilingualFunnelState> {
    constructor(availableLanguagesService: AvailableLanguagesService) {
        super({
            availableLanguages:
                availableLanguagesService.getAvailableLanguages(),
        });
    }

    public getAvailableSourceLanguages() {
        return this.state.availableLanguages;
    }

    public getSourceLanguageCode() {
        return this.getSourceLanguage()?.languageCode;
    }
    public getSourceLanguage() {
        return this.state.selectedSourceLanguage;
    }

    public getSelectedTargetLanguage() {
        return this.state.selectedTargetLanguage;
    }

    public setSourceLanguage(sourceLanguage: Language): void {
        this.setState({
            selectedSourceLanguage: sourceLanguage,
            selectedTargetLanguage:
                sourceLanguage.languageCode ===
                this.getSelectedTargetLanguage()?.languageCode
                    ? undefined
                    : this.getSelectedTargetLanguage(),
        });
    }

    public setSelectedTargetLanguage(targetLanguage: Language): void {
        this.setState({
            selectedTargetLanguage: targetLanguage,
        });
    }

    public setSelectedAttributes(attributes: AttributeMetaInfo[]) {
        this.setState({
            selectedAttributes: attributes,
        });
    }

    public constructSettings(): MultilingualSettings {
        return {
            sourceLang: this.getSourceLanguageCode(),
        };
    }

    public constructAttributeSettings(): MultilingualAttributeSetting[] {
        const selectedAttributes = this.state.selectedAttributes;
        return (
            selectedAttributes?.map((a) => ({
                attributePath: a.AttributePath as string,
                enabled: true,
            })) ?? []
        );
    }
}
