import { Inject, Injectable } from '@angular/core';
import {
    RestApiService,
    V3_REST_API_SERVICE_TOKEN,
} from '@datagalaxy/data-access';
import { IEntityIdentifier } from '@datagalaxy/dg-object-model';
import { getContextId, getLocalId } from '@datagalaxy/webclient/utils';
import {
    AttributeValueTranslation,
    ObjectTranslation,
} from '@datagalaxy/webclient/multilingual/domain';
import { ObjectTranslationDto } from './object-translation-dto';

@Injectable({ providedIn: 'root' })
export class TranslationApiService {
    constructor(
        @Inject(V3_REST_API_SERVICE_TOKEN)
        private restApiService: RestApiService
    ) {}

    public async getObjectTranslation(
        entityIdentifier: IEntityIdentifier,
        languageCode: string
    ): Promise<ObjectTranslation> {
        const result =
            await this.restApiService.getPromise<ObjectTranslationDto>(
                `${this.getBaseTranslationsRouteUrl(
                    entityIdentifier
                )}/${languageCode}`
            );
        return this.adaptObjectTranslation(
            result,
            languageCode,
            entityIdentifier
        );
    }

    public async getObjectsTranslations(
        entityIdentifiers: IEntityIdentifier[],
        languageCode: string
    ): Promise<ObjectTranslation[]> {
        const idr = entityIdentifiers[0];
        const spaceId = getContextId(idr.ReferenceId);
        const versionId = idr.VersionId;
        const result = await this.restApiService.getPromise<
            ObjectTranslationDto[]
        >(
            `workspaces/${spaceId}/versions/${versionId}/objects/translations/${languageCode}`,
            {
                objectId: entityIdentifiers.map(
                    (e) => getLocalId(e.ReferenceId) ?? ''
                ),
            }
        );
        return result.map((o) =>
            this.adaptObjectTranslation(
                o,
                languageCode,
                entityIdentifiers.find(
                    (e) => getLocalId(e.ReferenceId) === o.objectLocalId
                ) as IEntityIdentifier
            )
        );
    }

    public async updateObjectAttributeTranslation(
        entityIdentifier: IEntityIdentifier,
        languageCode: string,
        value: AttributeValueTranslation
    ) {
        return this.restApiService.putPromise<AttributeValueTranslation>(
            `${this.getBaseTranslationsRouteUrl(
                entityIdentifier
            )}/${languageCode}/attributes/${value.attributePath}`,
            value
        );
    }

    public async translateEntity(
        entityIdentifier: IEntityIdentifier,
        languageCode: string
    ) {
        const result =
            await this.restApiService.postPromise<ObjectTranslationDto>(
                `${this.getBaseTranslationsRouteUrl(entityIdentifier).replace(
                    'translations',
                    languageCode
                )}/translate`
            );
        return this.adaptObjectTranslation(
            result,
            languageCode,
            entityIdentifier
        );
    }
    public async regenerateAttributeTranslation(params: {
        from: string;
        entityIdentifier: IEntityIdentifier;
        attributePath: string;
    }) {
        await this.restApiService.postPromise<ObjectTranslationDto>(
            `${this.getBaseObjectRouteUrl(
                params.entityIdentifier
            )}/attributes/${params.attributePath}/regenerate-translations`,
            {
                from: params.from,
            }
        );
    }

    private getBaseTranslationsRouteUrl(entityIdentifier: IEntityIdentifier) {
        return `${this.getBaseObjectRouteUrl(entityIdentifier)}/translations`;
    }

    private getBaseObjectRouteUrl(entityIdentifier: IEntityIdentifier) {
        const spaceId = getContextId(entityIdentifier.ReferenceId);
        const versionId = entityIdentifier.VersionId;
        const localId = getLocalId(entityIdentifier.ReferenceId);
        return `workspaces/${spaceId}/versions/${versionId}/objects/${localId}`;
    }

    private adaptObjectTranslation(
        translation: ObjectTranslationDto,
        languageCode: string,
        entityIdentifier: IEntityIdentifier
    ): ObjectTranslation {
        return {
            languageCode,
            entityIdentifier,
            translatedAttributeValues: translation.translatedAttributeValues,
        };
    }
}
