import { AttributesFinder } from '@datagalaxy/webclient/attribute/feature';
import { AttributeDataService } from './attribute-data.service';
import { DataUtil } from '../util/DataUtil';
import { Injectable } from '@angular/core';
import { EntityServerTypeUtils } from '@datagalaxy/webclient/entity/utils';
import { ServerType } from '@datagalaxy/dg-object-model';
import { AttributeMetaInfo } from '@datagalaxy/webclient/attribute/domain';

@Injectable()
export class AttributeFinderService implements AttributesFinder {
    constructor(private attributeDataService: AttributeDataService) {}

    public async findAttributeByPath(
        attributePath: string
    ): Promise<AttributeMetaInfo | undefined> {
        const attributes = await this.searchAttributes(
            (a) => a.AttributePath == attributePath
        );
        return attributes?.[0];
    }

    public async searchAttributes(
        predicate: (ami: AttributeMetaInfo) => boolean
    ): Promise<AttributeMetaInfo[]> {
        DataUtil.getServerTypesFromModule();
        const serverTypes = [
            ...EntityServerTypeUtils.firstClassEntityServerTypes,
            ServerType.Diagram,
        ];
        await this.attributeDataService.loadAttributes([
            ServerType[ServerType.Diagram],
        ]);
        const result = await this.attributeDataService.getAttributes(
            serverTypes.map((st) => ServerType[st]),
            false,
            false
        );

        return Array.from(result.values()).filter(predicate);
    }
}
