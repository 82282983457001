import { CollectionsHelper, CoreUtil } from '@datagalaxy/core-util';
import {
    Component,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import {
    EntityType,
    IEntityIdentifier,
    ServerType,
} from '@datagalaxy/dg-object-model';
import {
    ITabViewData,
    ITabViewItem,
} from '../../shared/shared-ui/dxy-tab-view/dxy-tab-view.types';
import { IDropdownButtonOption } from '../../shared/shared-ui/dropdownButton.types';
import {
    EntityCacheService,
    ICurrentEntityData,
} from '../../shared/entity/services/entity-cache.service';
import { ModelerDataUtil } from '../../shared/util/ModelerDataUtil';
import { HddUtil } from '../../shared/util/HddUtil';
import { StateName } from '@datagalaxy/webclient/routing';
import {
    IGotoDetailsOptions,
    NavigationService,
} from '../../services/navigation.service';
import { EntityUiService } from '../../shared/entity/services/entity-ui.service';
import {
    EntityCreateEventData,
    EntityDeleteEventData,
    EntityEventService,
} from '../../shared/entity/services/entity-event.service';
import { EntityDockingPaneService } from '../../shared/entity/services/entity-docking-pane.service';
import { AppEventsService } from '../../services/AppEvents.service';
import { DataProcessingUiService } from '../../data-processing/services/data-processing-ui.service';
import { ModelerService } from '../../modeler/services/modeler.service';
import { UserService } from '../../services/user.service';
import { EntityService } from '../../shared/entity/services/entity.service';
import { EntitySecurityService } from '../../shared/entity/services/entity-security.service';
import { AttributeDataService } from '../../shared/attribute/attribute-data.service';
import { ExportService } from '../../services/export.service';
import { ViewTypeService } from '../../services/viewType.service';
import { DataUtil } from '../../shared/util/DataUtil';
import {
    EntityCreationOrigin,
    IEntityCreationContext,
} from '../../shared/entity/entity.types';
import { DiagramDataService } from '../../diagrams/diagram/diagram-data.service';
import { EntityTypeUtil } from '@datagalaxy/dg-object-model';
import { DataQualityService } from '../../data-quality/data-quality.service';
import { ModuleSecurityData } from '@datagalaxy/webclient/workspace/data-access';
import { EntitySuggestionStoreService } from '../../suggestions/entity-suggestion-store.service';
import { SetEntitiesParentResult } from '@datagalaxy/webclient/entity/data-access';
import {
    CrudOperation,
    FunctionalLogService,
} from '@datagalaxy/webclient/monitoring/data-access';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { EntityUtils } from '@datagalaxy/webclient/entity/utils';
import { ISpaceIdentifier } from '@datagalaxy/webclient/workspace/domain';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { DgModule } from '@datagalaxy/shared/dg-module/domain';
import { ServerConstants } from '@datagalaxy/shared/server/domain';

@Component({
    selector: 'app-entity-dashboard',
    templateUrl: 'entity-dashboard.component.html',
    styleUrls: ['entity-dashboard.component.scss'],
})
export class EntityDashboardComponent
    extends DxyBaseComponent
    implements OnDestroy, OnInit
{
    @Input() dgModule: DgModule;
    @Input() spaceIdr: ISpaceIdentifier;
    @Input() moduleSecurityData: ModuleSecurityData;
    @Input() entityData: EntityItem;
    @Input() serverType: ServerType;

    public tabsData: ITabViewData;
    public entityActions: IDropdownButtonOption[];

    public get hasEntity() {
        return !!this.entityData;
    }

    public get toggleIconClass() {
        return this.navigationService.isEntityFullPage
            ? 'glyph-fullscreen-contract'
            : 'glyph-fullscreen-expand';
    }

    public get toggleDisplayModeTooltipKey() {
        return `UI.EntityHeader.actions.${
            this.navigationService.isEntityFullPage
                ? 'collapseTooltip'
                : 'expandTooltip'
        }`;
    }

    public get showEntityActionsDropDown() {
        if (this.isCatalog) {
            const baseAccess =
                this.hasEntityCloneAccess ||
                this.hasEntityDeleteAccess ||
                this.hasEntityImportAccess;
            switch (this.serverType) {
                case ServerType.Model:
                case ServerType.Table:
                    return baseAccess || this.canGenerateDdl;
                case ServerType.Container:
                case ServerType.Column:
                    return baseAccess;
                default:
                    return false;
            }
        }
        return this.securityData?.HasWriteAccess;
    }

    public get msgNoSelectionTranslateKey() {
        if (this.isCatalog) {
            switch (this.serverType) {
                case ServerType.Model:
                    return 'UI.ModelDashboard.msgNoSelection';
                case ServerType.Container:
                    return 'UI.ModelDashboard.msgNoContainerSelection';
                case ServerType.Table:
                    return 'UI.ModelDashboard.msgNoTableSelection';
                case ServerType.Column:
                    return 'UI.ModelDashboard.msgNoColumnSelection';
                default:
                    break;
            }
        }
        return `${this.tradkeyPrefix}msgNoSelection`;
    }

    private featureCode: string;
    private tradkeyPrefix: string;
    private currentTabRelativeStateName: string;
    private tabCounts = new Map<TabId, number>();

    private get currentEntityDataService() {
        return this.entityCacheService as ICurrentEntityData;
    }

    private get isProcessings() {
        return this.dgModule == DgModule.Processing;
    }

    private get isCatalog() {
        return this.dgModule == DgModule.Catalog;
    }

    private get isGlossary() {
        return this.dgModule == DgModule.Glossary;
    }

    private get hasModuleExportAccess() {
        return this.moduleSecurityData?.HasExportAccess;
    }

    private get hasEntityExportAccess() {
        return this.securityData?.HasExportAccess || this.hasModuleExportAccess;
    }

    private get hasEntityCloneAccess() {
        return this.securityData?.HasCreateAccess;
    }

    private get hasEntityDeleteAccess() {
        return this.securityData?.HasDeleteAccess;
    }

    private get hasEntityImportAccess() {
        return this.securityData?.HasImportAccess;
    }

    private get securityData() {
        return this.entityData?.SecurityData;
    }

    private get modelId() {
        return this.isCatalog ? this.modelDescriptor.DataReferenceId : null;
    }

    private get isModelRelational() {
        return (
            this.isCatalog &&
            ModelerDataUtil.isModelRelational(
                this.serverType,
                this.modelDescriptor
            )
        );
    }

    private get isTechnicalView() {
        return this.viewTypeService.isTechnicalView;
    }

    // Temporarily disabled for Catalog module
    private get showEntityCloneButton() {
        return (
            this.hasEntityCloneAccess && !!this.securityData?.HasCreateAccess
        );
    }

    private get showEntityFusionButton() {
        return (
            this.isGlossary &&
            this.entitySecurityService.hasWriteAccessSecurity(this.securityData)
        );
    }

    private get showEntityExportButton() {
        return this.hasEntityExportAccess;
    }

    private get showEntityDeleteButton() {
        return (
            this.hasEntityDeleteAccess &&
            this.entitySecurityService.hasEntityDeleteAccess(
                this.entityData.ServerType,
                this.securityData
            )
        );
    }

    private get exportButtonText() {
        return this.attributeDataService.getEntityTypeTranslationWithArticle(
            this.entityData.EntityType,
            'UI.Global.btnExportEntity'
        );
    }

    private get modelDescriptor() {
        return this.isCatalog && this.entityData?.ServerType == ServerType.Model
            ? this.entityData.HddData.Data
            : HddUtil.getParentDataDescriptorByType(this.entityData.HddData, [
                  ServerType.Model,
              ]);
    }

    private get canGenerateDdl() {
        if (!this.isCatalog) {
            return false;
        }
        switch (this.serverType) {
            case ServerType.Table:
            case ServerType.Model:
                return !!this.entityData?.getAttributeValue(
                    ServerConstants.PropertyName.IsScriptGenerationEnabled
                );
            default:
                return false;
        }
    }

    //#region Processing specific
    private showProcessingGlobalUnmappedChildren = false;

    private get showProcessingUnmappedGlobalColumnsBtn() {
        return (
            this.isProcessings &&
            this.navigationService.getCurrentStateName() ==
                StateName.DataProcessingMapping
        );
    }

    //#endregion

    //#region Catalog specific
    private get showSeparatorDdl() {
        if (!this.isCatalog) {
            return false;
        }
        switch (this.serverType) {
            case ServerType.Model:
                return (
                    this.canGenerateDdl &&
                    (this.hasEntityCloneAccess || this.hasEntityDeleteAccess)
                );
            case ServerType.Table:
                return this.canGenerateDdl;
            default:
                return false;
        }
    }

    private get showGenerateDdl() {
        if (!this.isCatalog || !this.canGenerateDdl) {
            return false;
        }
        switch (this.serverType) {
            case ServerType.Model:
            case ServerType.Table:
                return true;
            default:
                return false;
        }
    }

    //#endregion

    constructor(
        private navigationService: NavigationService,
        private entityUiService: EntityUiService,
        private entityEventService: EntityEventService,
        private functionalLogService: FunctionalLogService,
        private entityDockingPaneService: EntityDockingPaneService,
        private entitySuggestionStore: EntitySuggestionStoreService,
        private appEventsService: AppEventsService,
        private entityCacheService: EntityCacheService,
        private dataProcessingUiService: DataProcessingUiService,
        private modelerService: ModelerService,
        private userService: UserService,
        private entityService: EntityService,
        private entitySecurityService: EntitySecurityService,
        private attributeDataService: AttributeDataService,
        private exportService: ExportService,
        private viewTypeService: ViewTypeService,
        private dataQualityService: DataQualityService
    ) {
        super();
    }

    ngOnInit() {
        this.log(
            'ngOnInit',
            DgModule[this.dgModule],
            this.spaceIdr,
            this.entityData
        );

        this.serverType = DataUtil.getDefaultServerTypeFromModule(
            this.dgModule
        );
        this.tradkeyPrefix = DataUtil.getModuleTranslateKeyPrefix(
            this.dgModule
        );

        if (this.entityData?.HddData) {
            this.currentEntityDataService.setCurrentEntityData(this.entityData);
            this.notifyEntityDashboardFocus();
            this.featureCode =
                FunctionalLogService.getEntityItemActionFeatureCode(
                    this.entityData
                );
        }

        if (!this.hasEntity) {
            return;
        }

        if (this.isCatalog) {
            this.serverType = this.entityData.Type;
        } else if (this.isProcessings) {
            this.showProcessingGlobalUnmappedChildren = true;
        }

        this.onEntityChanged(this.entityData, true);

        this.entityEventService.notifyEntityChange(this.entityData);

        this.functionalLogService.logEntityItemAction(
            this.entityData,
            CrudOperation.R
        );

        const tabItems = this.tabsData.tabItems;
        CollectionsHelper.withFirstFound(
            tabItems,
            (ti) => this.navigationService.isUiTabActive(ti),
            (ti) => (this.currentTabRelativeStateName = ti.tabStateName)
        );
        this.log(
            'ngOnInit-tabItems',
            this.currentTabRelativeStateName,
            tabItems
        );

        if (this.currentTabRelativeStateName == undefined) {
            this.log('ngOnInit', 'setting first tab as current');
            this.currentTabRelativeStateName = tabItems[0].tabStateName;
            this.navigationService.goToRelativeTab(
                this.currentTabRelativeStateName
            );
        }

        this.entitySuggestionStore.setEntity(this.entityData);
        this.subscribeEvents();
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.entitySuggestionStore.clearState();
    }

    //#region event handlers

    public onClickToggleFullPage() {
        this.navigationService.toggleIsEntityFullPage();
    }

    public onTabChanged(tabItem: ITabViewItem) {
        this.log('onTabChanged', tabItem);
        this.buildDropdownOptions();
    }

    private async onCloneBtnClick() {
        this.functionalLogService.logEntityItemAction(
            this.entityData,
            CrudOperation.C
        );
        await this.entityService.cloneEntity(this.entityData, {
            actionOrigin: EntityCreationOrigin.dashboardCloneButton,
        });
        await this.navigationService.setIsEntityFullPage(false);
    }

    private async onFusionBtnClick() {
        await this.entityUiService.onFusionEntity(this.entityData);
    }

    private async onExportBtnClick() {
        await this.exportService.exportEntity(this.entityData);
    }

    private async onDeleteBtnClick() {
        await this.entityUiService.onDeleteEntity(this.entityData);
    }

    private onCreateEntity(
        entityIdr: IEntityIdentifier,
        ctx: IEntityCreationContext
    ) {
        if (
            ![
                EntityCreationOrigin.burgerMenu,
                EntityCreationOrigin.burgerMenuCreateChild,
                EntityCreationOrigin.dashboardCloneButton,
            ].includes(ctx.origin) ||
            //https://datagalaxy.atlassian.net/browse/DG-2468
            (ctx.isMultiCreation &&
                ctx.origin == EntityCreationOrigin.burgerMenuCreateChild)
        ) {
            return;
        }

        this.log(
            'onCreateEntity',
            entityIdr,
            EntityCreationOrigin[ctx?.origin],
            ctx.isMultiCreation
        );
        setTimeout(() =>
            this.navigationService.goToEntityDetails(this.dgModule, entityIdr, {
                modelId: this.modelId,
                isEntityFullPage: false,
            })
        );
    }

    private async onDeleteEntities(entityIds: string[], external: boolean) {
        const isCurrent = () => {
            const entityId = this.entityData?.ReferenceId;
            return entityId && entityIds?.includes(entityId);
        };
        if (!isCurrent()) {
            return;
        }

        if (external) {
            await this.entityUiService.showDeleteInfoDialog();
        }

        this.entityDockingPaneService.resetPanel();
        if (isCurrent()) {
            await this.navigationService.setIsEntityFullPage(false);
        }

        const options: IGotoDetailsOptions = {
            spaceIdr: this.spaceIdr,
            isEntityFullPage: false,
        };
        await this.navigationService.goToEntityDetails(
            this.dgModule,
            null,
            options
        );
    }

    /* Trigger an event to mark updated fields for multi-users */
    private onUpdateEntity(updatedEntity: EntityItem, external: boolean) {
        if (
            !updatedEntity ||
            !this.entityData ||
            this.entityData.ReferenceId !== updatedEntity.ReferenceId
        ) {
            return;
        }
        this.entityEventService.notifyEntityFieldsUpdate(
            updatedEntity,
            external
        );
        EntityUtils.mergeEntity(this.entityData, updatedEntity, true);
    }

    private onUpdateEntityParent(
        result: SetEntitiesParentResult,
        external: boolean
    ) {
        result.UpdatedEntities.forEach((entity) =>
            this.onUpdateEntity(entity, external)
        );
    }

    private onEntityChanged(entity: EntityItem, isInit = false) {
        this.buildDropdownOptions();
        this.updateTabs(entity, isInit);
    }

    //#endregion

    //#region dropdown actions
    private buildDropdownOptions() {
        const options: IDropdownButtonOption[] = [];
        //#region common actions
        this.showEntityCloneButton &&
            options.push({
                titleKey: 'UI.Global.btnClone',
                glyphClass: 'glyph-file-copy',
                callback: () => this.onCloneBtnClick(),
                logFunctional: 'CLONE,C',
            });
        this.showEntityFusionButton &&
            options.push({
                titleKey: 'UI.Global.btnFusion',
                glyphClass: 'glyph-fusion',
                callback: () => this.onFusionBtnClick(),
            });
        this.showEntityExportButton &&
            options.push({
                titleKey: this.exportButtonText,
                glyphClass: 'glyph-download',
                callback: () => this.onExportBtnClick(),
                logFunctional: 'EXPORT_DP,R',
            });
        this.showEntityDeleteButton &&
            options.push({
                titleKey: 'UI.Global.btnDelete',
                glyphClass: 'glyph-delete',
                callback: () => this.onDeleteBtnClick(),
            });
        //#endregion

        //#region catalog specific actions
        this.showSeparatorDdl &&
            options.push({
                isSeparator: true,
            });
        this.showGenerateDdl &&
            options.push({
                titleKey: 'UI.Modeler.generateScript',
                glyphClass: 'glyph-ddl',
                callback: () => this.generateDdl(),
            });
        //#endregion

        //#region processing specific actions
        this.showProcessingUnmappedGlobalColumnsBtn &&
            options.push({
                titleKey: this.showProcessingGlobalUnmappedChildren
                    ? 'UI.DataProcessingItem.ttToggleHideMappingColumns'
                    : 'UI.DataProcessingItem.ttToggleShowMappingColumns',
                glyphClass: 'glyph-hide',
                callback: () => this.showProcessingUnmappedGlobalColumns(),
            });
        //#endregion
        this.entityActions = options;
    }

    //#region Processing specific
    private showProcessingUnmappedGlobalColumns() {
        if (!this.isProcessings) {
            return;
        }
        this.dataProcessingUiService.setShowGlobalUnmappedChildren(
            this.showProcessingGlobalUnmappedChildren
        );
        this.showProcessingGlobalUnmappedChildren =
            !this.showProcessingGlobalUnmappedChildren;
        setTimeout(
            () =>
                this.dataProcessingUiService.emitMappingToggleShowUnmappedGlobalColumns(),
            500
        );
    }

    //#endregion

    //#region Catalog specific
    private generateDdl() {
        if (!this.isCatalog) {
            return;
        }
        switch (this.serverType) {
            case ServerType.Model:
                this.modelerService.generateDdlScriptForModel(
                    this.entityData.ReferenceId
                );
                break;
            case ServerType.Table:
                this.modelerService.generateDdlScriptForTable(
                    this.entityData.ReferenceId,
                    this.modelDescriptor.DataReferenceId
                );
                break;
        }
    }

    //#endregion
    //#endregion

    //#region tabs

    private updateTabs(entity: EntityItem, isInit = false) {
        if (
            !isInit &&
            (!this.entityData ||
                entity.ReferenceId != this.entityData.ReferenceId)
        ) {
            return;
        }

        this.computeTabCounts(entity, isInit);
        this.tabsData = {
            tabItems: this.makeTabItems(),
            tabFeatureCodeCustomPrefix: `${FunctionalLogService.getEntityItemActionFeatureCode(
                this.entityData
            )}_`,
            getTabItemTranslateKey: (tabItem) =>
                this.getTabItemTranslateKey(tabItem),
            /* note this is called only for tabItems having *isStateNameRelative* true,
               that is, only when in the catalog */
            onActiveTabItem: async (tabItem: ITabViewItem) => {
                this.currentTabRelativeStateName = tabItem.tabStateName;
                this.log('onActiveTabItem', tabItem.tabStateName);
                await this.navigationService.goToRelativeTab(
                    tabItem.tabStateName
                );
            },
        };
    }

    private updateCatalogTabs(
        tabId: TabId,
        data: EntityCreateEventData | EntityDeleteEventData,
        increment: boolean
    ) {
        this.log('updateCatalogTabs', TabId[tabId], data, increment);

        // TODO : subscribe to entities deletion when real-time & entities subscriptions are mature enough

        if (
            !data ||
            !this.entityData ||
            !this.isTabEnabled(tabId) ||
            !this.tabCounts.has(tabId)
        ) {
            return;
        }

        const count = this.tabCounts.get(tabId) ?? 0;
        const currentEntityId = this.entityData.ReferenceId;
        if (increment) {
            const entity = (data as EntityCreateEventData)?.entity;
            switch (tabId) {
                case TabId.containers:
                case TabId.tables:
                    if (
                        !entity.HddData.Parents.some(
                            (parent) =>
                                parent.DataReferenceId == currentEntityId
                        )
                    ) {
                        return;
                    }
                    break;
                case TabId.columns:
                    if (entity.LogicalParentId != currentEntityId) {
                        return;
                    }
                    break;
                case TabId.diagram:
                    if (
                        !DiagramDataService.hasEntityId(entity, currentEntityId)
                    ) {
                        return;
                    }
                    break;
            }
            this.tabCounts.set(tabId, count + 1);
        } else {
            if (count <= 0) {
                return;
            }
            switch (tabId) {
                case TabId.containers:
                    /* Multi user & multi deletion count update is not handled for now : it would need sever to send back all deleted entities hdd which is potentially huge*/
                    /* Single & local deletion is not handled as well because it would need to temporary send HddData*/
                    return;
                case TabId.tables:
                    /* Multi user & multi deletion count update is not handled for now : it would need sever to send back all deleted entities hdd which is potentially huge*/
                    return;
                case TabId.columns:
                    /* Multi user & multi deletion count update is not handled for now : it would need sever to send back all deleted entities hdd which is potentially huge*/
                    return;
                case TabId.diagram:
                    //same as up: with only the deleted entityId, we cannot know if it's a diagram that contains the current entity without calling the backend
                    return;
            }
            this.tabCounts.set(tabId, count - 1);
        }
        this.log(
            'updateCatalogTabs-updated',
            TabId[tabId],
            this.tabCounts.get(tabId)
        );
        this.makeTabItems();
    }

    private computeTabCounts(entity: EntityItem, isInit?: boolean) {
        let countDiagrams = false;
        switch (this.dgModule) {
            case DgModule.Glossary:
                countDiagrams = true;
                this.tabCounts.set(
                    TabId.mapping,
                    (entity?.getAttributeValue(
                        ServerConstants.PropertyName.ImplementationLinkCount
                    ) as number) ?? 0
                );
                break;

            case DgModule.Processing:
                countDiagrams = true;
                break;

            case DgModule.Usage:
                countDiagrams = true;
                this.tabCounts.set(
                    TabId.mapping,
                    (entity?.getAttributeValue(
                        ServerConstants.PropertyName.SoftwareTotalLinkCount
                    ) as number) ?? 0
                );
                break;

            case DgModule.Catalog:
                if (this.isTabEnabled(TabId.diagram)) {
                    countDiagrams = true;
                }
                this.tabCounts.set(
                    TabId.columns,
                    this.entityData.getAttributeValue(
                        ServerConstants.PropertyName.TableColumnCount
                    ) as number
                );
                this.tabCounts.set(
                    TabId.containers,
                    this.entityData.getAttributeValue(
                        ServerConstants.PropertyName.ContainerContainerCount
                    ) as number
                );
                this.tabCounts.set(
                    TabId.tables,
                    this.entityData.getAttributeValue(
                        ServerConstants.PropertyName.ContainerTableCount
                    ) as number
                );
                if (this.isModelRelational) {
                    this.tabCounts.set(
                        TabId.pks,
                        this.entityData.getAttributeValue(
                            ServerConstants.PropertyName.ModelPrimaryKeyCount
                        ) as number
                    );
                    this.tabCounts.set(
                        TabId.fks,
                        this.entityData.getAttributeValue(
                            ServerConstants.PropertyName.ModelForeignKeyCount
                        ) as number
                    );
                }
                break;
        }

        const entityLinkCount =
            (entity?.getAttributeValue(
                ServerConstants.PropertyName.EntityLinkCount
            ) as number) ?? (isInit ? 0 : undefined);
        if (entityLinkCount != undefined) {
            this.tabCounts.set(TabId.linkedObjects, entityLinkCount);
        }
        if (countDiagrams) {
            this.tabCounts.set(
                TabId.diagram,
                this.entityData.getAttributeValue(
                    ServerConstants.PropertyName.EntityDiagramCount
                ) as number
            );
        }
    }

    private makeTabItems() {
        return this.getTabIds().map(
            (tabId) =>
                ({
                    tabId: this.getTabIdOrTranslateKey(tabId),
                    tabStateName: this.getTabStateName(tabId),
                    disabled: !this.isTabEnabled(tabId),
                    contentDataCount: this.tabCounts.get(tabId),
                    isStateNameRelative: this.isCatalog,
                } as ITabViewItem)
        );
    }

    private getTabIds(): TabId[] {
        switch (this.dgModule) {
            case DgModule.Glossary:
                return [
                    TabId.details,
                    TabId.linkedObjects,
                    TabId.mapping,
                    TabId.diagram,
                    TabId.impactAnalysis,
                ];

            case DgModule.Processing:
                return [
                    TabId.details,
                    TabId.linkedObjects,
                    TabId.mapping,
                    TabId.diagram,
                    TabId.impactAnalysis,
                ];

            case DgModule.Usage:
                return [
                    TabId.details,
                    TabId.components,
                    TabId.fields,
                    TabId.linkedObjects,
                    TabId.diagram,
                    TabId.impactAnalysis,
                ];

            case DgModule.Catalog: {
                const commonTabs = [
                    TabId.details,
                    TabId.dataQuality,
                    TabId.linkedObjects,
                ];
                switch (this.serverType) {
                    case ServerType.Model:
                        return [
                            ...commonTabs,
                            TabId.containers,
                            TabId.tables,
                            TabId.pks,
                            TabId.fks,
                            TabId.diagram,
                            TabId.impactAnalysis,
                            TabId.settings,
                        ];

                    case ServerType.Container:
                        return [
                            ...commonTabs,
                            TabId.containers,
                            TabId.tables,
                            TabId.impactAnalysis,
                        ];

                    case ServerType.Table: {
                        const baseTabs = [
                            ...commonTabs,
                            TabId.columns,
                            TabId.diagram,
                            TabId.impactAnalysis,
                        ];
                        const sampleParams =
                            this.userService.clientDenodoSampleParameters;
                        if (sampleParams?.featureEnabled) {
                            const modelName =
                                this.entityData.HddData.Parents.find(
                                    (ancestor) =>
                                        ancestor.DataReferenceId == this.modelId
                                )?.TechnicalName;
                            if (
                                modelName.toLowerCase() ==
                                sampleParams.sourceTechnicalName.toLowerCase()
                            ) {
                                baseTabs.push(TabId.sample);
                            }
                        }
                        return baseTabs;
                    }
                    case ServerType.Column:
                        return [...commonTabs, TabId.impactAnalysis];
                }
                break;
            }
        }
    }

    private getTabIdOrTranslateKey(tabId: TabId) {
        if (this.isCatalog) {
            switch (tabId) {
                case TabId.pks:
                    return this.getPrimaryKeysTabTranslateKey();
                case TabId.fks:
                    return this.getForeignKeysTabTranslateKey();
                default:
                    break;
            }
        }
        return TabId[tabId];
    }

    private getTabStateName(tabId: TabId) {
        switch (this.dgModule) {
            case DgModule.Glossary:
                switch (tabId) {
                    case TabId.details:
                        return StateName.GlossaryDetails;
                    case TabId.linkedObjects:
                        return StateName.GlossaryLinkedObjects;
                    case TabId.mapping:
                        return StateName.GlossaryMapping;
                    case TabId.diagram:
                        return StateName.GlossaryDiagramsTab;
                    case TabId.impactAnalysis:
                        return StateName.GlossaryImpactAnalysis;
                }
                break;

            case DgModule.Processing:
                switch (tabId) {
                    case TabId.details:
                        return StateName.DataProcessingDetails;
                    case TabId.linkedObjects:
                        return StateName.DataProcessingLinkedObjects;
                    case TabId.mapping:
                        return StateName.DataProcessingMapping;
                    case TabId.diagram:
                        return StateName.DataProcessingDiagramsTab;
                    case TabId.impactAnalysis:
                        return StateName.DataProcessingImpactAnalysis;
                }
                break;

            case DgModule.Usage:
                switch (tabId) {
                    case TabId.details:
                        return StateName.SoftwareDetails;
                    case TabId.components:
                        return StateName.SoftwareComponents;
                    case TabId.fields:
                        return StateName.SoftwareFields;
                    case TabId.linkedObjects:
                        return StateName.SoftwareLinkedObjects;
                    case TabId.diagram:
                        return StateName.SoftwareDiagramsTab;
                    case TabId.impactAnalysis:
                        return StateName.SoftwareImpactAnalysis;
                }
                break;

            case DgModule.Catalog:
                switch (tabId) {
                    case TabId.details:
                        return StateName.RelativeTabDetails;
                    case TabId.dataQuality:
                        return StateName.RelativeTabDataQuality;
                    case TabId.linkedObjects:
                        return StateName.RelativeTabLinkedObjects;
                    case TabId.impactAnalysis:
                        return StateName.RelativeTabImpactAnalysis;
                    case TabId.diagram:
                        return StateName.RelativeTabDiagrams;
                    case TabId.settings:
                        return StateName.RelativeTabModelSettings;
                    case TabId.columns:
                        return StateName.RelativeTabColumns;
                    case TabId.containers:
                        return StateName.RelativeTabContainers;
                    case TabId.tables:
                        return StateName.RelativeTabTables;
                    case TabId.pks:
                        return StateName.RelativeTabPrimaryKeys;
                    case TabId.fks:
                        return StateName.RelativeTabForeignKeys;
                    case TabId.sample:
                        return StateName.RelativeTabSample;
                }
                break;
        }

        CoreUtil.warn(
            'getTabStateName',
            'not implemented: tabId',
            TabId[tabId],
            'module:',
            DgModule[this.dgModule]
        );
    }

    private isTabEnabled(tabId: TabId) {
        const entity = this.entityData,
            entityType = entity?.EntityType;
        switch (this.dgModule) {
            case DgModule.Glossary:
                if (!this.hasEntity) {
                    return false;
                }
                switch (tabId) {
                    case TabId.linkedObjects:
                        return DataUtil.isLinkedObjectsEnabled(entityType);
                    case TabId.mapping:
                        return DataUtil.isGlossaryMappingEnabled(
                            this.spaceIdr,
                            entityType
                        );
                    default:
                        return true;
                }
            case DgModule.Processing:
                switch (tabId) {
                    case TabId.mapping:
                        return DataUtil.isDataProcessingMappingEnabled(
                            entityType
                        );
                    default:
                        return true;
                }
            case DgModule.Usage:
                switch (tabId) {
                    case TabId.components:
                        return EntityTypeUtil.hasEntityTypeAsChild(
                            entityType,
                            EntityType.UsageComponent
                        );
                    case TabId.fields:
                        return EntityTypeUtil.hasEntityTypeAsChild(
                            entityType,
                            EntityType.UsageField
                        );
                    default:
                        return true;
                }
            case DgModule.Catalog:
                switch (tabId) {
                    case TabId.diagram:
                        return DataUtil.isModelDiagramsEnabled(entity?.HddData);
                    case TabId.settings:
                        return ModelerDataUtil.isModelSettingEnabled(entity);
                    case TabId.pks:
                        return (
                            this.isModelRelational &&
                            this.tabCounts.get(TabId.pks) != undefined
                        );
                    case TabId.fks:
                        return (
                            this.isModelRelational &&
                            this.tabCounts.get(TabId.fks) > 0
                        );
                    case TabId.dataQuality:
                        return this.dataQualityService.isDataQualityEnabled(
                            entity?.entityType
                        );
                    default:
                        return true;
                }
        }
        return false;
    }

    private getTabItemTranslateKey(tabItem: ITabViewItem) {
        if (this.isCatalog) {
            switch (tabItem.tabStateName) {
                case StateName.RelativeTabPrimaryKeys:
                    return this.getPrimaryKeyListTranslateKey();
                case StateName.RelativeTabForeignKeys:
                    return this.getForeignKeysTabTranslateKey();
                case StateName.RelativeTabColumns:
                    return this.getColumnListTabTranslateKey(
                        this.isModelRelational
                    );
                case StateName.RelativeTabContainers:
                    return this.getContainerListTabTranslateKey(
                        this.isModelRelational
                    );
                case StateName.RelativeTabTables:
                    return this.getTableListTabTranslateKey(
                        this.isModelRelational
                    );
                case StateName.RelativeTabSample:
                    return this.getDenodoSampleTabTranslateKey();
                default:
                    break;
            }
        }
        return `${this.tradkeyPrefix}tabView.${tabItem.tabId}`;
    }

    private getColumnListTabTranslateKey(isRelationalModel: boolean) {
        return !isRelationalModel
            ? 'UI.Modeler.Navigator.fieldListTab'
            : this.isTechnicalView
            ? 'UI.Modeler.Navigator.columnListTabTechnical'
            : 'UI.Modeler.Navigator.columnListTabFunctional';
    }

    private getTableListTabTranslateKey(isRelationalModel: boolean) {
        return !isRelationalModel
            ? 'UI.Modeler.Navigator.structureListTab'
            : this.isTechnicalView
            ? 'UI.Modeler.Navigator.tableListTabTechnical'
            : 'UI.Modeler.Navigator.tableListTabFunctional';
    }

    private getContainerListTabTranslateKey(isRelationalModel: boolean) {
        return isRelationalModel
            ? 'UI.Modeler.Navigator.modelListTab'
            : 'UI.Modeler.Navigator.containerListTab';
    }

    private getPrimaryKeyListTranslateKey() {
        return this.isTechnicalView
            ? 'UI.Modeler.Navigator.primaryKeyList'
            : 'UI.Modeler.Navigator.identifierList';
    }

    private getPrimaryKeysTabTranslateKey() {
        return this.isTechnicalView
            ? 'UI.Modeler.tabView.primaryKeyList'
            : 'UI.Modeler.tabView.identifierList';
    }

    private getForeignKeysTabTranslateKey() {
        return this.isTechnicalView
            ? 'UI.Modeler.tabView.foreignKeyList'
            : 'UI.Modeler.tabView.referenceList';
    }

    private getDenodoSampleTabTranslateKey() {
        return 'UI.Modeler.tabView.denodoSampleTab';
    }

    //#endregion

    //#region events subscription

    @HostListener('click', ['$event'])
    private onHostClick() {
        this.notifyEntityDashboardFocus();
    }

    private subscribeEvents() {
        if (this.isCatalog) {
            this.subscribeCatalogEvents();
        } else {
            this.subscribeEventsByServerType(this.serverType);
        }
        this.subscribeDiagramEntityEvents();
    }

    private subscribeCatalogEvents() {
        // Subscribe to entity events for all modeler types to ensure update & navigation
        ModelerDataUtil.modelerServerTypes.forEach((modelerServerType) =>
            this.subscribeEventsByServerType(modelerServerType)
        );

        const serverType = this.serverType;
        if (
            serverType == ServerType.Model ||
            serverType == ServerType.Container
        ) {
            // Subscribe to containers and tables creation & deletion
            super.registerSubscriptions(
                this.entityEventService.subscribeEntityCreation(
                    ServerType.Container,
                    (data) =>
                        this.updateCatalogTabs(TabId.containers, data, true)
                ),
                this.entityEventService.subscribeEntityCreation(
                    ServerType.Table,
                    (data) => this.updateCatalogTabs(TabId.tables, data, true)
                ),
                this.entityEventService.subscribeEntityDelete(
                    ServerType.Container,
                    (data) =>
                        this.updateCatalogTabs(TabId.containers, data, false)
                ),
                this.entityEventService.subscribeEntityDelete(
                    ServerType.Table,
                    (data) => this.updateCatalogTabs(TabId.tables, data, false)
                )
            );
        } else if (serverType == ServerType.Table) {
            // Subscribe to columns creation & deletion
            super.registerSubscriptions(
                this.entityEventService.subscribeEntityCreation(
                    ServerType.Column,
                    (data) => this.updateCatalogTabs(TabId.columns, data, true)
                ),
                this.entityEventService.subscribeEntityDelete(
                    ServerType.Column,
                    (data) => this.updateCatalogTabs(TabId.columns, data, false)
                )
            );
        }
    }

    private subscribeDiagramEntityEvents() {
        super.registerSubscriptions(
            this.entityEventService.subscribeEntityCreation(
                ServerType.Diagram,
                (data) => this.updateCatalogTabs(TabId.columns, data, true)
            ),
            this.entityEventService.subscribeEntityDelete(
                ServerType.Diagram,
                (data) => this.updateCatalogTabs(TabId.columns, data, false)
            )
        );
    }

    private subscribeEventsByServerType(serverType: ServerType) {
        super.registerSubscriptions(
            this.entityEventService.subscribeEntityLinkAdd(null, (entity) =>
                this.onEntityChanged(entity)
            ),
            this.entityEventService.subscribeEntityLinkDelete(null, (entity) =>
                this.onEntityChanged(entity)
            )
        );

        this.registerSubscriptions(
            this.entityEventService.subscribeEntityCreationLocal(
                serverType,
                (entity, _, context) => this.onCreateEntity(entity, context)
            ),

            this.entityEventService.subscribeEntityUpdate(
                serverType,
                (entity, external) => this.onUpdateEntity(entity, external)
            ),

            this.entityEventService.subscribeEntityDelete(
                serverType,
                (data, external) =>
                    this.onDeleteEntities(data.deletedIds, external)
            ),

            this.entityEventService.subscribeEntityParentUpdate(
                serverType,
                (data, external) => this.onUpdateEntityParent(data, external)
            )
        );
    }

    //#endregion

    private notifyEntityDashboardFocus() {
        this.log('notifyEntityDashboardFocus');
        this.appEventsService.notifyEntityDashboardFocus();
    }
}

export enum TabId {
    unknown = 0,
    //#region glossary, usage, processing, catalog
    details,
    impactAnalysis,
    //#endregion
    //#region glossary, usage, processing
    mapping,
    linkedObjects,
    //#endregion
    // usage
    fields,
    components,
    //#region catalog
    dataQuality,
    diagram,
    settings,
    columns,
    containers,
    tables,
    pks,
    fks,
    sample,
    //#endregion
}
