import { IActionOption, IListOption } from '@datagalaxy/core-ui';
import { IHasHddData } from '@datagalaxy/dg-object-model';

export enum DksEntityMenuOption {
    Preview,
}

export type TDksEntityMenuOption =
    | IActionOption<IHasHddData>
    | DksEntityMenuOption
    | IListOption<IHasHddData>;
export type TDksEntityMenu = TDksEntityMenuOption[];
