import { GenericDeserialize } from 'cerialize';
import { CollectionsHelper, CoreUtil } from '@datagalaxy/core-util';
import { ModelerDataUtil } from './ModelerDataUtil';
import {
    EntityType,
    IHierarchicalData,
    ServerType,
} from '@datagalaxy/dg-object-model';
import { HierarchicalData } from '@datagalaxy/dg-object-model';
import { HierarchyDataDescriptor } from '@datagalaxy/dg-object-model';
import { Space } from '@datagalaxy/webclient/workspace/data-access';
import { getLocalId } from '@datagalaxy/webclient/utils';
import { LinkedDataItem } from '@datagalaxy/webclient/entity/domain';
import { ServerConstants } from '@datagalaxy/shared/server/domain';

/* ATTENTION: Cannot import DataUtil here */

declare type HdInput = {
    readonly ReferenceId: string;
    readonly ServerType: ServerType;
    DisplayName: string;
    SubTypeName?: string;
    VersionId?: string;
};

export class HddUtil {
    static isSpace(hdd: HierarchyDataDescriptor) {
        return !!hdd && hdd.DataLocalId === hdd.DataContextId;
    }

    static isDataDescriptorDirectChildOfModule(
        dataDescriptor: HierarchyDataDescriptor
    ) {
        switch (dataDescriptor?.DataServerType) {
            case ServerType.Model:
            case ServerType.Property:
            case ServerType.DataProcessing:
            case ServerType.SoftwareElement:
                return true;
            default:
                return false;
        }
    }

    static getHierarchyDataDescriptorModuleDisplayName(
        hdd: HierarchyDataDescriptor
    ) {
        switch (ServerType[hdd.DataTypeName]) {
            case ServerType.Model:
                return 'UI.NavBreadcrumb.lblModelerModule';
            case ServerType.Property:
                return 'UI.NavBreadcrumb.lblGlossaryModule';
            case ServerType.DataProcessing:
                return 'UI.NavBreadcrumb.lblDataProcessingModule';
            case ServerType.SoftwareElement:
                return 'UI.NavBreadcrumb.lblSoftwareModule';
            default:
                return null;
        }
    }

    /** returns the ordered ancestors of the given hierarchicalData, taking into account only one direct parent of each */
    static getHierarchyDataDescriptorList(hdata: IHierarchicalData) {
        return HddUtil.getHierarchyDataDescriptorListInternal(
            hdata.Data,
            hdata.Parents
        );
    }
    private static getHierarchyDataDescriptorListInternal(
        currentDataDescriptor: HierarchyDataDescriptor,
        parentDataDescriptors: HierarchyDataDescriptor[],
        hierarchyDataDescriptors: HierarchyDataDescriptor[] = []
    ): HierarchyDataDescriptor[] {
        if (
            currentDataDescriptor.DataTypeName !==
                ServerConstants.TypeName.Organization &&
            currentDataDescriptor.DataTypeName !==
                ServerConstants.TypeName.Project
        ) {
            if (
                currentDataDescriptor.DataServerType == ServerType.FilteredView
            ) {
                if (parentDataDescriptors?.length) {
                    hierarchyDataDescriptors.push(parentDataDescriptors[0]);
                }
            } else {
                const directParentDataDescriptors =
                    currentDataDescriptor.ParentList.map((parentId) =>
                        parentDataDescriptors.find(
                            (o) => o.DataLocalId == parentId
                        )
                    );
                const selectedParentDataDescriptor =
                    HddUtil.selectParentDataDescriptor(
                        currentDataDescriptor,
                        directParentDataDescriptors
                    );
                if (selectedParentDataDescriptor) {
                    hierarchyDataDescriptors.push(selectedParentDataDescriptor);
                    return HddUtil.getHierarchyDataDescriptorListInternal(
                        selectedParentDataDescriptor,
                        parentDataDescriptors,
                        hierarchyDataDescriptors
                    );
                }
            }
        }
        return hierarchyDataDescriptors;
    }
    private static selectParentDataDescriptor(
        currentDataDescriptor: HierarchyDataDescriptor,
        parentDataDescriptors: HierarchyDataDescriptor[]
    ) {
        if (!currentDataDescriptor || !parentDataDescriptors?.length)
            return null;
        if (parentDataDescriptors.length == 1) return parentDataDescriptors[0];

        switch (currentDataDescriptor.DataServerType) {
            case ServerType.ForeignKey: {
                const dtn = ServerType[ServerType.Table];
                return parentDataDescriptors.find(
                    (hdd) => hdd.DataTypeName == dtn
                );
            }
            default:
                return parentDataDescriptors[0];
        }
    }

    static getLegacyCatalogFirstLevelParent(
        serverType: ServerType,
        hddData: HierarchicalData
    ) {
        let serverTypes: ServerType[];
        switch (serverType) {
            case ServerType.Table:
                serverTypes = [ServerType.Model];
                break;

            case ServerType.Column:
                serverTypes = [ServerType.Table];
                break;

            default:
                CoreUtil.warn(
                    'getContainerParent',
                    'not implemented',
                    ServerType[serverType]
                );
                return;
        }
        return HddUtil.getParentDataDescriptorByType(
            hddData,
            serverTypes,
            true
        );
    }

    /** returns the first item of the given item's ancestors corresponding to the given types */
    static getParentDataDescriptorByType(
        hdata: IHierarchicalData,
        serverTypes: ServerType[],
        excludeSelf = false
    ) {
        return (
            hdata &&
            HddUtil.getParentDataDescriptorByTypeInternal(
                hdata.Data,
                hdata.Parents,
                serverTypes,
                excludeSelf
            )
        );
    }

    /** returns the first item of the given item's ancestors corresponding to the given types */
    private static getParentDataDescriptorByTypeInternal(
        currentDataDescriptor: HierarchyDataDescriptor,
        parentDataDescriptors: HierarchyDataDescriptor[],
        serverTypes: ServerType[],
        excludeSelf = false
    ): HierarchyDataDescriptor {
        if (
            !currentDataDescriptor ||
            (!excludeSelf &&
                serverTypes?.includes(currentDataDescriptor.DataServerType))
        ) {
            return currentDataDescriptor;
        }

        const parentList = currentDataDescriptor.ParentList;
        // If no immediate parent, we abandon the recursion
        if (!parentList.length) {
            return null;
        }

        const id = parentList[0];
        const parentDataDescriptor = parentDataDescriptors?.find(
            (hdd) => hdd.DataLocalId == id
        );
        if (!parentDataDescriptor) {
            return null;
        }

        return HddUtil.getParentDataDescriptorByTypeInternal(
            parentDataDescriptor,
            parentDataDescriptors,
            serverTypes
        );
    }

    static getModelId(hd: IHierarchicalData) {
        return HddUtil.getModelHdd(hd)?.DataReferenceId;
    }

    static getModelHdd(hd: IHierarchicalData) {
        return (
            ModelerDataUtil.isModelerServerType(hd.DataServerType) &&
            HddUtil.getFirstHddByType(hd, ServerType.Model)
        );
    }

    static getFirstHddByType(
        hd: IHierarchicalData,
        type: ServerType | ServerType[]
    ) {
        return (
            hd &&
            type &&
            HddUtil.getParentDataDescriptorByType(
                hd,
                Array.isArray(type) ? type : [type]
            )
        );
    }

    static createHDataForSpace(space: Space) {
        return HddUtil.createHData(space);
    }

    private static createHData(object: HdInput, ...orderedParents: HdInput[]) {
        const parentIds = orderedParents.map((o) => getLocalId(o.ReferenceId));
        const data = HddUtil.createHdd(object, parentIds[0]);
        const parents = orderedParents.map((p, i) =>
            HddUtil.createHdd(p, parentIds[i + 1])
        );
        const result = new HierarchicalData(data, parents);
        let versionId = data.VersionId;
        if (!versionId) {
            CollectionsHelper.withFirstFound(
                parents,
                (hd) => hd.VersionId != undefined,
                (hd) => (versionId = hd.VersionId)
            );
        }
        if (versionId) {
            result.setVersionId(versionId);
        }
        return result;
    }
    private static createHdd(object: HdInput, parentLocalId: string) {
        const data = new HierarchyDataDescriptor(
            object.ReferenceId,
            object.ServerType
        );
        data.DisplayName = object.DisplayName;
        data.SubTypeName = object.SubTypeName;
        data.VersionId = object.VersionId;
        data.ParentList = parentLocalId ? [parentLocalId] : [];
        return data;
    }

    static hasAnyParent(hdd: IHierarchicalData, parentIds: string[]) {
        return (
            !!parentIds?.length &&
            CollectionsHelper.hasAny(hdd.Parents, (e) =>
                parentIds.includes(e.DataReferenceId)
            )
        );
    }

    static deserializeLinkedDataItem<
        TData extends LinkedDataItem,
        TArg extends TData | TData[]
    >(objOrArray: TArg): TArg {
        return !objOrArray
            ? (objOrArray as TArg)
            : Array.isArray(objOrArray)
            ? (objOrArray.map(HddUtil.deserializeLinkedDataItem) as TArg)
            : (GenericDeserialize(objOrArray, LinkedDataItem) as TArg);
    }

    static deserializeHierarchicalData<
        TData extends HierarchicalData,
        TArg extends TData | TData[]
    >(objOrArray: TArg): TArg {
        return !objOrArray
            ? (objOrArray as TArg)
            : Array.isArray(objOrArray)
            ? (objOrArray.map(HddUtil.deserializeHierarchicalData) as TArg)
            : (GenericDeserialize(objOrArray, HierarchicalData) as TArg);
    }

    static deserializeHdd<
        TData extends HierarchyDataDescriptor,
        TArg extends TData | TData[]
    >(objOrArray: TArg): TArg {
        return !objOrArray
            ? (objOrArray as TArg)
            : Array.isArray(objOrArray)
            ? (objOrArray.map(HddUtil.deserializeHdd) as TArg)
            : (GenericDeserialize(objOrArray, HierarchyDataDescriptor) as TArg);
    }

    static getWithRemovedContainers(parents: HierarchyDataDescriptor[]) {
        // work with a copy
        parents = parents.slice();

        // find the closest table or model in the hierarchy - parents order is [child, parent, grand-parent]
        const serverTypes = [ServerType.Table, ServerType.Model];
        const firstParent = parents?.find((p) =>
            serverTypes.includes(p.DataServerType)
        );
        if (!firstParent) {
            return parents;
        }

        if (firstParent.DataServerType == ServerType.Model) {
            parents = CollectionsHelper.drop(
                parents,
                parents.findIndex((p) => p.DataServerType == ServerType.Model)
            );
        } else if (firstParent.DataServerType == ServerType.Table) {
            // unclear here
            const modelIndex = parents.findIndex(
                (p) => p.DataServerType == ServerType.Model
            );
            const firstParentId = firstParent.DataReferenceId;
            parents = CollectionsHelper.drop(
                parents.slice(1),
                modelIndex -
                    parents.findIndex(
                        (p) => p.DataReferenceId == firstParentId
                    ) -
                    1
            );
        }

        return parents;
    }

    static extractParentHData(hdata: IHierarchicalData, parentId: string) {
        const parentIndex = hdata.Parents.findIndex(
            (p) => p.DataReferenceId == parentId
        );
        if (parentIndex == -1) {
            CoreUtil.warn(
                'createHDataFromParent Error: No parent found in hData'
            );
            return null;
        }
        const hdd = hdata.Parents[parentIndex];
        const parents = hdata.Parents.slice(parentIndex + 1);
        return new HierarchicalData(hdd, parents);
    }

    public static hasHierarchicalChildren(hddData: IHierarchicalData): boolean {
        const modelHdd = HddUtil.getModelHdd(hddData);
        return (
            modelHdd &&
            modelHdd.EntityType != EntityType.RelationalModel &&
            modelHdd.EntityType != EntityType.TagBase
        );
    }
}
