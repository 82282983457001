<div class="entity-node-content" [ngClass]="colorClass">
    <div
        class="entity-node-card"
        [attr.gs-port-id]="node.entityIdr.ReferenceId"
    >
        <button
            mat-icon-button
            *ngIf="hasChildren"
            (click)="onNodeCollapse()"
            (mousedown)="$event.stopPropagation()"
        >
            <i
                class="glyph"
                [class.glyph-arrow-drop-right]="!node.opened"
                [class.glyph-arrow-drop-down]="node.opened"
            ></i>
        </button>

        <app-entity-card-cell
            [actions]="entityActions$ | async"
            [hierarchicalData]="hddData$ | async"
            [actionsCaptureMouseDown]="true"
            [noBreadcrumbNavLink]="true"
            [noLabelNavLink]="true"
            [forceExcludeSpace]="true"
        ></app-entity-card-cell>

        <ng-template #childComponent></ng-template>
    </div>

    <div class="extra-data" *ngIf="hasExtraData$ | async">
        <app-entity-node-extra-data
            *ngFor="let key of extraDataKeys$ | async"
            [attributeKey]="key"
            [entity]="entity$ | async"
        ></app-entity-node-extra-data>
    </div>

    <app-entity-node-tree
        *ngIf="showChildren"
        [node]="node"
        [actions]="entityActions$ | async"
        (collapse)="onTreeCollapse($event)"
        (showMore)="showMore.emit($event)"
    ></app-entity-node-tree>
</div>
