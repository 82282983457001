import {
    deserialize,
    deserializeAs,
    inheritSerialization,
    serialize,
    serializeAs,
} from 'cerialize';
import {
    BaseServiceParameter,
    BaseServiceResult,
} from '@datagalaxy/data-access';
import { DiagramEdgeDto, DiagramEdgeItem } from '../diagram-edge';
import { DiagramNodeDto, DiagramNodeItem } from '../diagram-node';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';

export interface IUpdateDiagramContent {
    AddedNodes?: DiagramNodeItem[];
    AddedEdges?: DiagramEdgeItem[];
    UpdatedNodes?: DiagramNodeItem[];
    UpdatedEdges?: DiagramEdgeItem[];
    DeletedNodes?: string[];
    DeletedEdges?: string[];
    DeletedNotes?: string[];
}

@inheritSerialization(BaseServiceParameter)
export class GetDiagramContentParameter extends BaseServiceParameter {
    /** ReferenceId */
    @serialize public DiagramId: string;

    constructor(
        /** ReferenceId */
        diagramId: string,
        versionId?: string
    ) {
        super();
        this.DiagramId = diagramId;
        this.VersionId = versionId || '';
    }
}

@inheritSerialization(BaseServiceParameter)
export class GetDiagramContentResult extends BaseServiceResult {
    @deserializeAs(DiagramNodeDto) public Nodes!: DiagramNodeDto[];
    @deserializeAs(DiagramEdgeDto) public Edges!: DiagramEdgeDto[];
}

@inheritSerialization(BaseServiceParameter)
export class UpdateDiagramContentParameter
    extends BaseServiceParameter
    implements IUpdateDiagramContent
{
    /** ReferenceId */
    @serialize public DiagramId?: string;

    @serializeAs(DiagramNodeItem) public AddedNodes?: DiagramNodeItem[];
    @serializeAs(DiagramEdgeItem) public AddedEdges?: DiagramEdgeItem[];

    @serializeAs(DiagramNodeItem) public UpdatedNodes?: DiagramNodeItem[];
    @serializeAs(DiagramEdgeItem) public UpdatedEdges?: DiagramEdgeItem[];

    /** Expressed as ReferenceId */
    @serialize public DeletedNodes?: string[];
    /** Expressed as ReferenceId */
    @serialize public DeletedEdges?: string[];
}

@inheritSerialization(BaseServiceResult)
export class UpdateDiagramContentResult extends BaseServiceResult {
    /** ReferenceId */
    @deserialize public DiagramId!: string;

    @deserializeAs(DiagramNodeDto) public AddedNodes!: DiagramNodeDto[];
    @deserializeAs(DiagramEdgeDto) public AddedEdges!: DiagramEdgeDto[];

    @deserializeAs(DiagramNodeDto) public UpdatedNodes!: DiagramNodeDto[];
    @deserializeAs(DiagramEdgeDto) public UpdatedEdges!: DiagramEdgeDto[];

    /** Expressed as ReferenceId */
    @deserialize public DeletedNodes!: string[];
    /** Expressed as ReferenceId */
    @deserialize public DeletedEdges!: string[];
}

@inheritSerialization(BaseServiceParameter)
export class DiagramSourceParameter extends BaseServiceParameter {
    /** ReferenceId */
    @serialize public DiagramId: string;
    /** ReferenceId's */
    @serialize public SourceIds: string[];

    public constructor(diagramId: string, sourceIds: string[]) {
        super();
        this.DiagramId = diagramId;
        this.SourceIds = sourceIds;
    }
}

@inheritSerialization(BaseServiceResult)
export class DiagramSourceResult extends BaseServiceResult {
    @deserializeAs(EntityItem) DiagramEntity!: EntityItem;
}
