<dxy-spinner *ngIf="loading$ | async; else content"></dxy-spinner>

<ng-template #content>
    <header>
        <dxy-search-input
            class="no-border-top"
            (onSearchString)="onSearchStringChange($event.searchString)"
        ></dxy-search-input>
        <button
            dxyIconButton
            (click)="openAddAttributesModal()"
            [matTooltip]="
                'Multilingual.Administration.AttributesTab.addAttributesTooltip'
                    | translate
            "
        >
            <i class="glyph-add"></i>
        </button>
    </header>
    <div
        *ngFor="let attributeSetting of attributeSettings$ | async"
        class="attribute"
    >
        <dxy-attribute-cell [attribute]="attributeSetting.attribute">
        </dxy-attribute-cell>

        <dxy-slide-toggle
            [matTooltip]="
                (attributeSetting.enabled
                    ? 'Multilingual.Administration.AttributesTab.deactivateAttributeTooltip'
                    : 'Multilingual.Administration.AttributesTab.activateAttributeTooltip'
                ) | translate
            "
            [value]="attributeSetting.enabled"
            (valueChange)="toggleAttribute(attributeSetting)"
        >
        </dxy-slide-toggle>
    </div>
</ng-template>
