import { Component, Input, OnInit } from '@angular/core';
import { IOmniGridApi, IOmniGridDataInfo } from '@datagalaxy/core-ui/omnigrid';
import { StringUtil } from '@datagalaxy/core-util';
import { TranslateService } from '@ngx-translate/core';
import { ImportContext } from '../../../import/shared/ImportContext';
import { ConnectorService } from '../../connector.service';
import { IOnlineImportEntity } from '@datagalaxy/webclient/connectivity/data-access';
import { DxyBaseComponent } from '@datagalaxy/ui/core';

@Component({
    selector: 'dxy-connection-form-entity-selection',
    templateUrl: './dxy-connection-form-entity-selection.component.html',
    styleUrls: ['./dxy-connection-form-entity-selection.component.scss'],
})
export class DxyConnectionFormEntitySelectionComponent
    extends DxyBaseComponent
    implements OnInit
{
    @Input() importContext: ImportContext;
    public get isLoading() {
        return this.connectorService.connectionObjectsLoading;
    }
    public set isLoading(value: boolean) {
        this.connectorService.connectionObjectsLoading = value;
    }
    public objects: IOnlineImportEntity[];
    public selectedObjects: IOnlineImportEntity[] = [];
    public gridApi: IOmniGridApi<IOnlineImportEntity>;

    public omniGridData: IOmniGridDataInfo<IOnlineImportEntity>;
    public searchString = '';
    public isSelectAll = true;

    public get titleText() {
        const message = this.translate.instant(
            'Import.GenericImportWizard.ConnectionForm.ObjectsSelection.title'
        );
        return `${message} ${this.connectorService.targetPlugin.title.fr}`;
    }

    constructor(
        private connectorService: ConnectorService,
        private translate: TranslateService
    ) {
        super();
    }

    ngOnInit() {
        this.isLoading = true;
        this.isSelectAll =
            this.connectorService.selectedObjectNames.length === 0;
        this.connectorService.isAllObjectsSelected = this.isSelectAll;
        this.initConnectionObjects();
    }

    public async onSelectionChange() {
        const selectedFilteredRow = await this.gridApi.getSelectedRows();
        const newSelectedRows = selectedFilteredRow.filter(
            (selectedRow) =>
                !this.selectedObjects.some(
                    (object) => object.name == selectedRow.name
                )
        );
        if (newSelectedRows.length) {
            this.selectedObjects.push(...newSelectedRows);
        } else {
            const newUnselectRows = this.omniGridData.objects.filter(
                (object) =>
                    this.selectedObjects.some(
                        (selectedObject) => selectedObject.name == object.name
                    ) &&
                    !selectedFilteredRow.some(
                        (selectedObject) => selectedObject.name == object.name
                    )
            );
            if (newUnselectRows.length) {
                this.selectedObjects = this.selectedObjects.filter(
                    (selectedRow) =>
                        !newUnselectRows.some(
                            (object) => object.name == selectedRow.name
                        )
                );
            }
        }
        this.connectorService.selectedObjectNames = this.selectedObjects.map(
            (row) => row.name
        );
    }

    public async onReadyGrid(omniGridApi: IOmniGridApi<IOnlineImportEntity>) {
        this.gridApi = omniGridApi;
        await this.gridApi.setRowsSelected(
            this.selectedObjects.map((o) => o.name),
            true
        );
    }

    public async onSearchTermChange() {
        this.omniGridData.objects = StringUtil.filterSearched(
            this.searchString,
            this.objects,
            (object) => object.name
        );
        await this.gridApi?.updateGridData();
        await this.gridApi?.setRowsSelected(
            this.selectedObjects.map((o) => o.name),
            true
        );
    }

    public onChangeSelectAll() {
        this.connectorService.isAllObjectsSelected = this.isSelectAll;
    }

    private async initConnectionObjects() {
        this.objects =
            await this.connectorService.getCurrentConnectionFoundObjects(
                this.importContext.spaceIdr.spaceId
            );
        this.omniGridData = {
            objects: this.objects,
            getObjectId: (object) => object.name,
            columns: [
                {
                    field: 'name',
                    headerName: this.translate.instant(
                        'Import.GenericImportWizard.ConnectionForm.ObjectsSelection.colLbl'
                    ),
                },
            ],
            headerHeight: 50,
            rowHeight: 50,
            groupRowHeight: 0,
            rowSelection: 'multiple',
        };
        this.selectedObjects = this.connectorService.selectedObjectNames.map(
            (name) => ({ name })
        );
        this.isLoading = false;
    }
}
