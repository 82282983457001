<dxy-rich-text-field
    #field
    [ngModel]="value$ | async"
    (ngModelChange)="onValueChange($event)"
    [readonly]="readonly"
    [labelText]="labelText"
    [labelTooltipText]="description"
    [mandatory]="mandatory"
    [errorMessageText]="errorMessage"
    (focus)="onFieldFocus($event)"
    (blur)="onFieldBlur($event)"
    toolbarDisplayMode="focus"
    [noTabCapture]="true"
    [class.is-proposal]="aiOrigin$ | async"
></dxy-rich-text-field>

<ng-container *ngIf="translationResult$ | async as result">
    <dxy-translation-status
        [translation]="result.translation"
    ></dxy-translation-status>
    <button
        *ngIf="result.canAccept && !readonly"
        type="button"
        class="accept-translaton-proposal-button"
        [matTooltip]="'Multilingual.acceptTranslation' | translate"
        dxyIconButton
        [disabled]="loading$ | async"
        (click)="acceptTranslationProposal(result.translation)"
    >
        <i class="glyph glyph-check"></i>
    </button>
</ng-container>

<button
    *ngIf="edited"
    type="button"
    dxyIconButton
    [disabled]="loading$ | async"
>
    <i class="glyph glyph-check"></i>
</button>

<ng-container *dxyIfFeatureFlag="'batch-translation'">
    <dxy-regenerate-attribute-translation-button
        *ngIf="regenerateTranslationButtonVisible"
        [attributePath]="attributeMetaInfo.AttributePath ?? ''"
        [entityIdentifier]="entity"
    ></dxy-regenerate-attribute-translation-button>
</ng-container>
