import { Injectable, inject } from '@angular/core';
import { FeatureFlagService } from '@datagalaxy/webclient/feature-flag';
import { CurrentUserService } from '@datagalaxy/webclient/user/feature/current-user';
import { Observable, delay, firstValueFrom, map, of } from 'rxjs';
import { TranslationApiService } from '@datagalaxy/webclient/multilingual/data-access';
import {
    AttributeValueTranslation,
    TranslationOrigin,
    TranslationStatus,
} from '@datagalaxy/webclient/multilingual/domain';
import { IEntityIdentifier } from '@datagalaxy/dg-object-model';
import { EntityTranslationStore } from './entity-translation-store.service';
import { MultilingualStateService } from '../multilingual-state.service';
import {
    AttributeValueTranslationResult,
    mapAttributeValueTranslationToResult,
} from './attribute-value-translation-result';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';

@Injectable({ providedIn: 'root' })
export class AttributeValueTranslationService {
    private currentUserLanguageCode = 'en';
    private multilingualEnabled = false;

    constructor(
        featureFlagService: FeatureFlagService,
        private currentUserService: CurrentUserService,
        private multilingualStateService: MultilingualStateService,
        private translationApiService: TranslationApiService,
        private store: EntityTranslationStore
    ) {
        if (!featureFlagService.isFeatureEnabled('multilingual')) {
            return;
        }
        this.store = inject(EntityTranslationStore);
        this.multilingualEnabled =
            this.multilingualStateService.state.settings?.enabled ?? false;
        this.currentUserLanguageCode =
            this.currentUserService?.userInfo?.LanguageCode ?? 'en';
    }

    public getAttributeValueTranslationResultFromEntityItem$(
        entity: EntityItem,
        attributePath: string
    ): Observable<AttributeValueTranslationResult> {
        const sourceValue = entity.getAttributeValue(attributePath) as string;

        const inSourceLanguage =
            this.currentUserLanguageCode ===
            this.multilingualStateService?.state.settings?.sourceLang;

        if (inSourceLanguage) {
            return of({
                value: sourceValue,
            });
        }

        return this.getAttributeValueTranslationResult$(
            entity,
            attributePath,
            sourceValue
        );
    }

    public getAttributeValueTranslationResult$(
        entity: IEntityIdentifier,
        attributePath: string,
        defaultValue: string
    ) {
        return this.store
            .selectObjectAttributeTranslation(
                entity,
                this.currentUserLanguageCode,
                attributePath
            )
            .pipe(
                map((t) =>
                    mapAttributeValueTranslationToResult(t, defaultValue)
                )
            );
    }

    public async updateAttributeTranslation(
        entity: IEntityIdentifier,
        attributePath: string,
        updatedValue: string
    ) {
        const result =
            await this.translationApiService.updateObjectAttributeTranslation(
                entity,
                this.currentUserLanguageCode,
                {
                    attributePath: attributePath,
                    origin: TranslationOrigin.Human,
                    value: updatedValue,
                    status: TranslationStatus.Ok,
                }
            );
        this.refreshStoredObjectTranslation(entity, result);
    }

    public async regenerateAttributeTranslationMock(
        _entityIdentifier: IEntityIdentifier,
        _attributePath: string
    ) {
        await firstValueFrom(of('mock').pipe(delay(1000)));
    }

    public async regenerateAttributeTranslation(
        entityIdentifier: IEntityIdentifier,
        attributePath: string
    ) {
        await this.translationApiService.regenerateAttributeTranslation({
            from: this.currentUserLanguageCode,
            entityIdentifier,
            attributePath,
        });
    }

    public displayTranslatedValues(attributePath: string | undefined) {
        if (!attributePath) {
            return false;
        }
        return (
            this.multilingualEnabled &&
            this.currentUserLanguageCode !==
                this.multilingualStateService.state.settings?.sourceLang &&
            this.multilingualStateService.state.settings?.attributes?.some(
                (a) => a.attributePath === attributePath && a.enabled
            )
        );
    }

    private async refreshStoredObjectTranslation(
        entityIdentifier: IEntityIdentifier,
        attributeValueTranslation: AttributeValueTranslation
    ) {
        if (
            this.store.haveObjectTranslation(
                entityIdentifier,
                this.currentUserLanguageCode
            )
        ) {
            this.store.upsertAttributeTranslation(
                entityIdentifier,
                this.currentUserLanguageCode,
                attributeValueTranslation
            );
            return;
        }
        const result = await this.translationApiService.getObjectTranslation(
            entityIdentifier,
            this.currentUserLanguageCode
        );
        this.store.upsertObjectTranslation(result);
    }
}
