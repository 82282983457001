import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultilingualStateService } from '../../multilingual-state.service';
import { LanguageIconComponent } from '@datagalaxy/webclient/multilingual/ui';
import { DxyButtonsModule } from '@datagalaxy/ui/buttons';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { LanguageCardComponent } from '../language-card/language-card.component';
import {
    DxyTranslateModule,
    executeOnce,
    withLoading,
} from '@datagalaxy/core-ui';
import { BaseComponent } from '@datagalaxy/utils';
import {
    SetUserSettingsParameter,
    UserApiService,
} from '@datagalaxy/webclient/user/data-access';
import { CurrentUserService } from '@datagalaxy/webclient/user/feature/current-user';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { UIRouterModule } from '@uirouter/angular';
import { MultilingualStateName } from '../../multilingual.routes';
import { SpinnerComponent } from '@datagalaxy/ui/spinner';

@Component({
    selector: 'dxy-language-selector-button',
    standalone: true,
    imports: [
        CommonModule,
        LanguageIconComponent,
        DxyButtonsModule,
        MatMenuModule,
        LanguageCardComponent,
        SpinnerComponent,
        MatTooltipModule,
        DxyTranslateModule,
        UIRouterModule,
    ],
    templateUrl: './language-selector-button.component.html',
    styleUrls: ['./language-selector-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageSelectorButtonComponent extends BaseComponent {
    @Input() administrationLinkVisible = false;
    protected selectedLanguageCode: string;
    protected activatedLanguagesCodes$ =
        this.multilingualStateService.selectAllLanguageCodes();
    protected enabled$ = this.multilingualStateService.selectEnabled();
    protected adminRouteStateName = MultilingualStateName.Languages;
    protected sourceLanguageCode$ =
        this.multilingualStateService.selectSourceLanguageCode();

    constructor(
        private multilingualStateService: MultilingualStateService,
        private userApiService: UserApiService,
        private currentUserService: CurrentUserService
    ) {
        super();
        this.selectedLanguageCode =
            currentUserService.userInfo?.LanguageCode ?? 'en';
    }

    @withLoading()
    @executeOnce()
    protected async selectLanguage(languageCode: string) {
        const userInfo = this.currentUserService.userInfo;
        if (!userInfo || languageCode === this.selectedLanguageCode) {
            return;
        }
        const clientLocalId = userInfo.CurrentClientId ?? '';
        const userLocalId = userInfo.UserId;
        const param = new SetUserSettingsParameter(clientLocalId, userLocalId);
        param.UserLanguageCode = languageCode;
        await this.userApiService.setUserSettings(param);
        this.selectedLanguageCode = languageCode;
        location.reload();
    }
}
