import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { EntityService } from '../../shared/entity/services/entity.service';
import { ImportContext, ImportTarget } from '../../import/shared/ImportContext';
import { GlossaryService } from '../../glossary/glossary.service';
import { FilteredViewService } from '../../shared/filter/services/filteredView.service';
import { NavigationService } from '../../services/navigation.service';
import { SearchService } from '../../search/search.service';
import { ExportService } from '../../services/export.service';
import { ImportWizardService } from '../../import/services/import-wizard.service';
import { DataUtil } from '../../shared/util/DataUtil';
import {
    ModuleSecurityData,
    Project,
    Space,
} from '@datagalaxy/webclient/workspace/data-access';
import {
    CrudOperation,
    FunctionalLogService,
} from '@datagalaxy/webclient/monitoring/data-access';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { SuggestionService } from '../../suggestions/suggestion.service';
import { SpaceIdentifier } from '@datagalaxy/webclient/workspace/utils';
import { ISpaceIdentifier } from '@datagalaxy/webclient/workspace/domain';
import { DgZone } from '@datagalaxy/webclient/domain';
import { DgModule } from '@datagalaxy/shared/dg-module/domain';

@Component({
    selector: 'app-navbar-title-more-actions',
    templateUrl: './navbar-title-more-actions.component.html',

    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarTitleMoreActionsComponent
    extends DxyBaseComponent
    implements OnChanges, OnInit
{
    @Input() dgZone: DgZone;
    @Input() dgModule: DgModule;
    @Input() spaceData: Space;
    @Input() moduleSecurityData: ModuleSecurityData;
    @Input() showSearchPreferencesRedirection: boolean;

    protected spaceIdr: ISpaceIdentifier;

    protected get isGlossary() {
        return this.dgModule == DgModule.Glossary;
    }

    protected get isMenuEmpty() {
        return (
            !this.showModuleExportButton &&
            !this.showImportButton &&
            !this.showSearchPreferencesRedirection
        );
    }

    protected get showImportButton() {
        return this.canImportData();
    }

    protected get showModuleExportButton() {
        return this.canExportData;
    }

    protected get showFilteredViewExportButton() {
        if (this.dgZone === DgZone.Search) {
            const currentSearch = this.searchService.getCurrentSearch();
            return (
                !!currentSearch.spaceIdr?.spaceId &&
                !currentSearch.isSearchEmpty
            );
        }
        return this.dgZone === DgZone.Module;
    }

    protected get moduleExportButtonTranslateKey() {
        switch (this.dgModule) {
            case DgModule.Glossary:
                return 'UI.Glossary.home.exportData';
            case DgModule.Processing:
                return 'UI.DataProcessing.export';
            case DgModule.Usage:
                return 'UI.Software.export';
            case DgModule.Catalog:
                return 'UI.Catalog.export';
        }
    }

    protected get btnDataTrackerId() {
        const trackerId = 'navigation-more-action';
        let suffix = '';
        switch (this.dgZone) {
            case DgZone.Module:
                suffix = DgModule[this.dgModule].toLowerCase();
                break;
            case DgZone.Search:
                suffix = 'search';
                break;
            default:
                return trackerId;
        }
        return `${trackerId}-${suffix}`;
    }

    protected get hasImportAccess() {
        return (
            !!this.moduleSecurityData?.HasImportAccess ||
            this.moduleSecurityData?.hasModuleImportAccess
        );
    }

    protected get isSpaceProject() {
        return SpaceIdentifier.isProject(this.spaceIdr);
    }

    protected get showImportSourceActionBtn() {
        return this.isGlossary && this.isSpaceProject && this.hasImportAccess;
    }

    protected get showSeparatorMenu() {
        return (
            this.hasImportAccess &&
            (this.showSearchPreferencesRedirection ||
                this.showModuleExportButton ||
                this.showSearchPreferencesRedirection)
        );
    }

    protected get showGenerateGlossary() {
        const hideGenerationBtn =
            !this.modulesEntitiesCount?.get(DgModule.Catalog) &&
            !this.modulesEntitiesCount?.get(DgModule.Usage);
        return (
            !hideGenerationBtn &&
            [DgModule.Glossary, DgModule.Catalog, DgModule.Usage].includes(
                this.dgModule
            ) &&
            this.moduleSecurityData.HasCreateAccess
        );
    }

    protected get glossaryAutoGenerationFuncLog() {
        return this.glossaryService.glossaryAutoGenerationFuncLog(
            this.dgModule
        );
    }

    protected get linkAutoGenerationFuncLog() {
        return SuggestionService.getAutoGenerationLinksActionNameFromModule(
            this.dgModule
        );
    }

    protected get showLinksGeneration() {
        return (
            !!this.modulesEntitiesCount?.get(this.dgModule) &&
            this.moduleSecurityData.HasCreateAccess
        );
    }

    private modulesEntitiesCount: Map<DgModule, number>;

    private get isModeler() {
        return this.dgModule == DgModule.Catalog;
    }

    private get canExportData() {
        return this.moduleSecurityData?.HasExportAccess;
    }

    constructor(
        private navigationService: NavigationService,
        private entityService: EntityService,
        private exportService: ExportService,
        private filteredViewService: FilteredViewService,
        private searchService: SearchService,
        private importWizardService: ImportWizardService,
        private functionalLogService: FunctionalLogService,
        private glossaryService: GlossaryService,
        private translate: TranslateService
    ) {
        super();
    }

    ngOnInit() {
        this.initAsync();
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChange(
            changes,
            'spaceData',
            () => (this.spaceIdr = SpaceIdentifier.from(this.spaceData)),
            true
        );
    }

    //#region event handlers

    protected onImportData() {
        this.navigationService.reloadModuleActiveView(
            this.dgModule,
            this.spaceIdr
        );
    }

    protected async onExportModule() {
        this.functionalLogService.logFunctionalAction(
            this.getExportModuleFeatureCode(),
            CrudOperation.R
        );
        await this.entityService.exportModule(
            this.dgModule,
            this.spaceIdr,
            this.getModuleExportFilename()
        );
    }

    protected async onExportFilteredView() {
        const filteredView = this.filteredViewService.getCurrentView(
            this.dgZone
        );
        await this.exportService.exportFilteredView(filteredView);
    }

    protected onClickSearchPreferences() {
        this.navigationService.goToUserSettingsSearch();
    }

    protected async openImportFromCSVModal() {
        this.functionalLogService.logFunctionalAction(
            this.getImportFromModuleFeatureCode(),
            CrudOperation.R
        );
        const importContext = new ImportContext(
            this.spaceIdr,
            ImportTarget.Entities,
            this.dgModule,
            null
        );
        importContext.onModalClosed = (isImportDone: boolean) => {
            if (isImportDone) {
                this.onImportData();
            }
        };
        await this.importWizardService.openImportWizardModal(importContext);
    }

    protected async openGlossaryGenerationModal() {
        await this.glossaryService.openGenerateFromSourceModal(
            this.spaceIdr,
            this.dgModule
        );
    }

    protected async openLinksGenerationModal() {
        return await this.glossaryService.openLinksGenerationModalForModule(
            this.dgModule,
            this.spaceIdr
        );
    }

    //#endregion

    private async initAsync() {
        if (this.dgZone === DgZone.Module) {
            this.modulesEntitiesCount =
                await this.entityService.getModulesEntitiesCountForGlossaryAutoGeneration(
                    this.spaceIdr
                );
        }
    }

    private canImportData() {
        if (this.isModeler) {
            this.moduleSecurityData.hasModuleImportAccess =
                this.moduleSecurityData.HasImportAccess ||
                (this.spaceData as Project)?.ModelsSecurityData?.some(
                    (msd) => msd.HasImportAccess
                );
        }
        return this.moduleSecurityData?.hasModuleImportAccess;
    }

    private getImportFromModuleFeatureCode(): string {
        switch (this.dgModule) {
            case DgModule.Glossary:
                return 'IMPORT_FROM_GLOSSARY';
            case DgModule.Processing:
                return 'IMPORT_FROM_DATA_PROCESSING';
            case DgModule.Usage:
                return 'IMPORT_FROM_USES';
            case DgModule.Catalog:
                return 'IMPORT_FROM_DICTIONARY';
        }
    }

    private getExportModuleFeatureCode() {
        switch (this.dgModule) {
            case DgModule.Glossary:
                return SpaceIdentifier.isProject(this.spaceIdr)
                    ? 'EXPORT_GLOSSARY'
                    : 'ORGA_EXPORT_GLOSSARY';
            case DgModule.Usage:
                return 'EXPORT_USES';
            case DgModule.Processing:
                return 'EXPORT_DP';
            case DgModule.Catalog:
                return 'EXPORT_DICTIONARY';
        }
    }

    private getModuleExportFilename() {
        const prefix = this.spaceData?.DisplayName ?? 'Export';
        const infix = this.translate.instant(
            DataUtil.getModuleTranslateKey(this.dgModule)
        );
        const suffix = moment().format('YYYYMMDD_HHmmss');
        return `${prefix}-${infix}-${suffix}`.replace(/[,:;//|]/g, '_');
    }
}
