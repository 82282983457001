import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    Input,
} from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'dxy-translation-proposal-icon',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './translation-proposal-icon.component.html',
    styleUrls: ['./translation-proposal-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TranslationProposalIconComponent {
    @Input() @HostBinding('class.translation-error') hasError = false;
}
