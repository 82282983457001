import { FieldType } from '../../types/enums/field-type.enum';
import { SnowflakeImportMode } from '../../types/enums/snowflake-import-mode.enum';

export const snowflakeConfig = [
    {
        payloadField: 'account-name',
        formField: 'accountName',
        formMandatory: true,
        type: FieldType.text,
    },
    {
        payloadField: 'warehouse',
        formField: 'warehouse',
        formMandatory: true,
        type: FieldType.text,
    },
    {
        payloadField: 'import-mode',
        formField: 'snowflakeImportMode',
        formMandatory: false,
        type: FieldType.select,
        payloadValue: SnowflakeImportMode.SINGLE_DATABASE,
        selectValues: [
            SnowflakeImportMode.SINGLE_DATABASE,
            SnowflakeImportMode.MULTIPLE_DATABASES,
        ],
        translate: true,
        getDisplayValueTradKey: (value: SnowflakeImportMode) => value,
        dependencies: [
            {
                field: {
                    payloadField: 'database',
                    formField: 'database',
                    formMandatory: true,
                    type: FieldType.text,
                },
                show: (value: SnowflakeImportMode) =>
                    value === SnowflakeImportMode.SINGLE_DATABASE,
            },
            {
                field: {
                    payloadField: 'databases',
                    formField: 'databases',
                    formMandatory: false,
                    payloadValue: [],
                    type: FieldType.list,
                    isFilter: true,
                    listFieldTradKeys: {
                        gridTitle:
                            'UI.Connector.Wizard.Step3.Databases.Grid.title',
                        gridAddBtn:
                            'UI.Connector.Wizard.Step3.Databases.Grid.addBtn',
                        modalTitle:
                            'UI.Connector.Wizard.Step3.Databases.Modal.title',
                        modalLabel:
                            'UI.Connector.Wizard.Step3.Databases.Modal.value',
                    },
                },
                show: (value: SnowflakeImportMode) =>
                    value === SnowflakeImportMode.MULTIPLE_DATABASES,
            },
            {
                field: {
                    payloadField: 'schema',
                    formField: 'schema',
                    formMandatory: false,
                    type: FieldType.text,
                },
                show: (value: SnowflakeImportMode) =>
                    value === SnowflakeImportMode.SINGLE_DATABASE,
            },
        ],
    },
    {
        payloadField: 'role',
        formField: 'role',
        formMandatory: false,
        type: FieldType.text,
    },
    {
        payloadField: 'user',
        formField: 'user',
        formMandatory: true,
        type: FieldType.text,
    },
    {
        payloadField: 'password',
        formField: 'password',
        formMandatory: true,
        isPassword: true,
    },
    {
        payloadField: 'get-pk-fk',
        formField: 'getPKFK',
        type: FieldType.boolean,
        formMandatory: false,
    },
    {
        payloadField: 'get-dynamic-table-queries',
        formField: 'getDynamicTableQueries',
        type: FieldType.boolean,
        formMandatory: false,
    },

    { payloadField: 'database-technology', payloadValue: 'snowflake' },
    { payloadField: 'type', payloadValue: 'MODEL' },
];
