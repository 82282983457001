import {
    ICellParams,
    IOmniGridApi,
    IOmniGridColumnDef,
    IOmniGridDataInfo,
} from '@datagalaxy/core-ui/omnigrid';
import { CollectionsHelper, CoreUtil } from '@datagalaxy/core-util';
import { ResultDataGroup } from './entity-links.types';
import { IActionOption } from '@datagalaxy/core-ui';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EntityUiService } from '../services/entity-ui.service';
import {
    BaseLinkDataInfo,
    EntityLinkTypeKind,
    IHasHddData,
    ObjectLinkType,
    ServerType,
} from '@datagalaxy/dg-object-model';
import { ViewTypeService } from '../../../services/viewType.service';
import { EntityService } from '../services/entity.service';
import { DgStatusCellComponent } from '../../shared-ui/cells/dg-status-cell/dg-status-cell.component';
import { ToasterService } from '../../../services/toaster.service';
import { AppEventsService } from '../../../services/AppEvents.service';
import { EntityCardCellComponent } from '../../entityCard/entity-card/entity-card-cell.component';
import { DataUtil } from '../../util/DataUtil';
import { IEntityCardCellParams } from '../../entityCard/entity-card/entity-card-cell.types';
import { EntityLinkService } from '../../../entity-links/entity-link.service';
import { UserCollectionCellComponent } from '../../shared-ui/cells/user-collection-cell/user-collection-cell.component';
import { EntityEventService } from '../services/entity-event.service';
import { EntityGridUtil } from '../entity-grid/EntityGridUtil';
import { DeleteLinkedEntitiesResult } from '@datagalaxy/webclient/entity/data-access';
import { EntityDockingPaneService } from '../services/entity-docking-pane.service';
import { SuggestionService } from '../../../suggestions/suggestion.service';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import {
    EntityItem,
    LinkedDataGroup,
    LinkedDataItem,
} from '@datagalaxy/webclient/entity/domain';
import { EntityLifecycleStatus } from '@datagalaxy/webclient/attribute/domain';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'dxy-entity-links',
    templateUrl: 'dxy-entity-links.component.html',
    styleUrls: ['dxy-entity-links.component.scss'],
})
export class DxyEntityLinksComponent
    extends DxyBaseComponent
    implements OnInit
{
    @Input() entityData: EntityItem;
    @Input() serverType: ServerType;

    protected isLoading: boolean;
    protected linksCount: number;
    protected linkSuggestionCount: number;
    protected resultData: IOmniGridDataInfo<IEntityLinkRow>;
    protected htmlLinksSuggestionsLabel: string;

    protected get isEmptyGrid() {
        return !this.isLoading && !this.hasLinks;
    }

    protected get showDataGrid() {
        return !this.isEmptyGrid && !this.isLoading;
    }

    protected get hasWriteAccess() {
        return !!this.entityData.SecurityData.HasWriteAccess;
    }

    protected get areLinksSuggestionsAvaible() {
        return (
            !this.isLoading &&
            this.linkSuggestionCount > 0 &&
            this.hasWriteAccess
        );
    }

    private gridApi: IOmniGridApi;
    private resultEntities: EntityItem[] = [];
    private entityLinksDataGroups: LinkedDataGroup[] = [];

    private get isCurrentSpaceOrga() {
        return !this.entityData.VersionId;
    }

    private get hasLinks() {
        return !!this.entityLinksDataGroups?.length;
    }

    constructor(
        private translate: TranslateService,
        private toasterService: ToasterService,
        private entityService: EntityService,
        private entityEventService: EntityEventService,
        private linkedObjectService: EntityLinkService,
        private appEventsService: AppEventsService,
        private viewTypeService: ViewTypeService,
        private entityUiService: EntityUiService,
        private entityDockingPaneService: EntityDockingPaneService,
        private suggestionService: SuggestionService
    ) {
        super();
    }

    ngOnInit() {
        this.initAsync();
        this.subscribeEvents();
    }

    public onGridReady(gridApi: IOmniGridApi) {
        this.gridApi = gridApi;
    }

    public async onRowClick(row: IEntityLinkRow) {
        await this.entityUiService.openPreviewOrDetailsOrDefault(row.entity);
    }

    public getDeleteFeatureCode(entityLink: BaseLinkDataInfo) {
        if (!entityLink) {
            return;
        }
        const orga = this.isCurrentSpaceOrga ? 'ORGA_' : '';
        const type = entityLink.EntityLinkTypeKind
            ? EntityLinkTypeKind[entityLink.EntityLinkTypeKind]
            : entityLink.EntityLinkType;
        return `${orga}ENTITY_LINK_${type.toUpperCase()},D`;
    }

    public async createLinkedObject() {
        await this.linkedObjectService.openLinkCreationModal(
            this.entityData,
            this.entityLinksDataGroups,
            {
                includeEntityLinks: true,
            }
        );
    }

    protected onLinksSuggestionsClick() {
        this.entityDockingPaneService.gotoMetabotPane();
    }

    private subscribeEvents() {
        super.registerSubscriptions(
            this.entityEventService.subscribeEntityLinkAdd(null, (entity) => {
                if (entity.ReferenceId === this.entityData.ReferenceId) {
                    this.initAsync();
                }
            }),
            this.entityEventService.subscribeEntityLinkDelete(
                null,
                (entity) => {
                    if (entity.ReferenceId === this.entityData.ReferenceId) {
                        this.initAsync();
                    }
                }
            ),
            this.entityEventService.subscribeEntityUpdate(null, (entity) => {
                const foundEntity = this.resultEntities.some(
                    (e) => e.DataReferenceId == entity.DataReferenceId
                );
                if (foundEntity) {
                    this.initAsync();
                }
            })
        );
        super.subscribe(this.appEventsService.viewTypeChange$, () =>
            this.gridApi?.refreshView()
        );
    }

    private async initAsync() {
        this.isLoading = true;
        const linkTypes = this.linkedObjectService.getAvailableLinkTypes(
            this.entityData
        );
        const objectLinkTypes = linkTypes
            .filter((l) => l.isEntityLink)
            .map((l) => l.UniversalObjectLinkType);
        this.debug &&
            this.log(
                'loadLinksData-start',
                objectLinkTypes.map((olt) => ObjectLinkType[olt])
            );
        try {
            const result = await this.entityService.getEntityLinks(
                this.entityData,
                objectLinkTypes,
                true
            );
            this.resultEntities = result.Entities;
            this.entityLinksDataGroups = result.Groups;
            this.linksCount = CollectionsHelper.sum(
                result.Groups,
                (g) => g.Items.length
            );
            await this.loadLinksSuggestionsCount();
            this.debug &&
                this.log('loadLinksData-getEntityLinks-result', {
                    entities: this.resultEntities?.length,
                    groups: this.entityLinksDataGroups?.length,
                    links: this.linksCount,
                });
        } finally {
            const groups = this.buildRowGroups();
            this.resultData = {
                getObjectId: (row: IEntityLinkRow) =>
                    row.linkedDataItem.ReferenceId,
                animateRows: true,
                columns: this.getColumns(),
                rowHeight: 55,
                headerHeight: 55,
                groups,
                debug: this.debug,
            };
            this.isLoading = false;
            this.log('loadLinksData-end');
        }
    }

    private async loadLinksSuggestionsCount() {
        this.linkSuggestionCount =
            await this.suggestionService.getEntityLinkSuggestionsCount(
                this.entityData.VersionId,
                this.entityData.ReferenceId
            );
        this.htmlLinksSuggestionsLabel = this.translate.instant(
            'UI.EntityLinks.linksSuggestionsCount',
            { count: this.linkSuggestionCount }
        );
    }

    /**
     * Build groups of ResultDataGroup containing IEntityLinkRow[]
     * IEntityLinkRow are sorted by serverType, then by displayName
     */
    private buildRowGroups(): ResultDataGroup[] {
        let groupSortOrder = 1;
        return this.entityLinksDataGroups.map((dataGroup) => {
            const groupKey = dataGroup.EntityLinkType;
            const rows: IEntityLinkRow[] = CollectionsHelper.orderBy(
                dataGroup.Items.map((item) => {
                    const entity = this.resultEntities.find(
                        (entity) =>
                            entity.DataReferenceId == item.DataReferenceId
                    );
                    return {
                        entity: CoreUtil.clone(entity),
                        HddData: entity.HddData,
                        linkedDataItem: item,
                        linkedDataGroup: dataGroup,
                    };
                }),
                [
                    (row) =>
                        DataUtil.getModuleFromServerType(row.entity.ServerType),
                    (row) =>
                        this.viewTypeService
                            .getTechnicalOrDisplayName(row.entity)
                            .toLowerCase(),
                ]
            );
            return new ResultDataGroup(
                groupKey,
                rows,
                this.translate.instant(dataGroup.displayNameTranslateKey),
                groupSortOrder++,
                undefined
            );
        });
    }

    private getColumns(): IOmniGridColumnDef<IEntityLinkRow>[] {
        const commonOptions: IOmniGridColumnDef<IEntityLinkRow> = {
            resizable: true,
            lockPosition: true,
            sortable: false,
            // field is required in IOmniGridColumnDef
            field: undefined,
        };
        return [
            {
                ...commonOptions,
                headerName: this.translate.instant('UI.EntityLinks.NameColumn'),
                cellClass: 'linked-data-cell',
                field: '',
                width: 300,
                minWidth: 200,
                cellRendererFramework: EntityCardCellComponent,
                cellRendererParams: {
                    actions: this.getAvailableActions(),
                    inputs: {
                        breadcrumbOpenPreview: true,
                        getAttributes: (data: IEntityLinkRow) =>
                            data.entity.Attributes,
                    },
                } as IEntityCardCellParams,
                comparator: EntityGridUtil.makeTextComparator(
                    (value, node) => node.data?.DisplayName
                ),
                sortable: true,
            },
            {
                ...commonOptions,
                headerName: this.translate.instant(
                    'UI.EntityLinks.DescriptionColumn'
                ),
                field: 'entity.Attributes.Description',
                width: 200,
                sortable: true,
            },
            {
                ...commonOptions,
                headerName: this.translate.instant(
                    'UI.EntityLinks.StatusColumn'
                ),
                width: 100,
                cellRendererFramework: DgStatusCellComponent,
                valueGetter: (params: ICellParams<IEntityLinkRow>) =>
                    params.data?.entity.Status,
                comparator: EntityGridUtil.makeTextComparator(
                    (value: string) => {
                        if (!value) {
                            return '';
                        }
                        return this.translate.instant(
                            `DgServerTypes.EntityLifecycleStatus.${EntityLifecycleStatus[value]}`
                        );
                    }
                ),
                sortable: true,
            },
            {
                ...commonOptions,
                headerName: this.translate.instant(
                    'UI.EntityLinks.OwnerColumn'
                ),
                field: 'entity.Attributes.DataOwners',
                width: 50,
                cellRendererFramework: UserCollectionCellComponent,
            },
            {
                ...commonOptions,
                headerName: this.translate.instant(
                    'UI.EntityLinks.StewardColumn'
                ),
                field: 'entity.Attributes.DataStewards',
                width: 50,
                cellRendererFramework: UserCollectionCellComponent,
            },
        ];
    }

    public async deleteEntityLink(row: IEntityLinkRow) {
        try {
            await this.entityService.deleteEntityLink(
                this.entityData.ReferenceId,
                row.entity.ReferenceId,
                row.entity.VersionId,
                row.linkedDataGroup.UniversalObjectLinkType
            );
        } catch (error) {
            this.toasterService.warningToast({
                messageKey: (error as DeleteLinkedEntitiesResult)
                    .errorDetailsKey,
            });
        }
    }

    private getAvailableActions() {
        const options: IActionOption<IEntityLinkRow>[] = [];

        if (this.hasWriteAccess) {
            options.push({
                glyphClass: 'glyph-link-broken',
                labelKey: 'UI.EntityLinks.btnDeleteTooltip',
                logFunctional: (data) =>
                    this.getDeleteFeatureCode(data?.linkedDataGroup),
                callback: (data) => this.deleteEntityLink(data),
            });
        }

        return [
            this.entityUiService.getEntityCellNavigationMenu(
                'ENTITY_LINKS',
                options
            ),
        ];
    }
}

interface IEntityLinkRow extends IHasHddData {
    linkedDataGroup: LinkedDataGroup;
    linkedDataItem: LinkedDataItem;
    entity: EntityItem;
}
