<svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
>
    <path
        d="M9.765 10.605H6.75L6.155 12H5L7.715 5.97H8.82L11.545 12H10.375L9.77 10.605H9.765ZM9.395 9.725L8.26 7.09L7.13 9.725H9.395Z"
        fill="currentColor"
    />
    <path
        d="M5 1.99997V0.999969H4V1.99997H0.5V2.99997H5.5C5.5 4.34997 5.145 5.43997 4.635 6.32997C3.86 5.46497 3.125 4.60497 2.62 3.99997H1.32C1.72 4.48497 2.85 5.84997 4.06 7.18497C3.125 8.34997 1.965 9.01497 1.32 9.31997L2.055 10.055C2.78 9.65997 3.83 8.97497 4.73 7.90997C4.995 8.19497 5.26 8.46497 5.515 8.71997L5.985 7.77497C5.77 7.55497 5.55 7.32997 5.33 7.08997C6.01 6.02997 6.5 4.67997 6.5 2.99997H8.5V1.99997H5Z"
        fill="currentColor"
    />
</svg>
