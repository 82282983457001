import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import {
    CoreEventsService,
    DxyRichTooltipContentComponent,
    IFunctionalEvent,
    IGraphicalControlEvents,
    IGraphicalSidePanelSpec,
    IGraphicalToolbarOption,
} from '@datagalaxy/core-ui';
import { GraphSurface } from '@datagalaxy/core-d3-util';
import { Observable, shareReplay } from 'rxjs';
import { DomUtil } from '@datagalaxy/core-util';
import {
    DksGraphSurface,
    DksGraphSurfaceOptions,
} from './data-knowledge-studio.types';
import { DiagramConstants } from '../diagrams/diagram/diagram.constants';
import { ConfigBuilder } from './config/config.builder';
import { DksGraphOptions } from './config/dks-config.types';
import { DksApi } from './api/dks.api';
import { TDksLeftBarMenu } from './menu/dks-left-bar-menu/dks-left-bar-menu.types';
import { DksLeftBarMenu } from './menu/dks-left-bar-menu/dks-left-bar-menu';
import { DksNotifierService } from './notifier/dks-notifier';
import { DksEntitiesService } from './entities/dks-entities.service';
import { DksAssetPanelService } from './asset-panel/dks-asset-panel.service';
import { IOnStageAsset } from './asset-panel/asset-onstage/asset-onstage.types';
import { DksTooltip } from './tooltip/dks-tooltip';
import { RxjsUtils } from '@datagalaxy/webclient/utils';
import { DksEntityMenuService } from './menu/dks-entity-menu/dks-entity-menu.service';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { debounceTime } from 'rxjs/operators';
import { FunctionalLogService } from '@datagalaxy/webclient/monitoring/data-access';
import { ITooltipContentShowParams } from '@datagalaxy/ui/tooltip';

@Component({
    selector: 'app-data-knowledge-studio',
    templateUrl: './data-knowledge-studio.component.html',
    styleUrls: ['./data-knowledge-studio.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        DksAssetPanelService,
        DksNotifierService,
        DksEntitiesService,
        DksEntityMenuService,
    ],
})
export class DataKnowledgeStudioComponent<NodeData, EdgeData>
    extends DxyBaseComponent
    implements AfterViewInit, OnChanges
{
    @Input() config: DksGraphOptions<NodeData, EdgeData>;
    @Input() leftBarMenu: TDksLeftBarMenu;
    @Input() panelOpen: boolean;
    @Input() assetPanel: boolean;
    @Input() externalPanel: boolean;

    @Output() onReady = new EventEmitter<DksApi<NodeData, EdgeData>>();
    @Output() panelClose = new EventEmitter<void>();

    @ViewChild('graphSurfaceEl') graphSurfaceEl: ElementRef<HTMLDivElement>;
    @ViewChild('compsContainer', { read: ViewContainerRef })
    private nodesContainerRef: ViewContainerRef;
    @ViewChild(DxyRichTooltipContentComponent)
    private richTooltipContent: DxyRichTooltipContentComponent;

    protected graphSurface: DksGraphSurface<NodeData, EdgeData>;
    protected actionButtons$: Observable<IGraphicalToolbarOption[]>;
    protected toolBarEvents: IGraphicalControlEvents;
    protected sidePanelSpec: IGraphicalSidePanelSpec = {
        noHeader: true,
        side: 'left',
        buttonTextKey: 'UI.Diagrams.Assets.title',
        buttonGlyphClass: 'glyph-view-list1',
        width: 420,
        slideDelayMs: 200,
    };
    protected scale$: Observable<number>;
    protected onStageAssets$: Observable<IOnStageAsset<NodeData>[]>;
    protected dksLeftBarMenu?: DksLeftBarMenu<NodeData, EdgeData>;

    public get surface() {
        return this.graphSurface;
    }

    constructor(
        protected hostRef: ElementRef,
        private ngZone: NgZone,
        private functionalLogService: FunctionalLogService,
        private coreEventsService: CoreEventsService,
        private dksNotifier: DksNotifierService<NodeData>,
        private dksEntitiesService: DksEntitiesService,
        private dksAssetPanelService: DksAssetPanelService<NodeData, EdgeData>,
        private dksEntityMenuService: DksEntityMenuService
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChange(changes, 'leftBarMenu', () =>
            this.dksLeftBarMenu.setupMenu(this.leftBarMenu)
        );

        super.onChange(changes, 'panelOpen', () => {
            setTimeout(() => {
                this.graphSurface.viewport.updateViewport();
            }, DiagramConstants.assetsPanel.slideDelayMs * 1.5);
        });
    }

    ngAfterViewInit() {
        setTimeout(() => this.initGraphSurface());
    }

    protected onFunctionalLog(event: IFunctionalEvent) {
        this.functionalLogService.parseAndLog(event.text, event.origin);
    }

    protected onAssetsPanelExpandedChange(isExpanded: boolean) {
        if (!isExpanded) {
            this.panelClose.emit();
        }

        this.panelOpen = isExpanded;

        setTimeout(() => {
            this.graphSurface.viewport.updateViewport();
        }, DiagramConstants.assetsPanel.slideDelayMs * 1.5);
    }

    protected onSelectAssets(ids: string[]) {
        this.graphSurface?.selection.addToSelection(...ids);
    }

    protected onUnselectAssets(ids: string[]) {
        this.graphSurface?.selection.unselect(...ids);
    }

    private initGraphSurface() {
        const options = ConfigBuilder.buildGraphSurfaceConfig(
            this.config,
            this.dksEntitiesService
        );
        const graphSurface = this.ngZone.runOutsideAngular(() => {
            return (this.graphSurface = new GraphSurface(
                this.graphSurfaceEl.nativeElement,
                options
            ));
        });
        const dksApi = new DksApi(
            graphSurface,
            this.nodesContainerRef,
            this.dksEntitiesService,
            this.dksNotifier,
            this.dksEntityMenuService
        );
        const dksLeftBarMenu = (this.dksLeftBarMenu = new DksLeftBarMenu(
            graphSurface,
            this.config,
            this.dksNotifier
        ));
        const dksTooltip = new DksTooltip(graphSurface, this.config.tooltips);

        this.toolBarEvents = this.buildToolBarEvents(graphSurface, options);
        this.actionButtons$ = dksLeftBarMenu.menu$;
        this.scale$ = graphSurface.zoom.scale$.pipe(
            debounceTime(100),
            RxjsUtils.runInZone(this.ngZone)
        );
        this.onStageAssets$ = this.dksAssetPanelService
            .getOnStageAssets(graphSurface, this.config.assets)
            .pipe(shareReplay(1));

        this.dksEntityMenuService.setupMenu(this.config.entityMenu);
        this.dksNotifier.notifyExtraDataChange(
            this.config?.extraData?.selected
        );

        dksLeftBarMenu.setupMenu(this.leftBarMenu);
        dksTooltip.showHide$.subscribe((event) => this.onShowHide(event));

        this.subscribeEvents();
        this.onReady.emit(dksApi);
    }

    private subscribeEvents() {
        super.subscribe(this.coreEventsService.mainViewResize$, () =>
            this.onMainViewResize()
        );
    }

    private onMainViewResize() {
        /**
         * Delay the emission of this event so the fullscreen event can be handled before
         * otherwise the surface will already be refreshed and the recenter will not happen
         * Infos: The fullscreen event will trigger the global window resize event
         */
        setTimeout(() => this.graphSurface.viewport.updateViewport(), 250);
    }

    private buildToolBarEvents(
        graphSurface: DksGraphSurface<NodeData, EdgeData>,
        options: DksGraphSurfaceOptions<NodeData, EdgeData>
    ): IGraphicalControlEvents {
        return {
            onScreenshot: () => this.takeScreenShot(),
            onZoomIn: () => graphSurface.zoom.stepIn(),
            onZoomOut: () => graphSurface.zoom.stepOut(),
            onZoomReset: () =>
                graphSurface.zoom.zoomToFit({
                    ...options.zoomBestFit,
                    durationMs: 300,
                }),
            onFullScreenChanged: () =>
                graphSurface.viewport.updateViewport({
                    recenter: true,
                }),
            onMinimapVisibleChange: (visible) =>
                (graphSurface.minimap.active = visible),
        };
    }

    private async takeScreenShot() {
        const element = DomUtil.getElement(this.graphSurfaceEl);
        const fileName = this.config?.getScreenShotFileName?.();
        await DomUtil.screenshot(element, fileName);
    }

    private onShowHide(event: ITooltipContentShowParams) {
        if (!event) {
            this.richTooltipContent.hide();
        } else {
            this.richTooltipContent.showHide({
                ...event,
                position: 'over',
                options: { offsetY: 30 },
            });
        }
    }
}
