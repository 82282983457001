import { Inject, Injectable } from '@angular/core';
import { RestApiService } from '@datagalaxy/data-access';
import {
    Connection,
    CreateConnectionBody,
    CreateSchedulingBody,
    ExecuteConnectionBody,
    ExecuteConnectionResponse,
    GetConnectionEntitiesResponse,
    GetConnectionsResponse,
    Scheduling,
    TestConnectionResponse,
    UpdateConnectionBody,
    UpdateSchedulingBody,
} from './connection';
import {
    GetConnectorResponse,
    GetConnectorsResponse,
    ImportsHistoryResponse,
} from './connector';
import { webSocket } from 'rxjs/webSocket';
import { Location } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class ConnectivityApiService {
    private readonly baseUrlHost: string;
    private readonly baseUrlPath: string;
    private readonly baseUrlProtocol: string;

    constructor(
        @Inject('RestConnectivityApiService')
        private restApiService: RestApiService
    ) {
        const url = new URL(this.restApiService.baseUrl);
        this.baseUrlHost = url.hostname;
        this.baseUrlPath = url.pathname;
        this.baseUrlProtocol = url.protocol;
    }

    public async getConnections(spaceId: string) {
        return this.restApiService.getPromise<GetConnectionsResponse>(
            '/connections',
            { spaceId }
        );
    }

    public async createConnection(
        spaceId: string,
        createConnectionBody: CreateConnectionBody
    ) {
        return this.restApiService.postPromise<Connection>(
            '/connections',
            createConnectionBody,
            { spaceId }
        );
    }

    public async updateConnection(
        spaceId: string,
        connectionId: string,
        updateConnectionBody: UpdateConnectionBody
    ) {
        return this.restApiService.patchPromise<Connection>(
            this.buildConnectionPath(connectionId),
            updateConnectionBody,
            { spaceId }
        );
    }

    public async duplicateConnection(spaceId: string, connectionId: string) {
        return this.restApiService.postPromise<Connection>(
            `/${this.buildConnectionPath(connectionId)}/duplicate`,
            {},
            { spaceId }
        );
    }

    public async deleteConnection(spaceId: string, connectionId: string) {
        return this.restApiService.deletePromise(
            this.buildConnectionPath(connectionId),
            { spaceId }
        );
    }

    public async testConnection(spaceId: string, connectionId: string) {
        return this.restApiService.getPromise<TestConnectionResponse>(
            `${this.buildConnectionPath(connectionId)}/test`,
            { spaceId }
        );
    }

    public async executeConnection(
        spaceId: string,
        connectionId: string,
        executeConnectionBody: ExecuteConnectionBody
    ) {
        return this.restApiService.postPromise<ExecuteConnectionResponse>(
            `${this.buildConnectionPath(connectionId)}/execute`,
            executeConnectionBody,
            { spaceId }
        );
    }

    public async getConnectionObjects(spaceId: string, connectionId: string) {
        return this.restApiService.getPromise<GetConnectionEntitiesResponse>(
            `${this.buildConnectionPath(connectionId)}/objects`,
            { spaceId }
        );
    }

    public async getScheduling(spaceId: string, connectionId: string) {
        return this.restApiService.getPromise<Scheduling>(
            `${this.buildConnectionPath(connectionId)}/schedule`,
            { spaceId }
        );
    }

    public async createScheduling(
        spaceId: string,
        connectionId: string,
        createSchedulingBody: CreateSchedulingBody
    ) {
        return this.restApiService.postPromise<Scheduling>(
            `${this.buildConnectionPath(connectionId)}/schedule`,
            createSchedulingBody,
            { spaceId }
        );
    }

    public async updateScheduling(
        spaceId: string,
        connectionId: string,
        updateSchedulingBody: UpdateSchedulingBody
    ) {
        return this.restApiService.patchPromise<Scheduling>(
            `${this.buildConnectionPath(connectionId)}/schedule`,
            updateSchedulingBody,
            { spaceId }
        );
    }

    public async getConnectors() {
        return this.restApiService.getPromise<GetConnectorsResponse>(
            '/connectors'
        );
    }

    public async getConnector(name: string) {
        return this.restApiService.getPromise<GetConnectorResponse>(
            `/connectors/${encodeURIComponent(name)}/latest`
        );
    }

    public async getImportsHistory(spaceId: string, connectionId: string) {
        return this.restApiService.getPromise<ImportsHistoryResponse>(
            `/imports/${encodeURIComponent(connectionId)}`,
            { spaceId }
        );
    }

    public getConnectorImageUrl(connectorName: string) {
        return this.restApiService.url(
            `/packages/${encodeURIComponent(connectorName)}/images/icon500.png`
        );
    }

    public getExecutionStatusWebSocket(operationId: string) {
        const protocol = this.baseUrlProtocol === 'https:' ? 'wss' : 'ws';
        const baseUrl = Location.joinWithSlash(
            Location.joinWithSlash(this.baseUrlHost, this.baseUrlPath),
            this.restApiService.apiPrefix
        );
        return webSocket(
            `${protocol}://${baseUrl}/operation/${operationId}/status`
        );
    }

    private buildConnectionPath(connectionId: string) {
        return `/connections/${encodeURIComponent(connectionId)}`;
    }
}
