import { EntityAttributeLinkGroup } from '../../entity-links/entity-links.types';
import { IEntityIdentifier } from '@datagalaxy/dg-object-model';

export class LineageTreeNode {
    public readonly id: string;
    public links: EntityAttributeLinkGroup[] = [];

    #children: LineageTreeNode[] = [];
    #parent: LineageTreeNode | null = null;
    #entityIdentifier: IEntityIdentifier;
    #opened = false;
    #hidden: boolean;
    #primary: boolean;

    public get parentId() {
        return this.#parent?.id;
    }

    public get parent() {
        return this.#parent;
    }

    public get hasVisibleParent() {
        return this.#parent?.visible || this.#parent?.hasVisibleParent;
    }

    public get opened() {
        return this.#opened;
    }

    public get closed() {
        return !this.#opened;
    }

    public get entityIdentifier() {
        return this.#entityIdentifier;
    }

    public get children(): LineageTreeNode[] {
        return this.#children;
    }

    public get hiddenChildren(): LineageTreeNode[] {
        return this.#children.filter((child) => !child.visible);
    }

    public get visibleChildren(): LineageTreeNode[] {
        return this.#children.filter((child) => child.visible);
    }

    public get hasChildren() {
        return this.#children.length > 0;
    }

    public get hasVisibleChildren() {
        return this.visibleChildren.length > 0;
    }

    public get visible() {
        const visible = !this.#hidden;

        if (this.isVisibleRoot()) {
            return visible;
        }
        return visible && this.#parent?.visible;
    }

    public get primary() {
        return this.#primary;
    }

    constructor(
        entity: IEntityIdentifier,
        links?: EntityAttributeLinkGroup[],
        hidden?: boolean
    ) {
        this.id = entity.ReferenceId;
        this.links = links || [];
        this.#entityIdentifier = entity;
        this.#hidden = hidden;
    }

    public getAllVisibleExpandedChildren(): LineageTreeNode[] {
        if (this.closed) {
            return [];
        }
        return this.children.flatMap((child) => [
            ...(child.visible ? [child] : []),
            ...child.getAllVisibleExpandedChildren(),
        ]);
    }

    public getAllVisibleChildren(): LineageTreeNode[] {
        return this.children.flatMap((child) => [
            ...(child.visible ? [child] : []),
            ...child.getAllVisibleChildren(),
        ]);
    }

    public getAllExpandedChildren(): LineageTreeNode[] {
        if (this.closed) {
            return [];
        }
        return this.children.flatMap((child) => [
            child,
            ...child.getAllExpandedChildren(),
        ]);
    }

    public getAllChildren(): LineageTreeNode[] {
        return this.children.flatMap((child) => [
            child,
            ...child.getAllChildren(),
        ]);
    }

    public getAllHiddenChildren(): LineageTreeNode[] {
        return this.children.flatMap((child) => [
            ...(!child.visible ? [child] : []),
            ...child.getAllHiddenChildren(),
        ]);
    }

    public allVisibleChildren(): LineageTreeNode[] {
        return this.children.flatMap((child) => [
            ...(child.visible ? [child] : []),
            ...child.allVisibleChildren(),
        ]);
    }

    public getDepth(): number {
        return (this.#parent ? 1 : 0) + this.#parent?.getDepth() || 0;
    }

    public find(id: string): LineageTreeNode {
        if (this.id === id) {
            return this;
        }
        for (const child of this.children) {
            const found = child.find(id);
            if (found) {
                return found;
            }
        }
        return null;
    }

    /**
     * A node is considered a visible root node if it is not hidden and
     * it does not have a visible parent.
     */
    public isVisibleRoot() {
        return !this.#hidden && !this.hasVisibleParent;
    }

    public getRoot(): LineageTreeNode {
        return this.#parent?.getRoot() || this;
    }

    public getVisibleRoot(): LineageTreeNode | null {
        if (this.isVisibleRoot()) {
            return this;
        }

        return this.#parent?.getVisibleRoot();
    }

    public getClosestOpened(): LineageTreeNode | null {
        if (this.isVisibleRoot() || (this.#parent?.opened && this.visible)) {
            return this;
        }

        return this.#parent?.getClosestOpened();
    }

    public hasVisibleSibling(): boolean {
        return this.#parent?.visibleChildren.length > 1;
    }

    public setVisible() {
        this.#hidden = false;

        if (this.hasVisibleParent) {
            this.#parent.setVisible();
        }
    }

    public setHidden() {
        this.#hidden = true;

        this.children.forEach((child) => child.setHidden());
    }

    public toggleVisibility() {
        if (this.#hidden) {
            this.setVisible();
        } else {
            this.setHidden();
        }
    }

    public setPrimary() {
        this.#primary = true;
    }

    public setParent(parent: LineageTreeNode) {
        if (this.#parent === parent) {
            return;
        }
        this.#parent = parent;
        parent.addChild(this);
    }

    public addChild(child: LineageTreeNode) {
        if (this.#children.includes(child)) {
            return;
        }
        this.#children.push(child);
        child.setParent(this);
    }

    public removeChild(child: LineageTreeNode) {
        const index = this.#children.indexOf(child);
        if (index === -1) {
            return;
        }
        this.#children.splice(index, 1);
    }

    public toggleExpansion() {
        if (this.#opened) {
            this.close();
        } else {
            this.open();
        }
    }

    public open() {
        this.#opened = true;
        this.#parent?.open();
    }

    public close() {
        this.#opened = false;
        this.children.forEach((child) => child.close());
    }
}
