import {
    AfterViewChecked,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import {
    executeOnce,
    IFieldSelectAdapter,
    IOptionAdapter,
} from '@datagalaxy/core-ui';
import { TranslateService } from '@ngx-translate/core';
import {
    IConnectionToken,
    IOrphanedObjectsHandlingDropdownOption,
    IPersonalAccessTokenDecoded,
    OrphanedObjectsHandling,
} from './dxy-connection-form-target.types';
import { ImportContext } from '../../../import/shared/ImportContext';
import { DxyModalService } from '../../../shared/dialogs/DxyModalService';
import { AppDataService } from '../../../services/app-data.service';
import { IntegrationService } from '../../../services/integration.service';
import { ConnectorService, IS_OTHER_USER_PAT } from '../../connector.service';
import { SecurityService } from '../../../services/security.service';
import { ImportEntityTarget } from '../../dxy-target-selection/target-entity-selector.types';
import { ServerType } from '@datagalaxy/dg-object-model';
import { JwtUtil } from '../../../shared/util/Jwt.util';
import {
    LoadKnownUsersParameter,
    PersonalAccessTokenResult,
    UserApiService,
} from '@datagalaxy/webclient/user/data-access';
import { UserService } from '../../../services/user.service';
import { ClientIntegrationDTO } from '@datagalaxy/webclient/integration/data-access';
import {
    DataStructure,
    IConnectorPlugin,
} from '@datagalaxy/webclient/connectivity/data-access';
import {
    GetObjectSecurityResultObjectPrincipalItem,
    SecurityRoleConstant,
} from '@datagalaxy/webclient/security/data-access';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { ISpaceIdentifier } from '@datagalaxy/webclient/workspace/domain';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { IFieldDef } from '../form-configs/types/interfaces/field-def.interface';

@Component({
    selector: 'dxy-connection-form-target',
    templateUrl: './dxy-connection-form-target.component.html',
    styleUrls: ['./dxy-connection-form-target.component.scss'],
})
export class DxyConnectionFormTargetComponent
    extends DxyBaseComponent
    implements OnInit, AfterViewChecked
{
    @Input() importContext: ImportContext;
    @Input() selectedSolution?: IConnectorPlugin;

    /** Emitted when a menu is opened or closed. The argument is true on open. */
    @Output() readonly onMenuOpenClose = new EventEmitter<boolean>();

    //#region html bindings

    public displayNameHint = this.translate.instant(
        'UI.Connector.Wizard.Step3.displayNameHint'
    );

    public get importEntityTargets() {
        return this.connectorService.importEntityTargets;
    }

    public get spaceIdr() {
        return this.importContext.spaceIdr;
    }
    public get isSpaceVersionSelectable() {
        return !this.importContext.hasInitialWorkspace;
    }

    public tokenOptions: IConnectionToken[] = [];
    public tokenAdapter: IOptionAdapter<IConnectionToken> = {
        getText: (token) => token.displayName,
        getId: (token) => token.value,
    };
    public selectedToken: IConnectionToken;
    public get isSelectTokenDisabled() {
        return (
            this.tokenOptions.length <= 1 ||
            this.isTokenLoading ||
            !this.isTokenEditable
        );
    }

    public get supportUrn() {
        return (
            this.targetPlugin.settings?.supportUrn === true &&
            this.securityService.isConnectorUrnSupportEnabled()
        );
    }

    public get iconUrl() {
        return this.targetPlugin.iconUrl ?? 'images/dg3-connector.png';
    }

    public get pluginDisplayName() {
        return this.targetPlugin.title[
            this.appDataService.currentUserLanguageCode
        ];
    }

    public get connectionName() {
        return this._connectionName;
    }

    public set connectionName(value: string) {
        this.connectorService.connectionName = this._connectionName = value;
        this.validForm();
    }

    public async onEnableUrnUpdated(value: boolean) {
        const dataStructure = value ? DataStructure.Urn : DataStructure.Tree;
        this.connectorService.fields.dataStructure = dataStructure;
        this.dataStructure = dataStructure;
        this.dataStructureUrn = dataStructure === DataStructure.Urn;
    }

    public get connectionToken() {
        return this.connectorService.connectionToken;
    }

    public set connectionToken(value) {
        this.connectorService.connectionToken = value;
    }

    public isInitLoading: boolean;
    public isTokenLoading: boolean;
    public isTokenEditable: boolean;
    public isEditConnection: boolean;
    public dataStructure = DataStructure.Tree;
    public dataStructureUrn = false;

    protected orphanedObjectsHandlingAdapter: IFieldSelectAdapter<IOrphanedObjectsHandlingDropdownOption>;

    //#endregion - html bindings

    private _connectionName: string;

    private isGeneratingAccessToken: boolean;
    private get pluginName() {
        return this.targetPlugin.name ?? '';
    }
    private get targetPlugin() {
        return this.connectorService.targetPlugin;
    }
    private get currentUserId() {
        return this.appDataService.currentUserId;
    }

    constructor(
        private translate: TranslateService,
        private dxyModalService: DxyModalService,
        private appDataService: AppDataService,
        private userService: UserService,
        private userApiService: UserApiService,
        private integrationService: IntegrationService,
        private connectorService: ConnectorService,
        private securityService: SecurityService,
        private changeDetector: ChangeDetectorRef
    ) {
        super();
    }

    ngOnInit() {
        this.isInitLoading = true;
        this.isEditConnection = false;
        this.isTokenEditable = true;
        const sol = this.selectedSolution;
        if (sol) {
            this.connectorService.targetPlugin = sol;
            this.connectorService.targetSubTypeName = sol.sourceType;
        } else {
            this.isEditConnection = true;
            this.isTokenEditable = false;
        }

        // init with connection saved values
        if (!this.importContext.isTargetAlreadySet) {
            if (this.connectorService.connectionCredentials) {
                this._connectionName = this.connectorService.connectionName;
            } else {
                this.initConnectionName().then();
            }
        } else {
            this._connectionName = this.connectorService.connectionName;
        }
        this.dataStructure = this.connectorService.fields.dataStructure;
        this.initOrphanedObjectsHandling();

        this.initAsync();
    }

    ngAfterViewChecked() {
        this.preventNg0100Error(this.changeDetector);
    }

    //#region html bindings

    public getTranslationKey(
        importEntityTarget: ImportEntityTarget,
        keySuffix: string
    ) {
        return `Import.GenericImportWizard.ImportTargetSelection.${
            ServerType[importEntityTarget.serverType]
        }.${keySuffix}`;
    }

    // triggered on init
    public async onSpaceVersionSelected(spaceIdr: ISpaceIdentifier) {
        if (!this.isInitLoading) {
            this.connectionToken = '';
        }
        this.importContext.spaceIdr = spaceIdr;
        await this.initAsync();
    }

    public async onSelectedToken(selectedValue: IConnectionToken) {
        if (!selectedValue.value) {
            selectedValue.value = await this.getNewPersonalAccessToken();
        }
        this.connectionToken = selectedValue.value;
        this.validForm();
    }

    public onIsUpdateChange(
        isUpdate: boolean,
        entityTargetSelectorParam: ImportEntityTarget
    ) {
        entityTargetSelectorParam.isUpdate = isUpdate;
        if (!isUpdate) {
            entityTargetSelectorParam.newEntityName =
                this.buildDefaultTargetEntityName(entityTargetSelectorParam);
        } else {
            entityTargetSelectorParam.selectedEntityId = null;
        }
        this.validForm();
    }

    public onNewEntityNameChange(
        newEntityName: string,
        entityTargetSelectorParam: ImportEntityTarget
    ) {
        entityTargetSelectorParam.newEntityName = newEntityName;
        this.validForm();
    }

    public onEntitySubTypeChange(
        subTypeName: string,
        entityTargetSelectorParam: ImportEntityTarget
    ) {
        entityTargetSelectorParam.subTypeName = subTypeName;
        this.validForm();
    }

    public onEntityChange(
        value: EntityItem,
        entityTargetSelectorParam: ImportEntityTarget
    ) {
        entityTargetSelectorParam.selectedEntityId = value?.DataReferenceId;
        this.validForm();
    }

    public getOnlineImportPluginImgDataTrackerId() {
        const code = this.targetPlugin.name;
        return `online-import-plugin-img-${code}`;
    }

    public onTokenButtonClick() {
        this.isTokenEditable = true;
    }

    //#region list-field

    public async openListFieldModal(fieldDef: IFieldDef) {
        const result = await this.dxyModalService.prompt({
            titleKey: fieldDef.conf.listFieldTradKeys.modalTitle,
            userInputLabelKey: fieldDef.conf.listFieldTradKeys.modalLabel,
            confirmButtonKey: 'UI.Global.btnCreate',
        });
        this.log('openListFieldModal', result);

        if (result?.trim()) {
            fieldDef.data?.push(result);
            this.connectorService.listFieldUpdateCallSource.next();
        }
    }

    //#endregion

    //#endregion
    protected getOrphanedObjectsHandlingTranslationKey(endKey: string): string {
        return `Import.GenericImportWizard.ConnectionForm.OrphanedObjectsHandlingSelection.${endKey}`;
    }

    @executeOnce()
    private async initAsync() {
        try {
            this.isTokenLoading = true;
            await this.initTokenSelection();
            this.isTokenLoading = false;
            await this.initTargetEntitiesSelection();
            this.validForm();
        } finally {
            this.isInitLoading = false;
        }
    }

    private async initConnectionName() {
        const conName = this.connectorService.connectionName;
        if (conName) {
            this._connectionName = conName;
        } else {
            this._connectionName = this.connectorService.connectionName =
                await this.buildDefaultConnectionName();
        }
    }

    private async buildDefaultConnectionName() {
        if (!this.importContext.hasInitialWorkspace) {
            return this.pluginName;
        }

        const savedConnections = await this.connectorService?.getConnections();
        if (!savedConnections) {
            return;
        }

        return this.buildDefaultName(
            savedConnections,
            this.pluginName,
            (connection) => connection.name
        );
    }

    private buildDefaultTargetEntityName(
        entityTargetSelectorParam: ImportEntityTarget
    ): string {
        return this.buildDefaultName(
            entityTargetSelectorParam.availableEntities,
            this.pluginDisplayName,
            (entity) => entity.DisplayName
        );
    }

    // defaultName (x) if defaultName already exists
    private buildDefaultName<T>(
        dataArray: T[],
        defaultName: string,
        getName: (data: T) => string
    ) {
        const filteredNames = dataArray
            .map(getName)
            .filter((name) => this.isDefaultName(defaultName, name));
        if (!filteredNames.length) {
            return defaultName;
        }
        const max = filteredNames
            .map((name) => parseInt(name.match(/\((\d+)\)$/)?.[1] || '0', 10))
            .reduce((a, b) => Math.max(a, b), 0);

        return `${defaultName} (${1 + max})`;
    }

    private isDefaultName(defaultName: string, name: string): boolean {
        const defaultNameRegex = new RegExp(
            `^(${defaultName.toLowerCase()}) ?(\\(\\d+\\))?$`
        );
        return defaultNameRegex.test(name.toLowerCase());
    }

    private async initTargetEntitiesSelection() {
        this.connectorService.targetModule = this.targetPlugin.modules[0];

        if (this.importContext.spaceIdr) {
            await this.connectorService.initImportEntityTargets(
                this.importContext.spaceIdr,
                (target: ImportEntityTarget) =>
                    this.buildDefaultTargetEntityName(target)
            );
        }
    }

    private async initTokenSelection() {
        const isClientAdmin = this.securityService.isCurrentUserClientAdmin();
        let getIntegrationsResult: ClientIntegrationDTO[];
        if (isClientAdmin) {
            getIntegrationsResult =
                await this.integrationService.getClientIntegrations();
        }

        const personalAccessTokenResult =
            await this.userService.getUserPersonalAccessToken(
                this.currentUserId
            );

        let principalItems: GetObjectSecurityResultObjectPrincipalItem[] = [];
        if (this.importContext.spaceIdr) {
            const param = this.securityService.createGetObjectSecurityParameter(
                this.importContext.spaceIdr.spaceId,
                null,
                false,
                false
            );
            const objectSecurityData =
                await this.securityService.getObjectSecurityData(param);
            const firstItem = objectSecurityData?.Items?.[0];
            principalItems = firstItem?.ObjectItem?.PrincipalItems;
        }

        let filteredIntegrationTokens: IConnectionToken[] = [];
        if (getIntegrationsResult?.length && principalItems?.length) {
            filteredIntegrationTokens = this.filterAdminTokens(
                getIntegrationsResult,
                principalItems
            ).map((i) => ({
                id: i.IntegrationUserId,
                displayName: i.DisplayName,
                value: i.IntegrationToken,
            }));
        }

        const personalAccessToken: string | null =
            this.isValidPersonalAccessToken(personalAccessTokenResult)
                ? personalAccessTokenResult.PersonalAccessToken
                : null;
        const personalAccessTokenDecoded: IPersonalAccessTokenDecoded =
            personalAccessToken
                ? JwtUtil.parseJwt(
                      personalAccessTokenResult.PersonalAccessToken
                  )
                : null;
        const personalAccessTokenOption = {
            id: personalAccessTokenDecoded?.uid,
            displayName: this.translate.instant(
                'UI.User.Profile.personalAccessToken.fieldLbl'
            ),
            value: personalAccessToken,
        };

        this.tokenOptions = [
            personalAccessTokenOption,
            ...filteredIntegrationTokens,
        ];
        let selectedToken = this.tokenOptions.find(
            (token) => token.id === this.connectorService.tokenUid
        );
        // if is not integration token, neither a personal token, it's an old personal token, or of another user
        if (this.connectorService.tokenUid && !selectedToken) {
            // if personal access token is not user token add new option
            if (this.connectorService.tokenUid !== this.currentUserId) {
                const loadKnownUsersParam = new LoadKnownUsersParameter();
                loadKnownUsersParam.UserIds = [this.connectorService.tokenUid];
                const otherUser = await this.userApiService.loadKnownUsers(
                    loadKnownUsersParam
                );
                const personalAccessTokenAlreadyUsed = {
                    displayName: this.translate.instant(
                        'Import.GenericImportWizard.ConnectionForm.Selectors.PersonalTokenInfoAlreadyUsed',
                        { username: `${otherUser.Users[0].FullName}` }
                    ),
                    value: IS_OTHER_USER_PAT,
                };
                this.tokenOptions = [
                    personalAccessTokenOption,
                    personalAccessTokenAlreadyUsed,
                    ...filteredIntegrationTokens,
                ];
                selectedToken = personalAccessTokenAlreadyUsed;
                // personal access token is user token and revoked, so get a new one
            } else if (!personalAccessToken) {
                this.tokenOptions[0].value = personalAccessToken;
            }
        }
        if (!selectedToken && !this.tokenOptions[0].value) {
            this.tokenOptions[0].value = await this.getNewPersonalAccessToken();
        }
        this.selectedToken = selectedToken ?? this.tokenOptions[0];
        this.connectionToken = this.selectedToken.value;
    }

    private filterAdminTokens(
        tokens: ClientIntegrationDTO[],
        principalItems: GetObjectSecurityResultObjectPrincipalItem[]
    ) {
        const systemRoles = this.securityService.getSystemRoles(
            true,
            false,
            []
        );
        return tokens.filter((token) => {
            const tokenPrincipalItem = principalItems.find(
                (pi) => pi.PrincipalId == token.IntegrationUserId
            );
            if (!tokenPrincipalItem) {
                return false;
            }

            const tokenRole = systemRoles.find(
                (sr) => sr.LocalRoleId == tokenPrincipalItem.LocalRoleId
            );
            return tokenRole?.Value == SecurityRoleConstant.ObjectAdministrator;
        });
    }

    private validForm() {
        this.connectorService.isConnectorFormValidated = !!(
            this._connectionName &&
            this.connectionToken &&
            !this.importEntityTargets.some(
                (targetSelector) => !targetSelector.isValid
            )
        );
    }

    private async getNewPersonalAccessToken() {
        if (this.isGeneratingAccessToken) {
            return;
        }
        this.isGeneratingAccessToken = true;
        const personalTokenResult =
            await this.userService.regeneratePersonalAccessToken(false);
        this.isGeneratingAccessToken = false;
        return personalTokenResult.PersonalAccessToken;
    }

    private isValidPersonalAccessToken(
        personalAccessTokenResult: PersonalAccessTokenResult
    ) {
        const creationDate = new Date(personalAccessTokenResult.CreationTime);
        const revocationTime = new Date(
            personalAccessTokenResult.RevocationTime
        );

        return !(revocationTime && revocationTime >= creationDate);
    }

    private initOrphanedObjectsHandling() {
        let orphanedObjectsHandlingDropdownOptions = [
            {
                action: OrphanedObjectsHandling.DO_NOTHING,
                text: this.translate.instant(
                    this.getOrphanedObjectsHandlingTranslationKey(
                        'actions.doNothing'
                    )
                ),
            },
            {
                action: OrphanedObjectsHandling.OBSOLETE_STATE,
                text: this.translate.instant(
                    this.getOrphanedObjectsHandlingTranslationKey(
                        'actions.obsoleteState.text'
                    )
                ),
                subText: this.translate.instant(
                    this.getOrphanedObjectsHandlingTranslationKey(
                        'actions.obsoleteState.subText'
                    )
                ),
            },
            {
                action: OrphanedObjectsHandling.DELETE,
                text: this.translate.instant(
                    this.getOrphanedObjectsHandlingTranslationKey(
                        'actions.delete'
                    )
                ),
            },
        ];
        this.orphanedObjectsHandlingAdapter = {
            options: orphanedObjectsHandlingDropdownOptions,
            isModel: true,
            current: orphanedObjectsHandlingDropdownOptions.find(
                (o) => o.action == this.connectorService.orphanedObjectsHandling
            ),
            getText: (o) => o.text,
            onSelectionChange: (o) =>
                (this.connectorService.orphanedObjectsHandling = o.action),
            getSubTextKey: (o) => o.subText,
        };
    }

    protected readonly DataStructure = DataStructure;
}
