<ng-container *ngIf="translationResult$ | async as result">
    <dxy-translation-status
        *ngIf="result.translation"
        [translation]="result.translation"
    ></dxy-translation-status>
    <span
        class="display-name-value"
        [dxyEllipsisTooltip]="displayNameTooltip"
        dxyEllipsisMode="alwaysVisible"
        dxyEllipsisTooltipPosition="below"
        (click)="titleClicked.emit()"
    >
        {{ result.value }}
    </span>
    <button
        *ngIf="result.canAccept && hasWriteAccess"
        type="button"
        dxyIconButton
        [matTooltip]="'Multilingual.acceptTranslation' | translate"
        [disabled]="loading$ | async"
        (click)="acceptTranslationProposal(result.translation)"
    >
        <i class="glyph glyph-check"></i>
    </button>
</ng-container>
