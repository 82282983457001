import {
    EntityGridRowClickAction,
    IEntityGridOptions,
} from '../entity-grid/EntityGridOptions';
import {
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { IDragDropConfig } from '@datagalaxy/core-ui';
import { IEntityIdentifier, ServerType } from '@datagalaxy/dg-object-model';
import { EntityService } from '../services/entity.service';
import { IDropdownButtonOption } from '../../shared-ui/dropdownButton.types';
import { EntityUiService } from '../services/entity-ui.service';
import { DataUtil } from '../../util/DataUtil';
import { EntityCreationOrigin } from '../entity.types';
import { ModelerDataUtil } from '../../util/ModelerDataUtil';
import { EntityEventService } from '../services/entity-event.service';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { SpaceIdentifier } from '@datagalaxy/webclient/workspace/utils';
import { ISpaceIdentifier } from '@datagalaxy/webclient/workspace/domain';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { AttributeMetaInfo } from '@datagalaxy/webclient/attribute/domain';
import { ServerConstants } from '@datagalaxy/shared/server/domain';

@Component({
    selector: 'dxy-entity-tree',
    templateUrl: 'dxy-entity-tree.component.html',
    styleUrls: ['dxy-entity-tree.component.scss'],
})
export class DxyEntityTreeComponent
    extends DxyBaseComponent
    implements OnChanges, OnInit
{
    @Input() entityData: EntityItem;
    @Input() showHeader: boolean;
    @Input() readOnly: boolean;
    @Input() dragDrop: IDragDropConfig<IEntityIdentifier>;

    public addButtonActions: IDropdownButtonOption[];

    public entityAttributes: AttributeMetaInfo[] = [];
    public spaceIdr: ISpaceIdentifier;
    private readonly actionOrigin = EntityCreationOrigin.dockingPane;
    public egOptions: IEntityGridOptions = {
        isSingleColumn: true,
        isSingleServerType: false,
        includeAllChildrenTypes: true,
        isHierarchical: true,
        autoHeight: true,
        noBreadCrumbs: true,
        rowClickAction: EntityGridRowClickAction.openEntityPreviewPanel,
        burgerMenuConfiguration: {
            actionButton: true,
            navigationButton: true,
            entityCreationOrigin: this.actionOrigin,
        },
        useParentDataIdIfNullEntityRefId: true,
        noMessagesAndTitleBar: true,
    };

    public get hasWriteAccess() {
        return (
            !this.readOnly && !!this.entityData?.SecurityData?.HasWriteAccess
        );
    }
    public get hasData() {
        return (
            this.entityData?.getAttributeValue<number>(
                ServerConstants.PropertyName.LogicalChildrenCount
            ) > 0
        );
    }

    constructor(
        private entityApiService: EntityService,
        private entityUiService: EntityUiService,
        private entityEventService: EntityEventService
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChange(changes, 'entityData', () => this.initData());
    }

    ngOnInit() {
        this.log('$onInit (readOnly)', this.entityData, this.readOnly);

        this.initData();

        this.egOptions.burgerMenuConfiguration = { readonly: this.readOnly };
        this.egOptions.cardCellDragDrop = this.readOnly
            ? undefined
            : this.dragDrop;
        this.egOptions.noNavToDetails = !!this.dragDrop && !this.readOnly;

        this.entityApiService
            .getEntityAttributesForEntityTree(this.entityData.Type)
            .then((result) => (this.entityAttributes = result));

        const serverTypes = [
            this.entityData.ServerType,
            ServerType.Table,
            ServerType.Container,
            ServerType.Column,
        ];

        serverTypes.forEach((serverType) => {
            this.registerSubscriptions(
                this.entityEventService.subscribeEntityCreation(
                    serverType,
                    (data) => this.onEntityAddOrUpdate(data.entity)
                ),
                this.entityEventService.subscribeEntityUpdate(
                    serverType,
                    (data) => this.onEntityAddOrUpdate(data)
                ),
                this.entityEventService.subscribeEntityDelete(
                    serverType,
                    (data) => this.onEntityDelete(data.deletedIds)
                )
            );
        });
    }

    private initData() {
        this.spaceIdr = SpaceIdentifier.fromEntity(this.entityData);
        this.createAddActions();
    }

    private async onEntityAddOrUpdate(entity: EntityItem) {
        const parentId = entity.getAttributeValue<string>(
            ServerConstants.PropertyName.LogicalParentId
        );
        if (
            this.entityData &&
            (parentId === this.entityData.ReferenceId ||
                this.entityData.ReferenceId === entity.ReferenceId)
        ) {
            await this.refreshEntity();
        }
    }

    private async onEntityDelete(deletedIds: string[]) {
        if (deletedIds.includes(this.entityData.ReferenceId)) {
            return;
        }
        await this.refreshEntity();
    }

    private async refreshEntity() {
        this.entityData = await this.entityApiService.getEntity(
            this.entityData,
            { includeHddSecurityAndSocialData: true }
        );
    }

    private createAddActions() {
        const serverType = this.entityData.ServerType;
        const addButtonActions: IDropdownButtonOption[] = [];

        if (ModelerDataUtil.isModelerServerType(serverType)) {
            const showCreateContainer =
                serverType == ServerType.Model ||
                serverType == ServerType.Container;
            const showCreateStructure = DataUtil.isCreateStructureAvailable(
                this.entityData.HddData
            );
            const showCreateField = serverType == ServerType.Table;

            if (showCreateContainer) {
                addButtonActions.push({
                    titleKey: 'UI.Global.btnCreateContainer',
                    callback: () => this.createChild(ServerType.Container),
                });
            }
            if (showCreateStructure) {
                addButtonActions.push({
                    titleKey: 'UI.Global.btnCreateStructure',
                    callback: () => this.createChild(ServerType.Table),
                });
            }
            if (showCreateField) {
                addButtonActions.push({
                    titleKey: 'UI.Global.btnCreateChild',
                    callback: () => this.createChild(ServerType.Column),
                });
            }
        } else {
            addButtonActions.push({
                titleKey: 'UI.Global.btnCreateChild',
                callback: () => this.createChild(serverType),
            });
        }

        this.addButtonActions = addButtonActions;
    }

    private async createChild(childServerType: ServerType) {
        await this.entityUiService.onCreateChild(
            this.entityData,
            this.actionOrigin,
            childServerType
        );
    }
}
