import {
    ChangeDetectionStrategy,
    Component,
    inject,
    Input,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { DxyButtonsModule } from '@datagalaxy/ui/buttons';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { SpinnerComponent, withLoading } from '@datagalaxy/core-ui';
import { DIALOG_SERVICE_TOKEN, IDialogService } from '@datagalaxy/ui/dialog';
import { AttributeValueTranslationService } from '../../translations';
import { IEntityIdentifier } from '@datagalaxy/dg-object-model';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DxyRichTooltipDirective } from '@datagalaxy/ui/tooltip';
import { Toaster } from '@datagalaxy/ui/toaster';

@Component({
    selector: 'dxy-regenerate-attribute-translation-button',
    standalone: true,
    imports: [
        CommonModule,
        DxyButtonsModule,
        SpinnerComponent,
        TranslateModule,
        DxyRichTooltipDirective,
    ],
    templateUrl: './regenerate-attribute-translation-button.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegenerateAttributeTranslationButtonComponent extends DxyBaseComponent {
    @Input() attributePath!: string;
    @Input() entityIdentifier!: IEntityIdentifier;

    private dialogService: IDialogService = inject(DIALOG_SERVICE_TOKEN);
    private attributeValueTranslationService = inject(
        AttributeValueTranslationService
    );
    private toaster = inject(Toaster);
    private translate = inject(TranslateService);

    constructor() {
        super();
    }

    protected async openModal() {
        const regenerate = await this.dialogService.confirm({
            titleKey: 'Multilingual.RegenerateTranslation.title',
            messageKey: 'Multilingual.RegenerateTranslation.message',
            confirmButtonKey: 'Multilingual.RegenerateTranslation.regenerate',
        });

        if (!regenerate) {
            return;
        }

        await this.regenerateTranslations();
    }

    @withLoading()
    private async regenerateTranslations() {
        await this.attributeValueTranslationService.regenerateAttributeTranslationMock(
            this.entityIdentifier,
            this.attributePath
        );
        this.toaster.successToast({
            title: this.translate.instant(
                'Multilingual.RegenerateTranslation.successTitle'
            ),
            message: this.translate.instant(
                'Multilingual.RegenerateTranslation.successMessage'
            ),
        });
    }
}
