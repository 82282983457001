<dxy-translation-proposal-icon
    *ngIf="translation?.origin === 'ai'"
    [hasError]="isKo"
    [matTooltip]="
        (isKo
            ? 'Multilingual.translationError'
            : 'Multilingual.automaticTranslation'
        ) | translate
    "
></dxy-translation-proposal-icon>
