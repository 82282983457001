<div
    class="tree-item"
    [style.padding-left.px]="paddingLeft"
    [attr.gs-port-id]="nodeTree.entity.ReferenceId"
    [ngClass]="nodeTree.className"
>
    <button
        mat-icon-button
        *ngIf="!isLeaf"
        class="dg5-small-icon-button"
        (click)="toggleCollapse($event)"
        (mousedown)="$event.stopPropagation()"
    >
        <i
            class="glyph"
            [class.glyph-arrow-drop-right]="!nodeTree.opened"
            [class.glyph-arrow-drop-down]="nodeTree.opened"
        ></i>
    </button>
    <app-entity-card-cell
        [hierarchicalData]="nodeTree.entity.HddData"
        [isMiniCard]="true"
        [noLabelNavLink]="true"
        [actions]="actions"
        [showActions]="true"
        [actionsCaptureMouseDown]="true"
    ></app-entity-card-cell>
</div>
<ng-container *ngIf="nodeTree.opened">
    <app-entity-node-tree-item
        *ngFor="let child of nodeTree.children"
        [nodeTree]="child"
        [actions]="actions"
        [level]="level + 1"
        (collapse)="collapse.emit($event)"
        (showMore)="showMore.emit($event)"
    ></app-entity-node-tree-item>
    <div
        *ngIf="showMoreOption"
        class="show-more"
        [attr.gs-port-id]="'show-more-' + nodeTree.entity.ReferenceId"
        [style.padding-left.px]="showMorePaddingLeft"
        (click)="onShowMoreClick()"
    >
        <button mat-icon-button class="dg5-small-icon-button">
            <i class="glyph-burger"></i>
        </button>
        <span
            translate
            [translateParams]="{
                count: nodeTree.children.length,
                total: nodeTree.childrenCount
            }"
            >UI.DKS.Node.Children.ShowMore</span
        >
    </div>
</ng-container>

<ng-template #childComponent></ng-template>
