import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    HostBinding,
    OnInit,
    ViewChild,
} from '@angular/core';
import { CoreEventsService, IListOption } from '@datagalaxy/core-ui';
import { INavSpaceSelectedEvent } from '../../space/space.types';
import { StateName } from '@datagalaxy/webclient/routing';
import { ViewIdentifier } from '../../shared/util/ViewIdentifier';
import { NavigationService } from '../../services/navigation.service';
import { AppDataService } from '../../services/app-data.service';
import { AppSpaceService } from '../../services/AppSpace.service';
import { CurrentSpaceService } from '../../services/currentSpace.service';
import { EntitySecurityService } from '../../shared/entity/services/entity-security.service';
import { EntityCreationOrigin } from '../../shared/entity/entity.types';
import { EntityUiService } from '../../shared/entity/services/entity-ui.service';
import { ImportWizardService } from '../../import/services/import-wizard.service';
import { SecurityService } from '../../services/security.service';
import { Space } from '@datagalaxy/webclient/workspace/data-access';
import { UserService } from '../../services/user.service';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { DxyNavigationSpaceListComponent } from '../../space-selector/dxy-navigation-space-list/dxy-navigation-space-list.component';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { SpaceIdentifier } from '@datagalaxy/webclient/workspace/utils';

@Component({
    //eslint-disable-next-line @angular-eslint/component-selector
    selector: 'dxy-nav-sidebar',
    templateUrl: './dxy-nav-sidebar.component.html',
    styleUrls: ['./dxy-nav-sidebar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DxyNavSidebarComponent extends DxyBaseComponent implements OnInit {
    static EXPAND_STATE_KEY = 'menu-expand-state';
    @HostBinding('class.expanded') public isExpanded = false;

    @HostBinding('class.collapsed')
    public get isCollapsed() {
        return !this.isExpanded;
    }

    public get expandGlyphClass() {
        return 'glyph-arrow-' + (this.isExpanded ? 'left' : 'right');
    }

    public get showItemsTooltip() {
        return !this.isExpanded;
    }

    protected readonly StateName = StateName;
    protected readonly ViewIdentifier = ViewIdentifier;

    protected get currentSpaceOrLatest() {
        return this.currentSpaceService.getCurrentOrLastSpace();
    }

    protected addBurgerMenuOptions: IListOption[] = [
        {
            glyphClass: 'glyph-add',
            labelKey:
                'UI.DashboardGrid.WidgetType.shortcutButtons.buttons.addEntity',
            callback: () =>
                this.entityUiService.openCreationModal(
                    EntityCreationOrigin.globalAddButton
                ),
            dataTestId: 'shortcut-button--add-object',
        },
        {
            glyphClass: 'glyph-upload',
            labelKey:
                'UI.DashboardGrid.WidgetType.shortcutButtons.buttons.importEntity',
            callback: () => this.openImportModal(),
            dataTestId: 'shortcut-button--import-objects',
        },
    ];

    protected get showNonOrgaModule() {
        return this.isSingleWorkspace || this.currentSpaceOrLatest?.isProject;
    }

    protected get hasManagementAccess() {
        return (
            this.hasCurrentSpace &&
            this.entitySecurityService.hasManagementAccessSecurity(
                this.currentSpaceOrLatest.SecurityData
            )
        );
    }

    protected get hasEntityCreationAccess() {
        return !this.hasCurrentSpace
            ? this.appDataService.canCreateEntities
            : this.entitySecurityService.hasWriteAccessToAnyModule(
                  this.currentSpaceOrLatest
              );
    }

    protected get hasImportAccess() {
        return this.appSpaceService.isSingleWorkspace() ||
            !this.currentSpaceService.hasCurrentSpace()
            ? this.appDataService.canImportEntities
            : this.entitySecurityService.hasImportAccessToAnyModule(
                  this.currentSpaceService.getCurrentSpace()
              );
    }

    protected get showAddBurgerMenu() {
        return (
            this.hasCurrentSpace &&
            this.hasImportAccess &&
            this.hasEntityCreationAccess
        );
    }

    protected get showAddEntityAction() {
        return !this.showAddBurgerMenu && this.hasEntityCreationAccess;
    }

    protected get showCampaignModule() {
        return this.securityService.areCampaignsEnabled();
    }

    protected get isSingleWorkspace() {
        return this.appSpaceService.isSingleWorkspace();
    }

    private get hasCurrentSpace() {
        return !!this.currentSpaceOrLatest;
    }

    @ViewChild(DxyNavigationSpaceListComponent, { static: true })
    workspaceList: DxyNavigationSpaceListComponent;
    protected shouldHideWorkspaceList = false;

    constructor(
        private navigationService: NavigationService,
        private appDataService: AppDataService,
        private entitySecurityService: EntitySecurityService,
        private appSpaceService: AppSpaceService,
        private currentSpaceService: CurrentSpaceService,
        private entityUiService: EntityUiService,
        private coreEventsService: CoreEventsService,
        private importWizardService: ImportWizardService,
        private securityService: SecurityService,
        private userService: UserService,
        private cd: ChangeDetectorRef
    ) {
        super();
    }

    ngOnInit() {
        this.subscribeEvents();
        this.updateExpandState(true);
    }

    protected async goToHomePage() {
        const spaceIdr = await this.appSpaceService.getASpaceIdentifier({
            includeCurrent: true,
            includeLast: true,
            includeNonUserDefault: true,
        });
        return this.navigationService.goToSpaceHome(spaceIdr);
    }

    protected async openImportModal() {
        await this.importWizardService.openImportModal();
    }

    protected async updateExpandState(init: boolean = false) {
        if (init) {
            const res = await this.userService.getUserSettingValue(
                DxyNavSidebarComponent.EXPAND_STATE_KEY,
                ''
            );
            const isNewComer = res.Value === null;
            // Newcomers should have their sidebar expanded
            this.isExpanded = isNewComer
                ? true
                : coerceBooleanProperty(res.Value);
            this.updateSidebarWidth();
            this.cd.detectChanges();
        } else {
            this.isExpanded = !this.isExpanded;
            this.userService.setUserSettingValue(
                DxyNavSidebarComponent.EXPAND_STATE_KEY,
                '',
                `${this.isExpanded}`
            );
            this.updateSidebarWidth();
        }
        this.coreEventsService.emitMainViewResize();
    }

    private updateSidebarWidth() {
        const root = (window.document as Document).documentElement;
        root.style.setProperty(
            '--nav-sidebar-width',
            `var(--nav-sidebar-width-${
                this.isExpanded ? 'expanded' : 'collapsed'
            })`
        );
    }

    private subscribeEvents() {
        this.subscribe(this.currentSpaceService.currentSpace$, (workspace) =>
            this.onSpaceChange(workspace)
        );
    }

    private onSpaceChange(space: Space) {
        if (SpaceIdentifier.areSame(space, this.currentSpaceOrLatest)) {
            return;
        }
        this.log('onSpaceChange', space);
        this.cd.detectChanges();
    }

    public navigateTo(itemIdentifier: string) {
        this.navigationService.goTo(itemIdentifier, this.currentSpaceOrLatest);
    }

    /**
     * To be called whenever a child component fires a MatMenu instance so that
     * the matmenu container can be translated to avoid overlap with the sidebar
     */
    protected updateMatMenuPosition(): void {
        const matMenuCssFixAttr = `data-matmenucssfix`;
        const cssContent = `.cdk-overlay-pane:has(.sidebar-item--mat-menu)
        {transform: translateX(var(--nav-sidebar-width)) translateY(calc(-1 * var(--nav-sidebar-item-height)))}`;
        let styleSheet = document.head.querySelector(
            `[${matMenuCssFixAttr}]`
        ) as HTMLStyleElement | null;

        if (!styleSheet) {
            styleSheet = document.createElement('style');
            styleSheet.setAttribute(matMenuCssFixAttr, '1');
            styleSheet.textContent = cssContent;
            document.head.appendChild(styleSheet);
            return;
        }
    }

    public async onNavSpaceSelected(event: INavSpaceSelectedEvent) {
        const navSpace = event.navSpace;

        this.shouldHideWorkspaceList =
            (this.workspaceList?.orderedNavSpaces.length ?? 0) <= 1;

        if (!event.isUserAction) {
            return;
        }
        return this.navigationService.goToSpaceHome(navSpace);
    }
}
