import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    QueryList,
    SimpleChanges,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import {
    AsyncPipe,
    JsonPipe,
    NgClass,
    NgForOf,
    NgIf,
    NgTemplateOutlet,
} from '@angular/common';
import { CheckboxComponent } from '@datagalaxy/ui/forms';
import { CdkDrag, CdkDragDrop, CdkDropList } from '@angular/cdk/drag-drop';
import { SpinnerComponent } from '@datagalaxy/ui/spinner';
import { IconComponent } from '@datagalaxy/ui/icon';
import { GridCellComponent } from '../grid-cell/grid-cell.component';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { GridConfig } from './grid.types';
import { debounceTime, distinctUntilChanged, map } from 'rxjs';
import { Row, Header, Cell, Column } from '@tanstack/table-core';
import { TColDef } from '../grid-column/grid-column.types';
import { PersistedGridState } from '../grid-persisted-state/grid-persisted-state.types';
import { GridTable } from '../grid-table/grid-table';
import { DomUtil } from '@datagalaxy/core-util';
import { isEqual } from 'lodash';
import {
    CdkVirtualScrollViewport,
    ScrollingModule,
} from '@angular/cdk/scrolling';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DxyButtonsModule } from '@datagalaxy/ui/buttons';
import { EllipsisTooltipDirective } from '@datagalaxy/ui/tooltip';

@Component({
    selector: 'dxy-grid',
    standalone: true,
    imports: [
        NgForOf,
        CheckboxComponent,
        NgIf,
        GridCellComponent,
        CdkDrag,
        CdkDropList,
        AsyncPipe,
        SpinnerComponent,
        IconComponent,
        ScrollingModule,
        MatTooltipModule,
        JsonPipe,
        DxyButtonsModule,
        NgTemplateOutlet,
        EllipsisTooltipDirective,
        NgClass,
    ],
    templateUrl: './grid.component.html',
    styleUrls: ['./grid.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridComponent<TRow = unknown>
    extends DxyBaseComponent
    implements OnChanges, OnInit, AfterViewInit
{
    @Input() config?: GridConfig<TRow>;
    @Input() items: TRow[] = [];
    @Input() gridState?: PersistedGridState;
    @Input() columns: TColDef<TRow>[] = [];

    @Output() rowClick = new EventEmitter<TRow>();
    @Output() gridStateChange = new EventEmitter<PersistedGridState>();
    @Output() selectionChange = new EventEmitter<TRow[]>();

    @HostBinding('style.--table-height') get heightStyle() {
        if (this.config?.autoHeight) {
            let tableHeightInPx =
                (this.tableRows.length + 1) * this.rowHeightInPx;
            if (this.config.horizontalScroll) {
                tableHeightInPx += 10;
            }
            return `${tableHeightInPx}px`;
        }
        return 'auto';
    }

    @HostBinding('class.horizontal-scroll') get horizontalScroll() {
        return this.config?.horizontalScroll;
    }

    @ViewChildren(GridCellComponent)
    private cellsComponents!: QueryList<GridCellComponent<TRow>>;
    @ViewChild(CdkVirtualScrollViewport)
    private cdkVirtualScrollViewport?: CdkVirtualScrollViewport;

    protected gridTable = new GridTable<TRow>();
    protected table$ = this.gridTable.table$;
    protected rowHeightInPx = 50;

    private activeRowId?: string;
    private resizingHeader?: Header<TRow, unknown>;

    private get availableWidth() {
        const staticUsedWidth = this.config?.multiSelect ? 60 : 0;
        return this.elementRef.nativeElement.clientWidth - staticUsedWidth;
    }

    protected get selectionEnabled() {
        return this.config?.multiSelect;
    }

    protected get orderingEnabled() {
        return !this.config?.disableOrdering;
    }

    public get selection(): TRow[] {
        return this.gridTable.selection.map((row: Row<TRow>) => row.original);
    }

    protected get tableRows() {
        return this.gridTable.table.getRowModel().rows;
    }

    // The Mouseup event is listened on the window
    // to ensure that resizing of the header is detected
    // when the mouse is released outside the grid.
    @HostListener('window:mouseup') onMouseUp() {
        if (this.resizingHeader) {
            this.refreshCellsLayout();
        }
        this.resizingHeader = undefined;
    }

    constructor(
        private elementRef: ElementRef<HTMLElement>,
        private cd: ChangeDetectorRef
    ) {
        super();
        this.subscribeToResizeToRefreshCellsLayout();
        this.initSelectionChangeEventEmitter();
    }

    ngOnInit() {
        this.initTable();

        const persistedState$ = this.gridTable.table$.pipe(
            debounceTime(500),
            map(() => this.gridTable.getPersistedState())
        );

        super.subscribe(persistedState$, (persistedState) => {
            if (
                this.elementRef.nativeElement.clientWidth === 0 ||
                isEqual(this.gridState, persistedState)
            ) {
                return;
            }
            this.gridState = persistedState;
            this.gridStateChange.emit(persistedState);
        });
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.gridTable.fitColumnsToWidth(this.availableWidth);
        }, 1);
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChange(changes, 'gridState', () => this.onGridStateChange());
        super.onChange(changes, 'items', () => this.onItemsChange());
        super.onChange(changes, 'columns', () => this.onColumnsChange());
    }

    public resetColumns() {
        this.gridTable.resetColumns();
        this.gridTable.fitColumnsToWidth(this.availableWidth);
    }

    public isColumnVisible(colId: string) {
        return this.gridTable.isColumnVisible(colId);
    }

    public toggleColumnVisibility(colId: string) {
        this.gridTable.toggleColumnVisibility(colId, this.availableWidth);
        this.gridTable.fitColumnsToWidth(this.availableWidth);
    }

    public scrollToIndex(
        id: string,
        behavior: ScrollBehavior = 'smooth'
    ): void {
        const getItemId = this.config?.getItemId;

        if (!getItemId) {
            // CoreUtil.warn('getItemId is not defined in grid config');
        }

        const index = this.tableRows.findIndex((row) => row.id === id);

        this.cdkVirtualScrollViewport?.scrollToIndex(index, behavior);

        this.activeRowId = id;
        this.cd.detectChanges();
    }

    public getRowRectPosition(id: string): DOMRect | undefined {
        const elem = this.elementRef.nativeElement.querySelector(
            `[id="${id}"]`
        );

        return elem?.getBoundingClientRect();
    }

    public refreshCellsLayout() {
        this.cellsComponents.forEach((c) => c.refreshLayout());
    }

    protected getCellColumn(cell: Cell<TRow, unknown>) {
        return cell.column.columnDef.meta as TColDef<TRow>;
    }

    protected isActive(row: Row<TRow>) {
        return row.getIsSelected() || this.activeRowId === row.id;
    }

    protected onHeaderCellDrop(event: CdkDragDrop<Column<TRow>>) {
        const minIndex = this.columns.filter((c) => c.fixed).length;
        this.gridTable.reorderHeader(
            event.previousIndex,
            Math.max(event.currentIndex, minIndex)
        );
    }

    protected trackByHeader(_index: number, header: Header<TRow, unknown>) {
        return header.column.id;
    }

    protected trackByRow(_index: number, row: Row<TRow>) {
        return row.id;
    }

    protected getSortIcon(header: Header<TRow, unknown>) {
        const sort = header.column.getIsSorted();

        if (!sort) {
            return 'glyph-unsorted';
        }
        return sort === 'asc' ? 'glyph-asc-sort' : 'glyph-desc-sort';
    }

    protected isSortDisabled(column: TColDef<TRow>): boolean {
        return !column.sortable;
    }

    protected onRowClick(row: Row<TRow>) {
        if (row.getIsGrouped()) {
            return;
        }
        this.activeRowId = row.id;
        this.rowClick.emit(row.original);
    }

    protected onResizeHeader(event: MouseEvent, header: Header<TRow, unknown>) {
        this.resizingHeader = header;
        this.gridTable.onResizeHeader(event, header);
    }

    private initTable() {
        this.gridTable.init(this.columns, this.items, this.config);

        if (this.gridState) {
            this.gridTable.restorePersistedState(this.gridState);
        }

        this.gridTable.fitColumnsToWidth(this.availableWidth);
    }

    private onGridStateChange() {
        if (!this.gridState) {
            return;
        }
        this.gridTable.restorePersistedState(this.gridState);
        this.gridTable.fitColumnsToWidth(this.availableWidth);
    }

    private onItemsChange() {
        this.gridTable.updateItems(this.items);
    }

    private onColumnsChange() {
        this.gridTable.updateColumns(this.columns);
    }

    private subscribeToResizeToRefreshCellsLayout() {
        super.subscribe(
            DomUtil.resizeObservable(this.elementRef.nativeElement, 100),
            () => {
                this.gridTable.fitColumnsToWidth(this.availableWidth);
                this.cdkVirtualScrollViewport?.checkViewportSize();
                this.cd.detectChanges();
                this.refreshCellsLayout();
            }
        );
    }

    private initSelectionChangeEventEmitter() {
        // On every table state change
        super.subscribe(
            this.gridTable.table$.pipe(
                map((t) =>
                    t.getSelectedRowModel().flatRows.map((f) => f.original)
                ),
                // Check that selection has changed compared to the previous state
                distinctUntilChanged(
                    (prev, curr) =>
                        prev.map((p) => this.config?.getItemId(p)).join() ===
                        curr.map((p) => this.config?.getItemId(p)).join()
                )
            ),
            (rows) => {
                // if selection changed, emit the new selection
                this.selectionChange.emit(rows);
            }
        );
    }
}
