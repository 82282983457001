import { deserialize } from 'cerialize';

export class ConnectorTranslations {
    @deserialize public fr!: string;
    @deserialize public en!: string;
}

export interface IConnectorPluginSettings {
    disableListObjects?: boolean;
    supportUrn?: boolean;
}

export class GetConnectorResponse {
    @deserialize public connector!: Connector;
}

export class Connector {
    @deserialize public name!: string;
    @deserialize public version!: string;
    @deserialize public title!: ConnectorTranslations;
    @deserialize public description!: ConnectorTranslations;
    @deserialize public modules!: ConnectorModule[];
    @deserialize public aliases?: string[];
    @deserialize public runModes!: ConnectorRunMode[];
    @deserialize public sourceType!: string;
    @deserialize public settings!: IConnectorPluginSettings;
}

export enum ConnectorModule {
    CATALOG = 'Catalog',
    USAGE = 'Usage',
    PROCESSING = 'Processing',
}

/**
 * Connector run mode.
 */
export enum ConnectorRunMode {
    /**
     * Connector server.
     */
    SERVER = 'server',
    /**
     * Docker image.
     */
    DOCKER = 'docker',
    /**
     * Jar file.
     */
    JAR = 'jar',
}

export class ConnectorVersionDto {
    @deserialize public Filename!: string;
    @deserialize public Version!: string;
    @deserialize public Architecture!: string;
    @deserialize public Status!: string;
}

export type ConnectorPackage = {
    name: string;
    version: string;
    _meta: {
        modules: string[];
        sourceType: string;
        description: {
            fr: string;
            en: string;
        };
        title: {
            fr: string;
            en: string;
        };
    };
    _links: {
        images: {
            icon500: {
                href: string;
            };
        };
        manifest: {
            href: string;
        };
        self: {
            href: string;
        };
    };
};

export type PackagesResponse = {
    connectors: ConnectorPackage[];
    desktop: {
        zip32bits?: {};
        zip64bits: {};
    };
};
