import { Subscription } from 'rxjs';
import { CollectionsHelper, CoreUtil, StringUtil } from '@datagalaxy/core-util';
import { IActionOption, IDragDropConfig, ITabItem } from '@datagalaxy/core-ui';
import {
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    Type,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IEntityLinkIdentifier } from '../linked-object.types';
import { EntityUiService } from '../services/entity-ui.service';
import { ViewTypeService } from '../../../services/viewType.service';
import {
    IDataIdentifier,
    IEntityIdentifier,
    IHasHddData,
    ObjectLinkType,
    ServerType,
} from '@datagalaxy/dg-object-model';
import { AppEventsService } from '../../../services/AppEvents.service';
import { EntityCardCellComponent } from '../../entityCard/entity-card/entity-card-cell.component';
import { IEntityCardCellParams } from '../../entityCard/entity-card/entity-card-cell.types';
import { EntityLinkService } from '../../../entity-links/entity-link.service';
import { EntityEventService } from '../services/entity-event.service';
import { IHasTechnicalName } from '@datagalaxy/data-access';
import { UpdateEntityLinkResult } from '@datagalaxy/webclient/entity/data-access';
import {
    CrudOperation,
    FunctionalLogService,
} from '@datagalaxy/webclient/monitoring/data-access';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import {
    EntityItem,
    LinkedDataGroup,
} from '@datagalaxy/webclient/entity/domain';
import { DgModule } from '@datagalaxy/shared/dg-module/domain';

/**
 * ## Role
 * List of entities linked to a provided entity
 * ## Features
 * - General
 *   - title header (hideable by option)
 *   - collapsible sections by link type
 *   - per-section items sorted by module then by display name
 *   - filter-by-name input field (displayed when 10+ items)
 *   - spinner while loading
 *   - no-data placeholder with image, title, text, link creation button
 *   - buttons to create links (on header and in no-data placeholder)
 *   - readonly mode (as option)
 *   - content update on external real-time events: link added, link deleted
 * - Linked objects
 *   - technical/functional name depending on the current view type
 *   - open preview on click
 *   - support for custom actions
 *   - burger menu
 *     - navigate to object detail
 *     - open preview
 *     - navigate to lineage
 *     - navigate to exploratory
 *     - delete link
 *     - remove 'golden link' status
 * - Tabs
 *   - by flow type: downstream, upstream, all
 *   - enabled via option
 *   - count of displayed items on each tab
 *   - configurable (visible tabs, initial active tab)
 *   - different titles when a single tab is displayed
 * - Diagram designer specific
 *   - drag & drop to a diagram
 *   - add-to-diagram action button on each linked object
 * ## Use-cases
 * - in preview panel and docking pane
 * - as a popover in diagram designer when clicking on a linked entity object's source port
 */
@Component({
    selector: 'app-entity-linked-objects',
    templateUrl: 'entity-linked-objects.component.html',
    styleUrls: ['entity-linked-objects.component.scss'],
})
export class EntityLinkedObjectsComponent
    extends DxyBaseComponent
    implements OnChanges, OnInit
{
    @Input() entityData: EntityItem;
    @Input() showHeader: boolean;
    @Input() readOnly: boolean;
    @Input() dragDrop: IDragDropConfig;
    @Input() tabs: ILinkedObjectsTabs;
    @Input() noCreateButton: boolean;
    @Input() objectsMoreActions: IActionOption<ILinkedObject>[];
    @Input() noBurgerMenu: boolean;
    @Input() onLinkCreated: (linkIdr: IEntityLinkIdentifier) => void;
    /** when true, clicking on a row will not trigger navigation (only preview panel opening is managed) */
    @Input() noRedirect: boolean;

    protected isLoading: boolean;
    protected isFirstLoad: boolean;
    protected searchTerm?: string;
    protected filteredLinkTypeGroups: ILinkTypeGroup[];
    protected renderableEntity: IRenderable;
    protected tabItems: ITabItem<FlowType>[];
    protected activeTabItem: ITabItem<FlowType>;
    protected get isFirstLoading() {
        return this.isFirstLoad && this.isLoading;
    }
    protected get isAnyLinkedObjects() {
        return this.filteredLinkTypeGroups?.length > 0;
    }
    protected get isSearchAvailable() {
        return (
            CollectionsHelper.sum(
                this.linkTypeGroups,
                (b) => b.linkedObjects.length
            ) >= 10
        );
    }
    protected get hasWriteAccess() {
        return (
            !this.readOnly && !!this.entityData?.SecurityData?.HasWriteAccess
        );
    }

    private readonly flowTypes = [
        FlowType.downstream,
        FlowType.upstream,
        FlowType.all,
    ];
    private isDeleting: boolean;
    private resultActions: IActionOption<ILinkedObject>[];
    private linkedDataResult: LinkedDataGroup[];
    private linkedDataEntitiesResult: EntityItem[];
    private dynamicSubscriptions: Subscription;
    private linkedObjects: ILinkedObject[] = [];
    private linkTypeGroupsByFlow = new Map<FlowType, ILinkTypeGroup[]>();
    private get linkTypeGroups() {
        return this.linkTypeGroupsByFlow.get(
            this.activeTabItem?.data ?? FlowType.all
        );
    }

    constructor(
        private translate: TranslateService,
        private viewTypeService: ViewTypeService,
        private entityEventService: EntityEventService,
        private linkedObjectService: EntityLinkService,
        private functionalLogService: FunctionalLogService,
        private appEventsService: AppEventsService,
        private entityUiService: EntityUiService
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChange(changes, 'entityData', () => this.initAsync());
    }

    ngOnInit() {
        this.initActions();
        this.initAsync();
    }

    protected onTabChange(tab: ITabItem<FlowType>) {
        this.activeTabItem = tab;
        this.applyNameFilter();
    }

    protected applyNameFilter() {
        const groups = this.linkTypeGroups;
        this.filteredLinkTypeGroups = this.searchTerm?.length
            ? groups
                  .map((g) => ({
                      ...g,
                      rows: StringUtil.filterSearched(
                          this.searchTerm,
                          g.rows,
                          (r) =>
                              this.viewTypeService.getTechnicalOrDisplayName(
                                  r?.rendererParam?.data?.HddData.Data
                              )
                      ),
                  }))
                  .filter((g) => g.rows.length)
            : groups;
    }

    protected async onRowClick(row: IRenderableRow) {
        await this.entityUiService.openPreviewOrDetailsOrDefault(
            row.rendererParam.data,
            this.noRedirect
        );
    }

    protected async createLinkedObject() {
        if (this.readOnly) {
            return;
        }
        const res = await this.linkedObjectService.openLinkCreationModal(
            this.entityData,
            this.linkedDataResult,
            { includeEntityLinks: true }
        );
        this.onLinkCreated?.(res.linkIdrs[0]);
    }

    private async initAsync() {
        this.log('init', ServerType[this.entityData?.ServerType]);
        this.isFirstLoad = true;
        this.searchTerm = '';
        this.renewEventsSubscription();
        await this.refreshLinkedData();
        this.renderableEntity = {
            renderer: EntityCardCellComponent,
            rendererParam: { data: this.entityData },
        };
    }

    private renewEventsSubscription() {
        this.dynamicSubscriptions = super.renewSubscriptionGroup(
            this.dynamicSubscriptions,

            this.entityEventService.subscribeEntityLinkIdentifierAdd(
                this.entityData.ServerType,
                (data) => this.refreshLinkedDataIfNeeded(data)
            ),

            this.entityEventService.subscribeEntityLinkIdentifierDelete(
                this.entityData.ServerType,
                (data) => this.refreshLinkedDataIfNeeded(data)
            ),

            this.appEventsService.viewTypeChange$.subscribe(() =>
                this.refreshLinkedData()
            ),
            this.entityEventService.subscribeEntityLinkUpdateGoldenLink(
                (data) => this.onGoldenLinkUpdate(data)
            )
        );
    }

    private initActions() {
        const actions: IActionOption<ILinkedObject>[] = [];
        this.objectsMoreActions?.forEach((ao) => {
            if (!ao) {
                return;
            }
            const disabled = ao.disabled;
            ao.disabled = () =>
                this.isDeleting || CoreUtil.fromFnOrValue(disabled);
            actions.push(ao);
        });
        if (!this.noBurgerMenu) {
            const burgerMenuActions: IActionOption<ILinkedObject>[] = [
                {
                    glyphClass: 'glyph-link-broken',
                    labelKey: 'UI.EntityLinkedObjects.deleteLink',
                    callback: (data) => this.actionDeleteLink(data),
                    hidden: () => !this.hasWriteAccess,
                    disabled: () => this.isDeleting,
                },
                {
                    glyphClass: 'glyph-golden-link-off',
                    labelKey: 'UI.EntityLinkedObjects.deleteGoldenLink',
                    callback: (data) => this.actionRemoveGoldenLink(data),
                    hidden: (data) =>
                        !(this.hasWriteAccess && data.isGoldenLink),
                    disabled: () => this.isDeleting,
                },
            ];
            const burgerMenu = this.entityUiService.getEntityCellNavigationMenu(
                'LINKED_OBJECTS',
                burgerMenuActions
            );

            actions.push(burgerMenu);
        }
        this.resultActions = actions;
    }
    private async actionDeleteLink(lo: ILinkedObject) {
        this.log('actionDeleteLink', this.isDeleting, lo);
        if (this.isDeleting) {
            return;
        }

        this.isDeleting = true;
        this.functionalLogService.logFunctionalAction(
            'LINKED_OBJECT',
            CrudOperation.D
        );
        try {
            await this.linkedObjectService.unlinkData(this.entityData, lo);
        } finally {
            this.isDeleting = false;
        }
    }
    private actionRemoveGoldenLink(lo: ILinkedObject) {
        this.functionalLogService.logFunctionalAction(
            'GOLDEN_LINK',
            CrudOperation.D
        );
        return this.linkedObjectService.removeGoldenLink(lo.linkReferenceId);
    }

    private async refreshLinkedDataIfNeeded(data: IDataIdentifier) {
        const isNeeded =
            this.entityData &&
            data &&
            this.entityData.ReferenceId === data.DataReferenceId;
        this.log('refreshLinkedDataIfNeeded', isNeeded);
        if (!isNeeded) {
            return;
        }
        await this.refreshLinkedData();
    }
    private async refreshLinkedData() {
        this.filteredLinkTypeGroups = this.linkedObjects = [];
        this.linkTypeGroupsByFlow.clear();
        if (!this.entityData || this.entityData.isSpace) {
            return;
        }
        this.log('refreshLinkedData', this.entityData);

        this.isLoading = true;
        try {
            const res = await this.linkedObjectService.getLinkedData(
                this.entityData
            );
            this.linkedDataResult = res.Groups;
            this.linkedDataEntitiesResult = res.Entities;
            this.makeLinkTypeGroups(this.linkedDataResult);
        } finally {
            this.isLoading = false;
            this.isFirstLoad = false;
            this.applyNameFilter();
            this.buildTabs();
        }
    }

    private makeLinkTypeGroups(ldgs: LinkedDataGroup[]) {
        const linkedObjects = (this.linkedObjects =
            EntityLinkService.makeLinkedObjects(this.entityData, ldgs));
        this.linkTypeGroupsByFlow = new Map(
            this.flowTypes.map((flow) => {
                const items =
                    flow == FlowType.all
                        ? linkedObjects
                        : linkedObjects.filter((lo) => lo.flow == flow);
                const groups = CollectionsHelper.groupBy(
                    items,
                    (lo) => lo.linkType,
                    (olt, los) => this.makeLinkTypeGroup(olt, los)
                );
                return [flow, groups];
            })
        );
    }
    private makeLinkTypeGroup(
        linkType: ObjectLinkType,
        linkedObjects: ILinkedObject[]
    ) {
        linkedObjects = CollectionsHelper.orderBy(linkedObjects, [
            (lo) => lo.dgModule,
            (lo) =>
                this.viewTypeService
                    .getTechnicalOrDisplayName(lo)
                    .toLowerCase(),
        ]);
        return {
            linkType,
            linkedObjects,
            displayName: this.translate.instant(
                `DgServerTypes.ObjectLinkType.${ObjectLinkType[linkType]}`
            ),
            isCollapsed: false,
            rows: linkedObjects.map((lo) => this.makeRow(lo)),
        } as ILinkTypeGroup;
    }

    private makeRow(lo: ILinkedObject) {
        return {
            renderer: EntityCardCellComponent,
            rendererParam: {
                data: lo,
                actions: this.resultActions?.slice(),
                inputs: {
                    hasGoldenLinkIndicator: lo.isGoldenLink,
                    dtContext: 'Linked Objects',
                    breadcrumbOpenPreview: true,
                    noLabelNavLink: this.noRedirect,
                    getAttributes: (data: ILinkedObject) =>
                        this.linkedDataEntitiesResult?.find(
                            (entity) =>
                                entity.ReferenceId === data.HddData.ReferenceId
                        )?.Attributes,
                },
            },
            linkIdr: lo.linkIdr,
        } as IRenderableRow;
    }

    private onGoldenLinkUpdate(data: UpdateEntityLinkResult) {
        if (!data.IsSuccess) {
            return;
        }
        const los: ILinkedObject[] = [];
        data.UpdatedEntityLinkItems.forEach(
            ({ DataReferenceId, IsGoldenLink }) => {
                this.linkedObjects
                    .filter((lo) => lo.linkReferenceId == DataReferenceId)
                    .forEach((lo) => {
                        lo.isGoldenLink = IsGoldenLink;
                        los.push(lo);
                    });
            }
        );
        this.linkTypeGroupsByFlow
            .get(FlowType.all)
            .filter((g) => g.linkedObjects.some((lo) => los.includes(lo)))
            .forEach(
                (g) => (g.rows = g.linkedObjects.map((lo) => this.makeRow(lo)))
            );
        this.applyNameFilter();
    }

    private buildTabs() {
        if (!this.tabs) {
            return;
        }
        const flowTypes = this.flowTypes.filter((ft) =>
            this.isFlowTabVisible(ft)
        );
        this.tabItems = flowTypes.map((ft) =>
            this.makeFlowTab(ft, flowTypes.length == 1)
        );
        this.activeTabItem = this.tabItems.find(
            (t) => t.data == this.tabs.initial
        );
        if (
            this.activeTabItem &&
            this.activeTabItem.data != FlowType.all &&
            this.tabs.initialAllIfEmptyFlow &&
            this.activeTabItem.contentDataCount == 0
        ) {
            this.activeTabItem = this.tabItems.find(
                (t) => t.data == FlowType.all
            );
        }
    }
    private isFlowTabVisible(ft: FlowType) {
        return !ft
            ? !this.tabs.noAllTab
            : !this.tabs.flowType || this.tabs.flowType == ft;
    }
    private makeFlowTab(
        flow: FlowType,
        useTitleSingleTab?: boolean
    ): ITabItem<FlowType> {
        flow ||= FlowType.all;
        const flowString = FlowType[flow];
        const distinct = false; // true to count linked objects, false to count links
        return {
            tabId: flowString,
            tabTranslateKey: `UI.EntityLinkedObjects.tabs.${flowString}.${
                useTitleSingleTab ? 'titleSingleTab' : 'title'
            }`,
            data: flow,
            contentDataCount: distinct
                ? CollectionsHelper.count(
                      this.linkedObjects,
                      (lo) => lo.flow == flow
                  )
                : CollectionsHelper.sum(
                      this.linkTypeGroupsByFlow.get(flow),
                      (g) => g.linkedObjects.length
                  ),
            showEmptyDataCount: true,
        };
    }
}

/** Type of flow: upstream, downstream, all (default) - Component-specific logic */
export enum FlowType {
    all = 0,
    downstream,
    upstream,
}
export interface ILinkedObjectsTabs {
    /** true not to display the 'All' tab */
    noAllTab?: boolean;
    /** when defined, displays a 'downstream' or 'upstream' tab - defaults to *both* */
    flowType?: FlowType;
    /** the initial tab to display - defaults to the first of the displayed tabs */
    initial?: FlowType;
    /** when true and initial tab is not *all* and no elements in the flow tab, then the *all* tab is displayed instead */
    initialAllIfEmptyFlow?: boolean;
}
export interface ILinkedObject extends IHasHddData, IHasTechnicalName {
    /** entity for which the request was made */
    localEntityIdr: IEntityIdentifier;

    /** linked entity identifier */
    linkedObjectIdr: IEntityIdentifier;
    /** module of the linked entity - used for sorting */
    dgModule: DgModule;

    /** type of link */
    linkType: ObjectLinkType;
    /** id of the link */
    linkReferenceId: string;
    isGoldenLink: boolean;

    /** direction of the link, seen from the local entity */
    flow: FlowType;

    linkIdr: IEntityLinkIdentifier;
}

interface ILinkTypeGroup {
    linkType: ObjectLinkType;
    linkedObjects: ILinkedObject[];
    displayName: string;
    isCollapsed: boolean;
    rows: IRenderableRow[];
}

interface IRenderableRow extends IRenderable {
    linkIdr: IEntityLinkIdentifier;
}

interface IRenderable {
    renderer: Type<EntityCardCellComponent>;
    rendererParam: IEntityCardCellParams<IHasHddData>;
}
