<dxy-spinner
    *ngIf="loading$ | async; else button"
    class="no-margin no-padding"
></dxy-spinner>

<ng-template #button>
    <button
        dxyIconButton
        color="secondary"
        (click)="openModal()"
        [dxyRichTooltip]="tooltip"
    >
        <i class="glyph-languages"></i>
    </button>
</ng-template>

<ng-template #tooltip>
    <div class="dg5-custom-tooltip">
        <span translate>Multilingual.RegenerateTranslation.title</span>
    </div>
</ng-template>
