<div class="entity-dashboard-wrapper">
    <header>
        <div class="entity-header-container">
            <dxy-entity-header [entityData]="entityData"></dxy-entity-header>
            <div class="actions-container">
                <dxy-translate-entity-button
                    *ngIf="entityData?.SecurityData?.HasWriteAccess"
                    [entityIdentifier]="entityData"
                ></dxy-translate-entity-button>
                <app-watch-entity-button
                    [entityItem]="entityData"
                ></app-watch-entity-button>
                <dxy-dropdown-button
                    *ngIf="showEntityActionsDropDown"
                    type="burger"
                    tooltipTranslateKey="UI.EntityHeader.actions.moreOptionsTooltip"
                    [actions]="entityActions"
                ></dxy-dropdown-button>
                <button
                    type="button"
                    mat-icon-button
                    class="action-btn"
                    (click)="onClickToggleFullPage()"
                    [matTooltip]="toggleDisplayModeTooltipKey | translate"
                >
                    <i [ngClass]="toggleIconClass"></i>
                </button>
            </div>
        </div>
    </header>

    <div class="entity-dashboard-container-main">
        <ng-container *ngIf="hasEntity">
            <dxy-tab-view
                class="alternative"
                customClass="no-header-border"
                [tabsData]="tabsData"
                (onTabChanged)="onTabChanged($event)"
            ></dxy-tab-view>
            <app-entity-docking-pane
                [entityData]="entityData"
                [dgModule]="dgModule"
                preferredTool="Insights"
                logId="module-main"
            ></app-entity-docking-pane>
        </ng-container>
        <div *ngIf="!hasEntity" class="no-item-container">
            <h4
                class="dg_noItem-containerMessage"
                [translate]="msgNoSelectionTranslateKey"
            ></h4>
            <div class="group-container no-item-group">
                <div class="group group-1">
                    <div class="shadowbox-rect-h"></div>
                    <div class="same-row">
                        <div class="shadowbox-square"></div>
                        <div class="shadowbox-square"></div>
                    </div>
                    <div class="shadowbox-rect-h"></div>
                </div>
                <div class="group group-4">
                    <div class="shadowbox-rect-h-flat"></div>
                    <div class="same-row">
                        <div class="shadowbox-rect-v"></div>
                        <div class="same-col">
                            <div class="shadowbox-little-square"></div>
                            <div class="shadowbox-square"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
