<ng-template #cardContent>
    <ng-container *ngIf="hasData">
        <dxy-action-list
            [actions]="actions"
            [hideActions]="isMiniCard && !showActions"
            [actionsCaptureMouseDown]="actionsCaptureMouseDown"
            [data]="actionsData"
            (onLogFunctional)="onLogFunctional($event)"
        >
            <dxy-entity-type-icon
                *ngIf="typeIconVisible"
                [entityType]="hierarchicalData.entityType"
            ></dxy-entity-type-icon>
            <dxy-entity-type-card
                *ngIf="!isMiniCard"
                [displayMode]="entityCardSettings.entityCardDisplay"
                [entityType]="hierarchicalData.entityType"
                [iconUrl]="technoImgUrl"
                [iconTooltip]="technoTooltip"
            ></dxy-entity-type-card>
            <div class="entity-data-container">
                <dxy-nav-breadcrumb
                    *ngIf="showBreadcrumb"
                    [class.no-height]="!noName"
                    [hierarchicalData]="hierarchicalData"
                    [readOnly]="noBreadcrumbNavLink"
                    [openPreview]="breadcrumbOpenPreview"
                    [forceIncludeSpace]="forceIncludeSpace"
                    [forceExcludeSpace]="forceExcludeSpace"
                    [dtContext]="dtContext"
                ></dxy-nav-breadcrumb>
                <div *ngIf="!noName" class="entity-names">
                    <div *ngIf="aliasNameValue" class="alias-name primary-name">
                        <span class="entity-name">{{ aliasNameValue }}</span>
                    </div>
                    <div
                        class="display-name"
                        [class.secondary-name]="aliasNameValue"
                        [class.is-obsolete]="isObsolete"
                    >
                        <a
                            *ngIf="!noLabelNavLink"
                            dxyNavDataLink
                            [hierarchicalData]="hierarchicalData"
                            [isFromHierarchical]="isFromHierarchical"
                            [withEntityFullPage]="withEntityFullPage"
                            class="entity-name"
                            [dxyEllipsisTooltip]="
                                obsoleteTooltipKey | translate
                            "
                            dxyEllipsisMode="alwaysVisible"
                            >{{ displayName$ | async }}{{ nameSuffixValue }}</a
                        >
                        <span
                            *ngIf="noLabelNavLink"
                            class="entity-name"
                            [dxyEllipsisTooltip]="
                                obsoleteTooltipKey | translate
                            "
                            dxyEllipsisMode="alwaysVisible"
                        >
                            {{ displayName$ | async
                            }}{{ nameSuffixValue }}</span
                        >
                    </div>
                    <span
                        *ngIf="hasGoldenLinkIndicator"
                        class="golden-link-icon glyph-golden-link"
                        [matTooltip]="
                            'UI.EntityLinkedObjects.goldenLinkIconTooltip'
                                | translate
                        "
                    ></span>
                </div>
            </div>
        </dxy-action-list>
    </ng-container>
    <span *ngIf="!hasData">{{ placeholderText }}</span>
</ng-template>

<!--
    don't use cdkDragDisabled on purpose:
    cdkDrag, even if disabled, causes big issues when entity-card is inside cdk-drop-list
-->
<div
    *ngIf="isDragDisabled"
    class="dg5-entity-card entity-card-content"
    [class.mini-card]="isMiniCard"
    [ngClass]="customClass"
>
    <ng-container *ngTemplateOutlet="cardContent"></ng-container>
</div>
<div
    *ngIf="!isDragDisabled"
    class="dg5-entity-card entity-card-content"
    [class.mini-card]="isMiniCard"
    [ngClass]="customClass"
    cdkDrag
    [cdkDragData]="hierarchicalData?.Data"
    [cdkDragPreviewContainer]="dragDrop?.previewContainer"
    [cdkDragPreviewClass]="dragDrop?.previewClass"
    [ngClass]="dragDrop?.itemClass"
>
    <ng-container *ngTemplateOutlet="cardContent"></ng-container>
</div>
<div *ngIf="withExtraData" class="extra-data">
    <ng-content></ng-content>
</div>
