import { DialogType } from '@datagalaxy/ui/dialog';
import { CollectionsHelper } from '@datagalaxy/core-util';
import {
    ILocalSynonymModalResolve,
    ILocalSynonymModalResult,
} from '../dxy-local-synonym-modal/local-synonym-modal.types';
import {
    BaseService,
    DxyPopoverService,
    IActionOption,
    UiSpinnerService,
} from '@datagalaxy/core-ui';
import {
    IEntityCreateModalInput,
    IEntityCreateModalOutput,
    IOpenCreationModalOptions,
} from '../interfaces/entity-create-modal.types';
import { DxyEntityCreateModalComponent } from '../dxy-entity-create-modal/dxy-entity-create-modal.component';
import { DxyEntityMoveModalComponent } from '../dxy-entity-move-modal/dxy-entity-move-modal.component';
import { IMoveEntityModalResolve } from '../dxy-entity-move-modal/entity-move-modal-types';
import { DxyLocalSynonymModalComponent } from '../dxy-local-synonym-modal/dxy-local-synonym-modal.component';
import { DxyEntityBulkEditModalComponent } from '../dxy-entity-bulk-edit-modal/dxy-entity-bulk-edit-modal.component';
import { IEntityBulkEditModalInputs } from '../dxy-entity-bulk-edit-modal/entity-bulk-edit-modal.types';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from '../../../services/navigation.service';
import { EntityPreviewPanelService } from './entity-preview-panel.service';
import {
    DxyEntityUserListPopoverTooltipComponent,
    IUsersPopoverTooltipResolve,
} from '../dxy-entity-user-list-popover-tooltip/dxy-entity-user-list-popover-tooltip.component';
import { coerceArray } from '@angular/cdk/coercion';
import {
    DxyEntityPopoverTooltipRichComponent,
    IEntityPopoverTooltipResolve,
} from '../dxy-entity-popover-tooltip-rich/dxy-entity-popover-tooltip-rich.component';
import {
    EntityType,
    EntityTypeUtil,
    IEntityIdentifier,
    IHasHddData,
    IHierarchicalData,
    ObjectLinkType,
    ServerType,
    TypeLinkDataInfo,
} from '@datagalaxy/dg-object-model';
import { EntityService } from './entity.service';
import {
    EntityLinkedObjectMode,
    ILinkedObjectModalInput,
    ILinkedObjectModalOutput,
} from '../linked-object-modal/linked-object-modal.types';
import {
    CreateEntityCheckParentMode,
    EntityCreationOrigin,
} from '../entity.types';
import { DisplayedHData } from '../../util/app-types/DisplayedHData';
import { DataUtil } from '../../util/DataUtil';
import { SecurityService } from '../../../services/security.service';
import { EntityLinkService } from '../../../entity-links/entity-link.service';
import { CurrentSpaceService } from '../../../services/currentSpace.service';
import { AttributeDataService } from '../../attribute/attribute-data.service';
import { ViewTypeService } from '../../../services/viewType.service';
import { AppSpaceService } from '../../../services/AppSpace.service';
import { EntityCardSettings } from '../../entityCard/entity-card/EntityCardSettings';
import { LinkedObjectModalComponent } from '../linked-object-modal/linked-object-modal.component';
import { DxyModalService } from '../../dialogs/DxyModalService';
import { DxyGlossaryFusionModalComponent } from '../../../glossary/dxy-glossary-fusion-modal/dxy-glossary-fusion-modal.component';
import { TechnologyService } from '../../../client-admin/services/technology.service';
import { IConfirmDeleteObjectOptions } from '../../dialogs/shared-dialogs.types';
import { ModelerDataUtil } from '../../util/ModelerDataUtil';
import { ExportService } from '../../../services/export.service';
import { IEntityFusionModalInput } from '../interfaces/entity-fusion-modal.interface';
import { Space } from '@datagalaxy/webclient/workspace/data-access';
import { FusionEntitiesResult } from '@datagalaxy/webclient/entity/data-access';
import { UserAdminData } from '@datagalaxy/webclient/user/data-access';
import {
    CrudOperation,
    FunctionalLogService,
} from '@datagalaxy/webclient/monitoring/data-access';
import { EntityCardDisplayMode } from '@datagalaxy/webclient/entity/ui';
import {
    EntityIdentifier,
    EntityTypeUtils,
} from '@datagalaxy/webclient/entity/utils';
import { SpaceIdentifier } from '@datagalaxy/webclient/workspace/utils';
import { UserPublicData } from '@datagalaxy/webclient/user/domain';
import { ISpaceIdentifier } from '@datagalaxy/webclient/workspace/domain';
import { FilteredViewDto } from '@datagalaxy/webclient/filter/domain';
import {
    AttributeMetaInfo,
    AttributeMetaType,
} from '@datagalaxy/webclient/attribute/domain';
import {
    EntityItem,
    IMiniEntityContent,
} from '@datagalaxy/webclient/entity/domain';
import { DgModule } from '@datagalaxy/shared/dg-module/domain';
import { ServerConstants } from '@datagalaxy/shared/server/domain';
import PropertyName = ServerConstants.PropertyName;

@Injectable({ providedIn: 'root' })
export class EntityUiService extends BaseService {
    constructor(
        private translate: TranslateService,
        private viewTypeService: ViewTypeService,
        private securityService: SecurityService,
        private entityService: EntityService,
        private linkedObjectService: EntityLinkService,
        private functionalLogService: FunctionalLogService,
        private uiSpinnerService: UiSpinnerService,
        private dxyModalService: DxyModalService,
        private attributeDataService: AttributeDataService,
        private appSpaceService: AppSpaceService,
        private currentSpaceService: CurrentSpaceService,
        private exportService: ExportService,
        private technologyApiService: TechnologyService,
        private navigationService: NavigationService,
        private entityPreviewPanelService: EntityPreviewPanelService,
        private dxyPopoverService: DxyPopoverService
    ) {
        super();
    }

    //#region modals (open)

    public openColumnDeletePkFkLimitationMessage() {
        return this.openOkDialogModal(
            'UI.Modeler.columnDeletePkFkLimitationModal.title',
            'UI.Modeler.columnDeletePkFkLimitationModal.message'
        );
    }

    public async openCreationModal(
        actionOrigin: EntityCreationOrigin,
        opt?: IOpenCreationModalOptions
    ): Promise<IEntityCreateModalOutput> {
        let space: Space;
        if (opt?.parentData) {
            space = await this.appSpaceService.getSpace(
                SpaceIdentifier.fromEntity(opt.parentData)
            );
        } else if (
            actionOrigin == EntityCreationOrigin.globalAddButton ||
            actionOrigin == EntityCreationOrigin.diagramAddEntityButton
        ) {
            if (
                !this.currentSpaceService.hasCurrentSpace() &&
                this.appSpaceService.isSingleWorkspace()
            )
                space = await this.appSpaceService.getSingleWorkSpace();
            else space = this.currentSpaceService.getCurrentOrLastSpace();
        } else {
            space = this.currentSpaceService.getCurrentSpace();
        }

        const consolidationMode = this.securityService.isSingleWorkspaceClient()
            ? CreateEntityCheckParentMode.NoCheckBox
            : undefined;

        return await this.dxyModalService.open<
            DxyEntityCreateModalComponent,
            IEntityCreateModalInput,
            IEntityCreateModalOutput
        >({
            componentType: DxyEntityCreateModalComponent,
            data: {
                currentSpace: space,
                serverType: opt?.serverType,
                modelHdd: opt?.modelHdd,
                parentData: opt?.parentData,
                consolidationMode,
                actionOrigin,
                filterEntityTypes: opt?.filterEntityTypes,
            },
        });
    }

    public showInfoModal(title: string, message: string) {
        return this.dxyModalService.inform({
            title,
            message,
            type: DialogType.Close,
        });
    }

    public async openFusionModal(
        spaceIdr: ISpaceIdentifier,
        sourceEntities: EntityItem[]
    ) {
        return this.dxyModalService.open<
            DxyGlossaryFusionModalComponent,
            IEntityFusionModalInput,
            FusionEntitiesResult
        >({
            componentType: DxyGlossaryFusionModalComponent,
            data: { spaceIdr, sourceEntities },
        });
    }

    public async confirmDelete(
        serverType: ServerType,
        opt?: IConfirmDeleteObjectOptions
    ) {
        return await this.dxyModalService.confirmDeleteObject(
            ServerType[serverType],
            opt
        );
    }

    public async showDeleteInfoDialog() {
        await this.dxyModalService.inform({
            titleKey: 'UI.EntityDeleteInfo.title',
            messageKey: 'UI.EntityDeleteInfo.msg',
        });
    }

    /** for add or replace */
    public async openLinkObjectModal(entityDataList: EntityItem[]) {
        return await this.dxyModalService.open<
            LinkedObjectModalComponent,
            ILinkedObjectModalInput,
            ILinkedObjectModalOutput
        >({
            componentType: LinkedObjectModalComponent,
            data: {
                mode: EntityLinkedObjectMode.CreateOrReplace,
                entityDataList,
                availableLinkTypes:
                    await this.linkedObjectService.getAvailableLinkTypesForEntities(
                        entityDataList
                    ),
            },
        });
    }

    /** for delete */
    public async openDeleteLinkObjectModal(entityDataList: EntityItem[]) {
        await this.dxyModalService.open<
            LinkedObjectModalComponent,
            ILinkedObjectModalInput,
            ILinkedObjectModalOutput
        >({
            componentType: LinkedObjectModalComponent,
            data: {
                mode: EntityLinkedObjectMode.Delete,
                entityDataList,
                availableLinkTypes:
                    await this.linkedObjectService.getAvailableLinkTypesForEntities(
                        entityDataList
                    ),
            },
        });
    }

    public async openSynonymLinkObjectModal(entityData: EntityItem) {
        await this.linkedObjectService.openLinkCreationModal(entityData, null, {
            includeEntityLinks: true,
            onlyUniversalLinkTypes: [ObjectLinkType.IsSynonymOf],
        });
    }

    public async openLocalSynonymModal(
        isCreation = true,
        displayName?: string,
        description?: string
    ) {
        return await this.dxyModalService.open<
            DxyLocalSynonymModalComponent,
            ILocalSynonymModalResolve,
            ILocalSynonymModalResult
        >({
            componentType: DxyLocalSynonymModalComponent,
            data: { isCreation, displayName, description },
        });
    }

    /**  NOTE: the real action is done by the method caller (time-series modal)*/
    public async confirmDeleteTimeSeriesValuesModal() {
        return this.dxyModalService.confirm({
            titleKey: 'UI.EntityTimeSeriesHistoryModal.deleteAllValuesLbl',
            messageKey:
                'UI.EntityTimeSeriesHistoryModal.deleteAllValuesMessage',
            type: DialogType.Action,
            confirmButtonKey: 'UI.Dialog.btnDelete',
        });
    }

    private openOkDialogModal(titleKey: string, messageKey: string) {
        return this.dxyModalService.inform({
            titleKey,
            messageKey,
            type: DialogType.Ok,
        });
    }

    //#endregion modal

    //#region popover

    public openEntityUserListPopover(
        target: HTMLElement,
        entityData: IMiniEntityContent,
        data: UserPublicData | UserPublicData[],
        title?: string,
        openingDelay = 300
    ) {
        this.dxyPopoverService.open<
            DxyEntityUserListPopoverTooltipComponent,
            IUsersPopoverTooltipResolve
        >({
            componentType: DxyEntityUserListPopoverTooltipComponent,
            target,
            openingDelay,
            data: {
                title,
                entityData,
                users: coerceArray(data),
            },
        });
    }

    public async openEntityPopover(
        el: HTMLElement,
        entityIdr: IEntityIdentifier,
        openingDelay?: number
    ) {
        this.dxyPopoverService.open<
            DxyEntityPopoverTooltipRichComponent,
            IEntityPopoverTooltipResolve
        >({
            componentType: DxyEntityPopoverTooltipRichComponent,
            target: el,
            openingDelay,
            data: { entityIdr },
        });
    }

    //#endregion popover

    //#region bulk

    public async openBulkEditModal(
        serverType: ServerType,
        entityDataList: EntityItem[]
    ): Promise<{ IsSuccess: boolean }> {
        const noWriteAccessEntityDataList = entityDataList.filter(
            (a) => a.SecurityData.HasWriteAccess == false
        );
        const hasWriteAccess = !noWriteAccessEntityDataList.length;
        const selectedEntityDataListServerTypes =
            CollectionsHelper.distinctValues(
                entityDataList,
                (a) => a.ServerType
            );
        const isMultiEntityTypes = selectedEntityDataListServerTypes.length > 1;
        serverType = serverType ?? ServerType.AllTypes;
        const entityServerType = isMultiEntityTypes
            ? serverType
            : selectedEntityDataListServerTypes[0];
        const availableLinkTypes =
            await this.linkedObjectService.getAvailableLinkTypesForEntities(
                entityDataList
            );
        let entityAttributes =
            await this.entityService.getEntityAttributesForBulk(
                selectedEntityDataListServerTypes,
                isMultiEntityTypes
            );
        entityAttributes = this.filterAllowedAttributesForBulk(
            entityDataList,
            entityAttributes,
            availableLinkTypes
        );

        await this.dxyModalService.open<
            DxyEntityBulkEditModalComponent,
            IEntityBulkEditModalInputs,
            void
        >({
            componentType: DxyEntityBulkEditModalComponent,
            data: {
                entityServerType,
                entityDataList,
                availableLinkTypes,
                entityAttributes,
                hasWriteAccess,
                noWriteAccessEntityDataList,
            },
        });

        // { IsSuccess: true } would clear the grid selection (cf EntityGridCore.doBulk)
        return { IsSuccess: false };
    }

    private filterAllowedAttributesForBulk(
        entityDataList: EntityItem[],
        attributes: AttributeMetaInfo[],
        availableLinks: TypeLinkDataInfo[]
    ) {
        //const entityStatusAttributeIndex = attributes.findIndex(a => a.AttributeKey == ServerTypesConstants.EntityStatus);
        const nonOfficialRoleWriteAccess = entityDataList.some(
            (e) => e.SecurityData.HasOfficialRoleAttributesWriteAccess == false
        );
        const nonEntityStatusWriteAccess = entityDataList.some(
            (e) => e.SecurityData.HasEntityStatusWriteAccess == false
        );

        if (nonOfficialRoleWriteAccess) {
            attributes = attributes.filter(
                (a) => !AttributeDataService.isOfficialRoleAttribute(a)
            );
        }

        if (nonEntityStatusWriteAccess) {
            attributes = attributes.filter(
                (a) => a.AttributeKey != PropertyName.EntityStatus
            );
        }

        CollectionsHelper.remove(attributes, (a) => {
            if (a.AttributeType == AttributeMetaType.EntityLinkShortcut) {
                return (
                    a.EntityLinkTypeKinds.findIndex((kind) => {
                        return (
                            availableLinks.findIndex((link) => {
                                if (
                                    link.EntityLinkTypeKind == kind &&
                                    link.IsReverseEntityLink ==
                                        a.IsReversedEntityLink
                                ) {
                                    a.excludedEntitiesIds =
                                        link.ExcludedEntitiesIds;
                                    return true;
                                }
                                return false;
                            }) > 0
                        ); //#Archi (fbo) what if findIndex returns 0 ? couldn't we use array.some, instead ?
                    }) < 0
                );
            }
            return false;
        });
        return attributes.filter((c) =>
            this.isAttributeAvailableForBulk(c, entityDataList)
        );
    }

    private isAttributeAvailableForBulk(
        attribute: AttributeMetaInfo,
        entityDataList: EntityItem[]
    ) {
        if (attribute.AttributeType != AttributeMetaType.EntityLogicalParent) {
            return true;
        }
        return this.isLogicalParentAttributeAvailable(entityDataList);
    }
    private isLogicalParentAttributeAvailable(entityDataList: EntityItem[]) {
        let subTypes = CollectionsHelper.distinct(
            entityDataList.map((c) => c.EntityType)
        );
        if (subTypes.length > 1) {
            let hasSameAllowedParentType = true;
            subTypes.forEach((a) => {
                if (!hasSameAllowedParentType) {
                    return;
                }
                subTypes.forEach((s) => {
                    if (!hasSameAllowedParentType) {
                        return;
                    }
                    hasSameAllowedParentType =
                        EntityTypeUtil.hasSameAllowedParentTypes(a, s) &&
                        hasSameAllowedParentType;
                });
            });
            return hasSameAllowedParentType;
        }
        return subTypes.some((et) => EntityTypeUtil.isParentUpdatable(et));
    }

    //#endregion

    /**
     * Open entity preview if available or, if *noRedirect* is falsy, redirect to its details page
     * If preview/details is not available, redirect to specific entity's page
     */
    public async openPreviewOrDetailsOrDefault(
        entity: IHasHddData,
        noRedirect?: boolean
    ) {
        if (DataUtil.isAvailableForPreviewPanel(entity)) {
            await this.entityPreviewPanelService.setupPanel({
                entityIdr: EntityIdentifier.fromIHasHddData(entity),
            });
        } else if (!noRedirect) {
            if (DataUtil.isAvailableForDetailsPage(entity.HddData.ServerType)) {
                await this.navigationService.goToEntityDetailsByIdentifier(
                    entity.HddData
                );
            } else {
                await this.navigationService.goToWithHierarchicalData(
                    entity.HddData
                );
            }
        }
    }

    public async getModuleEntitiesCount(
        dgModule: DgModule,
        spaceIdr: ISpaceIdentifier,
        filteredView?: FilteredViewDto
    ): Promise<number> {
        if (!dgModule) {
            return 0;
        }

        const serverTypes =
            dgModule == DgModule.Catalog
                ? ModelerDataUtil.modelerServerTypes
                : [DataUtil.getDefaultServerTypeFromModule(dgModule)];
        const filters = filteredView?.ListFilter;
        const result = await this.entityService.getEntitiesCount(
            spaceIdr,
            serverTypes,
            filters
        );
        return result?.TotalCount || 0;
    }

    public buildDisplayedHData(
        hd: IHierarchicalData,
        isReadOnly: boolean,
        navToTab?: string,
        originItem?: any
    ) {
        const dd = hd?.Data;
        const displayNameTranslateKey =
            this.viewTypeService.getTechnicalOrDisplayName(dd);
        const displayName = displayNameTranslateKey
            ? this.translate.instant(displayNameTranslateKey)
            : '';
        const glyphClassName = EntityTypeUtils.getColoredGlyphClass(
            dd.entityType
        );
        return new DisplayedHData(
            displayName,
            glyphClassName,
            hd,
            !isReadOnly,
            navToTab,
            originItem
        );
    }

    public onCloneEntity(
        entityData: EntityItem,
        actionOrigin: EntityCreationOrigin
    ) {
        this.functionalLogService.logEntityItemAction(
            entityData,
            CrudOperation.C
        );
        return this.entityService.cloneEntity(entityData, { actionOrigin });
    }

    public async onExportEntity(entityData: EntityItem) {
        return await this.exportService.exportEntity(entityData);
    }

    /** note: Pops up a confirmation dialog */
    public async onDeleteEntity(entityIdr: IEntityIdentifier) {
        const preResult = await this.entityService.preDeleteEntities(
            [entityIdr.ReferenceId],
            entityIdr.ServerType
        );
        const featureCode =
            FunctionalLogService.getEntityIdrActionFeatureCodeCrud(
                entityIdr,
                CrudOperation.D
            );
        const confirmed = await this.confirmDelete(null, {
            featureCode,
            totalCount: preResult.TotalDeletedCount,
        });
        if (!confirmed) {
            return false;
        }
        const entityId = await this.uiSpinnerService.executeWithSpinner(() =>
            this.entityService.deleteEntity(entityIdr)
        );
        return entityId == entityIdr.ReferenceId;
    }

    public async onFusionEntity(entityData: EntityItem) {
        await this.openFusionModal(SpaceIdentifier.fromEntity(entityData), [
            entityData,
        ]);
    }

    public async onCreateChild(
        entityData: EntityItem,
        actionOrigin: EntityCreationOrigin,
        serverType?: ServerType
    ) {
        return this.openCreationModal(actionOrigin, {
            parentData: entityData,
            serverType,
            modelHdd: entityData.HddData.Data,
        });
    }

    public async onChangeParent(entityData: EntityItem) {
        return this.openChangeParentModal(entityData);
    }

    public async openChangeParentModal(entityData: EntityItem) {
        await this.dxyModalService.open<
            DxyEntityMoveModalComponent,
            IMoveEntityModalResolve,
            void
        >({
            componentType: DxyEntityMoveModalComponent,
            data: { entityData },
        });
    }

    openDeleteUserGovernanceUserLimitationModal(currentUser: UserAdminData) {
        let message = this.translate.instant(
            'UI.Users.Profile.officialRolesUserDeletionPreventedDialog.messageHeader'
        );
        const attributeFunc = (ak: string) =>
            this.attributeDataService.getAttributeDisplayNameInternal(
                ak,
                false,
                null,
                ServerType.Property
            );

        const attributePartKey =
            'UI.Users.Profile.officialRolesUserDeletionPreventedDialog.messageSpacesPart';

        currentUser.DefaultOfficialUserSpaces.forEach((value, key) => {
            const attributeDisplayName = attributeFunc(key);
            const attributePart = this.translate.instant(attributePartKey, {
                attributeDisplayName: attributeDisplayName,
            });
            message += attributePart;
            value.forEach(
                (s) => (message += `&nbsp;&nbsp;${s.DisplayName}<br />`)
            );
        });

        return this.dxyModalService.inform({
            titleKey:
                'UI.Users.Profile.officialRolesUserDeletionPreventedDialog.title',
            message,
        });
    }

    public getEntityTypeTranslationWithArticle(
        entityType: EntityType,
        optionalTranslateKey?: string
    ) {
        return this.attributeDataService.getEntityTypeTranslationWithArticle(
            entityType,
            optionalTranslateKey
        );
    }

    public getUserEntityCardDisplaySettings(technoCode?: string) {
        const currentDisplay = this.technologyApiService.userEntityCardDisplay;
        let technoImgUrl = '';
        let technoTooltip = '';
        if (currentDisplay == EntityCardDisplayMode.GlyphOnly) {
            return new EntityCardSettings(currentDisplay);
        }
        if (technoCode) {
            technoImgUrl =
                this.technologyApiService.getTechnologyIconUrlFromCode(
                    technoCode,
                    true
                );
            technoTooltip =
                this.technologyApiService.getTechnologyFromCode(
                    technoCode
                )?.DisplayName;
        }
        return new EntityCardSettings(
            currentDisplay,
            technoImgUrl,
            technoTooltip
        );
    }

    public canDeleteParent(entityType: EntityType) {
        const mapping = EntityTypeUtil.getMapping(entityType);
        return !mapping.ParentRules[0].IsMandatory;
    }

    /**
     * Build a burgerMenu IActionOption to provide navigations options (details, preview, lineage, analysis)
     * It can take and additional param to add more actions, which will be split inside the burger menu
     * with navigation/actions categories
     */
    public getEntityCellNavigationMenu<T extends IHasHddData>(
        logId: string,
        opts?: IActionOption<T>[]
    ): IActionOption<T> {
        const navigationCategoryKey =
            'UI.EntityBurgerMenu.Categories.Navigation';
        const zoneFeatureCode =
            this.navigationService.getCurrentZoneFeatureCode();
        return {
            tooltipTranslateKey: 'UI.Global.ttMoreOptions',
            options: [
                {
                    labelKey: 'UI.EntityBurgerMenu.Navigation.goToDetails',
                    glyphClass: 'glyph-object-preview',
                    categoryKey: navigationCategoryKey,
                    logFunctional: `${logId}_OBJECT_DETAILS_${zoneFeatureCode},R`,
                    hidden: (data: IHasHddData) =>
                        !DataUtil.isAvailableForDetailsPage(
                            data.HddData.ServerType
                        ),
                    callback: (data: IHasHddData) =>
                        this.navigationService.goToEntityDetailsByIdentifier(
                            data.HddData
                        ),
                },
                {
                    labelKey: 'UI.EntityBurgerMenu.Navigation.openPreview',
                    glyphClass: 'glyph-object-preview',
                    categoryKey: navigationCategoryKey,
                    logFunctional: `${logId}_OBJECT_PREVIEW_${zoneFeatureCode},R`,
                    hidden: (data: IHasHddData) =>
                        !DataUtil.isAvailableForPreviewPanel(data),
                    callback: (data: IHasHddData) =>
                        this.entityPreviewPanelService.setupPanel({
                            entityIdr: EntityIdentifier.fromIHasHddData(data),
                        }),
                },
                {
                    labelKey: 'UI.EntityBurgerMenu.Navigation.goToLineage',
                    glyphClass: 'glyph-lineage',
                    categoryKey: navigationCategoryKey,
                    logFunctional: `${logId}_OBJECT_LINEAGE_${zoneFeatureCode},R`,
                    hidden: (data: IHasHddData) =>
                        !DataUtil.isAvailableForDetailsPage(
                            data.HddData.ServerType
                        ),
                    callback: (data: IHasHddData) =>
                        this.navigationService.goToEntityLineage(data.HddData),
                },
                {
                    labelKey:
                        'UI.EntityBurgerMenu.Navigation.goToImpactAnalysis',
                    glyphClass: 'glyph-analysis',
                    categoryKey: navigationCategoryKey,
                    logFunctional: `${logId}_IMPACT_ANALYSIS_${zoneFeatureCode},R`,
                    hidden: (data: IHasHddData) =>
                        !DataUtil.isAvailableResultItemLineageLink(data),
                    callback: (data: IHasHddData) =>
                        this.navigationService.goToEntityImpactAnalysis(
                            data.HddData
                        ),
                },
                ...(opts ?? []).map((opt) => ({
                    ...opt,
                    categoryKey: 'UI.EntityBurgerMenu.Categories.Actions',
                })),
            ],
        };
    }
}
