export interface IEntityCreationContext {
    origin?: EntityCreationOrigin;
    // true when create-another is checked in entity-create-modal
    isMultiCreation?: boolean;
}

export enum EntityCreationOrigin {
    unknown = 0,
    globalAddButton,
    dockingPane,
    burgerMenu,
    burgerMenuCreateChild,
    gridAddButton,
    dashboardCloneButton,
    diagramClone,
    diagramElementClone,
    diagramAddEntityButton,
}

export enum CreateEntityCheckParentMode {
    NoCheckBox,
    CheckBoxChecked,
    CheckBoxUnchecked,
}
