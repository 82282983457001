import { Injectable } from '@angular/core';
import { FeatureFlagService } from '@datagalaxy/webclient/feature-flag';
import { ViewTypeService } from '../../../services/viewType.service';
import {
    AttributeValueTranslationService,
    AttributeValueTranslationResult,
} from '@datagalaxy/webclient/multilingual/feature';
import { UpdateEntityAttributeResult } from '@datagalaxy/webclient/entity/data-access';
import { Observable, map, of, switchMap } from 'rxjs';
import { EntityService } from './entity.service';
import { EntityDockingPaneService } from './entity-docking-pane.service';
import { HierarchicalData } from '@datagalaxy/dg-object-model';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { ServerConstants } from '@datagalaxy/shared/server/domain';

const technicalPropertyName = ServerConstants.PropertyName.TechnicalName;
const functionalPropertyName = ServerConstants.PropertyName.DisplayName;

@Injectable({ providedIn: 'root' })
export class EntityDisplayNameService {
    constructor(
        featureFlagService: FeatureFlagService,
        private viewTypeService: ViewTypeService,
        private entityService: EntityService,
        private entityDockingPaneService: EntityDockingPaneService,
        private attributeValueTranslationService: AttributeValueTranslationService
    ) {
        if (!featureFlagService.isFeatureEnabled('multilingual')) {
            return;
        }
    }

    public getDisplayName$(entity: HierarchicalData) {
        return this.getDisplayNameTranslationResult$(entity).pipe(
            map((t) => t.value)
        );
    }

    public getDisplayNameTranslationResult$(
        entity: HierarchicalData
    ): Observable<AttributeValueTranslationResult> {
        const functionalName = entity.DisplayName;
        const technicalName = entity.TechnicalName;

        return this.viewTypeService.viewType$.pipe(
            switchMap(() => {
                const primaryNameAttrKey = this.getPrimaryNameAttrKey(entity);
                if (primaryNameAttrKey === technicalPropertyName) {
                    return of({
                        value: technicalName ?? functionalName,
                    });
                }
                if (
                    !this.attributeValueTranslationService.displayTranslatedValues(
                        functionalPropertyName
                    )
                ) {
                    return of({
                        value: functionalName,
                    });
                }
                return this.attributeValueTranslationService.getAttributeValueTranslationResult$(
                    entity,
                    functionalPropertyName,
                    functionalName
                );
            })
        );
    }

    public async updateDisplayName(
        entity: EntityItem,
        updatedEntityPrimaryName: string
    ) {
        if (this.isTranslatedDisplayNameUpdate(entity.HddData)) {
            await this.updateDisplayNameTranslation(
                entity.HddData,
                updatedEntityPrimaryName
            );
            return;
        }
        const primaryNameAttrKey = this.getPrimaryNameAttrKey(entity.HddData);
        const result = await this.entityService.updateEntity(
            entity,
            primaryNameAttrKey,
            updatedEntityPrimaryName,
            {
                includeQuality:
                    this.entityDockingPaneService.isInsightsPaneVisible(),
            }
        );
        entity.setAttributeValue(
            primaryNameAttrKey,
            (
                result as UpdateEntityAttributeResult
            ).UpdatedEntity.getAttributeValue(primaryNameAttrKey)
        );
    }
    public async updateDisplayNameTranslation(
        entity: HierarchicalData,
        updatedEntityPrimaryName: string
    ) {
        this.attributeValueTranslationService.updateAttributeTranslation(
            entity,
            functionalPropertyName,
            updatedEntityPrimaryName
        );
    }

    private isTranslatedDisplayNameUpdate(entity: HierarchicalData) {
        if (
            !this.attributeValueTranslationService.displayTranslatedValues(
                functionalPropertyName
            )
        ) {
            return false;
        }
        const primaryNameAttrKey = this.getPrimaryNameAttrKey(entity);
        return primaryNameAttrKey !== technicalPropertyName;
    }

    private getPrimaryNameAttrKey(entity: HierarchicalData) {
        return this.viewTypeService.getPrimaryNameAttribute(
            entity.ServerType,
            this.viewTypeService.viewType
        );
    }
}
