import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import { MultilingualStateService } from '../../multilingual-state.service';
import { CurrentUserService } from '@datagalaxy/webclient/user/feature/current-user';
import { Observable, map } from 'rxjs';
import { TranslationApiService } from '@datagalaxy/webclient/multilingual/data-access';
import { EntityTranslationStore } from '../entity-translation-store.service';
import { BaseComponent } from '@datagalaxy/utils';
import { withLoading } from '@datagalaxy/core-ui';
import { IEntityIdentifier } from '@datagalaxy/dg-object-model';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ToastType } from '@datagalaxy/ui/toaster';
import { FeatureFlagService } from '@datagalaxy/webclient/feature-flag';

@Component({
    selector: 'dxy-translate-entity-button',
    templateUrl: './translate-entity-button.component.html',
    styleUrls: ['./translate-entity-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TranslateEntityButtonComponent
    extends BaseComponent
    implements OnChanges
{
    @Input() entityIdentifier!: IEntityIdentifier;

    protected buttonVisible$ = this.multilingualStateService
        .selectEnabled()
        .pipe(
            map((enabled) => {
                const currentLanguageCode = this.languageCode;
                if (
                    this.featureFlagService.isFeatureEnabled(
                        'batch-translation'
                    ) ||
                    !enabled ||
                    !currentLanguageCode ||
                    currentLanguageCode ===
                        this.multilingualStateService.state.settings.sourceLang
                ) {
                    return false;
                }
                return true;
            })
        );
    protected translated$ = new Observable<boolean>();

    private get languageCode() {
        return this.currentUserService.userInfo?.LanguageCode;
    }

    constructor(
        private multilingualStateService: MultilingualStateService,
        private currentUserService: CurrentUserService,
        private translationApiService: TranslationApiService,
        private translationStore: EntityTranslationStore,
        private toastrService: ToastrService,
        private translate: TranslateService,
        private featureFlagService: FeatureFlagService
    ) {
        super();
    }
    ngOnChanges(changes: SimpleChanges) {
        if (changes['entityIdentifier']) {
            this.init();
        }
    }
    private init() {
        this.translated$ = this.translationStore.selectObjectTranslated(
            this.entityIdentifier,
            this.languageCode ?? 'en'
        );
    }

    @withLoading()
    protected async translateEntity() {
        if (!this.languageCode) {
            return;
        }
        try {
            const objectTranslation =
                await this.translationApiService.translateEntity(
                    this.entityIdentifier,
                    this.languageCode
                );
            this.translationStore.upsertObjectTranslation(objectTranslation);
        } catch (error) {
            this.toastError();
            const objectTranslation =
                await this.translationApiService.getObjectTranslation(
                    this.entityIdentifier,
                    this.languageCode
                );
            this.translationStore.upsertObjectTranslation(objectTranslation);
        }
    }

    private toastError() {
        const toaster = this.toastrService.show(
            this.translate.instant('Multilingual.translationErrorDescription'),
            this.translate.instant('Multilingual.translationError'),
            {
                progressBar: true,
                closeButton: true,
                timeOut: 5000,
                extendedTimeOut: 5000,
            }
        );
        toaster.toastRef.componentInstance.color = ToastType.Error;
    }
}
