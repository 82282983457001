import { Injectable } from '@angular/core';
import { IActionOption } from '@datagalaxy/core-ui';
import { StateName } from '@datagalaxy/webclient/routing';
import { NavigationService } from '../../services/navigation.service';
import { LineageGraphService } from '../lineage-graph/lineage-graph.service';
import { EntityDksNode } from '../../data-knowledge-studio/data-knowledge-studio.types';
import { EntityType } from '@datagalaxy/dg-object-model';

@Injectable()
export class LineageAssetsMenuService {
    constructor(
        private navigationService: NavigationService,
        private lineageGraph: LineageGraphService
    ) {}

    public getMenu<NodeData>(): IActionOption<EntityDksNode<NodeData>>[] {
        return [this.getLineageOption<NodeData>()];
    }

    private getLineageOption<NodeData>(): IActionOption<
        EntityDksNode<NodeData>
    > {
        return {
            labelKey: 'UI.EntityImpactAnalysis.lineageBurgerMenu.goToLineage',
            callback: (node: EntityDksNode<NodeData>) =>
                this.navigationService.goToEntityDetailsByIdentifier(
                    node.entityIdr,
                    { tabName: StateName.RelativeTabLineage }
                ),
            hidden: (node: EntityDksNode<NodeData>) => {
                const rootNode = this.lineageGraph.getRoot();

                if (!rootNode) {
                    return false;
                }

                const isRoot = rootNode.id === node.entityIdr.ReferenceId;
                const isDataProcessingItem =
                    node.entityIdr.entityType === EntityType.DataProcessingItem;

                return isRoot || isDataProcessingItem;
            },
            glyphClass: 'glyph-analysis',
        };
    }
}
