<div
    (click)="openMenu()"
    class="nav-profile-menu-btn"
    [matTooltip]="'UI.NavBar.lblProfile' | translate"
    dxyDataTestId="profile-avatar-button"
>
    <dxy-profile-avatar [userId]="currentUserId"></dxy-profile-avatar>
</div>
<div
    class="profile-mode"
    [ngClass]="profileModeClass"
    [matMenuTriggerFor]="menu"
    #menuTrigger="matMenuTrigger"
></div>
<mat-menu
    #menu="matMenu"
    class="dg5-mat-menu dxy-navbar-profile-dropdown--mat-menu"
    dxyDataTestId="user-settings-menu"
>
    <ul class="navbar-profile-menu">
        <li
            (click)="onClickUserProfileLink()"
            dxyLogFunctional="PROFIL,R"
            dxyDataTestId="user-settings-menu--profile-link"
            [matTooltip]="'UI.NavBar.smuProfileTooltip' | translate"
            matTooltipPosition="above"
        >
            <i class="glyph glyph-profile"></i>
            <span translate>UI.NavBar.smuProfile</span>
        </li>
        <li class="profile-view-type" dxyLogFunctional="PROFIL,R">
            <i class="glyph glyph-show"></i>
            <span class="text" translate>UI.NavBar.smuViewMode</span>
            <!-- functional/technical switch -->
            <div
                *ngIf="viewTypeToggler$ | async as classes"
                (click)="toggleViewTypeSwitch($event)"
                class="mode-switch"
                dxyLogFunctional="SWITCH_FUNC_TECH,R"
                dxyDataTestId="user-settings-menu--view-type-switch"
            >
                <div class="text-block" [ngClass]="classes.technical" translate>
                    UI.ViewType.Technical
                </div>
                <div
                    class="switch-button blue-button"
                    [ngClass]="classes.technical"
                ></div>
                <div
                    class="switch-button green-button"
                    [ngClass]="classes.functional"
                ></div>
                <div
                    class="text-block functional-text"
                    [ngClass]="classes.functional"
                    translate
                >
                    UI.ViewType.Functional
                </div>
            </div>
            <!-- end functional/technical switch -->
        </li>
        <li
            *ngIf="isClientAdmin()"
            [uiSref]="StateName.ClientAdminDetails"
            dxyLogFunctional="CLIENT_ADMIN,R"
            dxyDataTestId="user-settings-menu--admin-link"
        >
            <i class="glyph glyph-settings"></i>
            <span translate>UI.NavBar.smuAdmin</span>
        </li>
        <li *ngIf="hasAvailableClients()" role="separator"></li>
        <li role="header" class="not-an-item" translate>
            UI.NavBar.smuOtherClients
        </li>
        <li class="submenu client-menu">
            <!-- client selection -->
            <ul>
                <li
                    *ngFor="let client of availableClients"
                    (click)="changeCurrentClient(client)"
                    [matTooltip]="client.DisplayName"
                    [class.inactive]="!isCurrentClient(client)"
                    matTooltipPosition="left"
                    [dxyDataTestId]="
                        'user-settings-menu--client-' +
                        client.ClientId +
                        '-link'
                    "
                >
                    <div class="glyph-wrapper">
                        <i
                            *ngIf="isCurrentClient(client)"
                            [dxyDataTestId]="
                                'user-settings-menu--client-' +
                                client.ClientId +
                                '-current'
                            "
                            class="glyph glyph-check"
                        ></i>
                    </div>
                    <div class="text">{{ client.DisplayName }}</div>
                </li>
            </ul>
            <!-- end client selection -->
        </li>
        <li role="separator"></li>
        <!-- Online help -->
        <li
            *ngIf="showOnlineHelp"
            (click)="openFreshworksWidget()"
            dxyLogFunctional="HELP_ONLINE_DATAGALAXY,R"
            dxyDataTestId="user-settings-menu--help-link"
        >
            <i class="glyph glyph-help"></i>
            <span translate>UI.NavBar.smuOnlineHelp</span>
        </li>
        <!-- Feedback -->
        <li
            *ngIf="showFeedback"
            (click)="onClickFeedback()"
            dxyLogFunctional="REPORT_BUG,R"
            dxyDataTestId="user-settings-menu--support-link"
        >
            <i class="glyph glyph-jirabug"></i>
            <span translate>UI.NavBar.ttJiraBug</span>
        </li>
        <!-- Blog -->
        <li *ngIf="showBlog" class="link-item">
            <a
                [href]="blogHref"
                target="_blank"
                dxyLogFunctional="DATAGALAXY_BLOG,R"
                dxyDataTestId="user-settings-menu--blog-link"
            >
                <i class="glyph navbar-dropdown-icon glyph-comment1"></i>
                <span translate>UI.NavBar.smuBlog</span>
            </a>
        </li>
        <li
            (click)="onClickAbout()"
            dxyLogFunctional="DATAGALAXY_ABOUT,R"
            dxyDataTestId="user-settings-menu--about-link"
        >
            <i class="glyph glyph-info"></i>
            <span translate>UI.NavBar.about</span>
        </li>
        <li *ngIf="showSeparator" role="separator"></li>
        <!-- Connector and plugins -->
        <li
            (click)="onClickExtension()"
            dxyLogFunctional="DATAGALAXY_EXTENSION,R"
            dxyDataTestId="user-settings-menu--extension-link"
        >
            <i class="glyph glyph-link1"></i>
            <span translate>UI.NavBar.extension</span>
        </li>
        <li
            *ngIf="showConnectors"
            (click)="onClickConnectors()"
            dxyLogFunctional="CLIENT_CONNECTOR,R"
            dxyDataTestId="user-settings-menu--connectors-link"
        >
            <i class="glyph glyph-link1"></i>
            <span translate>UI.NavBar.connector</span>
        </li>
        <li
            *ngIf="showApiDocumentation"
            (click)="onClickApiDocumentation()"
            dxyLogFunctional="DATAGALAXY_PUBLICAPI,R"
            dxyDataTestId="user-settings-menu--api-documentation-link"
        >
            <i class="glyph navbar-dropdown-icon glyph-link1"></i>
            <span translate>UI.NavBar.apiDocumentation</span>
        </li>
        <li role="separator"></li>
        <li
            (click)="onClickLogoutLink()"
            id="buttonLogout"
            dxyDataTestId="user-settings-menu--logout-link"
        >
            <i class="glyph glyph-logout"></i>
            <span translate>UI.NavBar.smuLogout</span>
        </li>

        <li *ngIf="showDevPreviewOptions" role="separator"></li>
    </ul>
    <app-dev-preview-options-list
        *ngIf="showDevPreviewOptions"
    ></app-dev-preview-options-list>
</mat-menu>
