import { CollectionsHelper, CoreUtil, StringUtil } from '@datagalaxy/core-util';
import {
    EntityGridRowClickAction,
    IEntityGridOptions,
} from './EntityGridOptions';
import { EntityGridUtil } from './EntityGridUtil';
import {
    DxyIconCellComponent,
    IActionOption,
    IDropdownSection,
    IFunctionalEvent,
    IListOption,
    ISectionOption,
    ITranslate,
} from '@datagalaxy/core-ui';
import {
    EntityGridColumnInfo,
    EntityGridDefaultRowHeight,
    EntityItemOmniGridApi,
    ICellRendererParams,
    ITreeNodeRendererParams,
} from './EntityGridTypes';
import { Subscription } from 'rxjs';
import { StateObject } from '@uirouter/angular';
import { NgZone } from '@angular/core';
import { DgEntityLinkShortcutCellComponent } from '../cells/dg-entity-link-shortcut-cell/dg-entity-link-shortcut-cell.component';
import { EntityPreviewPanelService } from '../services/entity-preview-panel.service';
import { EntityGridBurgerMenuService } from './entity-grid-burger-menu.service';
import {
    FilteredViewService,
    ICurrentFilteredViewChangeEvent,
} from '../../filter/services/filteredView.service';
import { AttributeCollectionCellComponent } from '../../shared-ui/cells/attribute-collection-cell/attribute-collection-cell.component';
import {
    EntityCacheService,
    ICurrentEntityData,
} from '../services/entity-cache.service';
import { DgStatusCellComponent } from '../../shared-ui/cells/dg-status-cell/dg-status-cell.component';
import { DgEntityPathCellComponent } from '../cells/dg-entity-path-cell/dg-entity-path-cell.component';
import { DgTimeSeriesCellComponent } from '../../shared-ui/cells/dg-time-series-cell/dg-time-series-cell.component';
import { UserCellComponent } from '@datagalaxy/webclient/user/ui';
import {
    EntityType,
    HierarchicalData,
    HierarchyDataDescriptor,
    IHasHddData,
    IHierarchicalData,
    ServerType,
} from '@datagalaxy/dg-object-model';
import {
    DxyBooleanCellComponent,
    DxyDgDateTimeCellComponent,
    ICellParams,
    IDgDateTimeCellParams,
    IGridCellParams,
} from '@datagalaxy/core-ui/cell-components';
import { DataUtil } from '../../util/DataUtil';
import { DgAttributeHtmlLinkModelCellComponent } from '../../shared-ui/cells/dg-attribute-html-link-model-cell/dg-attribute-html-link-model-cell.component';
import {
    IOmniGridColumnDef,
    IOmniGridDataInfo,
    IOmniGridState,
    OmniGridColumnInfo,
    OmniGridSortModel,
    OmniGridUtil,
} from '@datagalaxy/core-ui/omnigrid';
import { AttributeDataService } from '../../attribute/attribute-data.service';
import { EntityUiService } from '../services/entity-ui.service';
import { IDgEntityLinkShortcutCollectionCellParams } from '../cells/dg-entity-link-shortcut-collection-cell/dg-entity-link-shortcut-collection-cell.types';
import {
    IGotoDetailsOptions,
    IGotoWithHierarchicalData,
    NavigationService,
} from '../../../services/navigation.service';
import { ServerTimeService } from '../../../services/serverTime.service';
import { ModelerDataUtil } from '../../util/ModelerDataUtil';
import { ExportService } from '../../../services/export.service';
import { ViewType } from '../../util/app-types/ViewType';
import { EntityService } from '../services/entity.service';
import { EntityCreationOrigin } from '../entity.types';
import { EntitySecurityService } from '../services/entity-security.service';
import { EntityCardCellComponent } from '../../entityCard/entity-card/entity-card-cell.component';
import { EntityEventService } from '../services/entity-event.service';
import { ViewTypeService } from '../../../services/viewType.service';
import { HddUtil } from '../../util/HddUtil';
import { BreadcrumbService } from '../../../navigation/services/breadcrumb.service';
import { UserService } from '../../../services/user.service';
import { DgFormattedTextCellComponent } from '../../shared-ui/cells/dg-formatted-text-cell/dg-formatted-text-cell.component';
import { AppEventsService } from '../../../services/AppEvents.service';
import { DgEntityLinkShortcutCollectionCellComponent } from '../cells/dg-entity-link-shortcut-collection-cell/dg-entity-link-shortcut-collection-cell.component';
import { IEntityCardCellParams } from '../../entityCard/entity-card/entity-card-cell.types';
import { EntitySimpleLinkCellComponent } from '../cells/entity-simple-link-cell/entity-simple-link-cell.component';
import { IFilteredEntityCardCellParams } from '../../entityCard/filtered-entity-card-cell/filtered-entity-card-cell.types';
import { IDgEntityPathCellParams } from '../cells/dg-entity-path-cell/dg-entity-path-cell.types';
import {
    ILoadMultiResult,
    SetEntitiesParentResult,
} from '@datagalaxy/webclient/entity/data-access';
import {
    CrudOperation,
    FunctionalLogService,
} from '@datagalaxy/webclient/monitoring/data-access';
import { CampaignUiService } from '../../../campaign/campaign-ui.service';
import { CampaignSecurityService } from '../../../campaign/campaign-security.service';
import { GlyphUtil } from '../../util/GlyphUtil';
import { DataQualityService } from '../../../data-quality/data-quality.service';
import { BaseComponent } from '@datagalaxy/utils';
import {
    EntityIdentifier,
    EntityServerTypeUtils,
    EntityUtils,
} from '@datagalaxy/webclient/entity/utils';
import { SpaceIdentifier } from '@datagalaxy/webclient/workspace/utils';
import { ISpaceIdentifier } from '@datagalaxy/webclient/workspace/domain';
import { Filter, FilterOperator } from '@datagalaxy/webclient/filter/domain';
import { userSettingsValues } from '@datagalaxy/webclient/user/domain';
import {
    AttributeMetaInfo,
    AttributeMetaType,
    TimeSeriesColorRule,
} from '@datagalaxy/webclient/attribute/domain';
import { DgZone } from '@datagalaxy/webclient/domain';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { DgModule } from '@datagalaxy/shared/dg-module/domain';
import {
    EntitySecurityData,
    IHasSecurityData,
} from '@datagalaxy/webclient/security/domain';
import { ServerConstants } from '@datagalaxy/shared/server/domain';
import PropertyName = ServerConstants.PropertyName;

export class EntityGridCore extends BaseComponent {
    private static readonly autoColumnId = 'ag-Grid-AutoColumn';
    private static readonly sortableServerSideTypes = [
        AttributeMetaType.Boolean,
        AttributeMetaType.Date,
        AttributeMetaType.DateTime,
        AttributeMetaType.Text,
        AttributeMetaType.FormattedText,
        AttributeMetaType.MultiLineText,
        AttributeMetaType.Number,
        AttributeMetaType.TimeSeriesLastEntry,
    ];
    private static readonly sortableComputedAttributes = [PropertyName.Order];

    //#region constants
    /** to detect and show the 'your filters matched too many results', in tree mode */
    private readonly maxSize = 5000;
    /** to detect and show the 'your filters matched too many results', in infinityLoad mode (es query (from + size) limit) */
    private readonly maxResultWindow = 10000;
    /** when true then a server call is initiated on each node re-opening */
    private readonly purgeClosedRowNodesData = true;
    /** when true then expanded nodes are re-opened after the tree is refreshed. if gridData.tree.purgeClosedRowNodesData is true, a server call is made for each node to reopen */
    private readonly preserveExpandedRows = true;
    /** when true then when a node is closed, its currently selected descendants are unselected */
    private readonly purgeClosedRowNodesDescendantSelection = false;
    /** when true then assume that the parent of a node is set to null when the parent is deleted, instead of the node to be deleted */
    private readonly areChildrenReparentedToRoot = false;
    /** when true then the leftmost column will be locked in position and no other columns can be moved to its left */
    private readonly isPrimaryColumnLockedToLeft = true;
    /** when true allows ag-grid to add animations on filtering, sorting, expanding */
    private readonly animateRows = true;
    /** enable to scroll ag-grid to currentEntity on  focusDashboard$ event*/
    private readonly enableAutoResetScroll = false;
    /** when true clicking on a column header sorts the column (not ready) */
    private readonly canSortColumns = true;

    private readonly throwOnGridNotReady = false;
    //#endregion constants

    //#region getters
    //#region used as getters
    public gridData: IOmniGridDataInfo<EntityItem>;
    public noResult: boolean;
    public tooManyResults: boolean;
    public totalCount: number;
    public columnsList: EntityGridColumnInfo[];
    public filteredColumnListGroups: IDropdownSection[];
    public bulkActionDropdownOptions: IListOption[];
    public bulkActionDropdownButton: IListOption;
    public globalCollapseExpandDropdownOptions: IListOption[];
    public globalCollapseExpandDropdownButton: IListOption;
    public isHierarchical: boolean;
    public resettingGrid = false;

    //#endregion - used as getters
    public get searchBoxTerm() {
        return this._searchBoxTerm;
    }

    public get isList() {
        return this.egOptions.isSingleColumn;
    }

    public get isGrid() {
        return !this.egOptions.isSingleColumn;
    }

    public get isFlat() {
        return !this.isHierarchical;
    }

    public get isTree() {
        return this.isHierarchical;
    }

    public get isAutoHeight() {
        return !!this.egOptions.autoHeight;
    }

    public get isBreadcrumbs() {
        return this.showItemBreadcrumb;
    }

    public get rowHeight() {
        return EntityGridDefaultRowHeight;
    }

    public get headerHeight() {
        return this.egOptions.isSingleColumn ? 0 : 55;
    }

    public get showMessagesInGrid() {
        return (
            this.egOptions.isSingleColumn &&
            !this.egOptions.noTopMessages &&
            this.showMessagesAndTitleBar
        );
    }

    public get isAnyFilter() {
        return (
            this.dgZone &&
            this.filteredViewService.hasCurrentFilters(this.dgZone)
        );
    }

    public get selectedCount() {
        return this._selectedEntities?.length || 0;
    }

    public get selectedEntities() {
        return this._selectedEntities;
    }

    public get showTitleBar() {
        return (
            this.canCreate ||
            this.showBulkActions ||
            this.showColumnsSelector ||
            this.isAnyTitleMessage ||
            this.showSearchBox
        );
    }

    public get isAnyTitleMessage() {
        return (
            (!this.showMessagesInGrid && this.showMessagesAndTitleBar) ||
            this.externalTitleMessage
        );
    }

    public get selectionMessage() {
        return this.noResult
            ? ''
            : this.translate.instant('UI.OmniGrid.lblSelectionCount') +
                  ' : ' +
                  this.selectedCount +
                  (this.totalCount ? ' / ' + this.totalCount : '');
    }

    public get showTotalCountMessage() {
        return !this.noResult && !this.egOptions.noTotalCountMessage;
    }

    public get totalCountMessage() {
        return this.noResult || this.totalCount == undefined
            ? ''
            : this.translate.instant('UI.OmniGrid.lblTotalCount', {
                  count: this.totalCount || 0,
              });
    }

    public get isAnyBulkAction() {
        return (
            this.showGrid &&
            this.showBulkActions &&
            (this.isFusionAllowed ||
                this.isBulkEditAllowed ||
                this.isBulkDeleteAllowed)
        );
    }

    public get isFusionAllowed() {
        return this.canBulkWrite && this.dgModule == DgModule.Glossary;
    }

    public get isExportDisabled() {
        return (
            !this.selectedCount ||
            this._selectedEntities?.some(
                (entity) => !entity.SecurityData.HasExportAccess
            )
        );
    }

    public get isExportAllowed() {
        return this.egOptions.showExportEntitiesAction;
    }

    public get isBulkEditAllowed() {
        return this.canBulkWrite;
    }

    public get isBulkDeleteAllowed() {
        return (
            this.canBulkWrite &&
            !this.egOptions.bulkEditConfiguration?.hideDelete
        );
    }

    public get showColumnsSelector() {
        return this.showGrid && !!this.egOptions.showColumnsSelector;
    }

    public get showGrid() {
        return !this.egOptions.isSingleColumn || !this.noResult;
    }

    public get isMultiSelect() {
        return this.egOptions.isMultiSelect;
    }

    public get rowSelection() {
        return this.egOptions.isSingleColumn ||
            this.egOptions.isMultiSelect == false
            ? 'single'
            : 'multiple';
    }

    public get showHierarchicalToggleButton() {
        return (
            this.egOptions.showHierarchicalSwitch &&
            this.navigationService.showHierarchicalSwitch()
        );
    }

    public get showHierarchicalToggleButtonInGrid() {
        return this.showHierarchicalToggleButton && !this.showTitleBar;
    }

    public get showGlobalCollapseExpand() {
        return !!this.egOptions.directData?.groups;
    }

    public get externalTitleMessage() {
        return this.egOptions.directData?.headerMessage;
    }

    public get headerActionButton() {
        return typeof this.egOptions.headerActionButton == 'function'
            ? this.egOptions.headerActionButton()
            : this.egOptions.headerActionButton;
    }

    public get showSearchBox() {
        return this.egOptions.showSearchBox;
    }

    public get isCreateButtonVisible() {
        return this.canCreate && this.parentData?.SecurityData.HasWriteAccess;
    }

    public get hideColumnSelectorCollapse() {
        return !this.egOptions.entityAttributeGroups;
    }

    public get showBulkActions() {
        return (
            this.egOptions.isMultiSelect &&
            !!this.egOptions.bulkEditConfiguration?.showActions
        );
    }

    //#endregion

    //#region inputs
    private securityData: EntitySecurityData;
    private spaceIdr: ISpaceIdentifier;
    private egOptions: IEntityGridOptions = {};
    /** dynatrace context for the Entity Inside */
    private dtContext: string;
    private serverType: ServerType;
    private entityAttributes: AttributeMetaInfo[];
    private parentDataId: string;
    private parentData: EntityItem;
    private currentEntityId: string;
    /** important: the tupple ('omni-grid', gridStatePersistenceId) identifies the stored grid state for the user.
     * See UserSettingValue and table [UserSetting] for details */
    private gridStatePersistenceId: string;
    //#endregion

    //#region locals
    private isInitDone = false;
    private serverTypeSubscriptionGroup: Subscription;
    private _searchBoxTerm: string;
    private gridApi: EntityItemOmniGridApi;
    private defaultAttributes: string[];
    private columns: EntityGridColumnInfo[];
    private _selectedEntities: EntityItem[];
    private isFirstLoad: boolean;
    private isReloadingChildren: boolean;
    private isAddingEntity: boolean;
    private isShowingEntityDetails: boolean;
    private sortKey: string;
    private bulking: Promise<void>;
    private currentPrimaryName: string;
    private currentSecondaryName: string;
    private showBothNameColumns: boolean;
    private showItemBreadcrumb: boolean;
    private dgModule: DgModule;
    private lastVisibleColumns: string[];
    private selectedEntitiesHaveWriteAccess: boolean;
    private canCreateOrModifyCampaign: boolean;

    private get canCreate() {
        return !!this.egOptions.canCreate;
    }

    private get modelHdd() {
        return this.parentData && HddUtil.getModelHdd(this.parentData.HddData);
    }

    private get isSortingEnabled() {
        return this.canSortColumns;
    }

    private get showMessagesAndTitleBar() {
        return !this.egOptions.noMessagesAndTitleBar;
    }

    private get canSaveGridState() {
        return (
            !this.egOptions.isSingleColumn && !!this.egOptions.canSaveGridState
        );
    }

    private get canLoadGridState() {
        return this.canSaveGridState;
    }
    private get canBulkWrite() {
        return (
            !this.bulking &&
            (this.entitySecurityService.hasWriteAccessEntitySecurity(
                this.securityData
            ) ||
                this.selectedEntitiesHaveWriteAccess)
        );
    }

    private get currentEntityDataService() {
        return this.entityCacheService as ICurrentEntityData;
    }

    private get hasComparator() {
        return !!this.egOptions.directData;
    }

    private get isCatalog() {
        return this.dgModule == DgModule.Catalog;
    }

    private get isMultiTypeCatalog() {
        return this.isCatalog && !this.egOptions.isSingleServerType;
    }

    private get dgZone() {
        return this.egOptions.dgZone;
    }

    private get withEntityFullPage() {
        return this.egOptions.entityDetailsNoFullPage ? false : undefined;
    }

    private get noApi() {
        if (!this.gridApi) {
            this.log('no api');
            return true;
        }
    }

    private get cardCellDragDrop() {
        return this.egOptions.cardCellDragDrop;
    }

    //#endregion

    constructor(
        private translate: ITranslate,
        private attributeDataService: AttributeDataService,
        private entityService: EntityService,
        private viewTypeService: ViewTypeService,
        private entityUiService: EntityUiService,
        private entityPreviewPanelService: EntityPreviewPanelService,
        private entityEventService: EntityEventService,
        private navigationService: NavigationService,
        private breadcrumbService: BreadcrumbService,
        private entitySecurityService: EntitySecurityService,
        private serverTimeService: ServerTimeService,
        private userService: UserService,
        private entityCacheService: EntityCacheService,
        private functionalLogService: FunctionalLogService,
        private appEventsService: AppEventsService,
        private filteredViewService: FilteredViewService,
        private exportService: ExportService,
        private entityGridBurgerMenuService: EntityGridBurgerMenuService,
        private campaignSecurityService: CampaignSecurityService,
        private campaignUiService: CampaignUiService,
        private ngZone?: NgZone,
        debug?: boolean
    ) {
        super();
        this._debug = debug;
    }

    public async init(
        securityData: EntitySecurityData,
        spaceIdr: ISpaceIdentifier,
        egOptions: IEntityGridOptions,
        dtContext: string,
        serverType: ServerType,
        entityAttributes?: AttributeMetaInfo[],
        parentDataId?: string,
        parentData?: EntityItem,
        currentEntityId?: string,
        gridStatePersistenceId?: string,
        logId?: string
    ) {
        this.log('init', egOptions);

        !this.isInitDone && this.entityCacheService.registerClient(this);

        this.securityData = securityData;
        this.spaceIdr = spaceIdr;
        this.egOptions = egOptions ?? {};
        this.dtContext = dtContext;
        this.setServerType(serverType);
        this.entityAttributes = entityAttributes ?? [];
        this.parentDataId = parentDataId;
        this.parentData = parentData;
        this.currentEntityId = currentEntityId;
        this.gridStatePersistenceId = gridStatePersistenceId;
        this._selectedEntities = [];
        if (logId) {
            this._logId = logId;
        }
        await this.setup();
        this.isInitDone = true;
        this.subscribeEvents();
    }

    public destroy() {
        this.log('destroy');
        this.serverTypeSubscriptionGroup?.unsubscribe();
        this.registeredSubscriptions?.unsubscribe();
        this.entityCacheService.unregister(this);
    }

    private async setup() {
        const opt = this.egOptions;
        if (opt.directData) {
            opt.isHierarchical = false;
            opt.isSingleServerType = undefined;
            opt.useParentDataIdIfNullEntityRefId = undefined;
            opt.showHierarchicalSwitch = false;
            opt.filterEntityTypes = undefined;
            opt.dgZone = DgZone.none;
            opt.directData.update = () => this.updateDirectData();
        }

        if (opt.entityAttributes?.length) {
            this.entityAttributes = opt.entityAttributes;
        }
        if (opt.gridStatePersistenceId) {
            this.gridStatePersistenceId = opt.gridStatePersistenceId;
        }

        const eaps = opt.excludedAttributePaths;
        if (eaps?.length) {
            this.entityAttributes = this.entityAttributes.filter(
                (a) => !eaps.includes(a.AttributePath)
            );
        }

        this.setCurrentNameColumns();

        this.isHierarchical =
            opt.isHierarchical == undefined
                ? this.navigationService.isHierarchical
                : !!opt.isHierarchical;

        this.defaultAttributes =
            opt.isSingleColumn || opt.wantedColumns
                ? []
                : AttributeDataService.getDefaultAttributesForGrid(
                      this.serverType
                  );

        this.showItemBreadcrumb = !this.isHierarchical && !opt.noBreadCrumbs;

        this.isFirstLoad = true;

        this._debug &&
            this.logData('setup', {
                serverType: ServerType[this.serverType],
                dgModule: DgModule[this.dgModule],
                dgZone: DgZone[this.dgZone],
                spaceIdr: SpaceIdentifier.from(this.spaceIdr),
                currentEntityId: this.currentEntityId,
                entityAttributes: this.entityAttributes,
                defaultAttributes: this.defaultAttributes,
                modelHdd: this.modelHdd,
                parentData: this.parentData,
                securityData: this.securityData,
                gridStatePersistenceId: this.gridStatePersistenceId,
                egOptions: opt,
            });

        this.canCreateOrModifyCampaign =
            await this.campaignSecurityService.canCreateOrModifyCampaign(
                this.spaceIdr
            );
        this.initDropdownOptions();

        await this.buildColumns(true, false);
        this.setupOmniGrid();
        this.log('setup-done', this.gridData);
    }

    private initDropdownOptions() {
        const isBulkDisabled = () => !this.selectedCount;
        this.bulkActionDropdownButton = {
            glyphClass: 'glyph-splitter',
            disabled: isBulkDisabled,
            tooltipTranslateKey: () =>
                this.selectedCount
                    ? 'UI.Global.btnEditSelection'
                    : 'UI.Global.btnEditSelectionDisabled',
            tooltipHtml: true,
        };

        this.bulkActionDropdownOptions = [
            ...this.createBulkEditButtons(),
            this.createAddToExistingCampaignButtons(),
            this.createAddToNewCampaignButtons(),
            this.createMergeSelectionButton(),
            this.createDeleteLinksButton(),
            this.createExportSelectionButton(),
            this.createDeleteAllButton(),
        ];

        this.globalCollapseExpandDropdownButton = {
            glyphClass: 'glyph-burger',
        };

        this.globalCollapseExpandDropdownOptions = [
            {
                glyphClass: 'glyph-shrink-vertical',
                labelKey: 'UI.OmniGrid.tooltipCollapseAll',
                callback: () => this.onGlobalCollapseExpandClick(false),
            },
            {
                glyphClass: 'glyph-expand-vertical',
                labelKey: 'UI.OmniGrid.tooltipExpandAll',
                callback: () => this.onGlobalCollapseExpandClick(true),
            },
        ];
    }

    private async resetGrid() {
        this.log('resetGrid');
        // let the ngIf to destroy the grid
        this.resettingGrid = true;
        this.clearSourceCache();
        await this.timeout(async () => {
            // now we're clean
            await this.setup();
            this.resettingGrid = false;
        }, 111);
    }

    private createBulkEditButtons() {
        return [
            {
                labelKey: 'UI.BulkEdit.attributeEdit',
                glyphClass: 'glyph-edit',
                hidden: () => !this.isAnyBulkAction || !this.showBulkActions,
                disabled: () => !this.selectedCount,
                callback: () => this.editSelectedEntities(),
            },
            {
                labelKey: 'UI.BulkEdit.linkEdit',
                glyphClass: 'glyph-diagram',
                hidden: () => !this.isAnyBulkAction || !this.showBulkActions,
                disabled: () => !this.selectedCount,
                callback: () => this.multiLinkedObjectCreation(),
            },
        ];
    }

    private createAddToExistingCampaignButtons() {
        return {
            labelKey: 'UI.BulkEdit.addToExistingCampaign',
            glyphClass: 'glyph-campaign',
            hidden: () =>
                !this.canCreateOrModifyCampaign ||
                this.egOptions.bulkEditConfiguration?.hideCampaignActions,
            disabled: () => !this.selectedCount,
            callback: () => this.addObjectsToExistingCampaign(),
        };
    }

    private createAddToNewCampaignButtons() {
        return {
            labelKey: 'UI.BulkEdit.addToNewCampaign',
            glyphClass: 'glyph-campaign',
            hidden: () =>
                !this.canCreateOrModifyCampaign ||
                this.egOptions.bulkEditConfiguration?.hideCampaignActions,
            disabled: () => !this.selectedCount,
            callback: () => this.addObjectsToNewCampaign(),
        };
    }

    private createMergeSelectionButton() {
        return {
            labelKey: 'UI.Global.btnFusionSelection',
            glyphClass: 'glyph-fusion',
            hidden: () => !this.isFusionAllowed,
            disabled: () => !this.selectedCount,
            callback: () => this.fusionSelectedEntities(),
        };
    }

    private createDeleteLinksButton() {
        return {
            labelKey: 'UI.Global.btnBulkLinkDelete',
            glyphClass: 'glyph-link-broken',
            hidden: () => !this.isBulkDeleteAllowed,
            disabled: () => !this.selectedCount,
            callback: () => this.multiLinkedObjectDelete(),
        };
    }

    private createExportSelectionButton() {
        return {
            labelKey: 'UI.Global.btnExportSelection',
            glyphClass: 'glyph-download',
            hidden: () => !this.isExportAllowed,
            disabled: () => this.isExportDisabled,
            callback: () => this.exportSelectedEntities(),
        };
    }

    private createDeleteAllButton() {
        return {
            labelKey: 'UI.Global.btnDeleteSelection',
            glyphClass: 'glyph-delete',
            hidden: () => !this.isBulkDeleteAllowed,
            disabled: () => !this.selectedCount,
            callback: () => this.deleteSelectedEntities(),
        };
    }

    //#endregion init

    //#region update

    public setSearchBoxTerm(value: string) {
        this._searchBoxTerm = value;
    }

    public setServerType(serverType: ServerType) {
        const change = this.serverType != serverType;
        this.serverType = serverType;
        this.dgModule = DataUtil.getModuleFromServerType(serverType);
        this.log(
            'setServerType',
            ServerType[serverType],
            change,
            this.isInitDone
        );
        change && this.isInitDone && this.renewServerTypeSubscriptions();
    }

    public setEntityAttributes(entityAttributes: AttributeMetaInfo[]) {
        this.entityAttributes = entityAttributes;
        this.updateColumns();
    }

    public setSpaceIdr(spaceIdr: ISpaceIdentifier) {
        this.spaceIdr = spaceIdr;
        this.isFirstLoad = true;
        return this.withGrid((grid) => grid.refreshAllRows());
    }

    //#endregion

    //#region event handlers

    public onGridReady(gridApi: EntityItemOmniGridApi) {
        this.log('onGridReady', gridApi);
        this.gridApi = gridApi;
    }

    public onSortChange(sortModel: OmniGridSortModel) {
        this.egOptions?.directData?.onSortChanged?.(sortModel);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public async onRowClick(
        entity: EntityItem,
        _refreshRow?: (newRowData: EntityItem) => void
    ) {
        this.log(
            'onRowClick',
            entity?.ReferenceId,
            EntityGridRowClickAction[this.egOptions.rowClickAction]
        );
        if (
            !entity ||
            this.egOptions.rowClickAction == EntityGridRowClickAction.none
        ) {
            return;
        }
        if (
            this.egOptions.rowClickAction ==
            EntityGridRowClickAction.openEntityPreviewPanel
        ) {
            if (!DataUtil.isAvailableForPreviewPanel(entity)) {
                return this.showEntityDetails(entity);
            }
            await this.openEntityPane(entity);
        } else if (!this.egOptions.isMultiSelect) {
            await this.setCurrentEntityId(entity.ReferenceId, false, true);
        }
    }

    private async onGroupRowClick(entity: EntityItem) {
        this.log(
            'onGroupRowClick',
            entity,
            EntityGridRowClickAction[this.egOptions.rowClickAction]
        );
        const rowClickAction = this.egOptions.rowClickAction;
        if (!entity || rowClickAction == EntityGridRowClickAction.none) {
            return;
        }
        if (
            !this.egOptions.isMultiSelect &&
            rowClickAction === EntityGridRowClickAction.showEntityDetail
        ) {
            return this.setCurrentEntityId(
                entity && entity.ReferenceId,
                true,
                true
            );
        }
        await this.rowAction(entity);
    }

    private async onGroupItemClick(entity: EntityItem) {
        this.log('onGroupItemClick', entity);
        await this.rowAction(entity);
    }

    private async rowAction(entity: EntityItem) {
        if (
            !entity ||
            this.egOptions.rowClickAction == EntityGridRowClickAction.none
        ) {
            return;
        }

        if (
            this.egOptions.rowClickAction ==
            EntityGridRowClickAction.openEntityPreviewPanel
        ) {
            await this.openEntityPane(entity);
        } else {
            await this.showEntityDetails(entity);
        }
    }

    public async onGridSelectionChanged() {
        if (this.noApi) {
            return;
        }
        const grid = this.gridApi;
        this._selectedEntities = await grid.getSelectedRows();
        if (!this.securityData && !this.isCatalog) {
            this.selectedEntitiesHaveWriteAccess =
                this._selectedEntities?.every(
                    (e) => e.SecurityData.HasWriteAccess
                );
        } else if (this.isCatalog) {
            this.selectedEntitiesHaveWriteAccess = this._selectedEntities?.some(
                (e) => e.SecurityData.HasWriteAccess
            );
        }

        this.log('onGridSelectionChanged', this._selectedEntities);
    }

    public async onGridColumnsChanged() {
        this.log('onGridColumnsChanged');
        if (this.noApi) {
            return;
        }
        const grid = this.gridApi;
        const state = await grid.getState();
        // ensure correct hidden/visible state in the columns selector when a column is dragged out
        state.columns.forEach((sc) =>
            CollectionsHelper.withFirstFound(
                this.columns,
                (c) => c.name == sc.colId,
                (c) => (c.visible = !sc.hide)
            )
        );
        const previousVisibleColumns = this.lastVisibleColumns;
        this.lastVisibleColumns = this.columns
            .filter((c) => c.visible)
            .map((c) => c.attributePath);
        this.updateColumnsList();
        this.log('onGridColumnsChanged-saveGridState');
        await this.saveGridState(state);
        await this.onDirectDataColumnsChanged(
            previousVisibleColumns,
            this.lastVisibleColumns
        );
    }

    private onGlobalCollapseExpandClick(toExpanded: boolean) {
        this.withGrid((api) => api.expandCollapseAll(toExpanded));
    }

    public onSubmitSearch() {
        this.clearSourceCache();
        this.withGrid((grid) => grid.refreshAllRows());
        this.isFirstLoad = true;
    }

    public onCreateButtonClick() {
        this.entityUiService.openCreationModal(
            EntityCreationOrigin.gridAddButton,
            {
                parentData: this.parentData,
                serverType: this.serverType,
                modelHdd: this.modelHdd,
                filterEntityTypes: this.egOptions?.filterEntityTypes,
            }
        );
    }

    private async onViewTypeChange() {
        this.log('onViewTypeChange');

        await this.gridApi?.refreshView();

        if (this.egOptions.directData && !this.hasBothNamesAttributes()) {
            // responsability is left to the provider
            return;
        }

        this.setCurrentNameColumns();
        await this.updateColumns();
    }

    /** called after data has been loaded, in infinity-load mode */
    private async onFlatRowsLoaded(from: number, entities: EntityItem[]) {
        this.log('onFlatRowsLoaded', from, entities && entities.length);
        if (this.egOptions.isMultiSelect) {
            return;
        }
        await this.setCurrentOrSelected(entities);
    }

    /** called after data has been loaded, in tree mode */
    private async onChildrenRefreshed(
        parent: EntityItem,
        children: EntityItem[]
    ) {
        this.log('onChildrenRefreshed', parent, children && children.length);
        if (this.isReloadingChildren) {
            return;
        }
        await this.setCurrentOrSelected(children);
    }

    private async onFilteredViewChanged(
        event: ICurrentFilteredViewChangeEvent
    ) {
        const { dgZone, context } = event;
        if (!dgZone || dgZone != this.dgZone || context?.isReloading) {
            return;
        }
        this.log('onFilteredViewChanged', DgZone[dgZone], context);
        if (this.egOptions.directData) {
            this.egOptions.directData.onFilteredViewChanged?.();
            return;
        }
        this.clearSourceCache();
        if (this.gridApi) {
            this.isFirstLoad = true;
            await this.gridApi.refreshAllRows();
            await this.clearSelectionOnFiltersUpdate();
        }
    }

    private async clearSelectionOnFiltersUpdate() {
        const selection = await this.gridApi.getSelectedRows();
        if (selection?.length) {
            for (const entity of selection) {
                const id = entity.ReferenceId;
                const rowData = await this.gridApi.getRowData(id);
                if (!rowData) {
                    await this.gridApi.setRowSelected(id, false);
                    await this.gridApi.clearSelection();
                    break;
                }
            }
            await this.onGridSelectionChanged();
        }
    }

    public onFunctional(event: IFunctionalEvent) {
        this.functionalLogService.parseAndLog(event.text, event.origin);
    }

    //#endregion event handlers

    //#region bulk actions

    private multiLinkedObjectCreation() {
        return this.doBulk('linking', () =>
            this.entityUiService
                .openLinkObjectModal(this._selectedEntities)
                .then(() => null)
        );
    }

    private multiLinkedObjectDelete() {
        return this.doBulk('linking', () =>
            this.entityUiService
                .openDeleteLinkObjectModal(this._selectedEntities)
                .then(() => null)
        );
    }

    private fusionSelectedEntities() {
        return this.doBulk('fusion', () =>
            this.entityUiService.openFusionModal(
                this.spaceIdr,
                this._selectedEntities
            )
        );
    }

    private editSelectedEntities() {
        return this.doBulk('edit', () =>
            this.entityUiService.openBulkEditModal(
                this.serverType,
                this._selectedEntities
            )
        );
    }

    private addObjectsToExistingCampaign() {
        const selectedEntitiesIds = this._selectedEntities.map(
            (e) => e.ReferenceId
        );
        this.campaignUiService.openCampaignSelectFormModal(
            this.spaceIdr,
            selectedEntitiesIds
        );
    }

    private addObjectsToNewCampaign() {
        const selectedEntitiesIds = this._selectedEntities.map(
            (e) => e.ReferenceId
        );
        this.campaignUiService.openCampaignFormModal(
            this.spaceIdr,
            selectedEntitiesIds
        );
    }

    private deleteSelectedEntities() {
        return this.doBulk('delete', async () => {
            const selectedEntities = this._selectedEntities;
            const serverTypes = CollectionsHelper.distinct(
                selectedEntities.map((e) => e.ServerType)
            );
            const actualServerType =
                serverTypes.length > 1 ? ServerType.AllTypes : serverTypes[0];

            if (
                this.serverType === ServerType.Column &&
                selectedEntities.some(
                    (e) =>
                        e.getAttributeValue('IsPrimaryKey') === true ||
                        e.getAttributeValue('IsForeignKey') === true
                )
            ) {
                await this.entityUiService.openColumnDeletePkFkLimitationMessage();
                return null;
            } else {
                const preResult = await this.entityService.preDeleteEntities(
                    selectedEntities.map((e) => e.ReferenceId),
                    actualServerType
                );
                const confirmed = await this.entityUiService.confirmDelete(
                    null,
                    {
                        count: selectedEntities.length,
                        totalCount: preResult.TotalDeletedCount,
                    }
                );
                if (!confirmed) {
                    return;
                }
                return await this.entityService.deleteEntities(
                    selectedEntities,
                    this.serverType
                );
            }
        });
    }

    private doBulk(
        actionName: string,
        action: () => Promise<{ IsSuccess: boolean }>
    ) {
        if (!this.selectedCount) {
            return;
        }
        this.log('doBulk', actionName);
        this.bulking = Promise.resolve()
            .then(action)
            .then((result) => {
                this.log('doBulk-result', result);
                if (result?.IsSuccess) {
                    return this.withGrid((grid) => grid.clearSelection());
                }
            })
            .finally(() => {
                this.bulking = undefined;
            });
    }

    /** if a bulk action is running, chains the given action to it, else executes it right away */
    private doPostBulk(action: () => void) {
        const bulking = this.bulking;
        this.log('doPostBulk', !!bulking);
        const after = () => this.withGrid((grid) => grid.refreshCellsLayout());
        if (bulking) {
            bulking.finally(() => action()).then(after);
        } else {
            action();
            after();
        }
    }

    //#endregion bulk actions

    //#region columns actions
    public async resetColumnStates() {
        this.log('resetColumnStates');
        const lastVisibleColumns = this.lastVisibleColumns;
        await this.updateColumns(true);
        await this.onDirectDataColumnsChanged(
            lastVisibleColumns,
            this.lastVisibleColumns
        );
    }

    public async setColumnVisibility(column: OmniGridColumnInfo) {
        if (this.noApi) {
            return;
        }
        const grid = this.gridApi;
        await grid.setColumnVisible(column.name, column.visible);
        await grid.sizeColumnsToFit();
        // already called by onGridColumnsChanged
        //await this.saveGridState()
        if (
            column.visible &&
            !this.egOptions?.directData?.updateDataOnColumnsChanged
        ) {
            await grid.updateGridData(true);
        }
    }

    public getColumnGlyphClass(column: EntityGridColumnInfo) {
        return this.attributeDataService.getGlyphClassName(
            column.attributeKey,
            column.sourceAttributeType || column.attributeType
        );
    }

    private async onDirectDataColumnsChanged(
        lastVisibleColumns: string[],
        currentVisibleColumns: string[]
    ) {
        const onColumnsChanged = this.egOptions?.directData?.onColumnsChanged;
        const resetGrid =
            this.egOptions?.directData?.updateDataOnColumnsChanged;
        if (!onColumnsChanged && !resetGrid) {
            return;
        }

        const diff = CollectionsHelper.getAddedAndRemoved(
            lastVisibleColumns,
            currentVisibleColumns
        );
        this.log('onDirectDataColumnsChanged', diff);
        if (!diff.any) {
            return;
        }

        try {
            await onColumnsChanged?.();
        } catch (e) {
            CoreUtil.warn(e);
        }

        await this.resetGrid();
    }

    public async exportSelectedEntities() {
        if (this.isExportDisabled) {
            return;
        }
        await this.exportService.exportEntities(this._selectedEntities);
    }

    //#endregion columns actions

    //#region grid setup

    private async buildColumns(isInit: boolean, isReset: boolean) {
        if (this.egOptions.isSingleColumn) {
            return;
        }

        const mergeWithSaved = !isReset;
        const loadGoldenSettings =
            (isReset || isInit) &&
            this.userService.isAnyGoldenSettingsUser &&
            !this.userService.isLoggedInGoldenSettingsUser;
        this.log(
            'buildColumns',
            isInit,
            isReset,
            mergeWithSaved,
            loadGoldenSettings
        );

        const columnInfos = this.entityAttributes.map((a) =>
            this.buildColumn(a)
        );

        if (this.canLoadGridState && (mergeWithSaved || loadGoldenSettings)) {
            const gridState = await this.loadGridState(
                this.gridStatePersistenceId,
                loadGoldenSettings,
                isInit
            );
            this.applySavedSettingsToColumns(columnInfos, gridState);
        }
        this.columns = columnInfos;
        this.sortKey = CollectionsHelper.getFromFirst(
            this.columns,
            (c) => c.isDefaultSort,
            (c) => c.attributePath
        );
        this.updateColumnsList();
        this.showBothNameColumns = this.areBothNamesVisible(columnInfos);
        this.lastVisibleColumns = this.columns
            .filter((c) => c.visible)
            .map((c) => c.attributePath);
        this.log(
            'buildColumns-done',
            this.columns,
            this.sortKey,
            this.showBothNameColumns,
            this.columnsList
        );
    }

    private updateColumnsList() {
        this.log('updateColumnsList');
        this.columnsList = this.isPrimaryColumnLockedToLeft
            ? this.columns.filter(
                  (c) => c.attributeKey != this.currentPrimaryName
              )
            : this.columns.slice();
        this.updateFilteredColumnsList();
    }

    private buildColumn(attributeMeta: AttributeMetaInfo) {
        // console.log('buildColumn', attributeMeta.AttributeKey,
        //     AttributeMetaType[attributeMeta.AttributeType],
        //     AttributeMetaType[attributeMeta.SourceAttributeType],
        //     attributeMeta)

        let displayName = this.attributeDataService.getAttributeDisplayName(
            attributeMeta,
            this.serverType
        );
        if (
            this.isMultiTypeCatalog &&
            attributeMeta.IsCdp &&
            !attributeMeta.IsAllTypes
        ) {
            const attributeServerTypeName = this.tradGetServerTypeName(
                attributeMeta.serverType
            );
            displayName = `${displayName} (${attributeServerTypeName})`;
        }

        const column = new EntityGridColumnInfo(
            attributeMeta,
            displayName,
            false,
            !!this.egOptions.canMoveColumns
        );
        const columnDef = column.colDef;

        if (this.egOptions.isServerSideSorting || !this.egOptions.directData) {
            column.colDef.sortable =
                (!attributeMeta.IsComputed &&
                    EntityGridCore.sortableServerSideTypes.includes(
                        attributeMeta.AttributeType
                    )) ||
                EntityGridCore.sortableComputedAttributes.includes(
                    attributeMeta.AttributePath
                );
        }

        switch (attributeMeta.AttributeType) {
            case AttributeMetaType.FormattedText:
                columnDef.cellRendererFramework = DgFormattedTextCellComponent;
                break;

            case AttributeMetaType.ObjectNameList:
            case AttributeMetaType.Text:
                if (attributeMeta.AttributeKey == this.currentPrimaryName) {
                    columnDef.cellRendererFramework =
                        this.egOptions.firstCellRendererFramework ??
                        EntityCardCellComponent;
                    columnDef.valueGetter = this.egOptions.firstCellValueGetter;
                    column.displayName =
                        this.egOptions.primaryDisplayName ?? column.displayName;
                    columnDef.cellRendererParams = () => {
                        const isAllSpaces =
                            this.egOptions.directData?.isAllSpaces;
                        return {
                            inputs: {
                                hideBreadcrumb: !this.showItemBreadcrumb,
                                isFromHierarchical: this.isHierarchical,
                                breadcrumbOpenPreview: true,
                                noLabelNavLink: this.egOptions?.noLabelNavLink,
                                withEntityFullPage: this.withEntityFullPage,
                                dtContext: this.dtContext || 'Entity Grid',
                                forceIncludeSpace:
                                    isAllSpaces === true ? true : undefined,
                                forceExcludeSpace:
                                    isAllSpaces === false ? true : undefined,
                                dragDrop: this.cardCellDragDrop,
                            },
                            actions: this.getAvailableActions(),
                        } as
                            | IEntityCardCellParams
                            | IFilteredEntityCardCellParams;
                    };
                    if (this.egOptions.directData?.groups) {
                        columnDef.minWidth =
                            this.egOptions.directData?.groupColumnMinWidth ??
                            200;
                    }
                } else if (
                    attributeMeta.AttributeKey == this.currentSecondaryName
                ) {
                    columnDef.cellRendererFramework =
                        EntitySimpleLinkCellComponent;
                    columnDef.cellRendererParams = {
                        propertyKey: attributeMeta.AttributeKey,
                        isFromHierarchical: this.isHierarchical,
                        withEntityFullPage: this.withEntityFullPage,
                    };
                }
                break;

            case AttributeMetaType.DateTime:
            case AttributeMetaType.Date:
                columnDef.cellRendererFramework = DxyDgDateTimeCellComponent;
                columnDef.cellRendererParams = {
                    noTime:
                        attributeMeta.AttributeType === AttributeMetaType.Date,
                    useDgFormat: true,
                } as IDgDateTimeCellParams;
                columnDef.valueGetter = (params) => {
                    const entityItem = params.data as EntityItem;
                    const value = entityItem?.getAttributeValue(
                        attributeMeta.AttributeKey
                    ) as string;
                    return (
                        value &&
                        this.serverTimeService
                            .getServerDateTimeAsMoment(value)
                            ?.toISOString()
                    );
                };
                if (this.hasComparator) {
                    columnDef.comparator =
                        EntityGridUtil.makeStringDateComparator(
                            (value) => value
                        );
                }
                break;

            case AttributeMetaType.Boolean:
                columnDef.cellRendererFramework = DxyBooleanCellComponent;
                break;

            case AttributeMetaType.HtmlLink:
                columnDef.cellRendererFramework =
                    DgAttributeHtmlLinkModelCellComponent;
                break;

            case AttributeMetaType.ValueList:
                if (attributeMeta.AttributeKey == 'EntityStatus') {
                    columnDef.cellRendererFramework = DgStatusCellComponent;
                    columnDef.valueGetter = (params: ICellParams<EntityItem>) =>
                        params.data.Status;
                } else if (attributeMeta.AttributeKey === 'QualityStatus') {
                    columnDef.cellRendererFramework = DxyIconCellComponent;
                    columnDef.valueGetter = (params) => {
                        const value =
                            params.data.Attributes[
                                attributeMeta.AttributeKey
                            ]?.toLowerCase();
                        return {
                            text: this.translate.instant(
                                DataQualityService.getDataQualityResultTranslateKey(
                                    value
                                )
                            ),
                            glyphClass:
                                GlyphUtil.getDataQualityGlyphClass(value),
                            glyphTooltip: this.translate.instant(
                                DataQualityService.getDataQualityResultTranslateKey(
                                    value
                                )
                            ),
                        };
                    };
                } else {
                    columnDef.cellRenderer = (params) =>
                        CollectionsHelper.getFromFirst(
                            attributeMeta.ListValues,
                            (o) => o.Value === params.value,
                            (o) => o.translatedDisplayName
                        );
                }
                break;

            case AttributeMetaType.ObjectValueList:
                if (
                    AttributeDataService.isTagAttribute(
                        attributeMeta.SourceAttributeType,
                        true
                    )
                ) {
                    columnDef.cellClass = 'dg-graphic-cell-tags';
                    columnDef.cellRendererFramework =
                        AttributeCollectionCellComponent;
                    columnDef.cellRendererParams = {
                        attributeMeta,
                        maxLines: 2,
                    };
                } else if (
                    AttributeDataService.isUserOrPersonAttribute(
                        attributeMeta.SourceAttributeType
                    )
                ) {
                    columnDef.cellClass = 'dg-graphic-cell-users';
                    columnDef.cellRendererFramework =
                        AttributeCollectionCellComponent;
                    columnDef.cellRendererParams = {
                        attributeMeta,
                        enablePopover: true,
                    };
                } else {
                    CoreUtil.warn(
                        'not implemented? ObjectValueList:',
                        AttributeMetaType[attributeMeta.SourceAttributeType],
                        attributeMeta
                    );
                }
                if (this.hasComparator) {
                    columnDef.comparator =
                        EntityGridUtil.makeAttributeMultiValueComparator(
                            attributeMeta
                        );
                }
                break;

            case AttributeMetaType.EntityLinkShortcut:
                columnDef.cellRendererFramework =
                    DgEntityLinkShortcutCollectionCellComponent;
                columnDef.cellRendererParams = {
                    asText: false,
                } as IDgEntityLinkShortcutCollectionCellParams;

                if (this.hasComparator) {
                    columnDef.comparator = EntityGridUtil.makeTextComparator(
                        DgEntityLinkShortcutCollectionCellComponent.getValueAsJoinedString
                    );
                }
                break;

            case AttributeMetaType.Number:
                // no specific renderer needed - so far
                break;

            case AttributeMetaType.EntityLogicalParent:
                columnDef.cellRendererFramework =
                    DgEntityLinkShortcutCellComponent;
                columnDef.valueGetter = (
                    params: IGridCellParams<EntityItem, HierarchyDataDescriptor>
                ) => {
                    const parents = params.data?.HddData?.Parents;
                    if (!parents?.length) {
                        return;
                    }
                    return new HierarchicalData(parents[0], parents.slice(1));
                };
                if (this.hasComparator) {
                    columnDef.comparator = EntityGridUtil.makeTextComparator(
                        (value: IHierarchicalData) => {
                            return value.Data?.DisplayName;
                        }
                    );
                }
                break;

            case AttributeMetaType.EntityLogicalPath:
                columnDef.cellRendererFramework = DgEntityPathCellComponent;
                columnDef.cellRendererParams = {
                    dtContext: this.dtContext || 'Entity Grid',
                } as IDgEntityPathCellParams;
                columnDef.valueGetter = (
                    params: IGridCellParams<IHasHddData>
                ) => {
                    return params.data?.HddData;
                };
                break;

            case AttributeMetaType.TimeSeriesObject:
            case AttributeMetaType.TimeSeriesLastEntry:
            case AttributeMetaType.TimeSeriesLastEntries:
                columnDef.cellRendererFramework = DgTimeSeriesCellComponent;
                columnDef.cellRendererParams = {
                    colorRule:
                        attributeMeta.TimeSeriesColorRule ??
                        TimeSeriesColorRule.ShouldIncrease,
                };
                break;

            case AttributeMetaType.SystemEntityType:
                columnDef.cellRenderer = (data) =>
                    this.attributeDataService.getEntityTypeTranslation(
                        data.value
                    );
                break;

            case AttributeMetaType.UserGuid:
            case AttributeMetaType.EntityReference:
            case AttributeMetaType.EntityLogicalPathString:
            case AttributeMetaType.Module:
                break;

            default:
                CoreUtil.warn(
                    'not implemented? AttributeType, SourceAttributeType:',
                    AttributeMetaType[attributeMeta.AttributeType],
                    AttributeMetaType[attributeMeta.SourceAttributeType],
                    attributeMeta
                );
                break;
        }
        switch (attributeMeta.AttributeKey) {
            case 'ModelType':
            case 'Type': {
                const getText = (entity: EntityItem) =>
                    (entity &&
                        this.translate.instant(
                            `DgServerTypes.${entity.DataTypeName}Type.${entity.SubTypeName}`
                        )) ??
                    '';
                columnDef.field = 'DataTypeName';
                columnDef.cellRenderer = (params: ICellRendererParams) =>
                    getText(params.data);
                if (this.hasComparator) {
                    columnDef.comparator = EntityGridUtil.makeTextComparator(
                        (_, n) => getText(n.data)
                    );
                }
                break;
            }

            case 'Order':
                // Order field means the Column Order, then we sort using this attribute.
                column.isDefaultSort = this.egOptions.useOrderColumn
                    ? true
                    : null;
                break;
            case 'CreationUserId':
                columnDef.cellRendererFramework = UserCellComponent;
                columnDef.valueGetter = (
                    params: ICellRendererParams<EntityItem>
                ) => ({ userId: params.data?.CreationUserId });
                if (this.hasComparator) {
                    columnDef.comparator = EntityGridUtil.makeTextComparator(
                        (value) => this.userService.getUserName(value.userId)
                    );
                }
                break;
        }

        const index = (
            this.egOptions.wantedColumns ||
            this.defaultAttributes ||
            []
        ).indexOf(attributeMeta.AttributeKey);
        if (index > -1) {
            column.visible = true;
            column.order = index;
        }

        return column;
    }

    private tradGetServerTypeName(serverType: ServerType): string {
        return this.translate.instant(
            `DgServerTypes.ServerTypeName.${ServerType[serverType]}`
        );
    }

    private getColumnDefs() {
        if (this.egOptions.isSingleColumn) {
            if (this.isHierarchical) {
                // the grid as a tree creates a grouping column for which we provide a renderer, so no need for any more columns
                return [];
            }
            return [
                {
                    field: 'DisplayName',
                    cellRendererFramework:
                        this.egOptions.firstCellRendererFramework ??
                        EntityCardCellComponent,
                    valueGetter: this.egOptions.firstCellValueGetter,
                    cellRendererParams: {
                        inputs: {
                            hideBreadcrumb: !this.showItemBreadcrumb,
                            isFromHierarchical: this.isHierarchical,
                            withEntityFullPage: this.withEntityFullPage,
                            breadcrumbOpenPreview: true,
                            noLabelNavLink: this.egOptions?.noLabelNavLink,
                            dtContext: this.dtContext || 'Entity List',
                            dragDrop: this.cardCellDragDrop,
                        },
                        actions: this.getAvailableActions(),
                    } as IEntityCardCellParams,
                } as IOmniGridColumnDef,
            ];
        }

        let getOrderIndex: (c: EntityGridColumnInfo) => number;

        if (this.isPrimaryColumnLockedToLeft) {
            getOrderIndex = (c) => {
                const cd = c.colDef;
                if (c.attributeKey == this.currentPrimaryName) {
                    cd.lockPosition = true;
                    cd.hide = false;
                    return 0;
                }
                cd.lockPosition = false;
                if (c.attributeKey == this.currentSecondaryName) {
                    cd.hide = !this.showBothNameColumns;
                }
                return cd.hide ? 1000 : 1 + c.order;
            };
        } else if (!this.showBothNameColumns) {
            getOrderIndex = (c) => {
                if (c.attributeKey == this.currentPrimaryName) {
                    c.colDef.hide = false;
                } else if (c.attributeKey == this.currentSecondaryName) {
                    c.colDef.hide = true;
                }
                return c.order;
            };
        } else {
            getOrderIndex = (c) => c.order;
        }

        // in tree mode, the autoColumn column replaces the primary name column
        const columns = this.isHierarchical
            ? this.columns.filter(
                  (c) => c.attributeKey != this.currentPrimaryName
              )
            : this.columns;
        const colDefs = CollectionsHelper.orderBy(columns, getOrderIndex).map(
            (c) => c.colDef
        );
        this.log('getColumnDefs', colDefs);
        return colDefs;
    }

    private setupOmniGrid() {
        this.log('setupOmniGrid');

        const isMultiColumn = !this.egOptions.isSingleColumn;

        const gridData: IOmniGridDataInfo<EntityItem> = {
            columns: this.getColumnDefs(),
            rowSelection: this.rowSelection,
            canRemoveColumnByDragging: isMultiColumn,
            animateRows: this.animateRows,
            actions: this.egOptions.hoverActions,
            rowHeight: this.rowHeight,
            autoHeight: this.isAutoHeight,
            headerHeight: this.headerHeight,
            showNoResults: this.showMessagesInGrid,
            noResultText: this.translate.instant('UI.Filter.noResult'),
            getRowHeight: this.egOptions.getRowHeight
                ? (params) => this.egOptions.getRowHeight(params)
                : undefined,
            dragDrop: this.cardCellDragDrop,
        };

        if (this.egOptions.directData) {
            this.setOmnigridDataDirect(gridData);
        } else if (this.isHierarchical) {
            gridData.tree = {
                getEntityId: (entity) => entity && entity.ReferenceId,
                getEntityChildren: (entityRefId: string, sortModel) =>
                    this.getEntityChildren(entityRefId, sortModel),
                getEntityChildrenCount: (entity) =>
                    (entity && entity.ContextualAllLevelChildrenCount) || 0,
                maxChildrenCountToLoad: this.maxSize,
                purgeClosedRowNodes: this.purgeClosedRowNodesData,
                purgeClosedRowNodesChildrenSelection:
                    this.purgeClosedRowNodesDescendantSelection,
                onGroupRowClick: (entity) => this.onGroupRowClick(entity),
                controlCellRendererParams: {
                    innerRendererFramework:
                        this.egOptions.firstCellRendererFramework ??
                        EntityCardCellComponent,
                    valueGetter: this.egOptions.firstCellValueGetter,
                    onGroupItemClick: (entity) => this.onGroupItemClick(entity),
                    inputs: {
                        isFromHierarchical: this.isHierarchical,
                        hideBreadcrumb: !this.showItemBreadcrumb,
                        breadcrumbOpenPreview: true,
                        noLabelNavLink: this.egOptions?.noLabelNavLink,
                        dtContext: this.dtContext || 'Entity Tree',
                        dragDrop: this.cardCellDragDrop,
                    },
                    actions: this.getAvailableActions(),
                } as ITreeNodeRendererParams,
                canSortByHeaderClick: this.isSortingEnabled,
                clearSourceCache: () => this.clearSourceCache(),
            };
            const treeOptions = gridData.tree;
            treeOptions.onChildrenRefreshed = (parent, children) =>
                this.onChildrenRefreshed(parent, children);
            if (isMultiColumn) {
                treeOptions.controlHeaderName = this.getControlHeaderName();
                treeOptions.lockGroupColumnToLeft =
                    this.isPrimaryColumnLockedToLeft;
                treeOptions.controlColumnWidth = CollectionsHelper.getFromFirst(
                    this.columns,
                    (c) => c.attributeKey == this.currentPrimaryName,
                    (c) => c && c.colDef.width
                );
            }
        } else {
            gridData.infiniteLoad = {
                getEntityId: (entity) => entity?.ReferenceId,
                getRows: (from, size, sortModel) =>
                    this.flatLoadItems(from, size, sortModel),
                fetchSize: 50,
                maxResultWindow: this.maxResultWindow,
                canSortByHeaderClick: this.isSortingEnabled,
                clearSourceCache: () => this.clearSourceCache(),
            };
            const infiniteLoadOptions = gridData.infiniteLoad;
            infiniteLoadOptions.onRowsLoaded = (from, entities) =>
                this.onFlatRowsLoaded(from, entities);
        }

        if (
            this.egOptions.isSingleColumn &&
            gridData.headerHeight == undefined
        ) {
            gridData.headerHeight = 0;
        }
        this.gridData = gridData;
    }

    private updateFilteredColumnsList(searchString?: string) {
        const columns = StringUtil.filterSearched(
            searchString,
            this.columnsList,
            (c) => c.displayName
        );
        const groupDefs = this.egOptions.entityAttributeGroups;

        const attributeOptionsToColumnOptions = (options: ISectionOption[]) =>
            options
                ?.map((option) => {
                    const ami = option.data as AttributeMetaInfo;
                    return {
                        ...option,
                        glyphClass:
                            this.attributeDataService.getAttributeGlyphClass(
                                ami
                            ),
                        data: columns?.find(
                            (c) => ami.AttributePath === c.attributePath
                        ),
                    };
                })
                .filter((option) => !!option.data);

        const transformAndFilterSectionToColumnSection = (
            section: IDropdownSection
        ) => {
            return {
                ...section,
                options: attributeOptionsToColumnOptions(section.options),
                subSections: section.subSections
                    ?.map((subSection) => ({
                        ...subSection,
                        options: attributeOptionsToColumnOptions(
                            subSection.options
                        ),
                    }))
                    .filter((subSection) => !!subSection.options.length),
            };
        };
        this.filteredColumnListGroups = groupDefs
            ? groupDefs.map((group) =>
                  transformAndFilterSectionToColumnSection(group)
              )
            : ([
                  { options: columns.map((column) => ({ data: column })) },
              ] as IDropdownSection[]);
    }

    private async updateDirectData() {
        this.log('updateDirectData', !!this.gridData);
        this.isFirstLoad = true;
        this.setOmnigridDataDirect(this.gridData);
        await this.withGrid((grid) => grid.updateGridData(true));
    }

    private setOmnigridDataDirect(omniGridData: IOmniGridDataInfo<EntityItem>) {
        if (!omniGridData) {
            return;
        }
        const directData = this.egOptions?.directData;
        if (!directData) {
            CoreUtil.warn('no directData');
        } else if (directData.items) {
            omniGridData.objects = directData.items;
            omniGridData.groups = null;
        } else if (directData.groups) {
            omniGridData.groups = directData.groups;
            omniGridData.objects = null;
        } else {
            CoreUtil.warn('directData: no items nor groups');
        }
        omniGridData.getObjectId = (e) => e.ReferenceId;
    }

    //#endregion  setup grid

    //#region load data
    private async flatLoadItems(
        from: number,
        size: number,
        sortModel: OmniGridSortModel
    ) {
        const { serverTypes, attributePaths, filters, sortKey } =
            this.prepareGetEntities(true, sortModel);
        const result = await this.entityCacheService.getEntitiesForFlat(
            this,
            this.spaceIdr,
            serverTypes,
            from,
            size,
            false,
            attributePaths,
            filters,
            sortKey
        );
        const loadedEntitiesById = CollectionsHelper.arrayToMap(
            result.Entities,
            (ei) => ei.DataReferenceId
        );
        this._selectedEntities = this._selectedEntities?.filter((ei_1) =>
            loadedEntitiesById.has(ei_1.DataReferenceId)
        );
        return this.postLoad(result);
    }

    private async getEntityChildren(
        entityRefId: string,
        sortModel: OmniGridSortModel
    ) {
        const previousSortKey = this.sortKey;
        const {
            serverTypes,
            filters,
            attributePaths,
            rootEntityRefId,
            sortKey,
        } = this.prepareGetEntities(false, sortModel, entityRefId);
        this._debug &&
            this.log('getEntityChildren', { previousSortKey, sortKey });
        if (previousSortKey !== sortKey) {
            this.entityCacheService.clear(this);
        }
        const result = await this.entityCacheService.getEntitiesForHierarchical(
            this,
            this.spaceIdr,
            serverTypes,
            filters,
            attributePaths,
            rootEntityRefId,
            this.maxSize,
            sortKey
        );
        return this.postLoad(result);
    }

    private prepareGetEntities(
        forFlat: boolean,
        sortModel: OmniGridSortModel,
        entityRefId?: string
    ) {
        this.sortKey = OmniGridUtil.sortModelToString(sortModel, (colId) =>
            colId == EntityGridCore.autoColumnId
                ? this.currentPrimaryName
                : colId
        );
        const rootEntityRefId =
                !forFlat &&
                !entityRefId &&
                this.egOptions.useParentDataIdIfNullEntityRefId
                    ? this.parentDataId
                    : entityRefId,
            sortKey = this.egOptions.isSingleColumn ? undefined : this.sortKey,
            serverTypes = this.getLoadServerTypes(),
            attributePaths = this.getLoadAttributePaths(),
            filters = this.getSearchFilters(forFlat);
        this._debug &&
            this.log('prepareGetEntities', {
                forFlat,
                entityRefId,
                rootEntityRefId,
                serverType: ServerType[this.serverType],
                serverTypes: serverTypes?.map((st) => ServerType[st]),
                attributePaths,
                filters,
                sortKey,
            });
        return {
            serverTypes,
            filters,
            sortKey,
            attributePaths,
            rootEntityRefId,
        };
    }

    private getLoadServerTypes() {
        if (!this.isMultiTypeCatalog) {
            return [this.serverType];
        }
        const includeAllChildrenTypes = this.egOptions.includeAllChildrenTypes;
        const ST = ServerType;
        switch (this.serverType) {
            case ST.Model:
                return ModelerDataUtil.modelerServerTypes;
            case ST.Container:
                return includeAllChildrenTypes
                    ? [ST.Container, ST.Table, ST.Column]
                    : [ST.Container];
            case ST.Table:
                return this.isHierarchical
                    ? includeAllChildrenTypes
                        ? [ST.Table, ST.Column]
                        : [ST.Container, ST.Table]
                    : [ST.Table];
            case ST.Column:
                return includeAllChildrenTypes
                    ? [ST.Column]
                    : [ST.Table, ST.Column];
        }
    }

    private getLoadAttributePaths() {
        return this.egOptions.isSingleColumn
            ? []
            : this.columnsList
                  .filter((c) => c.visible)
                  .map((c) => c.attributePath);
    }

    private getSearchFilters(forFlat: boolean) {
        const filters = this.filteredViewService.getCurrentFilters(this.dgZone);

        if (this._searchBoxTerm) {
            const primaryNameAttribute =
                this.viewTypeService.getPrimaryNameAttribute(this.serverType);
            filters.push(
                new Filter(primaryNameAttribute, FilterOperator.TextContains, [
                    this._searchBoxTerm,
                ])
            );
        }

        if (this.egOptions.filterEntityTypes?.length) {
            filters.push(
                new Filter(
                    ServerConstants.Search.EntityTypeFilterKey,
                    FilterOperator.ListContains,
                    this.egOptions.filterEntityTypes.map((e) => EntityType[e])
                )
            );
        }

        if (this.egOptions.filterOnParentId) {
            filters.push(
                new Filter(
                    ServerConstants.PropertyName.Parents,
                    FilterOperator.Equals,
                    [this.egOptions.filterOnParentId]
                )
            );
        }

        if (forFlat) {
            this.addFlatGridParentIdFilter(filters);
        }

        return filters;
    }

    /** This will add the Parents filter: direct OR indirect */
    private addFlatGridParentIdFilter(filters: Filter[]) {
        if (!this.parentDataId || this.parentDataId == this.spaceIdr.spaceId) {
            return;
        }

        filters.push(
            new Filter(
                ServerConstants.PropertyName.Parents,
                FilterOperator.Equals,
                [this.parentDataId]
            )
        );
    }

    private clearSourceCache() {
        this.log('clearSourceCache');
        this.entityCacheService.clear(this);
    }

    private postLoad(result: ILoadMultiResult<EntityItem>) {
        this.log('postLoad', this.isFirstLoad, result.Entities?.length, result);
        this.tooManyResults =
            result.IsSuccess &&
            ((this.isHierarchical && result.Size >= this.maxSize) ||
                (!this.isHierarchical &&
                    result.TotalCount > this.maxResultWindow));
        if (this.isFirstLoad) {
            if (result.Entities?.length) {
                this.timeout(
                    () =>
                        this.currentEntityId &&
                        this.ensureCurrentEntityVisible()
                );
                this.noResult = this.isFirstLoad = false;
                if (this.isHierarchical) {
                    this.totalCount = result.TotalCount;
                }
            } else {
                this.resetResults();
            }
        }
        if (!this.isHierarchical) {
            this.totalCount = result.TotalCount;
        }
        return result;
    }

    private resetResults() {
        this.isFirstLoad = this.noResult = true;
        this.tooManyResults = false;
        this.totalCount = 0;
    }

    //#endregion load data

    //#region load/save grid state

    private async updateColumns(reset = false) {
        this.log('updateColumns', reset);
        await this.buildColumns(false, reset);
        if (this.noApi) {
            return;
        }
        const grid = this.gridApi;
        const colDefs = this.getColumnDefs();
        if (this.isPrimaryColumnLockedToLeft) {
            // if we don't do this then the lockposition change is not taken into account. #ag-grid-bug
            const tmpColDefs = colDefs.filter(
                (c) =>
                    c.colId != this.currentPrimaryName &&
                    c.colId != this.currentSecondaryName
            );
            await grid.setColumnDefs(tmpColDefs, true);
        }
        await grid.setColumnDefs(colDefs, true);

        if (reset) {
            await this.saveGridState();
        }
    }

    private async saveGridState(state?: IOmniGridState) {
        const gridSettingId = this.gridStatePersistenceId;
        if (!this.canSaveGridState || !gridSettingId) {
            return;
        }

        this.log('saveGridState', gridSettingId);
        let gridState: IOmniGridState;
        if (state) {
            gridState = state;
        } else if (this.noApi) {
            return;
        }

        gridState = await this.gridApi.getState();

        const ca =
            this.isHierarchical &&
            gridState.columns.find(
                (c) => c.colId == EntityGridCore.autoColumnId
            );
        if (ca) {
            ca.colId = this.currentPrimaryName;
        }
        await this.persistGridState(gridSettingId, gridState);
        this.log('saveGridState-done');
    }

    private applySavedSettingsToColumns(
        columnInfos: EntityGridColumnInfo[],
        gridState: IOmniGridState
    ) {
        this.log('applySavedSettingsToColumns', columnInfos, gridState);
        const columnsStates = gridState?.columns;

        if (columnInfos?.length && columnsStates?.length) {
            columnInfos.forEach((ci) => {
                const cs = columnsStates?.find(
                    (cs) => cs.colId == ci.colDef.colId
                );
                if (cs) {
                    // the column is part of the saved settings => apply the setting to the column
                    ci.colDef.hide = cs.hide;
                    if (cs.width != undefined) {
                        ci.colDef.width = cs.width;
                    }
                    ci.order = columnsStates.indexOf(cs);
                } else {
                    // the column is not in the saved settings => hide it
                    ci.visible = false;
                }
            });
        }

        // Auto Cleanup: Delete all values from Storage that does not exist as available Attribute Paths
        CollectionsHelper.remove(
            columnsStates,
            (cs) => !columnInfos.some((ci) => ci.attributePath == cs.colId)
        );

        return gridState;
    }

    private async loadGridState(
        gridRouteId: string,
        useGoldenSettingsIfAny = false,
        useGoldenSettingsOnlyIfNoSettings = false
    ) {
        if (!gridRouteId) {
            return;
        }
        this.log(
            'loadGridState',
            gridRouteId,
            useGoldenSettingsIfAny,
            useGoldenSettingsOnlyIfNoSettings
        );
        const result = await this.userService.getUserSettingValue(
            userSettingsValues.omniGrid.category,
            gridRouteId,
            useGoldenSettingsIfAny,
            useGoldenSettingsOnlyIfNoSettings
        );
        return result?.Value && (JSON.parse(result.Value) as IOmniGridState);
    }

    private async persistGridState(
        gridRouteId: string,
        gridState: IOmniGridState
    ) {
        if (!gridRouteId) {
            return false;
        }
        this.log('persistGridState', gridRouteId, gridState);
        await this.userService.setUserSettingValue(
            userSettingsValues.omniGrid.category,
            gridRouteId,
            gridState && JSON.stringify(gridState)
        );
    }

    //#endregion load/save grid state

    //#region refresh grid rows after actions

    private async refreshForAddedEntity(
        entity: EntityItem,
        setAsCurrent = false
    ) {
        this.log('refreshForAddedEntity', entity, setAsCurrent);
        if (this.noApi) {
            return;
        }

        const grid = this.gridApi;
        const isFlatCache = !this.isHierarchical;

        // update cache with new entity
        this.isAddingEntity = true;
        try {
            // Special case to handle when cloning a Table with existing columns
            if (
                entity?.getAttributeValue<number>(
                    ServerConstants.PropertyName.LogicalChildrenCount
                ) > 0
            ) {
                this.clearSourceCache();
            }
            this.entityCacheService.addEntityToCache(this, entity, isFlatCache);

            // Refresh row's data to update expandability

            if (entity.entityParentId && !isFlatCache) {
                // force parent expandability to refresh
                await grid.forceNodeRefresh(
                    entity.entityParentId,
                    (entity) => entity.HasContextualAllLevelChildrenCount,
                    (entity) => entity.ContextualAllLevelChildrenCount
                );
            }

            // Refresh rows to show new values

            // If we are in flat mode or if the new entity parent is the grid parent, we need to refresh all rows
            const refreshAllRows =
                isFlatCache || this.parentDataId == entity.entityParentId;
            const parentId = refreshAllRows ? null : entity.entityParentId;
            // don't wait too long to avoid preview panel not oppening (DG-3802)
            await Promise.race([
                new Promise((resolve) => setTimeout(() => resolve(null), 1000)),
                grid.refreshNodeOnAction(parentId, this.preserveExpandedRows),
            ]);

            // Expand all ancestors
            if (!isFlatCache) {
                const ancestors =
                    entity?.LogicalPathParentIds.slice().reverse();
                if (setAsCurrent && ancestors?.length) {
                    await grid.setRowsExpanded(ancestors, true, true);
                }
            }
        } finally {
            // Update count and manage navigation

            this.isAddingEntity = false;
            const totalCountResult = this.entityCacheService.getTotalCount(
                this,
                isFlatCache
            );
            this.totalCount =
                totalCountResult != undefined
                    ? totalCountResult
                    : this.totalCount;

            if (setAsCurrent) {
                await this.setCurrentEntityId(
                    entity.ReferenceId,
                    true,
                    this.isMultiSelect
                );
            }
        }
    }

    private async refreshForUpdatedEntityParent(
        updatedEntities: EntityItem[],
        isCurrentEntityMoved = false
    ) {
        this.log('refreshForUpdatedEntityParent', updatedEntities);
        if (this.noApi) {
            return;
        }

        const grid = this.gridApi;
        const isFlatCache = !this.isHierarchical;

        this.isReloadingChildren = true;
        try {
            // Update cache and refresh updated rows
            if (isFlatCache) {
                updatedEntities.forEach((updatedEntity) =>
                    this.entityCacheService.updateCachedEntityParent(
                        this,
                        updatedEntity,
                        true
                    )
                );
                await grid.refreshAllRows();
            } else {
                await Promise.all(
                    updatedEntities.map(async (updatedEntity) => {
                        this.entityCacheService.updateCachedEntityParent(
                            this,
                            updatedEntity,
                            false
                        );
                        await grid.forceNodeRefresh(
                            updatedEntity.entityParentId,
                            (entity) =>
                                entity.HasContextualAllLevelChildrenCount,
                            (entity) => entity.ContextualAllLevelChildrenCount
                        );
                        await grid.refreshForParentChanged(
                            updatedEntity.ReferenceId,
                            updatedEntity.entityParentId,
                            this.preserveExpandedRows
                        );
                    })
                );
            }
            // Expand new ancestors   - or not ?
            if (isCurrentEntityMoved && !isFlatCache) {
                const newAncestors =
                    updatedEntities[0]?.LogicalPathParentIds.slice().reverse();
                this.log(
                    'refreshForUpdatedEntity-setRowsExpanded',
                    newAncestors
                );
                await this.ensureCurrentEntityVisible();
            }

            this.totalCount = this.entityCacheService.getTotalCount(
                this,
                !this.isHierarchical
            );
        } finally {
            this.isReloadingChildren = false;
        }
    }

    private async refreshForUpdatedEntity(
        updatedPartialEntity: EntityItem,
        isTechnologyUpdate: boolean
    ) {
        this.log('refreshForUpdatedEntity', updatedPartialEntity);
        if (this.noApi) {
            return;
        }

        const grid = this.gridApi;
        this.log(
            'refreshForUpdatedEntity-mergeEntity',
            updatedPartialEntity.ReferenceId
        );

        // Technology Update
        const updatedTechnologyCode =
            updatedPartialEntity.getAttributeValue<string>(
                ServerConstants.PropertyName.Technology
            );
        if (isTechnologyUpdate) {
            const updatedEntities =
                this.entityCacheService.updateCachedEntitiesTechnology(
                    this,
                    updatedPartialEntity.LogicalParentId,
                    updatedPartialEntity.ReferenceId,
                    !this.isHierarchical,
                    updatedTechnologyCode
                );
            return await this.refreshForUpdatedEntities(updatedEntities);
        }

        // Update Cache for attributes updates
        let updatedEntity = this.entityCacheService.updateCachedEntity(
            this,
            updatedPartialEntity,
            !this.isHierarchical
        );

        if (!updatedEntity) {
            const findPredicate = (item: EntityItem) =>
                item.ReferenceId === updatedPartialEntity.ReferenceId;
            let entity = this.egOptions.directData?.items?.find(findPredicate);
            if (!entity && this.isTree) {
                // Inside trees, cache contains only last loaded children
                // So refresh issues appears (DG-5817)
                // This line, at least, updates the selected item
                entity = this.selectedEntities?.find(findPredicate);
            }
            if (!entity) {
                return;
            }

            EntityUtils.mergeEntity(entity, updatedPartialEntity);
            updatedEntity = entity;
        }
        // Update & refresh node
        await grid.updateNodeData(
            updatedPartialEntity.ReferenceId,
            updatedEntity
        );
    }

    private async refreshForUpdatedEntities(updatedEntities: EntityItem[]) {
        this.log('refreshForUpdatedEntities', updatedEntities);
        const idsAndData = new Map<string, EntityItem>();
        if (this.noApi) {
            return;
        }

        const grid = this.gridApi;
        this.isReloadingChildren = false;
        await Promise.all(
            updatedEntities.map(async (updatedEntity) => {
                const entityId = updatedEntity.ReferenceId;
                const gridEntity = await grid.getRowData(entityId);
                if (gridEntity) {
                    EntityUtils.mergeEntity(gridEntity, updatedEntity);
                    idsAndData.set(entityId, gridEntity);
                }
            })
        );
        this.log('refreshForUpdatedEntities-setRowsData', idsAndData.size);
        const updatedGridEntityIds = await grid.setRowsData(idsAndData);
        this.log('refreshForUpdatedEntities-done', updatedGridEntityIds);
    }

    private async refreshForDeletedEntitiesById(
        entityIds: string[],
        preserveCurrentSingleSelected = false
    ) {
        this.log('refreshForDeletedEntitiesById', entityIds);
        if (this.noApi) {
            return;
        }
        const grid = this.gridApi;

        let newCurrentEntityId: string = undefined;
        let currentEntityHasBeenDeleted = false;

        if (this.isHierarchical) {
            if (preserveCurrentSingleSelected) {
                const gridEntities = await Promise.all(
                    entityIds.map((eid) => this.getGridEntity(grid, eid))
                );
                CollectionsHelper.withFirstFound(
                    gridEntities,
                    (e) =>
                        e && this.isCurrentEntityDeletedOrChildrenOfDeleted(e),
                    (e) => {
                        newCurrentEntityId = e.entityParentId;
                        currentEntityHasBeenDeleted = true;
                    }
                );
            }
            this.entityCacheService.deleteEntitiesFromCache(this, entityIds);
            await grid.refreshForRemoved(
                entityIds,
                this.areChildrenReparentedToRoot,
                this.preserveExpandedRows
            );
            await grid.forceNodeRefresh(
                this.currentEntityId,
                (entity) => entity.HasContextualAllLevelChildrenCount,
                (entity) => entity.ContextualAllLevelChildrenCount
            );
        } else {
            this.entityCacheService.deleteEntitiesFromCache(
                this,
                entityIds,
                true
            );
            await grid.refreshAllRows();
        }

        // Update entities total count
        this.totalCount = this.entityCacheService.getTotalCount(
            this,
            !this.isHierarchical
        );
        // If no entity is currently selected and no newCurrentEntity has been found, get the first one
        if (currentEntityHasBeenDeleted && !newCurrentEntityId) {
            const firstEntity = await grid.getFirstData();
            newCurrentEntityId = firstEntity?.ReferenceId;
        }
        // If newCurrentEntity has a value, it means the current entity has changed
        if (newCurrentEntityId) {
            await this.setCurrentEntityId(newCurrentEntityId, true, true);
        }
    }

    private isCurrentEntityDeletedOrChildrenOfDeleted(
        deletedEntity: EntityItem
    ) {
        // currentEntity is the one deleted
        if (deletedEntity.ReferenceId == this.currentEntityId) {
            return true;
        }
        // the deleted entity have no children or there is no currently selected entity
        if (
            deletedEntity.ContextualAllLevelChildrenCount == 0 ||
            !this.currentEntityId
        ) {
            return false;
        }
        //the deleted entity has the current amongst his children
        const currentEntity = this._selectedEntities.find(
            (e) => e.ReferenceId == this.currentEntityId
        );
        return currentEntity?.HddData.Parents.some(
            (p) => p.DataReferenceId == deletedEntity.ReferenceId
        );
    }

    private async ensureCurrentEntityVisible() {
        const currentEntity =
            this.currentEntityDataService.getCurrentEntityData();
        this.log('ensureCurrentEntityVisible', !!currentEntity);
        this.setCurrentEntityId(currentEntity?.ReferenceId);
        if (!currentEntity) {
            return;
        }

        const currentEntityAncestorsIds =
            currentEntity?.HddData?.Parents.slice()
                .reverse()
                .slice(1)
                .map((ancestor) => ancestor.DataReferenceId);
        this.log(
            'ensureCurrentEntityVisible-currentEntityAncestorsIds',
            currentEntityAncestorsIds
        );
        if (!currentEntityAncestorsIds) {
            return;
        }

        await this.timeout(() =>
            this.withGrid(async (grid) => {
                for (const ancestorId of currentEntityAncestorsIds) {
                    await grid.scrollToNode(ancestorId);
                    await grid.setRowsExpanded(ancestorId, true, true);
                }
                await grid.scrollToNode(currentEntity.ReferenceId);
            })
        );
    }

    //#endregion refresh grid after actions

    public getFeatureCodeHierarchy() {
        return this.isHierarchical
            ? 'DISPLAY_HIERARCHY_MODE,R'
            : 'DISPLAY_FLAT_MODE,R';
    }

    public async toggleHierarchicalView() {
        this.log('toggleHierarchicalView');
        if (this.egOptions?.isHierarchyToggleLocal) {
            this.egOptions.isHierarchical = !this.egOptions.isHierarchical;
            await this.resetGrid();
        } else {
            if (this.noApi) {
                return;
            }
            const entity = await this.getGridEntity(
                this.gridApi,
                this.currentEntityId
            );
            const spaceIdr =
                SpaceIdentifier.fromEntity(entity) ?? this.spaceIdr;
            await this.navigationService.toggleHierarchicalView(
                this.dgModule,
                entity,
                spaceIdr
            );
        }
    }

    public async resetCoreGrid() {
        await this.resetGrid();
    }

    public getHierarchicalTooltip() {
        const translationKey = this.isHierarchical
            ? 'UI.OmniGrid.goToFlatView'
            : 'UI.OmniGrid.goToHierarchicalView';
        return this.translate.instant(translationKey);
    }

    public filterColumns(searchString: string) {
        this.log('filterColumns', searchString);
        this.updateFilteredColumnsList(searchString);
    }

    private subscribeEvents() {
        this.renewServerTypeSubscriptions();

        super.subscribe(this.appEventsService.viewTypeChange$, () =>
            this.onViewTypeChange()
        );

        if (this.enableAutoResetScroll) {
            super.subscribe(this.appEventsService.entityDashboardFocus$, () =>
                this.ensureCurrentEntityVisible()
            );
        }
        if (this.dgZone) {
            super.subscribe(
                this.filteredViewService.currentViewChanged$,
                (data) => this.onFilteredViewChanged(data)
            );
        }
    }

    private renewServerTypeSubscriptions() {
        this.serverTypeSubscriptionGroup?.unsubscribe();
        this.serverTypeSubscriptionGroup = new Subscription();

        const serverTypes =
            this.isHierarchical &&
            ModelerDataUtil.isModelerServerType(this.serverType)
                ? ModelerDataUtil.modelerServerTypes
                : this.serverType
                ? [this.serverType]
                : EntityServerTypeUtils.firstClassEntityServerTypes;

        this.log(
            'renewServerTypeSubscription',
            ServerType[this.serverType],
            serverTypes.map((st) => ServerType[st])
        );
        serverTypes.forEach((st) =>
            this.serverTypeSubscriptionGroup.add(
                this.subscribeEventsForServerType(st)
            )
        );

        // In the dictionary, for the tables / containers / columns tabs
        // we need to subscribe to the parent's type for the updateParent event
        if (this.isCatalog && !this.isHierarchical && serverTypes.length == 1) {
            const parentServerType =
                this.currentEntityDataService.getCurrentEntityData()
                    ?.ServerType;
            this.serverTypeSubscriptionGroup.add(
                this.updateParentSubscription(parentServerType)
            );
        }
    }

    private subscribeEventsForServerType(serverType: ServerType) {
        const serverTypeSubscription = new Subscription();

        if (this.egOptions.isMultiSelect) {
            serverTypeSubscription.add(
                super.registerSubscription(
                    this.entityEventService.subscribeEntityBulkUpdate(
                        serverType,
                        (entities) => {
                            if (!entities || !entities.length) {
                                return;
                            }

                            this.doPostBulk(() => {
                                this.log('event EntityBulkUpdate', entities);
                                this.refreshForUpdatedEntities(entities);
                            });
                        }
                    )
                )
            );
        } else {
            serverTypeSubscription.add(
                super.registerSubscription(
                    this.entityEventService.subscribeEntityChange(
                        serverType,
                        (entity) => {
                            if (!entity || this.isShowingEntityDetails) {
                                return;
                            }

                            this.log('event EntityChange', entity);
                            this.setCurrentEntityId(
                                entity && entity.ReferenceId,
                                true
                            );
                            this.ensureCurrentEntityVisible();
                        }
                    )
                )
            );
        }

        serverTypeSubscription.add(
            super.registerSubscription(
                this.entityEventService.subscribeEntityCreation(
                    serverType,
                    (data, isExternal) => {
                        // Do not try to add new entities from Real Time if active filtered view
                        if (!data.entity || (isExternal && this.isAnyFilter)) {
                            return;
                        }

                        this.doPostBulk(() => {
                            const ctx = data.context ?? {};
                            this.log(
                                'event EntityCreation',
                                isExternal,
                                EntityCreationOrigin[ctx.origin],
                                ctx.isMultiCreation,
                                data.entity
                            );
                            const setAsCurrentEntity =
                                this.egOptions
                                    .preserveCurrentSingleSelectedOnEntityCreated &&
                                !isExternal &&
                                ctx.origin !=
                                    EntityCreationOrigin.dockingPane &&
                                !(
                                    ctx.origin ==
                                        EntityCreationOrigin.burgerMenuCreateChild &&
                                    ctx.isMultiCreation
                                );
                            this.refreshForAddedEntity(
                                data.entity,
                                setAsCurrentEntity
                            );
                        });
                    }
                )
            )
        );

        serverTypeSubscription.add(
            super.registerSubscription(
                this.entityEventService.subscribeEntityUpdate(
                    serverType,
                    (entity, isExternal) => {
                        this.entityUpdateSubscription(
                            serverType,
                            isExternal,
                            entity,
                            'event EntityUpdate',
                            false
                        );
                    }
                )
            )
        );

        serverTypeSubscription.add(
            super.registerSubscription(
                this.entityEventService.subscribeEntityTechnologyUpdate(
                    serverType,
                    (entity, isExternal) => {
                        this.entityUpdateSubscription(
                            serverType,
                            isExternal,
                            entity,
                            'event EntityTechnologyUpdate',
                            true
                        );
                    }
                )
            )
        );

        serverTypeSubscription.add(this.updateParentSubscription(serverType));

        serverTypeSubscription.add(
            super.registerSubscription(
                this.entityEventService.subscribeEntityDelete(
                    serverType,
                    (data) => {
                        if (!data?.deletedIds?.length) {
                            return;
                        }

                        this.doPostBulk(() => {
                            this.log('event EntityDelete', data.deletedIds);
                            this.refreshForDeletedEntitiesById(
                                data.deletedIds,
                                true
                            );
                        });
                    }
                )
            )
        );

        return serverTypeSubscription;
    }

    private entityUpdateSubscription(
        serverType: ServerType,
        isExternal: boolean,
        entity: EntityItem,
        logEvent: string,
        isTechnologyUpdate: boolean
    ) {
        this.log(logEvent, ServerType[serverType], isExternal, entity);
        // Do not try to add new entities from Real Time if active filtered view
        if (
            !entity ||
            this.isAddingEntity ||
            (isExternal &&
                this.isAnyFilter &&
                !this.egOptions.forceRefreshForUpdatedEntity)
        ) {
            return;
        }

        this.doPostBulk(() => {
            this.log('doPostBulk');
            this.log(logEvent, isExternal, entity);
            this.refreshForUpdatedEntity(entity, isTechnologyUpdate);
        });
    }

    private updateParentSubscription(serverType: ServerType) {
        return super.registerSubscription(
            this.entityEventService.subscribeEntityParentUpdate(
                serverType,
                (data, isExternal) =>
                    this.onEntityParentUpdate(data, isExternal)
            )
        );
    }

    private onEntityParentUpdate(
        data: SetEntitiesParentResult,
        isExternal: boolean
    ) {
        // Do not try to add new entities from Real Time if active filtered view
        if (!data || this.isAddingEntity) {
            return;
        }
        this.log('onEntityParentUpdate', isExternal, data);

        this.doPostBulk(() => {
            const movedEntities = data.getMovedEntities();
            const isCurrentEntityMoved = movedEntities.some(
                (e) => e.ReferenceId == this.currentEntityId
            );
            this.refreshForUpdatedEntityParent(
                movedEntities,
                isCurrentEntityMoved
            );
        });
    }

    private async getGridEntity(grid: EntityItemOmniGridApi, entityId: string) {
        if (!grid || !entityId) {
            return;
        }
        return grid.getRowData(entityId);
    }

    private getControlHeaderName() {
        const attributeKey = this.viewTypeService.getPrimaryNameAttribute(
            this.serverType ?? ServerType.Property
        );
        return CollectionsHelper.getFromFirst(
            this.entityAttributes,
            (a) => a.AttributeKey == attributeKey,
            (a) => a.translatedDisplayName
        );
    }

    private setCurrentNameColumns() {
        if (this.egOptions.isSingleColumn) {
            return;
        }
        const currentViewType = this.viewTypeService.viewType;
        const otherViewType =
            currentViewType === ViewType.Functional
                ? ViewType.Technical
                : ViewType.Functional;
        const entityServerType = this.serverType ?? ServerType.Property;
        this.currentPrimaryName =
            this.egOptions.primaryName ??
            this.viewTypeService.getPrimaryNameAttribute(
                entityServerType,
                currentViewType
            );
        this.currentSecondaryName = this.egOptions.hasSecondaryName
            ? this.viewTypeService.getPrimaryNameAttribute(
                  entityServerType,
                  otherViewType
              )
            : null;
        this.log(
            'setCurrentNameColumns',
            this.currentPrimaryName,
            this.currentSecondaryName
        );
    }

    private areBothNamesVisible(columns: EntityGridColumnInfo[]) {
        return (
            this.currentSecondaryName != this.currentPrimaryName &&
            columns?.length > 1 &&
            CollectionsHelper.count(
                columns,
                (c) =>
                    c.visible &&
                    (c.attributeKey == this.currentPrimaryName ||
                        c.attributeKey == this.currentSecondaryName)
            ) > 1
        );
    }

    private hasBothNamesAttributes() {
        return (
            CollectionsHelper.count(
                this.entityAttributes,
                (a) =>
                    a.AttributeKey == this.currentPrimaryName ||
                    a.AttributeKey == this.currentSecondaryName
            ) > 1
        );
    }

    //#region for grid
    private async openEntityPane(entity: EntityItem) {
        this.log(
            'openEntityPane',
            entity,
            EntityGridRowClickAction[this.egOptions.rowClickAction]
        );
        if (
            this.egOptions.rowClickAction !=
            EntityGridRowClickAction.openEntityPreviewPanel
        ) {
            return;
        }
        this.entityPreviewPanelService.setupPanel({ entityIdr: entity });
    }

    //#endregion for grid

    //#region for list
    private async setCurrentOrSelected(entities: EntityItem[]) {
        if (!entities || !entities.length) {
            return;
        }
        this.log('setCurrentOrSelected', this.currentEntityId);
        if (!this.currentEntityId && !this.isMultiSelect) {
            // set the first received entity as the selected one
            await this.setCurrentEntityId(
                entities[0].ReferenceId,
                true,
                !this.egOptions.noNavToDetails
            );
        } else if (
            entities.some((e) => e.ReferenceId == this.currentEntityId) &&
            !this.isAddingEntity
        ) {
            // select the currentEntity that is now visible in the grid
            await this.setCurrentEntityAsSelected(this.isMultiSelect);
        }
    }

    private async setCurrentEntityId(
        entityId: string,
        setAsSelected = false,
        showEntityDetails = false
    ) {
        this.log(
            'setCurrentEntityId',
            entityId,
            setAsSelected,
            showEntityDetails
        );
        this.currentEntityId = entityId;
        if (setAsSelected) {
            await this.setCurrentEntityAsSelected(showEntityDetails);
        } else if (showEntityDetails && !this.isMultiSelect) {
            await this.showCurrentEntityDetails();
        } else {
            this.log('setCurrentEntityId-noop', entityId);
        }
    }

    private async setCurrentEntityAsSelected(showEntityDetails: boolean) {
        this.log(
            'setCurrentEntityAsSelected',
            showEntityDetails,
            this.currentEntityId
        );
        await this.withGrid((grid) =>
            grid.setRowSelected(this.currentEntityId)
        );
        await this.withGrid((grid) => grid.scrollToNode(this.currentEntityId));
        if (!showEntityDetails) {
            return;
        }
        if (this.isMultiSelect) {
            await this.showCurrentEntityPreview();
        } else {
            await this.showCurrentEntityDetails();
        }
    }

    private async showCurrentEntityDetails() {
        if (
            this.egOptions.isMultiSelect ||
            this.egOptions.rowClickAction == EntityGridRowClickAction.none
        ) {
            return;
        }

        this.log('showCurrentEntityDetails', this.currentEntityId);
        const entity = await this.withGrid((grid) =>
            this.getGridEntity(grid, this.currentEntityId)
        );
        this.showEntityDetails(entity);
    }

    private async showCurrentEntityPreview() {
        const entity = await this.withGrid((grid) =>
            this.getGridEntity(grid, this.currentEntityId)
        );
        if (!entity) {
            return;
        }
        this.entityPreviewPanelService.setupPanel({
            entityIdr: EntityIdentifier.fromIHasHddData(entity),
        });
    }

    private async showEntityDetails(entity: EntityItem) {
        this.log('showEntityDetails', entity);
        const egOptions = this.egOptions;
        if (
            !entity ||
            egOptions.rowClickAction == EntityGridRowClickAction.none
        ) {
            this.log(
                'showEntityDetails-noop',
                entity?.ReferenceId,
                EntityGridRowClickAction[egOptions.rowClickAction]
            );
            return;
        }

        if (this.navigationService.isCurrentEntity(entity)) {
            this.log('showEntityDetails-current-already', entity?.ReferenceId);
            return;
        }

        egOptions.onShowEntityDetailsCallBack?.(entity);

        this.isShowingEntityDetails = true;
        let goTo: () => Promise<StateObject>;
        const useIdentifier = DataUtil.isAvailableForDetailsPage(
            entity.ServerType
        );
        if (useIdentifier) {
            const opt: IGotoDetailsOptions = {
                modelId: HddUtil.getModelId(entity.HddData),
                entityObject: entity,
                isEntityFullPage: this.withEntityFullPage,
            };
            goTo = () =>
                this.navigationService.goToEntityDetailsByIdentifier(
                    entity,
                    opt
                );
        } else {
            const opt: IGotoWithHierarchicalData = {
                withEntityFullPage: this.withEntityFullPage,
            };
            goTo = () =>
                this.navigationService.goToWithHierarchicalData(
                    entity.HddData,
                    opt
                );
        }

        this.log(
            'before timeout for ',
            useIdentifier
                ? 'goToEntityDetailsByIdentifier'
                : 'goToWithHierarchicalData'
        );
        const res = await this.timeout(goTo, 111);
        this.log('after goto', res);
        await this.timeout(() => {
            this.isShowingEntityDetails = false;
        });
    }

    //#endregion for list

    private async withGrid<TResult>(
        action: (grid: EntityItemOmniGridApi) => TResult | Promise<TResult>
    ) {
        if (this.gridApi) {
            return Promise.resolve(action(this.gridApi));
        } else if (this.throwOnGridNotReady) {
            Promise.reject('grid not ready');
        } else {
            CoreUtil.warn('grid not ready');
            return Promise.resolve(null);
        }
    }

    private async navigateToAnalysis(data: IHasHddData) {
        if (this.navigationService.isInSearchResultsView) {
            this.functionalLogService.logFunctionalAction(
                'IMPACT_ANALYSIS_SEARCH_RESULTS',
                CrudOperation.R
            );
        }
        const navTo = this.breadcrumbService.getNavTo(true, null);
        await this.breadcrumbService.navigateByHierarchicalData(
            data.HddData,
            navTo,
            false
        );
    }

    private getAvailableActions() {
        const options: IActionOption<IHasHddData & IHasSecurityData>[] = [];
        const go = this.egOptions;

        if (go.actionButtonOpenAnalysis) {
            options.push({
                glyphClass: 'glyph-analysis',
                tooltipTranslateKey:
                    'UI.Search.resultActionTooltip.openAnalysis',
                callback: async (entity) =>
                    await this.navigateToAnalysis(entity),
                hidden: (data: IHasHddData) =>
                    !DataUtil.isAvailableResultItemLineageLink(data),
            });
        }

        if (go.actionButtonOpenEntityPane) {
            options.push({
                glyphClass: 'glyph-object-preview',
                tooltipTranslateKey: 'UI.Search.resultActionTooltip.openPane',
                callback: (data: EntityItem) => {
                    if (this.navigationService.isInSearchResultsView) {
                        // archi-EntityGridCore (rvi) previously in DgMiniObjectCellRenderer, move actions to another file
                        this.functionalLogService.logFunctionalAction(
                            'OBJECT_PREVIEW_SEARCH_RESULTS',
                            CrudOperation.R
                        );
                    }
                    this.entityPreviewPanelService.setupPanel({
                        entityIdr: EntityIdentifier.fromIHasHddData(data),
                    });
                },
                hidden: (data: IHasHddData) =>
                    !EntityPreviewPanelService.isPanelAvailable(
                        data?.HddData?.EntityType
                    ),
            });
        }

        this.configureBurgerMenu(options);

        if (go.optionalActions) {
            options.push(...go.optionalActions);
        }

        return options;
    }

    private async timeout<T>(
        fn?: () => T | Promise<T>,
        ms?: number
    ): Promise<T> {
        try {
            return await CoreUtil.startTimeout(fn, ms, this.ngZone);
        } catch (e) {
            CoreUtil.warn(e);
        }
    }

    private configureBurgerMenu(
        options: IActionOption<IHasHddData & IHasSecurityData>[] = []
    ) {
        const burgerMenuConfig = this.egOptions.burgerMenuConfiguration;
        if (
            burgerMenuConfig?.navigationButton ||
            burgerMenuConfig?.actionButton
        ) {
            const burgerMenu = this.entityGridBurgerMenuService.getBurgerMenu({
                logId: 'ENTITY_GRID',
                hideNavigationOptions: !burgerMenuConfig?.navigationButton,
                hideReadonlyActions: burgerMenuConfig?.hideReadonlyActions,
                readonly:
                    burgerMenuConfig?.readonly ||
                    !burgerMenuConfig?.actionButton,
                entityCreationOrigin: burgerMenuConfig?.entityCreationOrigin,
            });
            options.push(burgerMenu);
        }
    }
}
