import { DxyBaseModalComponent } from '@datagalaxy/ui/dialog';
import {
    AfterViewChecked,
    Component,
    Inject,
    OnInit,
    ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DxyFieldMultiSelectComponent } from '@datagalaxy/core-ui/fields';
import { IMultiSelectData } from '@datagalaxy/core-ui';
import { TeamService } from '../team.service';
import { UserService } from '../../services/user.service';
import { MultiSelectAdapter } from '../../shared/shared-ui/UiMultiSelect.util';
import {
    AddTeamMembersResult,
    TeamDto,
    TeamMemberDto,
} from '@datagalaxy/webclient/team/data-access';
import {
    CrudOperation,
    FunctionalLogService,
} from '@datagalaxy/webclient/monitoring/data-access';
import { UserPublicData } from '@datagalaxy/webclient/user/domain';

@Component({
    selector: 'app-team-add-member-modal',
    templateUrl: 'team-add-member-modal.component.html',
})
export class TeamAddMemberModalComponent
    extends DxyBaseModalComponent<
        IAddTeamMemberModalInput,
        AddTeamMembersResult
    >
    implements OnInit, AfterViewChecked
{
    @ViewChild(DxyFieldMultiSelectComponent)
    multiselectField: DxyFieldMultiSelectComponent<TeamMemberDto>;

    public multiSelectData: IMultiSelectData<UserPublicData>;
    public selectedMembers: UserPublicData[];
    public isLoading = false;

    public get canAdd() {
        return !!this.selectedMembers?.length;
    }

    private currentMembers: TeamMemberDto[];

    constructor(
        dialogRef: MatDialogRef<TeamAddMemberModalComponent>,
        @Inject(MAT_DIALOG_DATA) data: IAddTeamMemberModalInput,
        private teamService: TeamService,
        private userService: UserService,
        private functionalLogService: FunctionalLogService
    ) {
        super(dialogRef, data);
        this.currentMembers = data.currentMembers;
    }

    ngOnInit() {
        const items = this.userService
            .getUserList()
            .filter(
                (user) =>
                    !this.currentMembers.some(
                        (member) => member.ReferenceId == user.ReferenceId
                    )
            );

        this.multiSelectData = {
            items,
            adapter: MultiSelectAdapter.userPublicData,
            hasSearch: true,
        };
    }

    public async onCloseSubmit() {
        this.isLoading = true;
        const newMemberIds = this.multiSelectData.selectedItems.map(
            (memberItem) => memberItem.UserId
        );
        this.result = await this.teamService.addTeamMembers(
            this.data.team,
            newMemberIds
        );
        this.isLoading = false;
        super.onCloseSubmit();
    }

    public onCloseCancel() {
        this.functionalLogService.logFunctionalAction(
            'CANCEL_ADD_TEAM_MEMBER',
            CrudOperation.R
        );
        super.onCloseCancel();
    }
}

export interface IAddTeamMemberModalInput {
    currentMembers: TeamMemberDto[];
    team: TeamDto;
}
