export const amazonDynamoDbFieldInfo = {
    region: {
        hint: {
            fr: 'Code de la région AWS, exemple : eu-west-3',
            en: 'AWS region code, example: eu-west-3',
        },
    },
    accessKey: {
        hint: {
            fr: "Identifiant de la clé d'accès de votre compte AWS",
            en: 'AWS account access key identifier',
        },
    },
    secretKey: {
        hint: {
            fr: "Clé d'accès secrète de votre compte AWS ",
            en: 'AWS account access key secret',
        },
    },
    sampleSize: {
        hint: {
            fr: "Taille de l'échantillon de données utilisé afin pour déduire les schémas (100 par défaut)",
            en: 'Data sample size used in order to infer its schemas, default to 100.',
        },
    },
};
