<div class="asset" (click)="openPreviewPanel(asset.entity)">
    <mat-checkbox
        *ngIf="!disabledSelection"
        [(ngModel)]="asset.selected"
        (ngModelChange)="toggleSelection([asset], asset.selected)"
        (click)="$event.stopPropagation()"
    ></mat-checkbox>
    <div class="asset-content" [style.padding-left.px]="depth * 20">
        <button
            mat-icon-button
            *ngIf="asset?.children?.length"
            class="tree-toggle"
            (click)="onToggleClick($event)"
        >
            <i
                class="glyph"
                [class.glyph-arrow-drop-right]="!asset.opened"
                [class.glyph-arrow-drop-down]="asset.opened"
            ></i>
        </button>
        <app-entity-card-cell
            [class.leaf]="!asset?.children?.length"
            [params]="asset.params"
        ></app-entity-card-cell>
    </div>
</div>
<ng-container *ngIf="asset.opened">
    <app-asset-onstage-item
        *ngFor="let child of asset.children"
        [asset]="child"
        [depth]="depth + 1"
        [disabledSelection]="disabledSelection"
    >
    </app-asset-onstage-item>
</ng-container>
