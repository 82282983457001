import { FieldType } from '../../types/enums/field-type.enum';

export const amazonDynamoDbConfig = [
    { payloadField: 'type', payloadValue: 'MULTI' },
    { payloadField: 'multi-technology', payloadValue: 'amazondynamodb' },
    {
        payloadField: 'region',
        formField: 'region',
        formMandatory: true,
        type: FieldType.text,
    },
    {
        payloadField: 'access-key',
        formField: 'accessKey',
        formMandatory: true,
        type: FieldType.text,
    },
    {
        payloadField: 'secret-access-key',
        formField: 'secretKey',
        isPassword: true,
        formMandatory: true,
    },
    {
        payloadField: 'sample-size',
        formField: 'sampleSize',
        payloadValue: '100',
        formMandatory: true,
        type: FieldType.text,
    },
];
