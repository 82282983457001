import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { IActionOption } from '@datagalaxy/core-ui';
import { EntityDksNode } from '../../data-knowledge-studio.types';
import { HierarchicalData } from '@datagalaxy/dg-object-model';
import { DksEntitiesService } from '../../entities/dks-entities.service';
import { map, Observable } from 'rxjs';
import { DksEntityMenuService } from '../../menu/dks-entity-menu/dks-entity-menu.service';
import { EntityNodeTree } from './entity-node.types';
import { EntityNodeTreeToggleEvent } from '../../notifier/dks-notifier';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { GraphicalColor } from '@datagalaxy/shared/graphical/domain';

@Component({
    selector: 'app-entity-node',
    templateUrl: './entity-node.component.html',
    styleUrls: ['./entity-node.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityNodeComponent<NodeData>
    extends DxyBaseComponent
    implements OnInit, AfterViewInit
{
    @Input() node: EntityDksNode<NodeData>;

    @Output() nodeCollapse = new EventEmitter<
        EntityNodeTreeToggleEvent<NodeData>
    >();
    @Output() showMore = new EventEmitter<string>();

    @ViewChild('childComponent', { read: ViewContainerRef })
    private viewContainerRef?: ViewContainerRef;

    protected entityActions$: Observable<IActionOption[]>;
    protected hddData$: Observable<HierarchicalData>;
    protected entity$: Observable<EntityItem>;
    protected extraDataKeys$: Observable<string[]>;
    protected hasExtraData$: Observable<boolean>;

    protected get colorClass() {
        const color = this.node.color;
        if (!color) {
            return;
        }
        return `graphical-color-background-before ${GraphicalColor[color]}`;
    }

    protected get hasChildren() {
        return this.node.hasChildren || this.node.children?.length > 0;
    }

    protected get showChildren() {
        return this.hasChildren && this.node.opened;
    }

    constructor(
        private dksEntitiesService: DksEntitiesService,
        private menuService: DksEntityMenuService
    ) {
        super();
    }

    ngOnInit() {
        this.entity$ = this.dksEntitiesService.selectEntity(
            this.node.entityIdr
        );
        this.extraDataKeys$ = this.dksEntitiesService.selectEntityExtraDataKeys(
            this.node.entityIdr
        );
        this.hasExtraData$ = this.extraDataKeys$.pipe(
            map((keys) => !!keys?.length)
        );
        this.hddData$ = this.entity$.pipe(map((entity) => entity?.HddData));
        this.entityActions$ = this.menuService.menu$;
    }

    ngAfterViewInit() {
        this.initInnerComponent();
    }

    public onTreeCollapse(tree: EntityNodeTree) {
        this.nodeCollapse.emit({ node: this.node, nodeTree: tree });
    }

    public onNodeCollapse() {
        this.nodeCollapse.emit({ node: this.node });
    }

    private initInnerComponent() {
        const componentType = this.node.customChild?.component;
        if (!componentType) {
            return;
        }
        const componentRef =
            this.viewContainerRef?.createComponent(componentType);

        if (!componentRef || !this.node.customChild?.inputs) {
            return;
        }

        Object.entries(this.node.customChild.inputs).forEach(([key, value]) =>
            componentRef.setInput(key, value)
        );

        componentRef.changeDetectorRef.detectChanges();
    }
}
