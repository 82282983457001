import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConnectorService } from '../../connector.service';
import { TextAndToolsCellComponent } from '../../../shared/shared-ui/cells/text-and-tools-cell/text-and-tools-cell.component';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { GridCellType, GridConfig, TColDef } from '@datagalaxy/ui/grid';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'dxy-connection-form-field-list',
    templateUrl: './dxy-connection-form-field-list.component.html',
    styleUrls: ['./dxy-connection-form-field-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DxyConnectionFormFieldListComponent
    extends DxyBaseComponent
    implements OnInit
{
    @Input() values: string[] = [];
    @Input() fieldKey: string;
    @Input() titleTradKey: string;
    @Input() addBtnTranslateKey: string;
    @Output() onClickOpen = new EventEmitter<void>();

    protected gridConfig: GridConfig<string> = {
        getItemId: (row) => row,
        autoHeight: true,
    };

    protected cols: TColDef<string>[] = [];

    private rowsSubject = new BehaviorSubject<string[]>([]);
    protected rows$ = this.rowsSubject.asObservable();

    constructor(
        private translate: TranslateService,
        private connectorService: ConnectorService
    ) {
        super();
    }

    ngOnInit() {
        super.subscribe(this.connectorService.listFieldUpdateCalled$, () => {
            this.refreshRows();
        });
        this.initColumns();
    }

    private initColumns() {
        this.cols = [
            {
                id: this.fieldKey,
                headerLabel: this.translate.instant(this.titleTradKey),
                resizable: false,
                type: GridCellType.custom,
                customCellComponent: TextAndToolsCellComponent,
                getInputs: (row) =>
                    ({
                        text: row,
                        actions: [
                            {
                                glyphClass: 'glyph-delete',
                                callback: (value) => this.deleteValue(value),
                            },
                        ],
                        actionsData: row,
                    } as Partial<TextAndToolsCellComponent<string>>),
                sortable: true,
                getSortableValue: (row) => row,
            },
        ];
    }

    private deleteValue(value: string) {
        this.values.splice(this.values.indexOf(value), 1);
        this.refreshRows();
    }

    private refreshRows() {
        this.rowsSubject.next([...this.values]);
    }
}
