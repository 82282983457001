import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AttributeValueTranslation } from '@datagalaxy/webclient/multilingual/domain';

@Component({
    selector: 'dxy-translation-status',
    templateUrl: './translation-status.component.html',
    styleUrls: ['./translation-status.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TranslationStatusComponent {
    @Input() translation?: AttributeValueTranslation;

    protected get isKo() {
        return this.translation?.status === 'ko';
    }
}
