import { IBuildConfig } from '../build';
import { IAppConfig } from './app-config.types';
import { crowdinInContextLang, ILanguageConfig } from '../lang';
import { IConfig } from '../config.types';
import { CoreUtil } from '@datagalaxy/core-util';

/** Helper to process build-time configuration. Used at run-time from Angular */
export class AppConfig {
    private static _loading: Promise<IConfig>;

    /** true for default configuration only (ie local development environment) */
    public static get isLocalDev() {
        return !CoreUtil.isProduction;
    }

    /** All-in-one application configuration data, loaded at run-time */
    public static get config() {
        return AppConfig._config;
    }

    private static _config: IConfig;

    /** Downloads build-time config files, stores and returns the whole configuration */
    public static async load(
        logger?: (...args: any[]) => void
    ): Promise<IConfig> {
        const log = (...args: any[]) => {
            logger?.('AppConfig-load', ...args);
        };
        if (AppConfig._config) {
            log('already loaded');
            return AppConfig._config;
        }
        log('loading-start');
        const config = await (AppConfig._loading ??= AppConfig._load(log));

        log('loading-done');
        return config;
    }

    private static async _load(
        log: (...args: any[]) => void
    ): Promise<IConfig> {
        let languageConfig: ILanguageConfig;
        try {
            languageConfig = AppConfig.getLanguageConfig(
                [
                    'fr',
                    'en',
                    'nl',
                    'es',
                    'it',
                    'de',
                    'pt',
                    crowdinInContextLang,
                ],
                'fr'
            );
            log('languageConfig', languageConfig);
        } catch (err) {
            if (!CoreUtil.isProduction) {
                console.error('error getting languageConfig');
            }
            throw err;
        }

        let buildConfig: IBuildConfig;
        try {
            buildConfig = await AppConfig.getBuildConfig();
            log('buildConfig', buildConfig);
        } catch (err) {
            if (!CoreUtil.isProduction) {
                console.error('error getting buildConfig');
            }
            throw err;
        }

        let appConfig: IAppConfig;
        try {
            appConfig = await AppConfig.getAppConfig();
            log('appConfig', appConfig);
        } catch (err) {
            if (!CoreUtil.isProduction) {
                console.error('error getting appConfig');
            }
            throw err;
        }

        return (AppConfig._config = {
            appConfig,
            buildConfig,
            languageConfig,
            startup: {
                ANALYTICS_SEGMENT_ENABLED: !AppConfig.isLocalDev,
                COMPILE_DEBUGINFO_ENABLED: false,
                TRANSLATION_RANDOM_KEY: buildConfig.randomKey,
                language: languageConfig.startup,
            },
        });
    }

    public static async getLatestVersionNumber() {
        const config = await AppConfig.getAppConfig();
        return config?.versionNumber?.toString();
    }

    private static async getAppConfig() {
        const conf = await AppConfig.getJson<IAppConfig>(
            `config/app-config.json?${Date.now()}`
        );
        return AppConfig.injectLocalDevProperties(conf);
    }

    private static injectLocalDevProperties(configuration: IAppConfig) {
        // Inject local dev properties
        if (!CoreUtil.isProduction) {
            if (process.env['API_HOST']) {
                Object.assign(configuration, {
                    apiHost: process.env['API_HOST'],
                });
            }
            if (process.env['PUBLIC_API_URL']) {
                Object.assign(configuration, {
                    publicApiHost: process.env['PUBLIC_API_URL'],
                });
            }
            if (process.env['ENABLE_AUTH_V2']) {
                Object.assign(configuration, {
                    enableAuthV2: process.env['ENABLE_AUTH_V2'],
                });
            }
        }
        return configuration;
    }

    private static getBuildConfig() {
        return AppConfig.getJson<IBuildConfig>(
            `config/build-config.json?${Date.now()}`
        );
    }

    private static async getJson<T>(url: string): Promise<T> {
        const response = await fetch(url);
        return response.json();
    }

    /** Determines the language code to be used at startup, based on the user's browser */
    private static getLanguageConfig(
        languageCodes: string[],
        defaultStartupLanguage: string
    ): ILanguageConfig {
        const nl = navigator.language.toLowerCase(),
            nls = navigator.languages.map((l) => l?.toLowerCase()),
            startup =
                languageCodes.find((l) => nl.indexOf(l) == 0) ||
                languageCodes.find((l) =>
                    nls.some((nl) => nl?.indexOf(l) == 0)
                ) ||
                defaultStartupLanguage;

        return { codes: languageCodes, startup };
    }
}
